import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom"
import { useDropzone } from 'react-dropzone';
import lodash from 'lodash';
import { TournamentContext } from '../TournamentProvider'
import logo from "../../../images/logo.svg";
import '../../../styles/styles.css';
//import { IconNotification,IconAnnouncement,IconMembership,IconCourse,IconScore,IconTournament,IconCompletion,IconPlus,IconEdit,IconPencil,IconGolfBall,IconDelete,IconPlayer } from "../../Icons";
import { IconTournament } from "../../Icons";
import ManagementFooter from '../../ManagementFooter'
import { orijinaltournamentInitialState } from '../TournamentProvider'
import { inputCancell, lodaImageAndInputCancell } from '../TournamentUtility'


function Tournament_006(props) {
    const [value] = useContext(TournamentContext);
    const history = useHistory();
    const [tournament006Data] = useState(value.tournament006Data);                //グローバル参照渡し
    const [imageAreaTag] = useState({ id: undefined });
    const [previewList, setPreviewList] = useState([undefined, undefined, undefined]);
    const [tournamentPictureInputStyleList, setTournamentPictureInputStyleList] = useState([true, true, true]);
    const [imageFileList] = useState([undefined, undefined, undefined]);

    useEffect(() => {

        //エントリー金額が何もなかったら過去に入力された事なし
        if (tournament006Data.entry_amount.length === 0) return;
        //一番上にスクロール
        window.scrollTo(0, 0);

        //エントリー金額
        document.getElementById("entry_amount").value = tournament006Data.entry_amount;
        //グループの試合間隔
        document.getElementById("group_match_interval").value = tournament006Data.group_match_interval;

    }, [tournament006Data.entry_amount, tournament006Data.group_match_interval]);


    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length < 1) {
            window.alert('ファイルの形式が適切ではありません');
        } else {
            let imageData = window.URL.createObjectURL(acceptedFiles[0]);
            let indexNb = 0;
            switch (imageAreaTag.id) {
                case "tournament_picture_01_drop":
                    indexNb = 0;
                    document.getElementById("tournament_picture_01").required = false;
                    break;
                case "tournament_picture_02_drop":
                    indexNb = 1;
                    //                    document.getElementById("tournament_picture_02").required = false;
                    break;
                case "tournament_picture_03_drop":
                    indexNb = 2;
                    //                    document.getElementById("tournament_picture_03").required = false;
                    break;
                default:
                    break;
            }

            tournamentPictureInputStyleList[indexNb] = false;
            previewList[indexNb] = imageData;
            imageFileList[indexNb] = acceptedFiles[0];


            setTournamentPictureInputStyleList([...tournamentPictureInputStyleList]);
            setPreviewList([...previewList]);
        }
        /*
        acceptedFiles.forEach((element,index) => {
            saveImage("qqq/",imageAreaTag.id+index,element,(result,data)=>{
                if(result===true){
                    window.alert('保存完了');
                }
            });
                
        });
*/

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previewList]);

    // eslint-disable-next-line no-unused-vars
    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        accept: "image/*",
        onDrop,
        maxSize: 932 * 257,
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true

    });

    const onSubmit = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();
        const { entry_amount, group_match_interval } = e.target.elements;

        if (imageFileList[1] === undefined && imageFileList[2] !== undefined) {
            alert('2番目の画像が選択されていません');
        } else {
            value.tournament006Data.entry_amount = entry_amount.value;    //エントリー金額を入力してください
            value.tournament006Data.group_match_interval = group_match_interval.value; //グループの試合間隔
            value.tournament006Data.tournament_picture = previewList;  //メイン画像データ
            value.tournament006Data.tournament_picture_file = imageFileList;    //firebaseにデータを保存するためにファイルオブジェクト
            history.push("Tournament_007");
        }
    }

    const onClickDelete = (e) => {
        //バリデーションが実行されないように
        e.preventDefault();
        let indexNb = 0;
        switch (e.target.id) {
            case "button_cancel_upload_01":
                indexNb = 0;
                inputCancell("tournament_picture_01");
                document.getElementById("tournament_picture_01").required = true;
                break;
            case "button_cancel_upload_02":
                indexNb = 1;
                inputCancell("tournament_picture_02");
                //                document.getElementById("tournament_picture_02").required = true;
                break;
            case "button_cancel_upload_03":
                indexNb = 2;
                inputCancell("tournament_picture_03");
                //                document.getElementById("tournament_picture_03").required = true;
                break;
            default:
                break;
        }
        tournamentPictureInputStyleList[indexNb] = true;
        previewList[indexNb] = undefined;
        imageFileList[indexNb] = undefined;

        setTournamentPictureInputStyleList([...tournamentPictureInputStyleList]);
        setPreviewList([...previewList]);



    }

    const onChangeInput = (e) => {
        let fileName = e.target.files[0];
        if (fileName.size > 1048576) {
            window.alert('ファイルサイズが１Mを超えています');
            inputCancell(e.target.id);
            return;
        }

        let indexNb = 0;
        switch (e.target.id) {
            case "tournament_picture_01":
                indexNb = 0;
                break;
            case "tournament_picture_02":
                indexNb = 1;
                break;
            case "tournament_picture_03":
                indexNb = 2;
                break;
            default:
                break;
        }

        lodaImageAndInputCancell(fileName, e.target.id, (currentSrc) => {
            previewList[indexNb] = currentSrc;
            tournamentPictureInputStyleList[indexNb] = false;
            setTournamentPictureInputStyleList([...tournamentPictureInputStyleList]);
            setPreviewList([...previewList]);

        });

        imageFileList[indexNb] = fileName;
    }

    const onClickButtonBack = (e) => {
        e.preventDefault();
        history.push("Tournament_002");
    }

    return (
        <div className="c-screen c-screen--gunmetal">
            <header className="c-container-vertical c-mt56">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt32">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconTournament></IconTournament>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">新規技能テスト追加画面</h1>
                </div>
            </header>

            <main>

                <div className="c-page-section c-mt32">
                    <form onSubmit={onSubmit}>
                        <div className="kanri-container">
                            <div className="input input__set">
                                <label htmlFor="given-name-kana" className="input input__label">エントリー金額を入力してください</label>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <input type="number" name="given-name-kana" id="entry_amount" placeholder="" required className="input input__field input__field--extend" />
                                    <span className="input__suffix c-paragraph">円</span>
                                </div>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="given-name-kana" className="input input__label">グループの試合間隔を入力してください</label>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <input type="number" min="0" max="99" name="given-name-kana" id="group_match_interval" placeholder="" required className="input input__field input__field--extend" />
                                    <span className="input__suffix input__suffix--88 c-paragraph">分間隔</span>
                                </div>
                            </div>

                            <h2 className="c-heading--2 c-heading--left c-mt56">メイン画像をアップロードしてください。</h2>

                            <p className="c-subtitle c-subtitle--left c-mt8">※最大3つまで指定できます。</p>
                            <p className="c-subtitle c-subtitle--left">※画像サイズは1MBまででお願いします。</p>
                            <p className="c-subtitle c-subtitle--left">※縦横比は932px×257pxでお願いします。</p>

                            <fieldset id="tournament_picture">

                                <div className="c-container-vertical c-container-vertical--center c-drag-and-drop c-mt32" id="tournament_picture_01_drop" {...getRootProps(
                                    {
                                        onDrop: event => {
                                            imageAreaTag.id = event.currentTarget.id;
                                        }
                                    }
                                )}>

                                    <p> ここに画像をドラッグアンドドロップ</p>
                                    <input {...getInputProps()} />
                                    <input type="file" name="tournament_picture_01" id="tournament_picture_01" accept="image/*" capture="camera" className="c-mt56" required onChange={(e) => { onChangeInput(e) }} style={{ display: tournamentPictureInputStyleList[0] ? '' : 'none' }} />
                                    <img id="tournament_picture_01_Image" className="c-mt56" src={previewList[0]} alt="" />
                                    <button id="button_cancel_upload_01" onClick={onClickDelete} className="button button__tertiary">アップロードした画像を削除</button>
                                </div>

                                <div className="c-container-vertical c-container-vertical--center c-drag-and-drop c-mt32" id="tournament_picture_02_drop" {...getRootProps(
                                    {
                                        onDrop: event => {
                                            imageAreaTag.id = event.currentTarget.id;
                                        }
                                    }
                                )}>


                                    <p> ここに画像をドラッグアンドドロップ</p>
                                    <input {...getInputProps()} />
                                    <input type="file" name="tournament_picture_02" id="tournament_picture_02" accept="image/*" capture="camera" className="c-mt56" onChange={(e) => { onChangeInput(e) }} style={{ display: tournamentPictureInputStyleList[1] ? '' : 'none' }} />
                                    <img id="tournament_picture_02_Image" className="c-mt56" src={previewList[1]} alt="" />
                                    <button id="button_cancel_upload_02" onClick={onClickDelete} className="button button__tertiary">アップロードした画像を削除</button>
                                </div>

                                <div className="c-container-vertical c-container-vertical--center c-drag-and-drop c-mt32" id="tournament_picture_03_drop" {...getRootProps(
                                    {
                                        onDrop: event => {
                                            imageAreaTag.id = event.currentTarget.id;
                                        }
                                    }
                                )}>
                                    <p> ここに画像をドラッグアンドドロップ</p>
                                    <input {...getInputProps()} />
                                    <input type="file" name="tournament_picture_03" id="tournament_picture_03" accept="image/*" capture="camera" className="c-mt56" onChange={(e) => { onChangeInput(e) }} style={{ display: tournamentPictureInputStyleList[2] ? '' : 'none' }} />
                                    <img id="tournament_picture_03_Image" className="c-mt56" src={previewList[2]} alt="" />
                                    <button id="button_cancel_upload_03" onClick={onClickDelete} className="button button__tertiary">アップロードした画像を削除</button>
                                </div>

                            </fieldset>


                        </div>

                        <div className="c-button-set c-mt56 c-mb88">
                            <button id="button_next" className="button button__primary">次へ</button>
                            <button id="button_back" className="button button__secondary" onClick={(e) => { onClickButtonBack(e) }}>戻る</button>
                        </div>
                    </form>

                </div>

            </main>


            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default Tournament_006

