import React, { useEffect, useState } from 'react';
import CourseEditStep2GreenAndYardComponent from './CourseEditStep2GreenAndYardComponent'
import '../../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";

function CourseEditStep2TeePositionComponent(props) {
    const [teeData, setTeeData] = useState(props.nowTeeData);
    const [greenList, setgreenList] = useState(props.nowTeeData.greenList);
    useEffect(() => {

    }, []);

    return (
        <div className="c-container-horizontal">    {/*<!-- 「ティー位置名」コンテナ -->*/}

            {/*<!-- 「ティー位置名」内容 -->*/}
            <div className="game-data__column game-data__column--144">
                <span className="game-data__slot">{teeData.name}</span>
            </div>

            {/*<!-- 「グリーン名」と「YARD」コンテナ -->*/}
            <div className="c-container-vertical">
                {/*動的にコンポーネントが増えていきます*/}
                {greenList.map(greenData => (
                    <CourseEditStep2GreenAndYardComponent
                        key={greenData.key}
                        nowGreenData={greenData}
                    />
                ))}
            </div>{/*<!-- 「グリーン名」と「YARD」コンテナ -->*/}
            {/*<!-- 「ティー位置名」コンテナ -->*/}
        </div>
    )
}


export default CourseEditStep2TeePositionComponent

