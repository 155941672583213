import React, { useEffect, useState } from 'react';

import '../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";


function CourseStep4GreenAndYardComponent(props) {
    const [greenData, setGreenData] = useState(props.nowGreenData);
    const [className, setClassName] = useState("game-data__slot");
    useEffect(() => {
        if(greenData.yard.diff===1){
            setClassName("game-data__slot game-data__slot--confirm");
        }
    }, []);
    return (
        <div className="c-container-horizontal">  {/*<!-- 「グリーン名」と「YARD」内容グルプ -->*/}

            {/*<!-- 「グリーン名」内容 -->*/}
            <div className="game-data__column game-data__column--144">
                <span className="game-data__slot">{greenData.name}</span>
            </div>

            {/*<!-- 「YARD」内容 -->*/}
            <div className="game-data__column">
                <span className={className}>{greenData.yard.yard}</span>
            </div>

        </div>

    )
}



export default CourseStep4GreenAndYardComponent

