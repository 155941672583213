import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { CourseContext } from '../../CourseProvider'
import '../../../../styles/styles.css';
import { getCourseList } from "../../../../Database";
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconPlus } from "../../../Icons";
import CourseEditHeader from '../CourseEditHeader'
import CourseEditStep2ColumnTitleContainer from './CourseEditStep2ColumnTitleContainer'
import CourseEditStep2CourseContainer from './CourseEditStep2CourseContainer'

import { makeCourseArrayCourseList, copyStep3FromStep4Data } from '../../CourseUtility'

function CourseEditStep2(props) {
    const [value, setValue] = useContext(CourseContext);
    const history = useHistory();
    const [arrayCourseList, setArrayCourseList] = useState([]);
    useEffect(() => {
        //コース追加データフォーマットのStep2データを元にコース追加データフォーマットStep3のarrayCourseListを作成
        value.CourseAddStep3.arrayCourseList = makeCourseArrayCourseList(value);
        setArrayCourseList(value.CourseAddStep3.arrayCourseList);
        //次のステップでデータを比較したいために現状のデータを次のステップ用ワークにコピー
        copyStep3FromStep4Data(value);
    }, []);

    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "back":
                history.push("CourseEditStep1");
                break;
            case "next":
                history.push("CourseEditStep3");
                break;
            default:
                break;
        }
    };

    return (
        <div className="c-screen c-screen--gunmetal">
            <CourseEditHeader></CourseEditHeader>
            <main>
                <section className="c-page-section c-mt16">
                    <h2 className="c-heading--2 c-heading--center c-mt32">ホール名、規定打数、Yard数をいれてください。</h2>
                    {/*<!-- メインコンテナ -->*/}
                    <div className="c-container-vertical c-mt16">

                        {/*動的にコンポーネントが増えていきます*/}
                        {
                            arrayCourseList.map(
                                courseAddItem =>
                                (
                                    <React.Fragment key={courseAddItem.key + "_React.Fragment"}>
                                        <CourseEditStep2ColumnTitleContainer key={courseAddItem.key + "_CourseEditStep2ColumnTitleContainer"}></CourseEditStep2ColumnTitleContainer>
                                        <CourseEditStep2CourseContainer key={courseAddItem.key} nowCourse={courseAddItem} ></CourseEditStep2CourseContainer>
                                    </React.Fragment>
                                )
                            )

                        }

                    </div> {/*<!-- メインコンテナ -->*/}
                    <div id="add-course-button" className="c-button-set c-mt32 c-mb88">
                        <button type="submit" id="next" onClick={(e) => { onButtonClick(e) }} className="button button__primary">次へ</button>
                        <button id="back" onClick={(e) => { onButtonClick(e) }} className="button button__secondary">戻る</button>
                    </div>
                </section>
            </main>
        </div>
    )
}



export default CourseEditStep2

