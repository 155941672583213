import React, { useEffect, useState, useRef } from 'react';
//import { useHistory } from "react-router-dom"
//import { TournamentContext } from '../TournamentProvider'
import '../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
//import { IconScore, IconPlus, IconEdit } from "../../Icons";

function Tournament011Container(props) {
    const initCourseData = {
        key: "",
        courseName: "",
        teeData: { key: "t", name: "" },
        greenData: { key: "g", name: "" },
    }
    //    const [value, setValue] = useContext(TournamentContext);
    //    const history = useHistory();
    const [courseData, setCourseData] = useState(initCourseData);       //コースコンテナ作成するためのデータ

    const teeSelectRef = useRef(null);
    const greenSelectRef = useRef(null);

    //props.courseDataデータが変化を起こした場合に来る
    useEffect(() => {
        if (props.courseData !== undefined) {

            let select_options = document.getElementById("tee_course_000"+ courseData.key).options;
            select_options[0].selected = true;
            select_options = document.getElementById("green_course_000"+ courseData.key).options;
            select_options[0].selected = true;

            //再描画
            setCourseData(props.courseData);
        }
    }, [props.courseData]);


    useEffect(() => {
    }, []);

    return (
        <React.Fragment>
            {/*<!-- コース一つ。 -->*/}
            {/*<!-- 確認画面に「course-select__group」クラス名は「course-select__confirm」に変更です。 -->*/}
            <div className="c-container-vertical c-radius--16 c-padding--16 c-mt24 course-select__confirm">
                <label htmlFor={"select_course_000" + courseData.key} className="c-heading--3 course-select__label">
                    {courseData.courseName}
                    <input type="checkbox" name={"select_course_000" + courseData.key} id={"select_course_000" + courseData.key} className="course-select__checkbox" disabled checked />
                </label>
                {/*<!-- コースティーを選択 -->*/}
                <label htmlFor={"tee_course_000" + courseData.key} className="input input__label">利用するティ</label>
                <select name={"tee_course_000" + courseData.key} id={"tee_course_000" + courseData.key} disabled className="input input__field input__field--minimal course-select__list" ref={teeSelectRef} >
                    <option key={courseData.teeData.key} value={courseData.teeData.name}>{courseData.teeData.name}</option>)

                </select>
                {/*<!-- グリーンを選択 -->*/}
                <label htmlFor={"green_course_000" + courseData.key} className="input input__label">利用するグリーン</label>
                <select name={"green_course_000" + courseData.key} id={"green_course_000" + courseData.key} disabled className="input input__field input__field--minimal course-select__list" ref={greenSelectRef} >
                    <option key={courseData.greenData.key} value={courseData.greenData.name}>{courseData.greenData.name}</option>)
                </select>

            </div>
        </React.Fragment>
    )
}


export default Tournament011Container

