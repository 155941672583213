import React, { useEffect, useState } from "react";

// contextの作成
export const AnnouncementContext = React.createContext([
    {input_title:"",
    send_notification:""
    }
]);
export const AnnouncementProvider = ({ children }) => {
    const [value, setValue] = useState({
        input_title: "",
        send_notification: ""
      });    
    useEffect(() => {
//        console.log("AnnouncementProvider");
      }, []);    
    return (
        // Contextを使用して認証に必要な情報をコンポーネントツリーに流し込む。
        <AnnouncementContext.Provider
            value={
                    [value, setValue]
                 }
        >
          {children}
        </AnnouncementContext.Provider>
      );    
}
