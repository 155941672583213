import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { TournamentContext } from '../TournamentProvider'
import ManagementFooter from '../../ManagementFooter'
import { getCourse } from "../../../Database";
import Tournament014Container from './Tournament014Container'
import logo from "../../../images/logo.svg";
import { IconTournament } from "../../Icons";
import '../../../styles/styles.css';
import { } from '../../../Storage'
import { onSubmitCommon11_14 } from "../TournamentUtility";
import Loader from '../../Loader'

function Tournament_014(props) {
    const [value] = useContext(TournamentContext);
    const [tournament013Data] = useState(value.tournament013Data);                //グローバル参照渡し
    const [previewList, setPreviewList] = useState([undefined, undefined, undefined]);

    const history = useHistory();
    const [loadingData, setLoadingData] = useState(undefined);

    useEffect(() => {
        //一番上にスクロール//
        window.scrollTo(0, 0);
        //大会名
        document.getElementById("tournament_title").innerText = tournament013Data.title;
        //カテゴリー
        document.getElementById("ournament_category").innerText = tournament013Data.category;
        //開催する地域
        document.getElementById("ournament_area").innerText = tournament013Data.area;

        //開催日時と終了日時
        let eventDate = tournament013Data.eventDate.split("-");
        let eventStartTime = tournament013Data.eventStartTime.split("-");
        let eventFinishDate = tournament013Data.eventFinishDate.split("-");
        let eventFinishTime = tournament013Data.eventFinishTime.split("-");
        //開催日時と終了日時
        let tournament_open_close_date_time = eventDate[0] + "年" + eventDate[1] + "月" + eventDate[2] + "日" + eventStartTime[0] + "分~" +
            eventFinishDate[0] + "年" + eventFinishDate[1] + "月" + eventFinishDate[2] + "日" + eventFinishTime[0] + "分";

        document.getElementById("tournament_open_close_date_time").innerText = tournament_open_close_date_time;

        //最大エントリー数
        document.getElementById("tournament_max_entry").innerText = tournament013Data.max_entry;

        //利用するゴルフ場
        //データベースからコースデータの読み込み
        const getCourseData = (async () => {
            let loadCourse = await getCourse(tournament013Data.golfCourseDocId);
            document.getElementById("tournament_club").innerText = loadCourse.club_name;
        });
        getCourseData();

        //エントリー金額
        document.getElementById("entry_amount").innerText = tournament013Data.entry_amount;
        //グループの試合間隔
        document.getElementById("group_match_interval").innerText = tournament013Data.group_match_interval;

        //画像プレビュー
        setPreviewList([...tournament013Data.tournament_picture]);


    }, [tournament013Data.area, tournament013Data.category, tournament013Data.entry_amount, tournament013Data.eventDate, tournament013Data.eventFinishDate, tournament013Data.eventFinishTime, tournament013Data.eventStartTime, tournament013Data.first_place, tournament013Data.fourth_place, tournament013Data.golfCourseDocId, tournament013Data.group_match_interval, tournament013Data.second_place, tournament013Data.special_prize, tournament013Data.third_place, tournament013Data.title, tournament013Data.tournament_picture]);

    const onSubmit = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();
        const asyncfunc = (async () => {
            let tournamentData = lodash.cloneDeep({ ...tournament013Data });
            let tournament_picture_file = lodash.cloneDeep(tournamentData.tournament_picture_file);

            onSubmitCommon11_14(document, tournamentData, tournament_picture_file, (result, status) => {
                //ローディング表示
                setLoadingData({ loadingFlg: true, title: "技能テストデータ変更中..." });
                if (result === false) {
                    alert("保存エラー=" + status);
                } else {
                    history.push("Tournament_015");
                }
            });
        });
        asyncfunc();
        //ローディング表示
        setLoadingData({ loadingFlg: true, title: "技能テストデータ変更中..." });
    }


    const onClick = (e) => {

        switch (e.currentTarget.id) {
            case "button_back":
                history.push("/Tournament_013");
                break;
            default:
                break;
        }

    }
    const renderUseCourseList = () => {
        if (tournament013Data.useCourseList !== undefined) {
            return tournament013Data.useCourseList.map((data, i) =>
                <Tournament014Container key={data.key} courseData={data}>
                </Tournament014Container>)
        } else {
            return null;
        }
    }
    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <header className="c-container-vertical c-mt56">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt32">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconTournament></IconTournament>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">技能テスト内容変更確認画面</h1>
                </div>
            </header>

            <main>

                <div className="c-page-section c-mt32">
                    <form onSubmit={onSubmit}>
                        <div className="kanri-container">

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">大会タイトル</span>
                                <p className="confirm confirm__field" id="tournament_title" >第2回ツアー</p>
                            </div>

                            <div className="confirm confirm__vertical c-mt56">
                                <span className="confirm confirm__label">開催する大会のカテゴリ</span>
                                <p className="confirm confirm__field" id="ournament_category" >男子</p>
                            </div>

                            <div className="confirm confirm__vertical c-mt56">
                                <span className="confirm confirm__label">開催する地域</span>
                                <p className="confirm confirm__field" id="ournament_area" >関東</p>
                            </div>

                            <div className="confirm confirm__vertical c-mt56">
                                <span className="confirm confirm__label">開催日時と終了日時</span>
                                <p className="confirm confirm__field" id="tournament_open_close_date_time" >2021年6月13日10時~2021年6月14日19時</p>
                            </div>

                            <div className="confirm confirm__vertical c-mt56">
                                <span className="confirm confirm__label">利用するゴルフ場</span>
                                <p className="confirm confirm__field" id="tournament_club" >大多喜カントリークラブ</p>
                            </div>

                            <div className="confirm confirm__vertical c-mt56">
                                <span className="confirm confirm__label">最大エントリー数</span>
                                <p className="confirm confirm__field" id="tournament_max_entry" >20</p>
                            </div>

                            {/*<!-- コースを選択エリア -->*/}
                            <div className="c-container-vertical c-container-vertical--pale-spring-bud c-padding--24 c-radius--16 c-mt56">

                                <h2 className="c-heading--2 c-heading--left">利用コース</h2>
                                <p className="c-paragraph c-paragraph--left">利用するコースを選択してください。</p>
                                <div className="c-container-horizontal c-container-horizontal--space-around c-container-horizontal--wrap">
                                    {renderUseCourseList()}
                                </div>
                            </div>

                            <div className="confirm confirm__vertical c-mt56">
                                <span className="input input__label">エントリー金額</span>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <p id="entry_amount" className="confirm confirm__field confirm__field--extend">1万</p>
                                    <span className="input__suffix c-paragraph">　</span>
                                </div>
                            </div>

                            <div className="confirm confirm__vertical c-mt56">
                                <span className="input input__label">グループの試合間隔</span>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <p id="group_match_interval" className="confirm confirm__field confirm__field--extend">5</p>
                                    <span className="input__suffix c-paragraph">分</span>
                                </div>
                            </div>

                            <h2 className="c-heading--2 c-heading--left c-mt56">メイン画像</h2>

                            <div className="c-container-vertical c-mt32">
                                <p className="c-paragraph c-paragraph--left">画像１</p>
                                <figure className="c-drag-and-drop__preview c-mt16">
                                    <img src={previewList[0]} id="tournament_picture_01" alt="" />
                                </figure>
                            </div>

                            <div className="c-container-vertical c-mt32">
                                <p className="c-paragraph c-paragraph--left">画像2</p>
                                <figure className="c-drag-and-drop__preview c-mt16">
                                    <img src={previewList[1]} id="tournament_picture_02" alt="" />
                                </figure>
                            </div>

                            <div className="c-container-vertical c-mt32">
                                <p className="c-paragraph c-paragraph--left">画像3</p>
                                <figure className="c-drag-and-drop__preview c-mt16">
                                    <img src={previewList[2]} id="tournament_picture_03" alt="" />
                                </figure>
                            </div>

                            <h2 className="c-heading--2 c-mt56">上記内容で作成します。よろしいですか?</h2>



                        </div>

                        <div className="c-button-set c-mt32 c-mb88">
                            <button id="button_next" className="button button__primary">変更する</button>
                            <button id="button_back" onClick={(e) => { onClick(e) }} className="button button__secondary">戻る</button>
                        </div>
                    </form>
                </div>

            </main>

            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>



        </div>
    )
}



export default Tournament_014

