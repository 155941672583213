import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { TournamentContext, orijinaltournamentInitialState } from '../TournamentProvider'
import ManagementFooter from '../../ManagementFooter'
import logo from "../../../images/logo.svg";
import { IconTournament } from "../../Icons";
import '../../../styles/styles.css';
import { delTournament } from "../TournamentUtility";
import '../../../styles/loader.css'
import Loader from '../../Loader'
function Tournament_022(props) {
    const [value] = useContext(TournamentContext);
    const history = useHistory();

    const [tournament009Data] = useState(value.tournament009Data);                //グローバル参照渡し
    const [tournament022Data] = useState(value.tournament009Data.tournamentData);                //グローバル参照渡し
    const [loadingData, setLoadingData] = useState(undefined);
    useEffect(() => {
        if (tournament022Data === undefined) return;
        document.getElementById("tournament_name").innerText = tournament022Data.eventDate + " " + tournament022Data.title + " " + tournament022Data.category + " " + tournament022Data.area;

    }, [tournament009Data, tournament022Data]);

    const onClick = (e) => {

        switch (e.currentTarget.id) {
            case "button_back":
                //設定をクリア
                value.tournament009Data = lodash.cloneDeep(orijinaltournamentInitialState.tournament009Data);
                value.tournament022Data = lodash.cloneDeep(orijinaltournamentInitialState.tournament010Data);
                history.push("/Tournament_009");
                break;
            //削除
            case "button_delete":
                document.getElementById("button_delete").disabled = true;
                document.getElementById("button_back").disabled = true;
                
                const asyncfunc = (async () => {
                    await delTournament(tournament009Data.tournamentData, (result, status) => {
                        setLoadingData({ loadingFlg: false, title: "削除中・・・" });   //ローデング非表示
                        if (result === true) {
                            history.push("/Tournament_023");
                        } else {
                            alert("削除失敗=" + status);
                            document.getElementById("button_delete").disabled = false;
                            document.getElementById("button_back").disabled = false;
                        }

                    });
                });
                setLoadingData({ loadingFlg: true, title: "削除中・・・" });   //ローデング表示
                asyncfunc();
                break;
            default:
                break;
        }

    }


    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <header className="c-container-vertical c-mt88">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconTournament></IconTournament>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">大会削除確認</h1>
                </div>
            </header>

            <main>

                <section className="kanri-container">
                    <h2 className="c-heading--2 c-mt72">以下の大会を削除します。</h2>

                    <div className="c-container-vertical c-mt32">
                        <div className="confirm confirm__vertical">
                            <span className="confirm confirm__label">大会名</span>
                            <p className="confirm confirm__field c-paragraph" id="tournament_name">第1回JCG男子ツアー 関東会場</p>
                        </div>
                    </div>

                    <div className="c-button-set c-mb88">
                        <p className="c-paragraph c-paragraph--center c-mt32">一度削除すると復旧できません。よろしいですか？</p>
                        <button id="button_delete" onClick={(e) => { onClick(e) }} className="button button__primary button__primary--red">削除する</button>
                        <button id="button_back" onClick={(e) => { onClick(e) }} className="button button__secondary">戻る</button>
                    </div>

                </section>

            </main>


            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default Tournament_022

