import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom"
import logo from "../../images/logo.svg";
import '../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconMembership, IconCompletion } from "../Icons";

function MembershipCancelCompleted(props) {
    const history = useHistory();
    useEffect(() => {
        //u
    }, []);

    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "back_menu":
                history.push("/");
                break;
            default:
                break;
        }

    };
    return (
        <div className="c-screen c-screen--gunmetal">
            <header className="c-container-vertical c-mt88">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconMembership></IconMembership>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">出場資格抹消完了画面</h1>
                </div>
            </header>
            <main>

                <div className="c-vertical-container c-mt56">
                    <figure className="c-icon c-icon--acid-green c-icon--88 animation--zoomIn">
                        <IconCompletion></IconCompletion>
                    </figure>
                </div>

                <p className="c-paragraph c-paragraph--center c-mt32"> 出場資格の抹消が完了しました。</p>

                <div className="c-button-set c-mt56 c-mb88">
                    <button id="back_menu" onClick={(e)=>{onButtonClick(e)}} className="button button__primary">メインへ戻る</button>
                </div>

            </main>
        </div>
    )
}



export default MembershipCancelCompleted

