import React, { useEffect, useState } from "react";
import lodash from 'lodash';
export const originalInitialState = {
  CourseAddStep1: {
    club_name: "",
    club_address: "",
    club_phone: "",
    club_website: "",
    version: "1.0"
  },
  CourseAddStep2: {
    arrayCourseList: [
      {
        key: "course_id_0",
        courseName: "",
        arrayTeeList: [],
        arrayGreenList: [],
        numberOfholes: -1,
        //コースのバリデーションフラグ
        validation: {
          validationName: false,
          validationHole: false,
          validationTee: false,
          validationGreen: false
        }
      }
    ],
  },
  CourseAddStep3: {
    arrayCourseList: [
  
    ],
    
  },
  CourseAddStep4: {
    arrayCourseList: [
  
    ],
  },
};

export const initialState=lodash.cloneDeep(originalInitialState);

// contextの作成
export const CourseContext = React.createContext(initialState);


export const CourseProvider = ({ children }) => {
  const [value, setValue] = useState(initialState);
  useEffect(() => {
//    console.log("CourseProvider");
  }, []);

  return (
    // Contextを使用して認証に必要な情報をコンポーネントツリーに流し込む。
    <CourseContext.Provider
      value={
        [value, setValue]
      }
    >
      {children}
    </CourseContext.Provider>
  );
}




