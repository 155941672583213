import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { ScoreContext } from './ScoreProvider'
import ManagementFooter from '../ManagementFooter'
import logo from "../../images/logo.svg";
import { IconScore } from "../Icons";
import '../../styles/styles.css';
import { setTotalUserAtBat, checkScoreInput, setUserDataToScoreDataAndEntryData, checkScoreConfirmation, setRanking } from "./ScoreUtility";
import { setFixFlgTournament } from "../../Database";
import '../../styles/loader.css'
import Loader from '../Loader'
import Score0012Accordion from './Score_001_2_Accordion'

function Score_001_2(props) {
    const [value] = useContext(ScoreContext);
    const history = useHistory();

    const [score_001_2_Data] = useState(value.score_001_2_Data);                //グローバル参照渡し
    const [loadingData, setLoadingData] = useState(undefined);

    const [allUserScoreList, setAllUserScoreList] = useState([]);
    //------------------------------------------
    //スコア確定ボタンの活性または非活性を判定する
    const checkButtonInactivity = (async (e) => {
        await checkScoreInput(allUserScoreList, (result, status) => {
            if (result === true) {
                //選択ボタン非活性
                document.getElementById("button_decide").disabled = true;
                setAllUserScoreList([...setRanking(score_001_2_Data.tournamentData.scoreDataList, score_001_2_Data.tournamentData)]);

            } else {
                //選択ボタン活性
                document.getElementById("button_decide").disabled = false;
                setAllUserScoreList([...setRanking(score_001_2_Data.tournamentData.scoreDataList, score_001_2_Data.tournamentData)]);
            }
        });

        //大会が終了していても非活性
        if (score_001_2_Data.tournamentData.fix_flg !== 0) {
            document.getElementById("button_decide").disabled = true;
        }
        //大会終了日以前でも非活性
        const date1 = new Date();
        let nowTime = date1.getTime();

        if (score_001_2_Data.tournamentData.finishMillisecond > nowTime) {
            document.getElementById("button_decide").disabled = true;
        }

    });
    //------------------------------------------


    useEffect(() => {
        if (allUserScoreList.length !== 0) return;
        //データベースからコースデータの読み込み
        const asyncFunction = (async () => {

            await setUserDataToScoreDataAndEntryData(score_001_2_Data.tournamentData.scoreDataList, (result, status) => {
                if (result === false) {
                    alert("スコアデータの読み込みにに失敗しました=" + status);
                } else {
                    setAllUserScoreList(setRanking(score_001_2_Data.tournamentData.scoreDataList, score_001_2_Data.tournamentData));
                }

            });

            setLoadingData({ loadingFlg: false, title: "検索中・・・" });   //ローデング非表示

        });

        setLoadingData({ loadingFlg: true, title: "検索中・・・" });   //ローデング表示
        asyncFunction();


    }, [allUserScoreList.length, score_001_2_Data.tournamentData]);

    useEffect(() => {
        //まだ読み込みされていなかったら判定はしない
        if (allUserScoreList.length === 0) return;
        const asyncFunction = (async () => {
            await checkButtonInactivity(undefined);
        });
        asyncFunction();
        //トーナメントタイトル
        document.getElementById("tournament_title").innerText = score_001_2_Data.tournamentData.eventDate + " " + score_001_2_Data.tournamentData.title;

        document.getElementById("button_clear_fix_flg").style.display = "none";         //最終スコア決定の無効化（デバッグ用）

    }, [allUserScoreList.length, score_001_2_Data.tournamentData.eventDate, score_001_2_Data.tournamentData.title]);


    const onClickButton = (e) => {
        switch (e.currentTarget.id) {
            case "button_decide":
                value.score_009_Data = value.score_001_2_Data
                history.push("Score_009");
                break;
            case "button_back":
                history.push("Score_001_1");
                break;
            case "button_clear_fix_flg":
                const asyncFunction = (async () => {
                    await setFixFlgTournament(score_001_2_Data.tournamentData.uid, 0, (result, status) => {
                        setLoadingData({ loadingFlg: false, title: "最終スコア決定の無効化中・・・" });   //ローデング表示
                        //選択ボタン活性
                        document.getElementById("button_decide").disabled = false;
                    });

                });
                setLoadingData({ loadingFlg: true, title: "最終スコア決定の無効化中・・・" });   //ローデング表示
                asyncFunction();
                break;
            default:
                break;
        }
    };
    //----------------------------------------------------
    //データ入力をチェックする
    const checkDataEntry = (e) => {
        //まだ読み込みされていなかったら判定はしない
        if (allUserScoreList.length === 0) return;
        const asyncFunction = (async () => {
            await checkButtonInactivity(e);
        });
        asyncFunction();
    }
    /**
     * スコアの入力
     * @param {*} e 
     */
    const inputScore = (e) => {
        //トータルスコア
        //     setTotalUserAtBat(allUserScoreList)
        //    setAllUserScoreList([...allUserScoreList]);
        const asyncFunction = (async () => {
            await checkButtonInactivity(e);
        });
        asyncFunction();

    }

    //----------------------------------------------------
    const renderAccordiontList = () => {

        if (allUserScoreList !== undefined && allUserScoreList.length > 0) {
            return allUserScoreList.map((data, i) => {
                return <Score0012Accordion key={"Score0012Accordion_" + i} userScoreData={data} callback={checkDataEntry} inputCallback={inputScore}></Score0012Accordion>
            })
        } else {
            return null;
        }
    }

    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <header className="c-container-vertical c-mt56">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt32">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconScore></IconScore>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">スコア管理画面</h1>
                </div>
            </header>
            {/*----------------------------------------------------------*/}

            <main>

                <div className="c-page-section c-mt32">
                    <h2 className="c-heading--2 c-heading--left c-mt32" id="tournament_title">2021/08/21 第1回JCGツアー</h2>
                    {/*<!-- 選手一覧 -->*/}
                    <div className="score-table c-container-vertical c-mt56">

                        {/*<!-- 列タイトル -->*/}
                        <div className="score-table__title-row c-container-horizontal">

                            <div className="c-data__title c-data__title--dark-slate-gray">順位</div>
                            <div className="c-data__title c-data__title--dark-slate-gray c-data__slot--232">会員ID</div>
                            <div className="c-data__title c-data__title--232 c-data__title--dark-slate-gray">選手名</div>
                            <div className="c-data__title c-data__title--232 c-data__title--dark-slate-gray">フリガナ</div>
                            <div className="c-data__title c-data__title--dark-slate-gray">合計<br />スコア</div>
                            <div className="c-data__title c-data__title--144 c-data__title--dark-slate-gray">電話番号</div>
                            <div className="c-data__title c-data__title--144 c-data__title--dark-slate-gray c-data__title--center c-subtitle">各ホールの<br />スコア表示</div>
                            <div className="c-data__title c-data__title--dark-slate-gray c-data__title--center c-subtitle">失格</div>

                        </div>

                        {/*ここに 選手一つ （アコーディオン一つ）を複数レンダリング*/}
                        {renderAccordiontList()}

                    </div>

                    <div className="c-button-set c-mt32 c-mb88">
                        <button id="button_decide" onClick={(e) => { onClickButton(e) }} className="button button__primary">大会のスコアを確定させる</button>
                        <button id="button_back" onClick={(e) => { onClickButton(e) }} className="button button__secondary">戻る</button>
                        <button id="button_clear_fix_flg" onClick={(e) => { onClickButton(e) }} className="button button__secondary">最終スコア決定の無効化</button>
                    </div>

                </div>

            </main>

            {/*----------------------------------------------------------*/}
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default Score_001_2

