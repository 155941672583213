import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom"
import ManagementFooter from './ManagementFooter'
import logo from "../images/logo.svg";
import '../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "./Icons";
import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconPlayer } from "./Icons";
import { getDebugFlg } from "../Database";

function Menu() {
    const history = useHistory();
    const [debugButton, setDebugButton] = useState("none");                //グローバル参照渡し

    useEffect(() => {
        //大会データ内のユーザー決算情報を取得
        const asyncfunc = (async () => {
            await getDebugFlg((result, status) => {
                if (result === true) {
                    if (status.debug_flg === "1") {
                        setDebugButton("");
                    }

                }
            });
        });

        asyncfunc();


    }, []);

    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "menuButtonNotifications":
                history.push("Notification");
                break;
            case "menuButtonAnnouncement":
                history.push("Announcement");
                break;
            case "menuButtonMembership":
                history.push("Membership");
                break;
            case "menuButtonCourse":
                history.push("Course");
                break;
            case "menuButtonTournament":
                history.push("Tournament_001");
                break;
            case "menuButtonScore":
                history.push("Score_001_1");
                break;
            case "button_notice":
                history.push("AnnouncementSetNewsText");
                break
            //デバッグ用のクリアボタン
            case "button_data_clear":
                history.push("DataClear");
                break
            //ユーザーが登録した銀行情報
            case "button_user_bank_info":
                history.push("UserBankInfopage");
                break;
            default:
                break;
        }
    };

    return (
        <div className="c-screen c-screen--gunmetal">
            <header className="c-container-vertical c-mt32">
                <div className="c-logo c-logo--232">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
            </header>
            <main>
                <div className="c-container-vertical c-mt88 c-mb88">

                    <div className="c-container-horizontal c-container-horizontal--responsive">
                        <button id="menuButtonNotifications" className="kanri-option c-mb56" onClick={(e) => { onButtonClick(e) }}>
                            <div className="kanri-option__icon">
                                <figure className="c-icon c-icon--white c-icon--32">
                                    <IconNotification></IconNotification>
                                </figure>
                            </div>
                            <h1 className="c-heading--1 kanri-option__text">プッシュ通知管理</h1>
                        </button>

                        <button id="menuButtonAnnouncement" className="kanri-option c-mb56" onClick={(e) => { onButtonClick(e) }}>
                            <div className="kanri-option__icon">
                                <figure className="c-icon c-icon--white c-icon--32">
                                    <IconAnnouncement></IconAnnouncement>
                                </figure>
                            </div>
                            <h1 className="c-heading--1 kanri-option__text">お知らせ送信管理</h1>
                        </button>

                        <button id="button_notice" className="kanri-option c-mb56" onClick={(e) => { onButtonClick(e) }}>
                            <div className="kanri-option__icon">
                                <figure className="c-icon c-icon--white c-icon--32">
                                    <IconAnnouncement></IconAnnouncement>
                                </figure>
                            </div>
                            <h1 className="c-heading--1 kanri-option__text">お知らせ文言設定</h1>
                        </button>


                    </div>

                    <div className="c-container-horizontal c-container-horizontal--responsive">
                        <button id="menuButtonMembership" className="kanri-option c-mb56" onClick={(e) => { onButtonClick(e) }}>
                            <div className="kanri-option__icon">
                                <figure className="c-icon c-icon--white c-icon--32">
                                    <IconMembership></IconMembership>
                                </figure>
                            </div>
                            <h1 className="c-heading--1 kanri-option__text">会員情報管理</h1>
                        </button>

                        <button id="menuButtonScore" className="kanri-option c-mb56" onClick={(e) => { onButtonClick(e) }}>
                            <div className="kanri-option__icon">
                                <figure className="c-icon c-icon--white c-icon--32">
                                    <IconScore></IconScore>
                                </figure>
                            </div>
                            <h1 className="c-heading--1 kanri-option__text">スコア管理</h1>
                        </button>

                        <button id="button_data_clear" className="kanri-option c-mb56" style={{ display: debugButton }} onClick={(e) => { onButtonClick(e) }}>
                            <div className="kanri-option__icon">
                                <figure className="c-icon c-icon--white c-icon--32">
                                    <IconAnnouncement></IconAnnouncement>
                                </figure>
                            </div>
                            <h1 className="c-heading--1 kanri-option__text">データ削除！！</h1>
                        </button>


                    </div>

                    <div className="c-container-horizontal c-container-horizontal--responsive">
                        <button id="menuButtonCourse" className="kanri-option c-mb56" onClick={(e) => { onButtonClick(e) }}>
                            <div className="kanri-option__icon">
                                <figure className="c-icon c-icon--white c-icon--32">
                                    <IconCourse></IconCourse>
                                </figure>
                            </div>
                            <h1 className="c-heading--1 kanri-option__text">ゴルフ場管理</h1>
                        </button>

                        <button id="menuButtonTournament" className="kanri-option c-mb56" onClick={(e) => { onButtonClick(e) }}>
                            <div className="kanri-option__icon">
                                <figure className="c-icon c-icon--white c-icon--32">
                                    <IconTournament></IconTournament>
                                </figure>
                            </div>
                            <h1 className="c-heading--1 kanri-option__text">大会追加管理</h1>
                        </button>

                        <button id="button_user_bank_info" className="kanri-option c-mb56" onClick={(e) => { onButtonClick(e) }}>
                            <div className="kanri-option__icon">
                                <figure className="c-icon c-icon--white c-icon--32">
                                    <IconAnnouncement></IconAnnouncement>
                                </figure>
                            </div>
                            <h1 className="c-heading--1 kanri-option__text">ユーザー銀行情報一覧</h1>
                        </button>


                    </div>

                    <div className="c-container-horizontal c-container-horizontal--responsive">
                        <button id="menuButtonTournament" className="kanri-option c-mb56">
                            <div className="kanri-option__icon">
                                <figure className="c-icon c-icon--white c-icon--32">
                                    <IconPlayer></IconPlayer>
                                </figure>
                            </div>
                            <h1 className="c-button-text c-button-text--left kanri-option__text c-margin-reset">技能テスト・シーズン追加・<br />参加者割り当て管理</h1>

                        </button>
                    </div>

                </div>

            </main>
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>

        </div>
    )
}

export default Menu
