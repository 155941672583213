import { originalInitialState } from './CourseProvider'
import lodash from 'lodash';
/**
 * データベースから取得したコースデータをコース追加用データに変更する
 * @param {*} courseData 
 * @returns CourseStepData
 */
const stepData = lodash.cloneDeep(originalInitialState);
export function change_CourseDatabase_To_CourseStepData(courseData) {
    //基本フォーマット
    stepData.CourseAddStep2.arrayCourseList = [];
    stepData.CourseAddStep3.arrayCourseList = courseData.arrayCourseList;
    stepData.CourseAddStep4.arrayCourseList = [];

    stepData.CourseAddStep1.club_name = courseData.club_name;
    stepData.CourseAddStep1.club_address = courseData.club_address;
    stepData.CourseAddStep1.club_phone = courseData.club_phone;
    stepData.CourseAddStep1.club_website = courseData.club_website;

    courseData.arrayCourseList.forEach((data) => {
        let basicCourseData = {
            key: data.key,
            courseName: data.courseName,
            arrayTeeList: [],
            arrayGreenList: [],
            numberOfholes: -1,
            //コースのバリデーションフラグ
            validation: {
                validationName: false,
                validationHole: false,
                validationTee: false,
                validationGreen: false
            }
        }

        //ティーのデータを取得
        data.holeList[0].teePositionList.forEach((data) => {
            let teeData = { key: data.key, name: data.name };
            basicCourseData.arrayTeeList.push(teeData);
        });

        //グリーンのデータを取得
        data.holeList[0].teePositionList[0].greenList.forEach((data) => {
            let greenData = { key: data.key, name: data.name };
            basicCourseData.arrayGreenList.push(greenData);
        });


        //コース数の追加
        basicCourseData.numberOfholes = data.holeList.length.toFixed()

        stepData.CourseAddStep2.arrayCourseList.push(basicCourseData);


    });
    //    stepData=makeCourseStep3Data(stepData);
    return stepData;

}

/**
 * Step2からStep3の作成
 * @param {*} stepData 
 * @returns Step3のデータ
 */
const tempArrayCourseList = [];
export function makeCourseArrayCourseList(stepData) {
    //配列をクリア
    tempArrayCourseList.splice(0);

    //Step2の値は必ずあるので、ループカウンタに使う
    for (let m = 0; m < stepData.CourseAddStep2.arrayCourseList.length; m++) {
        //Step3の入力値取り出し用
        let Step3data = stepData.CourseAddStep3.arrayCourseList[m];
        //Step2の入力値取り出しよう
        let Step2data = stepData.CourseAddStep2.arrayCourseList[m];

        let addObject = {
            key: Step2data.key,
            courseName: Step2data.courseName,
            holeList: [
            ],
        };

        //指定ホール数の数だけ、ホールと規定打数を追加
        for (let i = 0; i < Number(Step2data.numberOfholes); i++) {
            let name = i + 1;
            let atBat = 3;
            //Step3に値が含まれたら、それを使う
            if (Step3data !== undefined) {
                try {
                    name = Step3data.holeList[i].name;
                    atBat = Step3data.holeList[i].atBat;
                } catch (e) {
                }
            }

            addObject.holeList.push({
                //ホール名
                name: name,
                //規定打数
                atBat: atBat,
                //ティー一位置
                teePositionList: [
                ]
            });


            //ティー位置追加
            for (let j = 0; j < Step2data.arrayTeeList.length; j++) {
                //グリーン追加
                let greenList = [];
                let yard = 300
                for (let k = 0; k < Step2data.arrayGreenList.length; k++) {
                    //Step3に値が含まれたら、それを使う
                    if (Step3data !== undefined) {
                        try {
                            yard = Step3data.holeList[i].teePositionList[j].greenList[k].yard;
                        } catch (e) {
                        }
                    }
                    //グリーンのキーを再設定
                    Step2data.arrayGreenList[k].key = "green_id_" + i + "_" + j + "_" + k;
                    greenList.push({ ...Step2data.arrayGreenList[k], yard: yard });
                }

                //ティーのキーを再配置
                Step2data.arrayTeeList[j].key = "tee_id_" + i + "_" + j;

                let teeData = { ...Step2data.arrayTeeList[j], greenList }

                addObject.holeList[i].teePositionList.push(teeData);

            }


        }

        tempArrayCourseList.push(addObject);

    }



    return [...tempArrayCourseList];;
}
/**
 * 比較用のデータをCourseAddStep4に格納しておく
 * @param {*} stepData 
 */
export function copyStep3FromStep4Data(stepData) {
    if (stepData.CourseAddStep4.arrayCourseList.length <= 0) {
        stepData.CourseAddStep4.arrayCourseList = lodash.cloneDeep(stepData.CourseAddStep3.arrayCourseList);

        stepData.CourseAddStep4.arrayCourseList.forEach((data, courseIndex) => {
            data.holeList.forEach((holeData, holeIndex) => {
                //比較をしたいので、専用オブジェクトに変更
                if (holeData.atBat.atBat === undefined) {
                    holeData.atBat = { atBat: holeData.atBat, diff: 0 };
                }

                if (holeData.name.name === undefined) {
                    holeData.name = { name: holeData.name, diff: 0 };
                }

                holeData.teePositionList.forEach((teePositionData, teePositionIndex) => {
                    teePositionData.greenList.forEach((greenData, greenIndex) => {
                        //比較をしたいので、専用オブジェクトに変更
                        if (greenData.yard.yard === undefined) {
                            greenData.yard = { yard: greenData.yard, diff: 0 };
                        }
                    })
                })
            })
        })
    }

}

/**
 * Step3とStep4のデータを比較して、diffに0=同じ diff=1更新在りに設定する
 * @param {*} stepData 
 */
export function makeCourseStep4CmpData(stepData) {
    stepData.CourseAddStep4.arrayCourseList.forEach((data, courseIndex) => {
        data.holeList.forEach((holeData, holeIndex) => {
            let step3AtBat = stepData.CourseAddStep3.arrayCourseList[courseIndex].holeList[holeIndex].atBat;
            let step4AtBat = holeData.atBat.atBat;
            if (step3AtBat !== step4AtBat) {
                //差分があったのでdiff=1
                holeData.atBat = { atBat: step3AtBat, diff: 1 };
            }

            let step3Name = stepData.CourseAddStep3.arrayCourseList[courseIndex].holeList[holeIndex].name;
            let step4Name = holeData.name.name;

            if (step3Name !== step4Name) {
                //差分があったのでdiff=1
                holeData.name = { name: step3Name, diff: 1 };
            }

            holeData.teePositionList.forEach((teePositionData, teePositionIndex) => {
                teePositionData.greenList.forEach((greenData, greenIndex) => {
                    let step3Yard = stepData.CourseAddStep3.arrayCourseList[courseIndex].holeList[holeIndex].teePositionList[teePositionIndex].greenList[greenIndex].yard
                    let step4Yard = greenData.yard.yard;
                    if (step3Yard !== step4Yard) {
                        //差分があったのでdiff=1
                        greenData.yard = { yard: step3Yard, diff: 1 };
                    }
                })

            })
        })
    })
}

/**
 * コース情報設定のワーニングフラグ設定
 * @param {*} stepData 
 * @returns 
 */
export function setCourseStep2kWarningFlg(stepData) {

    //未入力確認
    let warningWindowFlg = { validationName: false, validationHole: false, validationTee: false, validationGreen: false };
    let arrayCourseList = stepData.CourseAddStep2.arrayCourseList;
    let findArrayCourseList = arrayCourseList.find((data) => {
        if (data.numberOfholes === -1) {
            warningWindowFlg.validationHole = true;
        } else {
            warningWindowFlg.validationHole = false;

        }
        if (data.arrayTeeList.length === 0) {
            warningWindowFlg.validationTee = true;
        } else {
            warningWindowFlg.validationTee = false;
        }
        if (data.arrayGreenList.length === 0) {
            warningWindowFlg.validationGreen = true;
        } else {
            warningWindowFlg.validationGreen = false;
        }
        data.validation = { ...warningWindowFlg };
        return warningWindowFlg.validationHole === true || warningWindowFlg.validationTee === true || warningWindowFlg.validationGreen === true;
    })
    return findArrayCourseList;
}

