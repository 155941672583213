import React, { useEffect, useState } from 'react';
import CourseEditStep3TeePositionComponent from './CourseEditStep3TeePositionComponent'
import '../../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";


function CourseEditStep3HoleAndAtBatContainer(props) {
    const [holeName, setCourseName] = useState(props.nowHoleAndAtBatData.name);
    const [atBat, setAtBat] = useState(props.nowHoleAndAtBatData.atBat);
    const [teePositionList, setTeePositionList] = useState(props.nowHoleAndAtBatData.teePositionList);
    const [classNameAtBat, setclassNameAtBat] = useState("game-data__slot");
    const [classNameCourseName, setclassNameCourseName] = useState("game-data__slot");

    useEffect(() => {
        if(atBat.diff===1){
            setclassNameAtBat("game-data__slot game-data__slot--confirm");
        }

        if(holeName.diff===1){
            setclassNameCourseName("game-data__slot game-data__slot--confirm");
        }
    }, []);

    return (

        <div className="c-container-horizontal">    {/* <!-- 「ホール名」と「規定打数」コンテナ -->*/}
            {/*<!-- 「ホール名」内容 -->*/}
            <div className="game-data__column">
                <span className={classNameCourseName}>{holeName.name}</span>
            </div>

            {/*<!-- 「規定打数」内容 -->*/}
            <div className="game-data__column">
                <span className={classNameAtBat}>{atBat.atBat}</span>
            </div>
            {/*<!-- 「規定打数」の子要素 -->*/}
            <div className="c-container-vertical">
                {/*!-- 「規定打数」の子要素 --> */}
                {/*動的にコンポーネントが増えていきます*/}
                {teePositionList.map(teeData => (
                    <CourseEditStep3TeePositionComponent
                        key={teeData.key}
                        nowTeeData={teeData}
                    />
                ))}                
            </div>

        </div>
    )
}


export default CourseEditStep3HoleAndAtBatContainer

