import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { CourseContext } from '../CourseProvider'
import CourseHeader from '../CourseHeader'
import ManagementFooter from '../../ManagementFooter'
import CourseAddStep2Container from './CourseAddStep2Container'
import '../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconPlus } from "../../Icons";
import { setCourseStep2kWarningFlg } from '../CourseUtility'

function CourseAddStep2(props) {
    const [value, setValue] = useContext(CourseContext);
    const history = useHistory();
    const [arrayCourseList, setArrayCourseList] = useState([]);

    useEffect(() => {
        //ここまで戻ったら、コースが全く変わるのでStep4はクリア
        value.CourseAddStep4.arrayCourseList = [];
        setArrayCourseList([...value.CourseAddStep2.arrayCourseList]);
    }, []);

    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "button_next":
                history.push("CourseAddStep2");
                break;
            case "button_back":
                clearWarningFlg();
                history.push("CourseAddStep1");
                break;
            default:
                break;
        }
    };
    //検索処理

    const onSubmit = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();
        //        const { tee_name } = e.target.elements;
        //未入力確認
        let findArrayCourseList =setCourseStep2kWarningFlg(value);

        //再描画
        setArrayCourseList([...arrayCourseList]);

        //エラーが無かったら次へ
        if (findArrayCourseList === undefined) {
            history.push("CourseAddStep3");
        }

    };


    //ゴルフコースの追加
    const onClickAdd = (e) => {
        let arrayCourseList = value.CourseAddStep2.arrayCourseList;
        let copyArrayCourseList = lodash.cloneDeep(arrayCourseList[arrayCourseList.length - 1]);

        copyArrayCourseList.key = "course_id_" + arrayCourseList.length;
        //コース名は初期化しておく
        copyArrayCourseList.courseName = "";
        arrayCourseList.push(copyArrayCourseList);
        setArrayCourseList([...value.CourseAddStep2.arrayCourseList]);
    };

    //ゴルフコースの削除
    const onClickRemove = (courseItemKey) => {
        let newArrayCourseList = value.CourseAddStep2.arrayCourseList.filter(data => {
            return data.key !== courseItemKey;
        });
        value.CourseAddStep2.arrayCourseList = newArrayCourseList;
        setArrayCourseList([...value.CourseAddStep2.arrayCourseList]);
    };

    //ワーニングフラグのクリア
    const clearWarningFlg = () => {
        let arrayCourseList = value.CourseAddStep2.arrayCourseList;

        arrayCourseList.forEach(data => {
            data.validationName = false;
            data.validationHole = false;
            data.validationTee = false;
            data.validationGreen = false;
        });


    }


    return (
        <div className="c-screen c-screen--gunmetal">
            <CourseHeader stepNb={"step2"}></CourseHeader>
            <main>
                <section className="c-page-section c-mt16">
                    <div className="kanri-container">
                        <div id="golf_course">

                            {/*動的にコンポーネントが増えていきます*/}
                            {arrayCourseList.map(courseAddItem => (
                                <CourseAddStep2Container
                                    key={courseAddItem.key}
                                    itemKey={courseAddItem.key}
                                    nowCourse={courseAddItem}
                                    onClickRemove={onClickRemove}
                                />
                            ))}
                        </div>

                        <p className="c-paragraph c-mt56">※コースが複数ある場合はすべて追加してください。</p>

                        <div className="c-button-set c-mt32 c-mb88">
                            <button id="add_course_button" className="button button__secondary c-margin-reset" onClick={(e) => { onClickAdd(e) }}>
                                <div className="button__icon button__icon--gunmetal">
                                    <figure className="c-icon c-icon--white c-icon--24">
                                        <IconPlus></IconPlus>
                                    </figure>
                                </div>
                                <span className="c-button-text">他のコースを追加する</span>
                            </button>

                            <form id="form_button_next" onSubmit={onSubmit}>
                                <button id="button_next" className="button button__primary" type="submit">次へ</button>
                                <button id="button_back" onClick={(e) => { onButtonClick(e) }} className="button button__secondary">戻る</button>
                            </form>

                        </div>

                    </div>
                </section>

            </main>
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default CourseAddStep2

