import React, { useEffect } from 'react';
import logo from "../../../images/logo.svg";
import '../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconCourse  } from "../../Icons";


function CourseEditHeader(props) {
    useEffect(() => {
    }, []);
    return (
        <header className="c-container-vertical c-mt56">

            <div className="c-logo c-logo--144">
                <img src={logo} alt="JCG Management" className="c-logo__source" />
            </div>

            <div className="kanri-title c-mt32">
                <div className="kanri-title__icon">
                    <figure className="c-icon c-icon--white c-icon--32">
                        <IconCourse></IconCourse>
                    </figure>
                </div>
                <h1 className="c-heading--1">ゴルフ場：登録済みのゴルフ場変更</h1>
            </div>

        </header>
    )
}



export default CourseEditHeader

