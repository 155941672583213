import React, { useEffect, useState } from "react";
import firebase  from "../firebase/Firebase";

// contextの作成
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState("not init");

  // ユーザーをログインさせる関数
    const login = async (email, password, history, path,errorCallBack) => {
        try {
            await firebase.auth().signInWithEmailAndPassword(email, password);
            history.push(path);
        } catch (error) {
            //ID又はパスワード間違えたら呼ばれる
            if(errorCallBack!=null){
                errorCallBack(error);
            }
        }
    };

  // 新しいユーザーを作成しログインさせる関数
    const signup = async (email, password, history, path, errorCallBack) => {
        try {
            await firebase.auth().createUserWithEmailAndPassword(email, password);
            history.push(path);
        } catch (error) {
            if(errorCallBack!=null){
                errorCallBack(error);
            }
        }
    };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(setCurrentUser);
  }, []);

  return (
    // Contextを使用して認証に必要な情報をコンポーネントツリーに流し込む。
    <AuthContext.Provider
      value={{
        login: login,
        signup: signup,
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};