import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { ScoreContext } from './ScoreProvider'
import ManagementFooter from '../ManagementFooter'
import logo from "../../images/logo.svg";
import { IconScore } from "../Icons";
import '../../styles/styles.css';
import { updateAllScoreDatatoDatabase, setTotalUserAtBat, checkScoreInput, setUserDataToScoreDataAndEntryData, checkScoreConfirmation, setRanking } from "./ScoreUtility";
import '../../styles/loader.css'
import Loader from '../Loader'
import Score009Accordion from './Score_009_Accordion'

function Score_009(props) {
    const [value] = useContext(ScoreContext);
    const history = useHistory();

    const [score_009_Data] = useState(value.score_009_Data);                //グローバル参照渡し
    const [loadingData, setLoadingData] = useState(undefined);

    const [allUserScoreList, setAllUserScoreList] = useState([]);
    //------------------------------------------



    useEffect(() => {
        if (allUserScoreList.length !== 0) return;
        //データベースからコースデータの読み込み
        const asyncFunction = (async () => {

            await setUserDataToScoreDataAndEntryData(score_009_Data.tournamentData.scoreDataList, (result, status) => {
                if (result === false) {
                    alert("スコアデータの読み込みにに失敗しました=" + status);
                } else {
                    setAllUserScoreList(setRanking(score_009_Data.tournamentData.scoreDataList, score_009_Data.tournamentData));
                }

            });

            setLoadingData({ loadingFlg: false, title: "検索中・・・" });   //ローデング非表示

        });

        setLoadingData({ loadingFlg: true, title: "検索中・・・" });   //ローデング表示
        asyncFunction();

    }, [allUserScoreList.length, score_009_Data.tournamentData]);

    useEffect(() => {
        if (allUserScoreList.length === 0) return;
        //トーナメントタイトル
        document.getElementById("tournament_title").innerText = score_009_Data.tournamentData.eventDate + " " + score_009_Data.tournamentData.title;
    }, [allUserScoreList.length, score_009_Data.tournamentData.eventDate, score_009_Data.tournamentData.title]);

    const onClickButton = (e) => {
        switch (e.currentTarget.id) {
            case "button_decide":
                //データベースへ書き込みをする
                const asyncFunction = (async () => {
                    await updateAllScoreDatatoDatabase(allUserScoreList, score_009_Data.tournamentData,(result, status) => {
                        setLoadingData({ loadingFlg: false, title: "スコア更新中・・・" });   //ローデング非表示
                        if (result === false) {
                            alert("スコアデータの更新に失敗しました=" + status);
                        } else {
                            history.push("Score_010");
                        }
                    });
                });
                setLoadingData({ loadingFlg: true, title: "スコア更新中・・・" });   //ローデング表示
                asyncFunction();
                break;
            case "button_back":
                history.push("Score_001_2");
                break;
            default:
                break;
        }
    };
    //----------------------------------------------------


    //----------------------------------------------------
    const renderAccordiontList = () => {

        if (allUserScoreList !== undefined && allUserScoreList.length > 0) {
            return allUserScoreList.map((data, i) => {
                return <Score009Accordion key={"Score009Accordion_" + i} userScoreData={data}></Score009Accordion>
            })
        } else {
            return null;
        }
    }

    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <header className="c-container-vertical c-mt56">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt32">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconScore></IconScore>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">大会のスコア確定確認画面</h1>
                </div>
            </header>
            {/*----------------------------------------------------------*/}

            <main>

                <div className="c-page-section c-mt32">
                    <h2 className="c-heading--2 c-heading--left c-mt32" id="tournament_title">2021/08/21 第1回JCGツアー</h2>
                    {/*<!-- 選手一覧 -->*/}
                    <div className="score-table c-container-vertical c-mt56">

                        {/*<!-- 列タイトル -->*/}
                        <div className="score-table__title-row c-container-horizontal">

                            <div className="c-data__title c-data__title--dark-slate-gray">順位</div>
                            <div className="c-data__title c-data__title--dark-slate-gray c-data__slot--232">会員ID</div>
                            <div className="c-data__title c-data__title--232 c-data__title--dark-slate-gray">選手名</div>
                            <div className="c-data__title c-data__title--232 c-data__title--dark-slate-gray">フリガナ</div>
                            <div className="c-data__title c-data__title--dark-slate-gray">合計<br />スコア</div>
                            <div className="c-data__title c-data__title--144 c-data__title--dark-slate-gray">電話番号</div>
                            <div className="c-data__title c-data__title--144 c-data__title--dark-slate-gray c-data__title--center c-subtitle">各ホールの<br />スコア表示</div>
                            <div className="c-data__title c-data__title--dark-slate-gray c-data__title--center c-subtitle">失格</div>

                        </div>

                        {/*ここに 選手一つ （アコーディオン一つ）を複数レンダリング*/}
                        {renderAccordiontList()}

                    </div>
                    <div className="c-container-vertical c-container-vertical--orange-blaze c-container-vertical--center c-prl--56 c-radius--8 c-mt32">
                        <p className="c-paragraph c-mt24">
                            スコアを確定すると、ユーザーにスコア確定のお知らせが送信され、結果がアプリに表示されます。
                        </p>
                        <p className="c-paragraph c-mb24">
                            取り消しはできませんのでよく確認の上確定ください。
                        </p>
                    </div>
                    <div className="c-button-set c-mt32 c-mb88">
                        <button id="button_decide" onClick={(e) => { onClickButton(e) }} className="button button__primary">確定</button>
                        <button id="button_back" onClick={(e) => { onClickButton(e) }} className="button button__secondary">戻る</button>
                    </div>

                </div>

            </main>

            {/*----------------------------------------------------------*/}
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default Score_009


