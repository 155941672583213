import React, { useEffect, useContext, useState,useRef } from 'react';
import { useHistory } from "react-router-dom"
import { MembershipContext } from './MembershipProvider'
import ManagementFooter from '../ManagementFooter'
import logo from "../../images/logo.svg";
import '../../styles/styles.css';
import { likeSearchUser } from "../../Database";
// import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconMembership } from "../Icons";


function MembershipEdit(props) {
    const [value, setValue] = useContext(MembershipContext);
    const history = useHistory();
    const [userData, setuserData] = useState(value.userData);  

    
    //userDataが変化があった時に呼ばれるuseEffect（初回レンダリングで呼ばれてしまうのでisFirstRender）
    useEffect(() => {
        //初回に呼ばれてしまうので、値が入力された時点で処理する
        let keyList=Object.keys(userData)
        keyList.forEach((value) => {
            switch (value) {
                case "occupation":
                case "sex":
                    let options = document.getElementsByTagName("*")[value].options;
                    for (let i = 0; i < options.length; i++) {
                        if (options[i].text === userData[value]) {
                            options.selectedIndex = i;
                        }
                    }
                    return value;
                //年齢は無いので無視
                case "age":
                    return value;
                default:
                    break;
            }

            //表示されないステータスもあるので、エラーの場合は何もしないようにする
            try{
                document.getElementsByTagName("*")[value].value = userData[value];
            }catch(e){
                //表示されないステータスはエラーになるけど分かっているのでOK
//                    console.log(e);
            }
        });

        //一番上にスクロール
        window.scrollTo( 0, 0);


    }, [userData]);

    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "back_menu":
                history.push("/");
                break;
            case "back_member_info":
                history.push("Membership");
                break;
            case "confirm_member_info":
                history.push("MembershipConfirm");
                break;
            default:
                break;
        }

    };

    const onSubmit = (e) => {
        //オブジェクトを配列に変換
        let elementList = Object.entries(e.target.elements);
        elementList.forEach((e)=>{
            if(userData[e[1].id]!==undefined){
                let tagId =e[1].id;
                let setValueData=e[1].value;
                userData[tagId]=setValueData;
            }
        });

        //編集した個人データを次の画面へデータを引継ぎ
        //会員情報変更画面へデータを引継ぎをしたいので
        setValue((data)=>{
                data.userData=userData;
                history.push("MembershipConfirm");
                return data;
            }
        )
        //        setValue({...value,userData:newUserData});

//        const { given_name_kanji } = e.target.elements;

    };

    return (
        <div className="c-screen c-screen--gunmetal">
            <header className="c-container-vertical c-mt88">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconMembership></IconMembership>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">会員情報変更画面</h1>
                </div>
            </header>

            <main>

                <section className="c-page-section c-top-triangle--gunmetal c-mt48">

                    <article className="kanri-container">
                        <h2 className="c-heading--2 c-mt72">選択されたユーザーの個人情報変更</h2>

                        <form name="register_form" method="post" className="h-adr form" id="register-form" onSubmit={onSubmit}>

                            <div className="input input__set">
                                <label htmlFor="given-name-kanji" className="input input__label">氏名(姓) <i className="input input__tag">必須</i></label>
                                <input type="text" name="given-name" id="given_name_kanji" minlenght="2" placeholder="例：山田" autoComplete="family-name" required className="input input__field" />
                                <span className="input input__validation">姓を入力してください。</span>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="family-name-kanji" className="input input__label">氏名(名) <i className="input input__tag">必須</i></label>
                                <input type="text" name="family-name-kanji" id="family_name_kanji" autoComplete="given-name" placeholder="例：太郎" required className="input input__field" />
                                <span className="input input__validation">名を入力してください。</span>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="given-name-kana" className="input input__label">氏名カナ(姓) <i className="input input__tag">必須</i></label>
                                <input type="text" name="given-name-kana" id="given_name_kana" placeholder="例：ヤマダ" required className="input input__field" />
                                <span className="input input__validation">フリガナを入力してください。</span>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="family-name-kana" className="input input__label">氏名カナ(名) <i className="input input__tag">必須</i></label>
                                <input type="text" name="family-name-kana" id="family_name_kana" placeholder="例：タロウ" required className="input input__field" />
                                <span className="input input__validation">フリガナを入力してください。</span>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="birthday" className="input input__label">生年月日 <i className="input input__tag">必須</i></label>
                                <input type="date" name="birthday" id="birthday" required className="input input__field" />
                                <span className="input input__validation">生年月日を入力してください。</span>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="sex" className="input input__label">性別<i className="input input__tag">必須</i></label>
                                <select name="sex" id="sex" required className="input input__field">
                                    <option value="">選択してください</option>
                                    <option value="女性">女性</option>
                                    <option value="男性">男性</option>
                                </select>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="phone-number" className="input input__label">電話番号 <i className="input input__tag">必須</i></label>
                                <input type="tel" name="phone-number" id="phone_number" pattern="[0-9]{3,11}" autoComplete="tel" placeholder="例：08077778888" required className="input input__field" />
                                <span className="input input__validation">電話番号を入力してください。</span>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="email" className="input input__label">メールアドレス <i className="input input__tag">必須</i></label>
                                <input type="email" name="email" id="email" placeholder="tatoeba@example.com" required className="input input__field" />
                                <span className="input input__validation">メールアドレスを入力してください。</span>
                            </div>

                            <fieldset className="address">

                                <div className="input input__title">
                                    <legend className="input__legend">住所</legend>
                                    {/*<!--<span className="input input__tag">必要</span>-->*/}
                                </div>

                                <small className="c-mt8">※「郵便番号」から住所が自動で入力されます。</small>

                                <div className="address address__row">
                                    <div className="input input__set input__set--no-mt">
                                        <label htmlFor="postal-code" className="input input__label">郵便番号<i className="input input__tag">必須</i></label>
                                        <input type="text" name="postal-code" id="postal_code" autofill="postal-code" size="8" maxLength="8" pattern="\d{3}-?\d{4}" placeholder="例：123-4567" required className="input input__field input__field--medium" />
                                        <span className="input input__validation">郵便番号を入力してください。</span>
                                    </div>
                                </div>

                                <div className="address address__row">
                                    <div className="input input__set input__set--no-mt">
                                        <label htmlFor="address-level1" className="input input__label">都道府県</label>
                                        <input type="text" name="address-level1" id="address_level1" autofill="address-level1" required className="input input__field input__field--small" />
                                    </div>
                                    <div className="input input__set input__set--no-mt input__set--stretch">
                                        <label htmlFor="address-level2" className="input input__label">市区町村や郡</label>
                                        <input type="text" name="address-level2" id="address_level2" required className="input input__field input__field--stretch" />
                                    </div>
                                </div>

                                <div className="address address__row">
                                    <div className="input input__set input__set--no-mt">
                                        <label htmlFor="address-line1" className="input input__label">町名</label>
                                        <input type="text" name="address-line1" id="address_line1" required className="input input__field input__field--medium" />
                                    </div>
                                    <div className="input input__set input__set--no-mt input__set--stretch">
                                        <label htmlFor="address-line2" className="input input__label">地番<i className="input input__tag">必須</i></label>
                                        <input type="text" name="address-line2" id="address_line2" required className="input input__field input__field--stretch" />
                                        <span className="input input__validation">地番を入力してください。</span>
                                    </div>
                                </div>

                                <div className="address address__row">
                                    <div className="input input__set input__set--no-mt input__set--stretch">
                                        <label htmlFor="address-line2" className="input input__label">建物名</label>
                                        <input type="text" name="address-line3" id="address_line3" className="input input__field input__field--stretch" />
                                    </div>
                                </div>

                            </fieldset>

                            <div className="input input__set">

                                <label htmlFor="occupation" className="input input__label">職業<i className="input input__tag">必須</i></label>

                                <select name="occupation" id="occupation" required className="input input__field">
                                    <option value="">選択してください</option>
                                    <option value="会社員">会社員</option>
                                    <option value="経営者・役員">経営者・役員</option>
                                    <option value="学生(高校生)">学生(高校生)</option>
                                    <option value="学生(専門・大学)">学生(専門・大学)</option>
                                    <option value="公務員">公務員</option>
                                    <option value="自営業">自営業</option>
                                    <option value="自由業">自由業(フリーランス含む)</option>
                                    <option value="専業主婦">専業主婦</option>
                                    <option value="パート・アルバイト">パート・アルバイト</option>
                                    <option value="その他">その他</option>
                                </select>

                            </div>

                            <div className="c-button-set c-mb88">
                                <button id="confirm_member_info" className="button button__primary" type="submit">次へ</button>
                                <button id="back_member_info" onClick={(e)=>{onButtonClick(e)}} className="button button__secondary">戻る</button>
                            </div>

                        </form>

                    </article>

                </section>

            </main>


            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>

        </div>
    )
}


export default MembershipEdit

