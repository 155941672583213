import React, { useEffect, useState, useRef } from 'react';
//import { useHistory } from "react-router-dom"
//import { TournamentContext } from '../TournamentProvider'
import '../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
//import { IconScore, IconPlus, IconEdit } from "../../Icons";
import { getUser } from "../../../Database";
import Tournament025MemberData from './Tournament025MemberData'

function Tournament025GroupNumber(props) {

    //    const [value, setValue] = useContext(TournamentContext);
    //    const history = useHistory();
    const [groupData] = useState(props.groupData);
    const [groupNb, setGroupNb] = useState(0);
    const [userDataList, setUserDataList] = useState([]);

    useEffect(() => {
        if (groupData === undefined) return;
        setGroupNb(groupData.groupNb);
        setUserDataList(groupData.userDataList);
    }, [groupData]);

    useEffect(() => {
        if (userDataList === undefined) return;
        //        document.getElementById("group_number").innerText = groupData.groupNumber;//

    }, [userDataList, groupNb]);

    const renderMemberData = () => {
        if (userDataList !== undefined && userDataList.length !== 0) {
            return userDataList.map((data, i) =>
                <Tournament025MemberData key={data.key} userData={data}>
                </Tournament025MemberData>)
        } else {
            return null;
        }
    }

    return (

        <div className="c-container-horizontal c-mt8">  {/*<!-- 会員グループ一つ -->*/}
            {/*<!-- 組番号 -->*/}
            <div className="c-data__slot c-data__slot--56 c-data__slot--variable-height">{groupNb}</div>
            {/*<!-- 会員データグループ -->*/}
            <div className="c-container-vertical">
                {/*<!-- 会員データグループ -->*/}
                {renderMemberData()}
            </div>

        </div>
    )
}


export default Tournament025GroupNumber

