import React, { useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { CourseContext, originalInitialState } from './CourseProvider'
import ManagementFooter from '../ManagementFooter'
import logo from "../../images/logo.svg";
import '../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconCourse, IconPlus, IconEdit } from "../Icons";


function Course(props) {
    const [value, setValue] = useContext(CourseContext);
    const history = useHistory();
    useEffect(() => {
        //グローバル変数を初期化する
        setValue(lodash.cloneDeep(originalInitialState));
    }, []);

    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "button_back":
                history.push("/");
                break;
            case "courseAdd":
                history.push("CourseAddStep1");
                break;
            case "courseEdit":
                //コース変更の場合は必要ないので配列を削除
                value.CourseAddStep2.arrayCourseList.splice(0);
                history.push("CourseEditStep1");
                break;
            default:
                break;
        }
    };

    return (
        <div className="c-screen c-screen--gunmetal">
            <header className="c-container-vertical c-mt56">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt32">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconCourse></IconCourse>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">ゴルフ場管理</h1>
                </div>
            </header>

            <main>

                <div className="c-container-horizontal c-mt144">
                    <button id="courseAdd" onClick={(e) => { onButtonClick(e) }} className="kanri-option">
                        <div className="kanri-option__icon">
                            <figure className="c-icon c-icon--white c-icon--32">
                                <IconPlus></IconPlus>
                            </figure>
                        </div>
                        <h1 className="c-heading--1 kanri-option__text">ゴルフ場追加</h1>
                    </button>

                    <button id="courseEdit" onClick={(e) => { onButtonClick(e) }} className="kanri-option">
                        <div className="kanri-option__icon">
                            <figure className="c-icon c-icon--white c-icon--32">
                                <IconEdit></IconEdit>
                            </figure>
                        </div>
                        <h1 className="c-heading--1 kanri-option__text">登録済みの<br></br>ゴルフ場変更</h1>
                    </button>
                </div>

                <div className="c-button-set c-mt88 c-mb88">
                    <button id="button_back" onClick={(e) => { onButtonClick(e) }} className="button button__secondary">戻る</button>
                </div>

            </main>
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default Course

