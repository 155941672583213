import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import { AnnouncementContext } from './AnnouncementProvider';
import ManagementFooter from '../ManagementFooter'
import logo from "../../images/logo.svg";
import '../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconAnnouncement } from "../Icons";


function AnnouncementConfirm(props) {
    const [value, setValue] = useContext(AnnouncementContext);
    const [inputTitle] = useState(value.input_title);
    const [sendNotification] = useState(value.send_notification);
    const history = useHistory();
    useEffect(() => {
        document.getElementsByTagName("*")["input_title"].innerText = inputTitle;
        document.getElementsByTagName("*")["send_notification"].innerText = sendNotification;
    }, [inputTitle, sendNotification]);


    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "modifyAnnouncement":
                history.push("Announcement");
                break;
            case "sendAnnouncement":
                history.push("AnnouncementCompleted");
                break;



            default:
                break;
        }
    };

    return (
        <div className="c-screen c-screen--gunmetal">
            <header className="c-container-vertical c-mt88">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconAnnouncement></IconAnnouncement>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">お知らせ送信確認画面</h1>
                </div>
            </header>
            <main>

                <section className="kanri-container">

                    <div className="c-container-vertical c-mt32">
                        <div className="confirm confirm__vertical">
                            <span className="confirm confirm__label">ユーザー属性</span>
                            <p className="confirm confirm__field c-paragraph" id="">全ユーザー</p>
                        </div>
                    </div>

                    <div className="c-container-vertical c-mt32">
                        <div className="confirm confirm__vertical">
                            <span className="confirm confirm__label">お知らせタイトル</span>
                            <p className="confirm confirm__field c-paragraph" id="input_title">入力されたタイトル</p>
                        </div>
                    </div>

                    <div className="c-container-vertical c-mt32">
                        <div className="confirm confirm__vertical">
                            <span className="confirm confirm__label">お知らせ送信内容</span>
                            <p className="confirm confirm__field confirm__field--textarea c-paragraph" id="send_notification">ここに書いた文章が表示されます。ここに書いた文章が表示されます。 ここに書いた文章が表示されます。ここに書いた文章が表示されます。 ここに書いた文章が表示されます。 ここに書いた文章が表示されます。ここに書いた文章が表示されます。 ここに書いた文章が表示されます。ここに書いた文章が表示されます。 ここに書いた文章が表示されます。</p>
                        </div>
                    </div>

                    <div className="c-button-set c-mb88">
                        <button id="sendAnnouncement" className="button button__primary" onClick={(e) => { onButtonClick(e) }}>送信</button>
                        <button id="modifyAnnouncement" className="button button__secondary" onClick={(e) => { onButtonClick(e) }}>修正する</button>
                    </div>

                </section>

            </main>
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>

    )
}
export default AnnouncementConfirm

