import React, { useEffect, useState, useRef } from 'react';
//import { useHistory } from "react-router-dom"
//import { TournamentContext } from '../TournamentProvider'
import '../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
//import { IconScore, IconPlus, IconEdit } from "../../Icons";
import { getUser } from "../../../Database";

function Tournament024MemberData(props) {

    //    const [value, setValue] = useContext(TournamentContext);
    //    const history = useHistory();
    const [userData, setProps] = useState(undefined);
    const [userDatakey, setUserDatakey] = useState(undefined);
    //コンポーネント作成時のkeyが同じだとコンポーネントが使いまわしされるので↑のconst stateは作られたときに一回だけ初期化される

    useEffect(() => {
        //propsのデータが変わったら、stateに値をセットすれば使いまわされているconst stateに新しくデータが代入される
        if (props.userData === undefined) return;
        setProps(props.userData);
        setUserDatakey(props.userData.key);
    }, [props.userData]);

    useEffect(() => {
        //stateが変わったら、設定するようにする
        if (userData === undefined || userDatakey === undefined) return;
        let entry_flg = "entry_flg" + userDatakey;
        let serial_number = "serial_number" + userDatakey;
        let family_name = "family_name" + userDatakey;
        let family_name_kana = "family_name_kana" + userDatakey;
        let tours_count_0 = "tours_count_0" + userDatakey;
        let tours_count_1 = "tours_count_1" + userDatakey;
        let str_entry_flg="";
        switch (userData.entry_flg) {
            case 0:
                str_entry_flg="無";
                break;
            case 1:
                str_entry_flg="有";
                break;
            case 2:
                str_entry_flg="辞";
                break;
            default:
                str_entry_flg="無";                
                break;
        }
        document.getElementById(entry_flg).innerText = str_entry_flg;
        document.getElementById(serial_number).innerText = userData.serialNb;
        document.getElementById(family_name).innerText = userData.given_name_kanji;
        document.getElementById(family_name_kana).innerText = userData.family_name_kana;
        document.getElementById(tours_count_0).innerText = userData.tour_counts[0];
        document.getElementById(tours_count_1).innerText = userData.tour_counts[1];

    }, [userData, userDatakey]);

    const onClick = (e) => {
        props.callback(userData);
    }
    return (

        <div className="c-container-horizontal">    {/*<!-- 会員一人 -->*/}
            <div className="c-data__slot" id={"entry_flg" + userDatakey}>有</div>
            <div className="c-data__slot c-data__slot--232" id={"serial_number" + userDatakey}>p000001</div>
            <div className="c-data__slot c-data__slot--232" id={"family_name" + userDatakey}>太郎山田</div>
            <div className="c-data__slot c-data__slot--232" id={"family_name_kana" + userDatakey}>ヤマダタロウ</div>
            <div className="c-data__slot c-data__slot--144">
                <div className="double-slot">
                    <span className="double-slot__slot" id={"tours_count_0" + userDatakey}>0</span> {/*関東*/}
                    <span className="double-slot__slot" id={"tours_count_1" + userDatakey}>1</span> {/*関西*/}
                </div>
            </div>
            <div className="c-data__slot">
                <button id={"delete_member" + userDatakey} className="button button__data button__data--red" onClick={(e) => { onClick(e) }}>削除</button>
            </div>
        </div>
    )
}


export default Tournament024MemberData

