import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { TournamentContext, tournamentInitialState } from '../TournamentProvider'
import ManagementFooter from '../../ManagementFooter'
import logo from "../../../images/logo.svg";
import { IconTournament, IconCompletion } from "../../Icons";
import '../../../styles/styles.css';
import { checkTournamentTourCountsArray, getDate, addScoreDataTournamentUserDatas, getEntryAllCount, individualAddition, deleteTournamentUser, getTournamentLikeSearchUser, getTournamentAllUsersInTheArea, finish_allocation_function, finish_individual_addition_function, makeTournamentUser, getTournamentAllUserList, getUserSexFromTournamentSex, saveTournamentAllUserList } from "../TournamentUtility";
import { setGroupingFlgTournament, deleteTournamentEntryUser, getTournamentPaymentUserData, setTournamentPaymentUserData, getParticipationTournament, sortArray, setTournamentAllUserEntryFlg, getUsersNews, setUsersNews, likeSearchUser, initAllUsersTourCount, publishTournamentNewsAllUsersDel, addUserTournament, updateTournamentPart, deleteTestUser, testFunction, getTournamentEntryFlg, setTournamentStatus, getUserDataTournament, deleteAllUserTournament, setCopyUser, getAllUsersInTheArea, setTournamentAllocationFlg } from "../../../Database";
import Tournament024MemberData from './Tournament024MemberData'
import '../../../styles/loader.css'
import Loader from '../../Loader'



function Tournament_024(props) {

    const [value] = useContext(TournamentContext);
    const history = useHistory();

    const [tournament009Data] = useState(value.tournament009Data);                //グローバル参照渡し
    const [tournament024Data] = useState(value.tournament009Data.tournamentData);                //グローバル参照渡し  
    const [tournament025Data] = useState(value.tournament025Data);                //グローバル参照渡し  
    const [searchResults, setSearchResults] = useState([]);
    const [userDataList, setUserDataList] = useState([]);
    const [individualAdditionMessage, setIndividualAdditionMessage] = useState(undefined);

    const [loadingData, setLoadingData] = useState(undefined);
    useEffect(() => {
        if (tournament024Data === undefined) return;
        //検索エラーを一旦非表示
        document.getElementsByTagName("*")["search_error"].style.display = "none";

        let title = tournament024Data.eventDate + tournament024Data.title + "[" + tournament024Data.area + "]"

        let endry_period = tournament024Data.entryStartDate + "～" + tournament024Data.entryFinisDate;
        //エントリー期間
        document.getElementById("endry_period").innerText = endry_period;

        //        let dateTime=getDate();
        document.getElementById("tournament_title").innerText = title;
        //エントリー人数
        document.getElementById("now_entry").innerText = 0;
        //最大エントリー数
        document.getElementById("max_entry").innerText = tournament024Data.max_entry;
        /*
                //大会データ内のユーザー決算情報を取得
                const asyncfunc = (async () => {
                    await getTournamentPaymentUserData("G55obaJvm0ss7LO3aCW0","gEwm46DA1WfNzGN1FpT1iLet9Fz1", (result,status) => {
                        console.log(status);
                    });
                });
        
                asyncfunc();
        */


        /*
                //大会データ内のユーザー決算情報を設定
                const asyncfunc = (async () => {
                    await setTournamentPaymentUserData("G55obaJvm0ss7LO3aCW0","4wrsAIyxboH0Hj1m3Lhz",{order_id_paymnet:-2,tournamentId:"adfasdf",uid:"asdfasdfas"}, (result) => {
                    });
                });
        
                asyncfunc();
        */
        /*
                //大会に参加している全てのユーザーのエントリーフラグを設定する
                const asyncfunc = (async () => {
                    await setTournamentAllUserEntryFlg("2ZTzGIeuiLfmEBvGApDy", 1, (result) => {
                    });
                });
        
                asyncfunc();
          */
        /* 
        //ニュース取得
        const asyncfunc = (async () => {
            getUsersNews("6JUeTBMBjacD8otDJ5xO01i83NL2",(result,newsList) => {
                newsList.forEach((newsData)=>{
                    newsData.view_flag=1;
                    //ニュース設定
                    setUsersNews("6JUeTBMBjacD8otDJ5xO01i83NL2",newsData,(result)=>{
                        console.log(result);
                    });

                })
            });
        });

        asyncfunc();
        */
        /*
                //トーナメントカウント初期化
                const asyncfunc = (async () => {
                    initAllUsersTourCount((result) => {
                    });
                });
        
                asyncfunc();
*/
        /*
                        //ニュース削除
                        const asyncfunc = (async (count) => {
                            publishTournamentNewsAllUsersDel( (result) => {
                            });
                        });
                
                        asyncfunc();
                        */

        /*
        //参加大会の取得
        const asyncfunc = (async (count) => {
           getParticipationTournament("gEwm46DA1WfNzGN1FpT1iLet9Fz1",(result)=>{
           });
       });
 
       asyncfunc();
*/


        /*
         //大会データ取得
         testFunction("getParticipationTournament","",(result,data)=>{
            if(result===true){
                console.log(data);
            }
         });
         */
        /*
         //大会に参加しているユーザーの決済データを取得する
         testFunction("getTournamentPaymentUserData","",(result,data)=>{
            if(result===true){
                console.log(data);
            }
         });
         */
        /*
                 //大会に参加しているユーザーの決済データを設定する
                 testFunction("setTournamentPaymentUserData","",(result,data)=>{
                    if(result===true){
                        console.log(data);
                    }
                 });
        */

        /*
//APIでニュース取得＆ニュース設定
             testFunction("getUsersNews","",(result,data)=>{
                 if(result===true){
                    data.message.newsList.forEach((newsData)=>{
                        newsData.view_flag=1;
                        testFunction("setUsersNews",newsData,(result)=>{
                            if(result===true){
                                console.log("OKOK");
                            }
 
                        });
                    })
                 }
             });
*/
        /*
        const asyncfunc = (async (count) => {
            await setTournamentEntryFlg("VwIavVIf7mTnLpdydPKr", "7857tOBhAkaWN336I4b2",1,async (result)=>{
                await getTournamentEntryUser("VwIavVIf7mTnLpdydPKr", "7857tOBhAkaWN336I4b2", (result,data) => {
//                    console.log(result,data);
                });
    
            });



        });

        asyncfunc();
*/
            /*
                 //テストユーザー削除
                 const asyncfunc2 = (async (count) => {
                     deleteTestUser((result)=>{
                     });
                 });
         
                 asyncfunc2();
                 */
                /*
                        //テストユーザー作成
                        const asyncfunc = (async (count) => {
                            let userData = await setCopyUser("3VYd33rJHlhVipp9OwWYZTPZAy12", count);
                            userData = await setCopyUser("6JUeTBMBjacD8otDJ5xO01i83NL2", count);
                            userData = await setCopyUser("Eyqw3hrAWKd0qOBW6jzy8wF5Rsu1", count);
                            userData = await setCopyUser("LhowaydJ0ObPcOJ2IJova3tzaqg1", count);
                            userData = await setCopyUser("M5SWKPVKUGNqcJgaYMOt1fywvv52", count);
                        });
                        for (let i = 2; i < 30; i++) {
                            asyncfunc(i);
                        }
                        */
                        
        /*        
                        const asyncfunc = (async () => {
                            let userData = await getUserDataTournament("5kDunZSDKBPtQjIGtzS7","g4u69IAW2dZQRs1KckvQcOyI3Ys1",3,(result,userData)=>{
                                console.log(userData);
                            });
        //                    let userData = await searchUserTournament("g4u69IAW2dZQRs1KckvQcOyI3Ys1");
                            console.log(userData);
                        });
                        asyncfunc();
         */

        //個別追加した時のメッセージ（追加してからの表示なのでここでは非表示）
        document.getElementById("individual_addition_message").style.display = "none";

        //とりあえず全部非活性
        document.getElementById("allocate_players").disabled = true;            //エントリーの最大人数で割り振る
        document.getElementById("finish_allocation").disabled = true;           //割り振り完了
        document.getElementById("show_member_info").disabled = true;            //この選手を追加する
        document.getElementById("finish_individual_addition").disabled = true;  //個別追加完了
        document.getElementById("button_grouping").disabled = true;                //組み分けする

        document.getElementById("all_entry").style.display = "none";                //とりあえずエントリーは非表示（デバッグ時にしか使わない）
        document.getElementById("init_test").style.display = "none";                //テストデータ初期化は非表示（デバッグ時にしか使わない）
        document.getElementById("allocation_number_input").value = tournament024Data.max_entry; //エントリーの最大人数入力の初期化


        //割り当てフラグ確認            
        if (tournament024Data.allocationFlg === 0) {
            //エントリーの最大人数で割り振るは有効
            document.getElementById("allocate_players").disabled = false;
            //割り当て完了ボタン非活性にする
            document.getElementById("finish_allocation").disabled = true;
        } else {
            const asyncfunc = (async (count) => {
                if (tournament025Data.unmodifiedUserDataList.length > 0) {
                    //エントリー人数
                    if (document.getElementById("now_entry") !== null) {
                        document.getElementById("now_entry").innerText = getEntryAllCount(tournament025Data.unmodifiedUserDataList);
                    }
                    document.getElementById("button_grouping").disabled = false;                //組み分けする
                    //ツアー参加回数の配列の大きさが０の場合増やしておく
                    let tmpUserDataList = checkTournamentTourCountsArray(lodash.cloneDeep(tournament025Data.unmodifiedUserDataList));
                    setUserDataList(tmpUserDataList);
                    //ローデング非表示
                    setLoadingData({ loadingFlg: false, title: "書き込み中です..." });
                } else {
                    //設定済なので割り当てユーザーを読み込み
                    await getTournamentAllUserList(tournament024Data.id, (resutl, entryCount, userDataFormatList) => {
                        if (resutl === false) {
                            //名前はuserDataListだけどエラーの原因が入ってる
                            //                  ↓
                            alert("データベースの更新に失敗しました=" + entryCount);
                        } else {

                            //エントリー人数
                            if (document.getElementById("now_entry") !== null) {
                                document.getElementById("now_entry").innerText = entryCount;
                            }

                            document.getElementById("button_grouping").disabled = false;                //組み分けする
                            //ツアー参加回数の配列の大きさが０の場合増やしておく
                            let tmpUserDataList = checkTournamentTourCountsArray(lodash.cloneDeep(userDataFormatList));
                            setUserDataList(tmpUserDataList);
                            //ローデング非表示
                            setLoadingData({ loadingFlg: false, title: "ユーザーを読み込み中・・・" });
                        }
                    });

                }

                if (document.getElementById("show_member_info") !== null) {
                    //組み分けが完了していたら個別追加は出来なくなる
                    if (tournament024Data.grouping_flg !== 1) {
                        //この選手を追加する
                        document.getElementById("show_member_info").disabled = false;
                        //個別追加完了
                        document.getElementById("finish_individual_addition").disabled = false;
                    }

                }
            });
            //ローデング表示
            setLoadingData({ loadingFlg: true, title: "ユーザーを読み込み中・・・" });

            asyncfunc();


        }
    }, [tournament024Data, tournament025Data]);

    //個別追加時のメッセージ表示
    useEffect(() => {
        if (individualAdditionMessage === undefined) return;

        const setTimeoutId = setTimeout(() => {
            document.getElementById("individual_addition_message").style.display = "none";
            //終わったのでundefined
            setIndividualAdditionMessage(undefined);
        }, 2000);

        document.getElementById("individual_addition_message").style.display = "";

        return () => {
            clearTimeout(setTimeoutId);
        };
    }, [individualAdditionMessage]);

    //検索処理
    const onSubmitSearch = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();

        const { search_keyword_text } = e.target.elements;
        const search = (async () => {

            //検索ボタン非活性化
            if (document.getElementById("search_button") !== null) {
                document.getElementById("search_button").disabled = true;
            }

            await getTournamentLikeSearchUser(tournament024Data, search_keyword_text, userDataList, (result, tempSearchUsers) => {
                if (tempSearchUsers.length > 0) {
                    let number_of_searches = tempSearchUsers.length + "件";
                    if (document.getElementsByTagName("*")["number_of_searches"] !== null) {
                        document.getElementsByTagName("*")["number_of_searches"].innerHTML = number_of_searches;
                    }
                    //ツアー参加回数の配列の大きさが０の場合増やしておく
                    tempSearchUsers = checkTournamentTourCountsArray(tempSearchUsers);
                    setSearchResults(tempSearchUsers);
                } else {
                    //検索エラーを一表示
                    if (document.getElementsByTagName("*")["search_error"] != null) {
                        document.getElementsByTagName("*")["search_error"].style.display = "";
                        document.getElementsByTagName("*")["number_of_searches"].innerHTML = 0;

                    }
                }
                //検索ボタン活性化
                if (document.getElementById("search_button") !== null) {
                    document.getElementById("search_button").disabled = false;
                }

            });

        });
        search();
    }


    /**
     * 変更反映
     * @param {*} e 
     */
    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "button_back":
                value.tournament025Data.unmodifiedUserDataList.length = 0;
                value.tournament025Data.userDataList.length = 0;
                history.push("Tournament_009");
                break;
            case "button_grouping":
                //組を作成するので、エントリーユーザーのみのデータをtournament025Dataにデータを渡す
                let userDataEntryList = userDataList.filter((userData) => {
                    //エントリーユーザと未削除ユーザー
                    return userData.entry_flg === 1 && userData.deleteFlg === 0;
                })
                //トーナメントデータを渡す
                value.tournament025Data = lodash.cloneDeep(tournament024Data);
                value.tournament025Data.userDataList = lodash.cloneDeep(userDataEntryList);
                //削除されたユーザーは最終決定でツアー参加回数を減らすので、全てのユーザーデータを渡す
                value.tournament025Data.unmodifiedUserDataList = lodash.cloneDeep(userDataList);
                let checkUserDataCount = userDataEntryList.length % 4;
                let groupLength = Math.floor(userDataEntryList.length / 4) + ((userDataEntryList.length % 4) > 0 ? 1 : 0);;
                //0=OK 1=組が3組以上 ,2=組が2組以上
                switch (checkUserDataCount) {
                    case 0: //OK
                    case 3:
                        history.push("Tournament_025");
                        break;
                    case 1:
                        //組が3組以上OK
                        if (groupLength >= 3) {
                            history.push("Tournament_025");
                        } else {
                            alert("最終組の人数が" + checkUserDataCount + "人で" + (groupLength + 2) + "組以下なのでエントリーしているユーザーが足りません");
                        }
                        break;
                    case 2:
                        //組が2組以上OK
                        if (groupLength >= 2) {
                            history.push("Tournament_025");
                        } else {
                            alert("最終組の人数が" + checkUserDataCount + "人で" + (groupLength + 1) + "組以下なのでエントリーしているユーザーが足りません");
                        }
                        break;
                    default:
                        break;
                }

                break;
            case "all_entry":

                //大会に参加している全てのユーザーのエントリーフラグを設定する
                const asyncfunc = (async () => {
                    await setTournamentAllUserEntryFlg(tournament024Data.id, 1, async (result) => {
                        //ローデング非表示
                        setLoadingData({ loadingFlg: false, title: "エントリー設定中・・・" });
                        history.push("Tournament_001");
                    });
                });
                //ローデング表示
                setLoadingData({ loadingFlg: true, title: "エントリー設定中・・・" });
                asyncfunc();

                break;
            case "init_test":
                const asyncfunc2 = (async () => {

                    await deleteTournamentEntryUser(tournament024Data.id, async (result) => {

                        //トーナメントカウントの初期化
                        await initAllUsersTourCount(async (result) => {
                            //ニュースの初期化
                            await publishTournamentNewsAllUsersDel(async (result) => {
                                //大会の割り当てフラグを設定する
                                await setTournamentAllocationFlg(tournament024Data.id, 0, async (result) => {
                                    //組み分け確定フラグ無効
                                    await setGroupingFlgTournament(tournament024Data.id, 0, async (result) => {

                                        //ローデング非表示
                                        setLoadingData({ loadingFlg: false, title: "テストデータ初期化中・・" });
                                        history.push("Tournament_001");
                                    });
                                });
                            });
                        });

                    });
                });
                setLoadingData({ loadingFlg: true, title: "テストデータ初期化中・・・" });
                asyncfunc2();
                break;

            default:
                break;
        }
    };

    /**
     * エントリーの最大人数で割り振る
     */
    const onSubmitAllocation = (e) => {
        e.preventDefault();

        switch (document.activeElement.id) {
            case "allocate_players":            //最大人数で割り振り
                const asyncfunc = (async () => {

                    await getTournamentAllUsersInTheArea(tournament024Data, (result, tmpUserDataList) => {
                        //割り振り完了ボタンを活性化
                        if (document.getElementById("finish_allocation") !== null) {
                            document.getElementById("finish_allocation").disabled = false;
                            //データベースでランダム順になっているので不用
                            //                tmpUserDataList = shuffle(tmpUserDataList);
                            setUserDataList([...tmpUserDataList]);
                        }
                    })
                });

                asyncfunc();
                document.getElementById("allocate_players").disabled = true;            //エントリーの最大人数で割り振る
                break;
            case "finish_allocation":       //割り振り完了
                //割り振り完了以下処理
                //データベースに書き込み
                //大会の割り当てフラグを設定する
                //戻っただけではキャッシュで0なのでグローバルの変数にも
                //ニュース発行
                const asyncfunc2 = (async () => {
                    await finish_allocation_function(tournament024Data, userDataList, (result, status) => {
                        if (result === false) {
                            alert("データベースの更新に失敗しました=" + status);
                        }

                        if (document.getElementById("allocate_players") !== null) {

                            //この選手を追加する
                            document.getElementById("show_member_info").disabled = false;
                            //個別追加完了活性
                            document.getElementById("finish_individual_addition").disabled = false;
                            //組み分けする活性
                            document.getElementById("button_grouping").disabled = false;
                            //リストUIにも反省させる
                            setUserDataList([...userDataList]);
                            //ローデング非表示
                            setLoadingData({ loadingFlg: false, title: "割り振り完了処理中・・・" });

                        }
                    });
                });
                //ローディングテキストを変更
                setLoadingData({ loadingFlg: true, title: "割り振り完了処理中・・・" });
                asyncfunc2();
                if (document.getElementById("allocate_players") !== null) {
                    //エントリーの最大人数で割り振るは非活性
                    document.getElementById("allocate_players").disabled = true;
                    //割り当て完了ボタン非活性にする
                    document.getElementById("finish_allocation").disabled = true;
                }
                break;
            default:
                break;
        }

    }

    /**
     * 個別追加
     * @param {*} e 
     * @returns 
     */
    const onSubmitIndividualAddition = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();
        const { results_list,

        } = e.target.elements;

        switch (document.activeElement.id) {
            case "show_member_info":            //この選手を追加する
                const asyncfunc2 = (async () => {
                    await individualAddition(tournament024Data, results_list, userDataList, searchResults, (result, searchResults) => {
                        //追加できた
                        if (result === true) {
                            document.getElementById("now_entry").innerText = getEntryAllCount(userDataList);
                            document.getElementsByTagName("*")["number_of_searches"].innerHTML = searchResults.length + "件";

                            setSearchResults([...searchResults]);

                            setIndividualAdditionMessage(true);

                        } else {
                            //エラー
                            alert("最大人数より多い人数は追加できません");
                        }

                    })

                });
                asyncfunc2();

                break;
            case "finish_individual_addition":  //個別追加完了
                document.getElementById("finish_individual_addition").disabled = true;  //個別追加完了
                //個別追加完了処理（以下の処理を実行している）
                //最大エントリー数を保存
                //大会に参加するユーザーデータを全て削除
                //データベースに書き込み
                const asyncfunc = (async () => {
                    await finish_individual_addition_function(tournament024Data.id, "max_entry", tournament024Data.max_entry, userDataList, (result, status) => {
                        if (result === false) {
                            alert("データベースの更新に失敗しました=" + status);
                        } else {
                            document.getElementById("finish_individual_addition").disabled = false;  //個別追加完了
                        }
                        //ローデング非表示
                        setLoadingData({ loadingFlg: false, title: "ユーザーを追加中・・・" });

                    });
                });
                //ローデング表示
                setLoadingData({ loadingFlg: true, title: "ユーザーを追加中・・・" });
                asyncfunc();
                break;
            default:
                break;
        }

    }

    const chechMaxEntry = (e) => {
        let max_entry = 0;
        max_entry = document.getElementById("allocation_number_input").value;
        if (userDataList.length > max_entry) {
            document.getElementById("allocation_number_input").value = tournament024Data.max_entry;
            alert("割り振られた人数より少なくできません　ユーザー数=" + userDataList.length);

        } else {
            tournament024Data.max_entry = max_entry;
            //最大エントリー数
            document.getElementById("max_entry").innerText = max_entry;

        }

    }
    //エントリー最大人数が変更されたとき
    const eventAllocation_number_input = (e) => {
        switch (e.key) {
            case "Enter":
                chechMaxEntry(e);
                break;
            default:
                if (e.type === "blur") {
                    chechMaxEntry(e);
                }
                break;
        }
    }


    /**
     * 削除ボタンを押したときの処理
     * 割り振る前（データベースのカウントは進んでいない）
     * 割り振りの後（データベースのカウントは進んでいる）
     * 
     * @param {*} userData 
     */
    const deleteUser = (userData) => {
        const asyncfunc = (async () => {
            //削除する
            await deleteTournamentUser(tournament024Data, userDataList, userData, (result, resUserDataList) => {
                document.getElementById("now_entry").innerText = getEntryAllCount(userDataList);

                setUserDataList(resUserDataList);
            });

        });
        asyncfunc();
    }
    //--------------------------
    const renderUserList = () => {
        if (searchResults !== undefined) {
            return searchResults.map((d, index) => <option key={d.serial_nb + (Math.random() * 1000)} value={d.serial_nb}>{d.serial_nb} {d.given_name_kanji} {d.family_name_kanji}（{d.given_name_kana} {d.family_name_kana}）{d.phone_number} {d.delete_flg === 1 ? "削除" : ""}</option>);

        } else {
            return null;
        }
    }

    const renderMemberData = () => {
        if (userDataList !== undefined) {
            return userDataList.map((data, i) => {
                if (data.deleteFlg === 0) {
                    return <Tournament024MemberData key={data.key} userData={data} callback={deleteUser}>
                    </Tournament024MemberData>

                }
                return undefined;
            })
        } else {
            return null;
        }
    }

    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <header className="c-container-vertical c-mt88">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconTournament></IconTournament>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">エントリー選手閲覧・変更画面</h1>
                </div>
            </header>

            {/*---------------------------------------------------------*/}
            <main>
                <article>

                    <section className="c-poster c-radius-up--32 c-mt56">
                        <h2 className="c-heading--1" id="tournament_title">2021/08/01第1回JCGツアー[関東]</h2>
                        <h3 className="c-heading--2 c-mt8" id="entry_open_close_date_time">エントリー期間</h3>
                        <p className="c-paragraph" id="endry_period">2021年6月13日10時~2021年6月14日19時</p>

                        {/*<!-- エントリー済み部分開始。 -->*/}
                        <div className="c-container-horizontal c-container-horizontal--center c-mt32">
                            <span className="c-subtitle">エントリー済みの割合：</span>
                            <span className="c-subtitle" id="now_entry">50</span>
                            <span className="c-subtitle">/</span>
                            <span className="c-subtitle" id="max_entry">100</span>
                        </div>

                        {/*<!-- エントリー済み部分終了。 -->*/}

                        {/*<!-- 会員データ開始 -->*/}
                        <div className="c-container-vertical c-container-vertical--gunmetal c-padding--8 c-radius--8 c-mt32">

                            {/*<!-- カラムタイトル -->*/}
                            <div className="c-container-horizontal">
                                <div className="c-data__title c-data__title--dark-slate-gray">有無辞</div>
                                <div className="c-data__title c-data__title--232 c-data__title--dark-slate-gray">選手ID</div>
                                <div className="c-data__title c-data__title--232 c-data__title--dark-slate-gray">選手名</div>
                                <div className="c-data__title c-data__title--232 c-data__title--dark-slate-gray">フリガナ</div>
                                <div className="c-data__title c-data__title--144 c-data__title--dark-slate-gray">
                                    <div className="double-slot double-slot--vertical">
                                        <span>ツアー参加回数</span>
                                        <div className="double-slot">
                                            <span className="double-slot double-slot__slot">関東</span>
                                            <span className="double-slot double-slot__slot">関西</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="c-data__title c-data__title--dark-slate-gray">状態</div>
                            </div>

                            {/*<!-- データテーブルコンテナ -->*/}
                            <div className="c-container-vertical">

                                {/*<!-- 会員グループ一つ -->*/}
                                {renderMemberData()}


                            </div>

                        </div>{/*<!-- 会員データ終了。 -->*/}

                        <button id="csv_export" className="button button__tertiary c-mt16">全選手をcsvで保存する</button>

                        <form onSubmit={onSubmitAllocation} >

                            <div className="kanri-container">
                                <div className="c-container-vertical c-mt32">
                                    <label htmlFor="allocation_number_input" className="input input__label">エントリーの最大人数を入力してください</label>
                                    <input type="number" name="allocation_number_input" id="allocation_number_input" min="0" placeholder="例：１００" autoComplete="" className="input input__field" onBlur={(e) => eventAllocation_number_input(e)} onKeyDown={eventAllocation_number_input} />
                                </div>
                            </div>

                            <div className="c-button-set">
                                <button id="allocate_players" className="button button__secondary" type="submit">エントリーの最大人数で割り振る</button>
                                <button id="finish_allocation" className="button button__secondary" type="submit">割り振り完了</button>
                            </div>
                            <div className="c-container-vertical c-container-vertical--center c-mt8">
                                <p className="c-warning">
                                    この大会の割り振りを完了し、メイン画面にエントリーボタンを表示する
                                </p>
                                <p className="c-warning">
                                    ※エントリー期間の間は、組み分けを行わないでください。
                                </p>
                            </div>
                        </form>

                        <div className="c-button-set">
                            <button id="all_entry" className="button button__secondary" onClick={(e) => { onButtonClick(e) }}>とりあえずすべてエントリー</button>
                        </div>
                        <div className="c-button-set">
                            <button id="init_test" className="button button__secondary" onClick={(e) => { onButtonClick(e) }}>テストデータ初期化</button>
                        </div>

                    </section>

                    <section className="c-poster c-poster--ivory c-top-triangle--white">
                        <h2 className="c-heading--1">選手追加</h2>

                        <form onSubmit={onSubmitSearch}>

                            <div className="kanri-container">
                                <div className="c-container-vertical c-mt32">
                                    <label htmlFor="" className="input input__label">選手情報</label>
                                    <input type="text" name="" id="search_keyword_text" minlenght="2" placeholder="選手ID,名前,生年月日,メールアドレス,電話番号等で検索" autoComplete="" required className="input input__field" />
                                    <span className="input input__validation input__validation--display" id="search_error">ユーザーが見つかりませんでした。キーワードを変えて検索してください。</span>
                                </div>
                            </div>

                            <div className="c-button-set">
                                <button id="search_button" className="button button__primary" type="submit">検索</button>
                            </div>

                        </form>

                    </section>
                    <section className="c-poster c-poster--pale-spring-bud c-top-triangle--ivory c-radius-down--32">

                        <div className="c-container-horizontal c-container-horizontal--center c-mt32">
                            <h3 className="c-heading--2 c-heading--center">検索結果</h3>
                            <span className="c-tag c-tag--gunmetal c-heading--2 c-radius--16 c-ml8" id="number_of_searches">0件</span>
                        </div>

                        <form className="c-container-vertical c-container-vertical--center" onSubmit={onSubmitIndividualAddition}>
                            <div className="kanri-container">
                                <div className="input input__set input__set--kanri c-mt32">
                                    <label htmlFor="" className="input input__label">リストから検索結果を選択してください。</label>
                                    <select name="" id="results_list" className="input input__field">
                                        <option value=""> - - -</option>
                                        {renderUserList()}
                                    </select>
                                </div>
                            </div>

                            <div className="c-button-set">
                                <button id="show_member_info" className="button button__primary">この選手を追加する</button>
                                <button id="finish_individual_addition" className="button button__secondary">個別追加完了</button>
                            </div>

                            <div className="c-container-horizontal c-mt32 animation--jelly" id="individual_addition_message">
                                <figure className="c-icon c-icon--16 c-icon--dark-slate-gray c-mr8">
                                    <IconCompletion></IconCompletion>
                                </figure>
                                <span className="c-subtitle">テーブルの下側に選択された会員が追加されました。</span>
                            </div>

                        </form>

                    </section>
                </article>


                <div className="c-button-set">
                    <button id="button_grouping" onClick={(e) => { onButtonClick(e) }} className="button button__primary">組み分けする</button>
                    <button id="button_back" onClick={(e) => { onButtonClick(e) }} className="button button__secondary c-mb88">戻る</button>
                </div>

            </main>

            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div >

    )
}



export default Tournament_024

