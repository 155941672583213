import React, { useEffect, useState } from 'react';
import CourseEditStep2HoleAndAtBatContainer from './CourseEditStep2HoleAndAtBatContainer'

function CourseEditStep2CourseContainer(props) {
    const [courseName, setCourseName] = useState(props.nowCourse.courseName);
    const [holeAndAtBatList, setHoleAndAtBatList] = useState(props.nowCourse.holeList);
    
    useEffect(() => {

    }, []);
    return (
        <div className="c-container-horizontal">    {/*<!-- コース名コンテナ -->*/}
            {/* <!-- コース名内容 -->*/}
            <div className="game-data__column">
                <span className="game-data__slot">{courseName}</span>
            </div>

            {/*<!-- コース名の子要素 -->*/}
            <div className="c-container-vertical">

                {/*ここに ホール名」と「規定打数」コンテナをホール数ループ*/}
                {/*動的にコンポーネントが増えていきます*/}
                {holeAndAtBatList.map(holeAndAtBatItem => (
                    <CourseEditStep2HoleAndAtBatContainer
                        key={courseName + "_" + holeAndAtBatItem.name}
                        nowHoleAndAtBatData={holeAndAtBatItem}
                    />
                ))}


            </div>{/*<!-- コース名の子要素 -->*/}
         {/*<!-- コース名コンテナ -->*/ }
        </div>
    )
}


export default CourseEditStep2CourseContainer

