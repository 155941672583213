import React from 'react';
// Notification Icon
function IconNotification (){

  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.814 31.96">
        <defs/>
        <g data-name="グループ 214">
          <g data-name="グループ 213">
            <path d="M4.799 12.748a9.611 9.611 0 016.389-9.059l.068-.021a3 3 0 01-.04-.5 3.20016 3.20016 0 116.4.064 3.07 3.07 0 01-.038.459v-.02a9.626 9.626 0 016.438 9.069V22.357l4.8 3.2v1.6H0v-1.6l4.8-3.2zM17.607 28.76a3.2 3.2 0 11-6.4 0z"/>
            <g data-name="パス 1697">
              <path d="M1.907 13.671a1 1 0 01-1-.95C.544 5.467 7.272 1.655 7.559 1.496a1.00023022 1.00023022 0 01.971 1.749c-.06.033-5.926 3.382-5.626 9.376a1 1 0 01-.949 1.048z" data-name="パス 1704"/>
            </g>
            <g data-name="パス 1698">
              <path d="M26.852 13.671h-.05a1 1 0 01-.95-1.048c.3-5.994-5.565-9.343-5.625-9.376a1.00023022 1.00023022 0 01.971-1.749c.287.159 7.015 3.971 6.652 11.225a1 1 0 01-.998.948z" data-name="パス 1705"/>
            </g>
          </g>
        </g>
      </svg>      
  )

}



function IconAnnouncement() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 28">
        <defs/>
        <path d="M32 11.414C32 5.135 30.163.041 27.891 0h-2.565a40.732 40.732 0 01-14.851 6.367 29.267 29.267 0 000 10.094 40.732 40.732 0 0114.851 6.367h2.565C30.163 22.787 32 17.693 32 11.414zM27.026 21.1a1.289 1.289 0 01-.773-.486 6.47 6.47 0 01-1.1-1.979 21.1 21.1 0 01-1.127-7.223 21.1 21.1 0 011.127-7.223 6.471 6.471 0 011.1-1.979 1.288 1.288 0 01.773-.486 1.288 1.288 0 01.773.486 6.464 6.464 0 011.1 1.979 21.1 21.1 0 011.127 7.223 21.1 21.1 0 01-1.126 7.225 6.469 6.469 0 01-1.1 1.979 1.288 1.288 0 01-.774.484zM7.869 11.414a30.61 30.61 0 01.345-4.659 30.223 30.223 0 01-4.386.323h-2.1L0 10.037v2.755l1.733 2.959h2.1a30.24 30.24 0 014.386.323 30.635 30.635 0 01-.345-4.659zm3.631 6.654l-4-.766 2.562 10.048a.835.835 0 001.146.583l3.7-1.483a.853.853 0 00.444-1.221L11.5 18.068zm15.521-2.92a.5.5 0 01-.3-.187 2.492 2.492 0 01-.421-.761 9.137 9.137 0 010-5.567 2.494 2.494 0 01.424-.763.331.331 0 01.6 0 2.49 2.49 0 01.424.763 9.136 9.136 0 010 5.567 2.5 2.5 0 01-.424.763.5.5 0 01-.298.185z"/>
      </svg>      
  )
}



function IconMembership(props) {

  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 32">
        <defs/>
        <path d="M27 0H3a3.009 3.009 0 00-3 3v26a3.009 3.009 0 003 3h24a3.009 3.009 0 003-3V3a3.009 3.009 0 00-3-3zm-1 28H4V4h22zM8 18h14v2H8zm0 4h14v2H8zm2-13a3 3 0 113 3 3 3 0 01-3-3zm5 3h-4c-1.65 0-3 .9-3 2v2h10v-2c0-1.1-1.35-2-3-2z"/>
      </svg>
  )
}

function IconCourse(props) {
   
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.286 32">
        <defs/>
        <path d="M8 7.429v20h1.143v-14.2L24 6.857 8 0zm2.286 14.408c.92-.08 1.876-.122 2.857-.122 7.259 0 13.143 2.3 13.143 5.143S20.4 32 13.143 32 0 29.7 0 26.857c0-1.949 2.772-3.645 6.857-4.518v3.146a1.65 1.65 0 00-1.143 1.372c0 .947 1.279 1.714 2.857 1.714s2.857-.767 2.857-1.714a1.65 1.65 0 00-1.143-1.372v-3.649zm7.429 5.592c.947 0 1.714-.512 1.714-1.143s-.768-1.143-1.714-1.143S16 25.655 16 26.286s.768 1.143 1.714 1.143z"/>
      </svg>
  )
}

function IconScore(props) {
   
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.107 32">
        <defs/>
        <path d="M25.107 32H0V0h25.107v32zm-11.393-9.143v6.857h9.143v-6.857zm-11.429 0v6.857h9.143v-6.857zm11.429-9.143v6.857h9.143v-6.857zm-11.429 0v6.857h9.143v-6.857zm11.429-9.143v6.857h9.143V4.572zm-11.429 0v6.857h9.143V4.572z" data-name="前面オブジェクトで型抜き 13"/>
      </svg>
  )
}



function IconTournament(props) {
   
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 28">
        <defs/>
        <path d="M26 4V0H6v4H0v4a6 6 0 007.8 5.725A10.005 10.005 0 0014 17.8V24h-2a4 4 0 00-4 4h16a4 4 0 00-4-4h-2v-6.2a10 10 0 006.2-4.075A6 6 0 0032 8V4zM6 11.625A3.629 3.629 0 012.375 8V6H6v2a9.976 9.976 0 00.655 3.565 3.63 3.63 0 01-.655.06zM29.625 8a3.621 3.621 0 01-4.28 3.565A9.976 9.976 0 0026 8V6h3.625z"/>
      </svg>
  )
}



function IconCompletion(props) {
 
  return (
      <svg xmlns="http://www.w3.org/2000/svg" data-name="okay icon" viewBox="0 0 88 91.028">
        <defs/>
        <path fillRule="evenodd" d="M42.98 5.068a42.767 42.767 0 1033.629 16.075l-4.047 4.8c5.131 6.313 7.138 13.328 7.138 22.105a36.721 36.721 0 11-36.72-36.721c6.433 0 11.245.925 16.5 3.826l3.845-5.049A41.073 41.073 0 0042.98 5.067z" data-name="Path 1700"/>
        <path d="M27.073 42.396q2.6 0 3.933 4.266 2.666 8 3.8 8 .867 0 1.8-1.333Q55.342 23.331 71.269 4.801q4.132-4.8 13.132-4.8a6.813 6.813 0 012.866.4 1.161 1.161 0 01.733 1q0 .933-2.2 3.666a647.52 647.52 0 00-47.728 65.326q-1.533 2.4-6.266 2.4-4.8 0-5.666-.4-2.267-1-5.333-10.2-3.466-10.2-3.466-12.8 0-2.8 4.666-5.4a10.7 10.7 0 015.066-1.6" data-name="Path 1701"/>
      </svg>
  )
}

function IconPlus(props) {
 
  return (
      <svg xmlns="http://www.w3.org/2000/svg" data-name="icon add" viewBox="0 0 32 32">
        <defs/>
        <path d="M31 12H20V1a1 1 0 00-1-1h-6a1 1 0 00-1 1v11H1a1 1 0 00-1 1v6a1 1 0 001 1h11v11a1 1 0 001 1h6a1 1 0 001-1V20h11a1 1 0 001-1v-6a1 1 0 00-1-1z"/>
      </svg>
  )
}


function IconEdit(props) {
 
  return (
      <svg xmlns="http://www.w3.org/2000/svg" data-name="icon edit" viewBox="0 0 29.981 32">
        <defs/>
        <path d="M25.868 12.3L13.415 24.772 10.54 21.9 23.001 9.435zm.78-.781L23.78 8.651l1.906-1.906a1.1 1.1 0 011.563 0l1.3 1.3a1.1 1.1 0 01.005 1.562l-1.908 1.91zM9.825 22.739l2.753 2.753-3.459.681.706-3.435zM20.966 9.927V6.625a2.205 2.205 0 00-2.2-2.211H17.66v1.1a2.208 2.208 0 01-2.21 2.212H5.525a2.209 2.209 0 01-2.21-2.212v-1.1H2.201a2.2 2.2 0 00-2.2 2.211v23.164A2.205 2.205 0 002.201 32h16.557a2.2 2.2 0 002.2-2.211v-11.03l8.364-8.364a2.211 2.211 0 00.011-3.123l-1.3-1.3a2.2 2.2 0 00-3.123.011zM7.724 3.31V2.2A2.211 2.211 0 019.936 0h1.093a2.206 2.206 0 012.212 2.2v1.11h2.212a1.1 1.1 0 011.1 1.1v1.106a1.1 1.1 0 01-1.1 1.1H5.512a1.1 1.1 0 01-1.1-1.1V4.413a1.1 1.1 0 011.1-1.1h2.212zm2.759 0a.552.552 0 10-.552-.552.552.552 0 00.552.552z"/>
      </svg>
  )
}

function IconPencil(props) {
 
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 31.999">
        <defs/>
        <path d="M30.173 7.541l-.314.314-5.726-5.729.313-.313A4.738 4.738 0 0127.767 0a4.018 4.018 0 012.849 1.37c3.354 3.354-.443 6.171-.443 6.171zm-2.194 2.2L8.48 29.242 0 31.999l2.756-8.485 19.5-19.505.252.253zM2.76 29.238L7 28.019l-2.9-3.082-1.343 4.3z"/>
      </svg>
  )
}

function IconGolfBall(props) {
  
  return (
      <svg xmlns="http://www.w3.org/2000/svg" data-name="icon sports_golf" viewBox="0 0 22.385 32">
        <defs/>
        <path d="M3.155 27.192v-3.154H19.23v3.155H16a3.34 3.34 0 00-3.23 3.23V32H9.615v-1.577a3.34 3.34 0 00-3.23-3.23zm6.46-20.808a1.54 1.54 0 11.451 1.127 1.529 1.529 0 01-.451-1.126zm3.155 3.23a1.667 1.667 0 011.653-1.653 1.47 1.47 0 011.127.488 1.658 1.658 0 01.451 1.164 1.54 1.54 0 01-1.577 1.577 1.658 1.658 0 01-1.164-.451 1.47 1.47 0 01-.49-1.124zm-6.385 0a1.658 1.658 0 01.451-1.164 1.47 1.47 0 011.127-.488 1.667 1.667 0 011.653 1.653 1.47 1.47 0 01-.488 1.127 1.658 1.658 0 01-1.164.451 1.54 1.54 0 01-1.577-1.577zm4.808-6.46a8.079 8.079 0 105.671 2.366 7.736 7.736 0 00-5.672-2.365zm0 19.23A11.207 11.207 0 013.268 3.267a11.20764248 11.20764248 0 0115.85 15.85 10.791 10.791 0 01-7.926 3.268z"/>
      </svg>      
  )
}


function IconDelete(props) {

  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <defs/>
        <g data-name="Group 348">
          <path fill="none" d="M0 0h32v32H0z" data-name="Rectangle 690"/>
          <path d="M7 13h18a3 3 0 013 3 3 3 0 01-3 3H7a3 3 0 01-3-3 3 3 0 013-3z" data-name="Rectangle 665"/>
        </g>
      </svg>
  )
}

function IconPlayer(props) {
  
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <defs/>
        <path d="M275.076 93.952c2.991-24.117-14.126-46.108-38.252-49.108-24.126-3-46.107 14.117-49.107 38.252-3 24.126 14.126 46.108 38.256 49.107 24.112 2.992 46.103-14.125 49.103-38.251z" className="st0"/>
        <path d="M384.588 229.743c-20.572-32.927-36.882-70.296-41.076-77.557-2.487-4.316-2.73-7.361-.78-11.622 8.4-12.144 16.991-24.197 25.685-36.125 7.54-10.352 18.319-21.802 14.202-35.9-2.364-8.108-9.541-11.486-15.604-16.424-15.23-12.441-30.414-24.982-45.742-37.333-4.292-3.45-8.436-7.08-12.783-10.45-7.068-6.288-17.897-5.658-24.18 1.424-5.297 5.964-5.617 14.576-1.361 20.9L266.932 43.34c3.928 3.1 7.446 6.658 10.436 10.648l15.987-16.648 40.324 39.126-31.878 33.954c-17.613 17.252-59.472 42.072-49.891 77.035 7.302 26.648 32.418 72.404 32.418 85.323 0 17.892-9.81 108.287-9.81 108.287-.064.352-.081.703-.126 1.063l-28.396 98.143c-3.225 10.612 2.775 21.838 13.4 25.054 10.617 3.225 21.842-2.766 25.063-13.396l34.162-90.873c.473-1.054.892-2.153 1.225-3.297l.37-.982c.518-1.721 18.468-86.8 18.468-86.8l.798-7.91-8.586 85.324c-.248 1.504-.41 3.027-.41 4.613l4.207 97.665c0 12.333 9.991 22.333 22.324 22.333 12.33 0 22.333-10 22.333-22.333l5.77-94.134 13.792-86.927c2.977-26.84 6.243-45.938-14.324-78.865zM187.676 125.879l-73.224 76.242c-3.734 3.901-4.631 9.712-2.239 14.55l18.554 37.531c1.464 2.991 4.162 5.171 7.379 6.036 3.216.847 6.64.279 9.396-1.586l13.936-9.397c2.753-1.864 4.568-4.819 4.987-8.117.419-3.297-.608-6.612-2.815-9.099l-24.972-33.594 60.035-62.53c-4.073-2.811-7.762-6.198-11.037-10.036z" className="st0"/>
      </svg>
  )
}



export {IconNotification,IconAnnouncement,IconMembership,IconCourse,IconScore,IconTournament,IconCompletion,IconPlus,IconEdit,IconPencil,IconGolfBall,IconDelete,IconPlayer};


