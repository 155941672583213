import React, { useEffect, useContext, useState, useCallback } from 'react';
import { useHistory } from "react-router-dom"
import { useDropzone } from 'react-dropzone';
import { TournamentContext } from '../TournamentProvider'
import ManagementFooter from '../../ManagementFooter'
import logo from "../../../images/logo.svg";
import { IconTournament } from "../../Icons";
import '../../../styles/styles.css';
import { inputCancell, lodaImageAndInputCancell } from '../TournamentUtility'

function Tournament_003(props) {
    const [value] = useContext(TournamentContext);
    const history = useHistory();
    const [tournament003Data] = useState(value.tournament003Data);                //グローバル参照渡し
    const [imageAreaTag] = useState({ id: undefined });
    const [previewList, setPreviewList] = useState([undefined, undefined, undefined]);
    const [tournamentPictureInputStyleList, setTournamentPictureInputStyleList] = useState([true, true, true]);
    const [imageFileList] = useState([undefined, undefined, undefined]);

    useEffect(() => {
        //タイトルが何もなかったら過去に入力された事なし
        if (tournament003Data.first_place.length === 0) return;
        //一番上にスクロール
        window.scrollTo(0, 0);

        //１位
        document.getElementById("first_place").value = tournament003Data.first_place;
        //２位
        document.getElementById("second_place").value = tournament003Data.second_place;
        //３位
        document.getElementById("third_place").value = tournament003Data.third_place;
        //4位
        document.getElementById("fourth_place").value = tournament003Data.fourth_place;
        //5位
        document.getElementById("fifth_place").value = tournament003Data.fifth_place;
        //6位～10位
        document.getElementById("sixth_tenth").value = tournament003Data.sixth_tenth;
        //11位?60位
        document.getElementById("eleventh_sixtieth").value = tournament003Data.eleventh_sixtieth;
        //副賞
        document.getElementById("special_prize").value = tournament003Data.special_prize;
        //エントリー金額
        document.getElementById("entry_amount").value = tournament003Data.entry_amount;
        //グループの試合間隔
        document.getElementById("group_match_interval").value = tournament003Data.group_match_interval;

        /*
        画像のパスがセキュリテーで見れないので、設定できない
        setPreviewList([...tournament003Data.tournament_picture]);
        //画像データが入力されているか？
        tournament003Data.tournament_picture.forEach((data,index) => {
            if(data!==undefined){
                tournamentPictureInputStyleList[index] = false;
            }
        })
        */
        //引継ぎ
    }, [tournament003Data.eleventh_sixtieth, tournament003Data.entry_amount, tournament003Data.fifth_place, tournament003Data.first_place, tournament003Data.fourth_place, tournament003Data.group_match_interval, tournament003Data.second_place, tournament003Data.sixth_tenth, tournament003Data.special_prize, tournament003Data.third_place]);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length < 1) {
            window.alert('ファイルの形式が適切ではありません');
        } else {
            let imageData = window.URL.createObjectURL(acceptedFiles[0]);
            let indexNb = 0;
            switch (imageAreaTag.id) {
                case "tournament_picture_01_drop":
                    indexNb = 0;
                    document.getElementById("tournament_picture_01").required = false;
                    break;
                case "tournament_picture_02_drop":
                    indexNb = 1;
                    //                    document.getElementById("tournament_picture_02").required = false;
                    break;
                case "tournament_picture_03_drop":
                    indexNb = 2;
                    //                    document.getElementById("tournament_picture_03").required = false;
                    break;
                default:
                    break;
            }

            tournamentPictureInputStyleList[indexNb] = false;
            previewList[indexNb] = imageData;
            imageFileList[indexNb] = acceptedFiles[0];


            setTournamentPictureInputStyleList([...tournamentPictureInputStyleList]);
            setPreviewList([...previewList]);
        }
        /*
        acceptedFiles.forEach((element,index) => {
            saveImage("qqq/",imageAreaTag.id+index,element,(result,data)=>{
                if(result===true){
                    window.alert('保存完了');
                }
            });
                
        });
*/

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previewList]);

    // eslint-disable-next-line no-unused-vars
    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        accept: "image/*",
        onDrop,
        maxSize: 932 * 257,
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true

    });



    const onSubmit = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();
        const { first_place, second_place, third_place, fourth_place, fifth_place, sixth_tenth, eleventh_sixtieth, special_prize, entry_amount, group_match_interval } = e.target.elements;



        if (imageFileList[1] === undefined && imageFileList[2] !== undefined) {
            alert('2番目の画像が選択されていません');
        } else {
            value.tournament003Data.first_place = first_place.value;                    //1位
            value.tournament003Data.second_place = second_place.value;                  //2位
            value.tournament003Data.third_place = third_place.value;                    //3位
            value.tournament003Data.fourth_place = fourth_place.value;                  //4位
            value.tournament003Data.fifth_place = fifth_place.value;                    //5位
            value.tournament003Data.sixth_tenth = sixth_tenth.value;                    //6位?10位
            value.tournament003Data.eleventh_sixtieth = eleventh_sixtieth.value;        //11位?60位
            value.tournament003Data.special_prize = special_prize.value;                //副賞
            value.tournament003Data.entry_amount = entry_amount.value;                  //エントリー金額
            value.tournament003Data.group_match_interval = group_match_interval.value;  //グループの試合間隔
            value.tournament003Data.tournament_picture = previewList;                   //メイン画像データ
            value.tournament003Data.tournament_picture_file = imageFileList;            //firebaseにデータを保存するためにファイルオブジェクト
            history.push("Tournament_004");
        }
    }
    const onClickDelete = (e) => {
        //バリデーションが実行されないように
        e.preventDefault();
        let indexNb = 0;
        switch (e.target.id) {
            case "button_cancel_upload_01":
                indexNb = 0;
                inputCancell("tournament_picture_01");
                document.getElementById("tournament_picture_01").required = true;
                break;
            case "button_cancel_upload_02":
                indexNb = 1;
                inputCancell("tournament_picture_02");
                //                document.getElementById("tournament_picture_02").required = true;
                break;
            case "button_cancel_upload_03":
                indexNb = 2;
                inputCancell("tournament_picture_03");
                //                document.getElementById("tournament_picture_03").required = true;
                break;
            default:
                break;
        }
        tournamentPictureInputStyleList[indexNb] = true;
        previewList[indexNb] = undefined;
        imageFileList[indexNb] = undefined;

        setTournamentPictureInputStyleList([...tournamentPictureInputStyleList]);
        setPreviewList([...previewList]);



    }
    const onChangeInput = (e) => {
        let fileName = e.target.files[0];
        if (fileName.size > 1048576) {
            window.alert('ファイルサイズが１Mを超えています');
            inputCancell(e.target.id);
            return;
        }

        let indexNb = 0;
        switch (e.target.id) {
            case "tournament_picture_01":
                indexNb = 0;
                break;
            case "tournament_picture_02":
                indexNb = 1;
                break;
            case "tournament_picture_03":
                indexNb = 2;
                break;
            default:
                break;
        }

        lodaImageAndInputCancell(fileName, e.target.id, (currentSrc) => {
            previewList[indexNb] = currentSrc;
            tournamentPictureInputStyleList[indexNb] = false;
            setTournamentPictureInputStyleList([...tournamentPictureInputStyleList]);
            setPreviewList([...previewList]);

        });

        imageFileList[indexNb] = fileName;

    }

    const onClickButtonBack = (e) => {
        e.preventDefault();
        history.push("Tournament_002");
    }
    return (

        <div className="c-screen c-screen--gunmetal">
            <header className="c-container-vertical c-mt56">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt32">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconTournament></IconTournament>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">新規ツアー追加画面</h1>
                </div>
            </header>
            <main>

                <form onSubmit={onSubmit}>
                    <div className="c-page-section c-mt32">

                        <div className="kanri-container">


                            <h2 className="c-heading--2 c-heading--left c-mt56">賞金金額、副賞を入力してください。</h2>

                            <div className="input input__set">
                                <label htmlFor="given-name-kana" className="input input__label">1位</label>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <input type="number" name="given-name-kana" id="first_place" placeholder="" required className="input input__field input__field--extend" />
                                    <span className="input__suffix c-paragraph">円</span>
                                </div>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="given-name-kana" className="input input__label">2位</label>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <input type="number" name="given-name-kana" id="second_place" placeholder="" required className="input input__field input__field--extend" />
                                    <span className="input__suffix c-paragraph">円</span>
                                </div>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="given-name-kana" className="input input__label">3位</label>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <input type="number" name="given-name-kana" id="third_place" placeholder="" required className="input input__field input__field--extend" />
                                    <span className="input__suffix c-paragraph">円</span>
                                </div>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="given-name-kana" className="input input__label">4位</label>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <input type="number" name="given-name-kana" id="fourth_place" placeholder="" required className="input input__field input__field--extend" />
                                    <span className="input__suffix c-paragraph">円</span>
                                </div>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="fifth_place" className="input input__label">5位</label>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <input type="number" name="fifth_place" id="fifth_place" placeholder="" required className="input input__field input__field--extend" />
                                    <span className="input__suffix c-paragraph">円</span>
                                </div>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="sixth_tenth" className="input input__label">6位&#12316;10位</label>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <input type="number" name="sixth_tenth" id="sixth_tenth" placeholder="" required className="input input__field input__field--extend" />
                                    <span className="input__suffix c-paragraph">円</span>
                                </div>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="eleventh_sixtieth" className="input input__label">11位&#12316;60位</label>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <input type="number" name="eleventh_sixtieth" id="eleventh_sixtieth" placeholder="" required className="input input__field input__field--extend" />
                                    <span className="input__suffix c-paragraph">円</span>
                                </div>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="given-name-kana" className="input input__label">副賞</label>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <input type="text" name="given-name-kana" id="special_prize" placeholder="" required className="input input__field input__field--extend" />
                                    <span className="input__suffix c-paragraph">円</span>
                                </div>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="given-name-kana" className="input input__label">エントリー金額を入力してください</label>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <input type="number" name="given-name-kana" id="entry_amount" placeholder="" required className="input input__field input__field--extend" />
                                    <span className="input__suffix c-paragraph">円</span>
                                </div>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="given-name-kana" className="input input__label">グループの試合間隔を入力してください</label>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <input type="number" min="0" max="99" name="given-name-kana" id="group_match_interval" placeholder="" required className="input input__field input__field--extend" />
                                    <span className="input__suffix input__suffix--88 c-paragraph">分間隔</span>
                                </div>
                            </div>

                            <h2 className="c-heading--2 c-heading--left c-mt56">メイン画像をアップロードしてください。</h2>

                            <p className="c-subtitle c-subtitle--left c-mt8">※最大3つまで指定できます。</p>
                            <p className="c-subtitle c-subtitle--left">※画像サイズは1MBまででお願いします。</p>
                            <p className="c-subtitle c-subtitle--left">※縦横比は932px×257pxでお願いします。</p>

                            <fieldset id="tournament_picture">
                                <div className="c-container-vertical c-container-vertical--center c-drag-and-drop c-mt32" id="tournament_picture_01_drop" {...getRootProps(
                                    {
                                        onDrop: event => {
                                            imageAreaTag.id = event.currentTarget.id;
                                        }
                                    }
                                )}>
                                    <p> ここに画像をドラッグアンドドロップ</p>
                                    <input {...getInputProps()} />
                                    <input type="file" name="tournament_picture_01" id="tournament_picture_01" accept="image/*" capture="camera" className="c-mt56" required onChange={(e) => { onChangeInput(e) }} style={{ display: tournamentPictureInputStyleList[0] ? '' : 'none' }} />
                                    <img id="tournament_picture_01_Image" className="c-mt56" src={previewList[0]} alt="" />
                                    <button id="button_cancel_upload_01" onClick={onClickDelete} className="button button__tertiary">アップロードした画像を削除</button>
                                </div>

                                <div className="c-container-vertical c-container-vertical--center c-drag-and-drop c-mt32" id="tournament_picture_02_drop" {...getRootProps(
                                    {
                                        onDrop: event => {
                                            imageAreaTag.id = event.currentTarget.id;
                                        }
                                    }
                                )}>
                                    <p> ここに画像をドラッグアンドドロップ</p>
                                    <input  {...getInputProps()} />
                                    <input type="file" name="tournament_picture_02" id="tournament_picture_02" accept="image/*" capture="camera" className="c-mt56" onChange={(e) => { onChangeInput(e) }} style={{ display: tournamentPictureInputStyleList[1] ? '' : 'none' }} />
                                    <img id="tournament_picture_02_Image" className="c-mt56" src={previewList[1]} alt="" />
                                    <button id="button_cancel_upload_02" onClick={onClickDelete} className="button button__tertiary">アップロードした画像を削除</button>
                                </div>

                                <div className="c-container-vertical c-container-vertical--center c-drag-and-drop c-mt32" id="tournament_picture_03_drop" {...getRootProps(
                                    {
                                        onDrop: event => {
                                            imageAreaTag.id = event.currentTarget.id;
                                        }
                                    }
                                )}>
                                    <p> ここに画像をドラッグアンドドロップ</p>
                                    <input  {...getInputProps()} />
                                    <input type="file" name="tournament_picture_03" id="tournament_picture_03" accept="image/*" capture="camera" className="c-mt56" onChange={(e) => { onChangeInput(e) }} style={{ display: tournamentPictureInputStyleList[2] ? '' : 'none' }} />
                                    <img id="tournament_picture_03_Image" className="c-mt56" src={previewList[2]} alt="" />
                                    <button id="button_cancel_upload_03" onClick={onClickDelete} className="button button__tertiary">アップロードした画像を削除</button>
                                </div>

                            </fieldset>



                        </div>

                        <div className="c-button-set c-mt56 c-mb88">
                            <button id="button_next" className="button button__primary" >次へ</button>
                            <button id="button_back" className="button button__secondary" onClick={(e) => { onClickButtonBack(e) }}>戻る</button>
                        </div>

                    </div>
                </form>
            </main>
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}


export default Tournament_003

