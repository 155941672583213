import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import firebase from '../../firebase/Firebase';
import { sortArray } from '../Utility';
import './styles.css';

function UserBankInfoPageContainer(props) {
    const [userName, setUserName] = useState("");           //選手名
    const [userId, setUserId] = useState("");               //選手ID
    const [phoneNumber, setPhoneNumber] = useState("");     //電話番号
    const [bankeName, setBankeName] = useState("");         //銀行名
    const [branchName, setBranchName] = useState("");       //支店名
    const [accountType, setAccountType] = useState("");     //口座の種類
    const [accountNumber, setAccountNumber] = useState(""); //口座番号
    const [accountHolder, setAccountHolder] = useState(""); //名義


    useEffect(() => {
        if (Object.keys(props.userData).length <= 5 ) return;

        setUserName(props.userData.user_data.given_name_kanji + " " + props.userData.user_data.family_name_kanji);      //選手名
        setUserId(props.userData.user_data.serial_nb);                                                                  //選手ID
        setPhoneNumber(props.userData.user_data.phone_number);                                                          //電話番号
        setBankeName(props.userData.bank_info.bank_name);                                                               //銀行名
        setBranchName(props.userData.bank_info.branch_name);                                                            //支店名
        setAccountType(props.userData.bank_info.account_type);                                                          //口座の種類
        setAccountNumber(props.userData.bank_info.account_number);                                                      //口座番号
        setAccountHolder(props.userData.bank_info.account_holder);                                                      //名義


    }, [props.userData]);
    return (

        <tr className="c-container-horizontal">
            {/*選手名*/}
            <td className="c-data__slot c-data__slot--144" >{userName}</td>
            {/*選手ID*/}
            <td className="c-data__slot c-data__slot--232" >{userId}</td>
            {/*電話番号*/}
            <td className="c-data__slot c-data__slot--144" >{phoneNumber}</td>
            {/*銀行名*/}
            <td className="c-data__slot c-data__slot--144" >{bankeName}</td>
            {/*支店名*/}
            <td className="c-data__slot c-data__slot--144" >{branchName}</td>
            {/*口座の種類*/}
            <td className="c-data__slot c-data__slot--144" >{accountType}</td>
            {/*口座番号*/}
            <td className="c-data__slot c-data__slot--144" >{accountNumber}</td>
            {/*名義*/}
            <td className="c-data__slot c-data__slot--232" >{accountHolder}</td>
        </tr>
    )
}

export default UserBankInfoPageContainer

