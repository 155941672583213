import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import ManagementFooter from './ManagementFooter';
import logo from "../images/logo.svg";
import '../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { setAllTournamentCancellationPostponement, setAllTournamentScore, deleteAllUserAllTournament, setFixFlgAllTournament, setGroupingFlgAllTournament, setAllocationFlgAllTournament, setEntryFlgAllUsersTournament, setGroupingFlgTournament, deleteTournamentEntryUser, getTournamentPaymentUserData, setTournamentPaymentUserData, getParticipationTournament, sortArray, setTournamentAllUserEntryFlg, getUsersNews, setUsersNews, likeSearchUser, initAllUsersTourCount, publishTournamentNewsAllUsersDel, addUserTournament, updateTournamentPart, deleteTestUser, testFunction, getTournamentEntryFlg, setTournamentStatus, getUserDataTournament, deleteAllUserTournament, setCopyUser, getAllUsersInTheArea, setTournamentAllocationFlg } from "../Database";

import { IconAnnouncement } from "./Icons";
import Loader from './Loader'

function DataClear(props) {
    const history = useHistory();

    const [loadingData, setLoadingData] = useState(undefined);
    const [newsText, setNewsText] = useState({ news_text: { title: "", text: "" } });
    const [selectNb, setSelectNb] = useState(-1);

    useEffect(() => {
        /*
        document.getElementsByTagName("*")["input_title"].value = newsText.news_text.title;
        document.getElementsByTagName("*")["send_notification"].value = newsText.news_text.text;
        */
        /*
         const asyncfunc = (async () => {
             let data=await getParticipationTournament("1lAKWYFb6zJUWse2KwEV");
             console.log(data);
         })
         asyncfunc();
         */
        /*
          //大会データ取得
          testFunction("getPaymentStatus","",(result,data)=>{
             if(result===true){
                 console.log(data);
             }
          });
 */

    }, []);


    const onSelectChange = (e) => {
        let _selectNb = Number(e.target.value);
        setSelectNb(_selectNb);
        if (_selectNb === -1) return;
    };
    const onSubmit = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();
        const asyncfunc = (async () => {
            switch (selectNb) {
                case 0:             //エントリーフラグの設定（全ての大会）
                    await setEntryFlgAllUsersTournament(1, (result, status) => {
                        //ローディング非表示
                        setLoadingData({ loadingFlg: false, title: "データベース更新中..." });

                        if (result === false) {
                            alert("データベースの更新に失敗しました=" + status);
                        } else {

                        }
                    });

                    break;
                case 1:             //エントリーフラグの削除（全ての大会）
                    await setEntryFlgAllUsersTournament(0, (result, status) => {
                        //ローディング非表示
                        setLoadingData({ loadingFlg: false, title: "データベース更新中..." });
                        if (result === false) {
                            alert("データベースの更新に失敗しました=" + status);
                        } else {

                        }
                    });

                    break;
                case 2:             //振り分け完了解除（全ての大会）

                    await setAllocationFlgAllTournament(0, async (result, status) => {

                        if (result === true) {
                            await deleteAllUserAllTournament((result, status) => {
                                //ローディング非表示
                                setLoadingData({ loadingFlg: false, title: "データベース更新中..." });
                                if (result === false) {
                                    alert("データベースの更新に失敗しました=" + status);
                                } else {

                                }
                            });
                        } else {
                            //ローディング非表示
                            setLoadingData({ loadingFlg: false, title: "データベース更新中..." });

                        }

                    });
                    break;
                case 3:             //ニュースの削除（全ての大会）
                    await publishTournamentNewsAllUsersDel((result, status) => {
                        //ローディング非表示
                        setLoadingData({ loadingFlg: false, title: "データベース更新中..." });
                        if (result === false) {
                            alert("データベースの更新に失敗しました=" + status);
                        } else {

                        }
                    });

                    break;
                case 4:             // 組み分け解除（全ての大会）
                    await setGroupingFlgAllTournament(0, (result, status) => {
                        //ローディング非表示
                        setLoadingData({ loadingFlg: false, title: "データベース更新中..." });
                        if (result === false) {
                            alert("データベースの更新に失敗しました=" + status);
                        } else {

                        }
                    });
                    break;
                case 5:             // スコア決定の解除（全ての大会）
                    await setFixFlgAllTournament(0, (result, status) => {
                        //ローディング非表示
                        setLoadingData({ loadingFlg: false, title: "データベース更新中..." });
                        if (result === false) {
                            alert("データベースの更新に失敗しました=" + status);
                        } else {

                        }
                    });

                    break;
                case 6:             // トーナメントカウントのクリア（全ての大会）
                    await initAllUsersTourCount((result, status) => {
                        //ローディング非表示
                        setLoadingData({ loadingFlg: false, title: "データベース更新中..." });
                        if (result === false) {
                            alert("データベースの更新に失敗しました=" + status);
                        } else {

                        }

                    });
                    break;
                case 7:             // ランダムでスコアをセット（全ての大会）
                    await setAllTournamentScore((result, status) => {
                        //ローディング非表示
                        setLoadingData({ loadingFlg: false, title: "データベース更新中..." });
                        if (result === false) {
                            alert("データベースの更新に失敗しました=" + status);
                        } else {

                        }

                    });
                    break;
                case 8:             // 大会延期の解除（全ての大会）
                    await setAllTournamentCancellationPostponement(0, 0, (result, status) => {
                        //ローディング非表示
                        setLoadingData({ loadingFlg: false, title: "データベース更新中..." });
                        if (result === false) {
                            alert("データベースの更新に失敗しました=" + status);
                        } else {

                        }

                    });
                    break;

                default:
                    break;
            }
        });
        //ローディング表示
        setLoadingData({ loadingFlg: true, title: "データベース更新中..." });

        asyncfunc();
    };

    const onClickButton = (e) => {
        switch (e.currentTarget.id) {
            case "button_back":
                history.push("/");
                break;
            case "":
                break;
            default:
                break;
        }



    };
    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <header className="c-container-vertical c-mt88">

                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>

                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconAnnouncement></IconAnnouncement>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">お知らせ文言設定</h1>
                </div>

            </header>
            <main>

                <section className="kanri-container">
                    <div className="c-container-vertical c-mt32">
                        <div className="input input__set input__set--kanri">
                            <label htmlFor="" className="input input__label">お知らせの種類を選択してください。</label>
                            <select name="" id="select_kinds" required className="input input__field" onChange={(e) => onSelectChange(e)}>

                                <option value="-1">選択してください</option>
                                <option value="0">エントリーフラグの設定（全ての大会）</option>
                                <option value="1">エントリーフラグの削除（全ての大会）</option>
                                <option value="2">振り分け完了解除（全ての大会）</option>
                                <option value="3">ニュースの削除（全ての大会）</option>
                                <option value="4">組み分け解除（全ての大会）</option>
                                <option value="5">スコア決定の解除（全ての大会）</option>
                                <option value="6">トーナメントカウントのクリア（全ての大会）</option>
                                <option value="7">ランダムでスコアをセット（全ての大会）</option>
                                <option value="8">大会延期の解除（全ての大会）</option>

                            </select>
                        </div>
                    </div>
                    <form onSubmit={onSubmit} >
                        <div className="c-button-set">
                            <button id="button_next" className="button button__primary">データベースに保存</button>
                            <button id="button_back" onClick={(e) => { onClickButton(e) }} className="button button__secondary">戻る</button>
                        </div>
                    </form>
                </section>



            </main>
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default DataClear

