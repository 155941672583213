import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
import Login from '../components/Login';
/**
 * ログインしていたら
 * RouteComponentで指定されたコンポーネント表示
 * ログインしていない場合は
 * Loginコンポーネントpの表示
 */
const PrivateRoute = ({ component: RouteComponent, ...options }) => {
    const { currentUser } = useContext(AuthContext);
    let result =false;
    //まだAuthのチェックが終わっていないか確認
    if(currentUser==="not init"){
        return <div></div>
    }

    if(currentUser!==null){
        result=true;
    }

    const Component = result ? RouteComponent : Login;

    return <Route {...options} component={Component} />;

};

export default PrivateRoute;