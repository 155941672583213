import React, { useEffect, useState, useRef } from 'react';
//import { useHistory } from "react-router-dom"
//import { TournamentContext } from '../TournamentProvider'
import '../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
//import { IconScore, IconPlus, IconEdit } from "../../Icons";

function Tournament002Container(props) {
    const initCourseData = {
        indexNb: 0,                  //コースコンテナID名を付けるためのカウンター
        key: "",
        courseName: "",
        arrayTeeList: [],
        arrayGreenList: [],
        numberOfholes: -1,         //ホール数
    }
    //    const [value, setValue] = useContext(TournamentContext);
    //    const history = useHistory();
    const [courseData, setCourseData] = useState(initCourseData);       //コースコンテナ作成するためのデータ

    const divtRef = useRef(null);
    const inputRef = useRef(null);
    const teeSelectRef = useRef(null);
    const greenSelectRef = useRef(null);

    //props.courseDataデータが変化を起こした場合に来る
    useEffect(() => {
        if (props.courseData !== undefined) {
            //再描画
            setCourseData(props.courseData.primCourseData);
        }
        //Disableに設定
        setDisable();


    }, [props.courseData]);


    useEffect(() => {
        if (courseData.numberOfholes !== -1) {
            if (courseData.useFlg === true) {
                //Enableに設定
                setEnable();

            } else {
                //Disableに設定
                setDisable();

            }
            //Teeが以前選択されていたものを初期設定にしておく
            let teeSelectIndex = courseData.arrayTeeList.findIndex((data) => {
                return data.useFlg === true;
            })
            if (teeSelectIndex !== -1) {
                teeSelectRef.current.options[teeSelectIndex + 1].selected = true;
            } else {
                teeSelectRef.current.options[0].selected = true;
            }

            //Greenが以前選択されていたものを初期設定にしておく
            let greenSelectIndex = courseData.arrayGreenList.findIndex((data) => {
                return data.useFlg === true;
            })
            if (greenSelectIndex !== -1) {
                greenSelectRef.current.options[greenSelectIndex + 1].selected = true;
            } else {
                greenSelectRef.current.options[0].selected = true;

            }


        }
    }, [courseData]);

    const onChangeInput_select_course = (e) => {
        courseData.useFlg = e.target.checked;
        if (e.target.checked === true) {
            //Enableに設定
            setEnable();
        } else {
            //Disableに設定
            setDisable();
        }
    }

    const onChangeSelectTee = (e) => {
        let indexNb = e.target.selectedIndex - 1;
        courseData.arrayTeeList.forEach((data) => {
            data.useFlg = false;
        })
        courseData.arrayTeeList[indexNb].useFlg = true;
    }

    const onChangeSelectGreen = (e) => {
        let indexNb = e.target.selectedIndex - 1;
        courseData.arrayGreenList.forEach((data) => {
            data.useFlg = false;
        })
        courseData.arrayGreenList[indexNb].useFlg = true;
    }

    const setEnable = () => {
        inputRef.current.checked = true;
        courseData.useFlg = true;
        //Enableに設定
        teeSelectRef.current.disabled = false;
        greenSelectRef.current.disabled = false;
        //見た目をEnable
        divtRef.current.className = "c-container-vertical c-radius--16 c-padding--16 c-mt24 course-select__group course-select__group--selected";
    }

    const setDisable = () => {
        inputRef.current.checked = false;
        courseData.useFlg = false;
        //Disableに設定
        teeSelectRef.current.disabled = true;
        greenSelectRef.current.disabled = true;
        //見た目をDisable
        divtRef.current.className = "c-container-vertical c-radius--16 c-padding--16 c-mt24 course-select__group";
    }

    return (

        <div className="c-container-vertical c-radius--16 c-padding--16 c-mt24 course-select__group" ref={divtRef}>   {/*<!-- コース一つ。 -->*/}

            <label htmlFor={"select_course_000" + courseData.key} className="c-heading--3 course-select__label">
                {courseData.courseName}
                <input type="checkbox" name={"select_course_000" + courseData.key} id={"select_course_000" + courseData.key} className="course-select__checkbox" onChange={onChangeInput_select_course} ref={inputRef} />
            </label>
            {/*<!-- コースティーを選択 -->*/}
            <label htmlFor={"tee_course_000" + courseData.key} className="input input__label">利用するティ</label>
            <select name={"tee_course_000" + courseData.key} id={"tee_course_000" + courseData.key} required className="input input__field input__field--minimal course-select__list" ref={teeSelectRef} onChange={onChangeSelectTee}>
                <option value="">ー　ー　ー</option>
                {courseData.arrayTeeList.map((data, i) =>
                    <option key={data.key} value={data.name}>{data.name}</option>)
                }

            </select>
            {/*<!-- グリーンを選択 -->*/}
            <label htmlFor={"green_course_000" + courseData.key} className="input input__label">利用するグリーン</label>
            <select name={"green_course_000" + courseData.key} id={"green_course_000" + courseData.key} required className="input input__field input__field--minimal course-select__list" ref={greenSelectRef} onChange={onChangeSelectGreen}>
                <option value="">ー　ー　ー</option>
                {courseData.arrayGreenList.map((data, i) =>
                    <option key={data.key} value={data.name}>{data.name}</option>)
                }
            </select>

        </div>
    )
}


export default Tournament002Container

