import { originalInitialState } from './TournamentProvider'
import lodash, { result } from 'lodash';
import { saveImage, loadImageFromFirebase, deleteImage } from '../../Storage'
import { updateMaxEntryTournament, deleteTournamentEntryUser, getAnnouncementNewsTemplate, setGroupingFlgTournament, addPaymentTournamentUserData, getCourse, addScoreDataTournamentUserData, publishTournamentNewsUsers, updateUsersTourCount, deleteUserTournament, subUsersTourCount, updateAllUserDataTournament, getAreaNb, addUsersTourCount, likeSearchUser, getAllUsersInTheArea, publishTournamentNewsAllUsers, setTournamentAllocationFlg, updateTournamentPart, deleteAllUserTournament, updateTournament, saveTournament, getCourseList, getTournamentList, deleteTournament, getTournamentAllUser, addUserTournament } from "../../Database";
import { checkMoji, addMinutes, deleteListDeleted, getMillisecond, getDate, getTime, unifyGolfCourse, sortArray } from '../Utility'


export function getTeeAndGreenData(courseData) {

    //目的のコースを探す

    //格納オブジェクト
    let primCourseData = {
        key: courseData.key,
        courseName: courseData.courseName,
        arrayTeeList: [],
        arrayGreenList: [],
        numberOfholes: -1,

    }
    //ティーのデータを取得
    courseData.holeList[0].teePositionList.forEach((data) => {
        let teeData = { key: data.key, name: data.name };
        primCourseData.arrayTeeList.push(teeData);
    });

    //グリーンのデータを取得
    courseData.holeList[0].teePositionList[0].greenList.forEach((data) => {
        let greenData = { key: data.key, name: data.name };
        primCourseData.arrayGreenList.push(greenData);
    });


    //ホール数の追加
    primCourseData.numberOfholes = courseData.holeList.length.toFixed()

    return primCourseData;

}


export function setParamTournament002Data(tournament002Data, courseList) {

    //何か設定さてているはず
    if (tournament002Data.golfCourseId !== undefined && courseList !== undefined) {
        let findCourse = courseList.find((data) => {
            return data.id === tournament002Data.golfCourseId;
        });

        //コースリスト取得
        let arrayCourseList = findCourse.arrayCourseList;
        tournament002Data.useCourseList = arrayCourseList;

        tournament002Data.useCourseList.map((data) => {
            let primCourseData = getTeeAndGreenData(data);
            data.primCourseData = primCourseData;
            //ホール情報はいらないので削除
            delete data.holeList
            return data;
        });
    }
    return tournament002Data;
}


/**
 * Inputされたファイル名を解除する
 * @param {*} cancellTagId 
 */
export function inputCancell(cancellTagId) {
    let inputTag = undefined;
    inputTag = document.getElementById(cancellTagId);
    inputTag.value = "";
}

/**
 * 画像の読み込み(端末のフォルダからの読み込みinputタグも変更する)
 * @param {*} fileName 
 * @param {*} tagId 
 * @param {*} callBack 
 */
export function lodaImageAndInputCancell(fileName, tagId, callBack) {
    // FileReaderを生成
    let fileReader = new FileReader();

    fileReader.onload = (data) => {
        let image = new Image();
        image.src = data.target.result;
        image.onload = (data) => {
            //            console.log(data);
            if (data.target.naturalWidth > 932 || data.target.naturalHeight > 257) {
                window.alert('画像サイズが大きいです');
                inputCancell(tagId);
                return
            }
            callBack(data.target.currentSrc);

        }

    }
    // ファイルの読み込み(Data URI Schemeの取得)
    fileReader.readAsDataURL(fileName);
}


/**
 * 画像の読み込み
 * @param {*} fileName 
 * @param {*} tagId 
 * @param {*} callBack (result false=エラー true=成功,status=resultがfalseの場合エラーステータスtrueの場合は画像URL)
 */
export function lodaImage(uid, stragePath, callback) {

    loadImageFromFirebase("tournament/" + uid, stragePath, (result, status) => {
        if (callback !== null && callback !== undefined) {
            callback(result, status);
        }
    });
}




/**
 * ゴルフ場の、どのコース、ティー、グリーンがトーナメントで使用されているコース、ティー、グリーンに使用されているか調べてuseFlgを設定する
 * @param {*} courseList 
 * @param {*} useCourseList 
 */
export function setUseFlagForCourse(courseList, useCourseList) {

    //ゴルフ場にある全てのコース情報からトーナメントで使用されるコースがあるか確認
    courseList.map((useCourseData) => {
        let primCourseData = getTeeAndGreenData(useCourseData);

        //トーナメントで使用するコース
        useCourseList.forEach((data) => {
            if (data.key === primCourseData.key) {
                //トーナメントで使用するコースが見つかったので仕様中にする
                primCourseData.useFlg = true;
                //どのティーが使用されてるか検索
                primCourseData.arrayTeeList.map((teeData) => {
                    if (data.teeData.key === teeData.key) {
                        teeData.useFlg = true;
                    }
                    return teeData;
                });
                //どのグリーンが使用されているか検索
                primCourseData.arrayGreenList.map((greenData) => {
                    if (data.greenData.key === greenData.key) {
                        greenData.useFlg = true;
                    }
                    return greenData;
                });
            }
            useCourseData.primCourseData = primCourseData;
        });

        return useCourseData;
    });
    return courseList;
}


/**
 * データベースに保存するコースデータを作成、
 * @param {*} useCourseList 
 * @returns 
 */
export function makeUseCourseList(useCourseList) {

    //コース選択の整理
    let tempUseCourseList = lodash.cloneDeep(useCourseList);

    let findUseCourseList = tempUseCourseList.filter((data) => {
        return data.primCourseData.useFlg === true;
    });

    findUseCourseList.forEach((data) => {

        //Teeの整理
        data.teeData = data.primCourseData.arrayTeeList.find((teeData) => {
            return teeData.useFlg === true;
        });

        //Greenの整理
        data.greenData = data.primCourseData.arrayGreenList.find((teeData) => {
            return teeData.useFlg === true;
        });

        //不要なプロパティーは削除
        delete data.teeData.useFlg;
        delete data.greenData.useFlg;
        delete data.holeList;
        delete data.primCourseData;
    });

    return findUseCourseList;
}

/**
 * データベースに保存するコース数を取得、
 * @param {*} useCourseList 
 * @returns 
 */
export function getTotalNumberHoles(useCourseList) {

    //コース選択の整理
    let tempUseCourseList = lodash.cloneDeep(useCourseList);

    let findUseCourseList = tempUseCourseList.filter((data) => {
        return data.primCourseData.useFlg === true;
    });
    let numberHoles = 0;
    findUseCourseList.forEach((data) => {
        numberHoles += data.holeList.length;
    });

    return numberHoles;
}


/**
 * 04_07_29の共通submit
 * @param {*} document 
 * @param {*} tournamentData 
 * @param {*} tournament_picture_file 
 * @param {*} callback 
 */
export function onSubmitCommon04_07_29(document, tournamentData, tournament_picture_file, callback) {
    //保存するのに時間がかかるので2度クリック帽子
    document.getElementById("button_next").disabled = true;
    document.getElementById("button_back").disabled = true;
    //    tournamentData.pictureUrlList = ["", "", ""];
    tournamentData.pictureList = ["", "", ""];


    //ツアー開始日と終了日のミリ秒保存
    let tournamentDataTime = tournamentData.eventDate + " " + tournamentData.eventStartTime
    let tournamentFinishDataTime = tournamentData.eventFinishDate + " " + tournamentData.eventFinishTime
    tournamentData.startMillisecond = getMillisecond(tournamentDataTime);
    tournamentData.finishMillisecond = getMillisecond(tournamentFinishDataTime);

    //エントリーの受付開始日と受付終了日のミリ秒保存
    let tournamentEntryStartData = tournamentData.entryStartDate + " 00:00:00";
    let tournamentEntryFinishData = tournamentData.entryFinisDate + " 00:00:00";
    tournamentData.entryStartMillisecond = getMillisecond(tournamentEntryStartData);
    tournamentData.entryFinishMillisecond = getMillisecond(tournamentEntryFinishData);

    //不要なプロパティーは削除
    delete tournamentData.eventDate;
    delete tournamentData.eventStartTime;
    delete tournamentData.eventFinishDate;
    delete tournamentData.eventFinishTime;

    delete tournamentData.entryStartDate;
    delete tournamentData.entryFinisDate;

    delete tournamentData.id;
    delete tournamentData.temp;
    delete tournamentData.tournament_picture_file;
    delete tournamentData.tournament_picture;

    const saveSata = (async () => {
        //一度データベースに保存してドキュメントidを取得
        let docId = await saveTournament(tournamentData);
        //画像ファイルがいくつ選択されているか
        let fileCount = tournament_picture_file.filter((data) => {
            return data !== undefined;
        }).length;
        if (fileCount > 0) {
            await tournament_picture_file.forEach(async (data, index) => {
                if (data !== undefined) {
                    //画像の保存
                    let path = "tournament/" + docId + "/" + index
                    await saveImage(path, data, async (result, status) => {
                        tournamentData.pictureList[index] = status;
                        fileCount--;
                        if (fileCount <= 0) {
                            await updateTournament(tournamentData, docId);
                            if (callback !== null && callback !== undefined) {
                                callback(result, status);
                            }
                        }
                    });
                }
            })

        } else {
            //データベースの更新
            await updateTournament(tournamentData, docId);
            if (callback !== null && callback !== undefined) {
                callback();
            }
        }

    });

    saveSata();

}

/**
 * 11_14の共通submit
 * @param {*} document 
 * @param {*} tournamentData 
 * @param {*} tournament_picture_file 
 * @param {*} callback 
 */
export function onSubmitCommon11_14(document, tournamentData, tournament_picture_file, callback) {

    document.getElementById("button_next").disabled = true;
    document.getElementById("button_back").disabled = true;

    let id = tournamentData.id;
    //    tournamentData.pictureUrlList = [...tournamentData.tournament_picture];
    tournamentData.tournament_picture.forEach((data, index) => {
        if (data === undefined) {   //データベースの方でundefinedはサポートされていないので文字列0を入れる
            data = "";
        }
        tournamentData.pictureList[index] = data;
    });

    //ツアー開始日と終了日のミリ秒保存
    let tournamentDataTime = tournamentData.eventDate + " " + tournamentData.eventStartTime
    let tournamentFinishDataTime = tournamentData.eventFinishDate + " " + tournamentData.eventFinishTime
    tournamentData.startMillisecond = getMillisecond(tournamentDataTime);
    tournamentData.finishMillisecond = getMillisecond(tournamentFinishDataTime);

    //エントリーの受付開始日と受付終了日のミリ秒保存
    let tournamentEntryStartData = tournamentData.entryStartDate + " 00:00:00";
    let tournamentEntryFinishData = tournamentData.entryFinisDate + " 00:00:00";
    tournamentData.entryStartMillisecond = getMillisecond(tournamentEntryStartData);
    tournamentData.entryFinishMillisecond = getMillisecond(tournamentEntryFinishData);


    //不要なプロパティーは削除//
    delete tournamentData.eventDate;
    delete tournamentData.eventStartTime;
    delete tournamentData.eventFinishDate;
    delete tournamentData.eventFinishTime;

    delete tournamentData.entryStartDate;
    delete tournamentData.entryFinisDate;

    delete tournamentData.id;
    delete tournamentData.temp;
    delete tournamentData.tournament_picture_file;
    delete tournamentData.tournament_picture;

    const saveSata = (async () => {
        //画像ファイルがいくつ選択されているか
        let fileCount = tournament_picture_file.filter((data) => {
            return data !== undefined;
        }).length;
        if (fileCount > 0) {
            tournament_picture_file.forEach((data, index) => {
                if (data !== undefined) {
                    //画像の保存
                    let path = "tournament/" + id + "/" + index;
                    saveImage(path, data, async (result, status) => {
                        tournamentData.pictureList[index] = status;         //画像url
                        fileCount--;
                        if (fileCount <= 0) {
                            //データベースの更新
                            await updateTournament(tournamentData, id);
                            if (callback !== null && callback !== undefined) {
                                callback(result, status);
                            }
                        }
                    });
                }
            })
        } else {
            //データベースの更新
            await updateTournament(tournamentData, id);
            if (callback !== null && callback !== undefined) {
                callback();
            }
        }


    });

    saveSata();


}

export async function loadCourseList(tournamentData) {

    let golfCourseList = null;
    if (tournamentData.temp.loadCourseList.length === 0) {
        let loadCourseList = await getCourseList();
        golfCourseList = loadCourseList;
        //戻ってきたときに再度読み込みが起きないようにキャッシュ
        tournamentData.temp.loadCourseList = loadCourseList;
    } else {
        golfCourseList = [...tournamentData.temp.loadCourseList];
    }
    return golfCourseList;
};

/**
 * 大会データの取得
 */
export async function getTournamentDataList() {
    let dataList = await getTournamentList();

    //開催日順に並び替え
    dataList = sortArray(dataList, "startMillisecond", undefined, "asc");

    dataList.forEach((data) => {
        //開催日
        data.eventDate = getDate(data.startMillisecond);
        //開催時間
        data.eventStartTime = getTime(data.startMillisecond);
        //終了日
        data.eventFinishDate = getDate(data.finishMillisecond);
        //終了時間
        data.eventFinishTime = getTime(data.finishMillisecond);

        //エントリー受付開始日
        data.entryStartDate = getDate(data.entryStartMillisecond);;

        //エントリー受付終了日
        data.entryFinisDate = getDate(data.entryFinishMillisecond);;


    })
    return dataList;
}

/**
 * 大会データの削除
 * @param {*} tournamentData 
 * @param {*} callback 
 */
export async function delTournament(tournamentData, callback) {
    try {
        await deleteTournament(tournamentData.id, async (result, status) => {
            if (result === false) {
                throw new Error("大会の削除に失敗しました=" + status);
            }
            let path = "tournament/" + tournamentData.id;
            await deleteImage(path, (result, status) => {
                if (result === false) {
                    throw new Error("大会の削除に失敗しました=" + status);
                }
                if (callback !== null && callback !== undefined) {
                    callback(result, status);
                }
            });

        });

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }

}

/**
 * トーナメント用ユーザー登録の作成
 * @param {*} userData 
 * @param {*} orderNb 
 * @returns 
 */
export function makeTournamentUser(userData, indexNb) {

    let given_name_kanji = userData.given_name_kanji + userData.family_name_kanji;
    let family_name_kana = userData.given_name_kana + userData.family_name_kana;
    let userDataFormat = {
        deleteFlg: 0,
        uid: userData.uid,
        entry_flg: userData.entryData === undefined ? 0 : userData.entryData.entry_flg,
        statusFlg: 0,
        groupNb: -1,
        inGroupNb: -1,
        serialNb: userData.serial_nb,
        given_name_kanji: given_name_kanji,
        family_name_kana: family_name_kana,
        tour_counts: [...userData.tour_counts],
        key: userData.serial_nb + Math.floor((Math.random() * 1000)),                  //同じ値だとコンポーネントの内部ステータスが使いまわしされconstのデータは最初に作られたときのままになる
        indexNb: indexNb
    };
    return userDataFormat;
}

/**
 * 配列をシャッフルする
 * @param {*} param0 
 * @returns 
 */
export const shuffle = ([...array]) => {
    for (let i = array.length - 1; i >= 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

/**
 * 大会に参加している全てのユーザーを取得する
 * @param {*} tournamentId 
 * @param {*} callback 
 */
export async function getTournamentAllUserList(tournamentId, callback) {
    let entryCount = 0;
    await getTournamentAllUser(tournamentId, (result, userDataList) => {
        if (result === false) {
            if (callback !== null && callback !== undefined) {
                //名前はuserDataListだけどエラーの原因が入ってる
                //                  ↓
                callback(false, userDataList, []);
            }
            return;
        }
        let userDataFormatList = []
        userDataList.forEach((userData) => {
            let userDataFormat = makeTournamentUser(userData, userData.entryData.indexNb);
            if (userDataFormat.entry_flg === 1) {
                entryCount++;
            }
            userDataFormatList.push(userDataFormat);

        });

        //エントリーしているユーザーを上の方に詰める（大きい順にすればOK）
        userDataFormatList = sortArray(userDataFormatList, "entry_flg", undefined, "desc");
        //辞退ユーザーがソートで上に来ているので、辞退ユーザーだけ取り出し、辞退ユーザーを取り出した配列の後ろに結合する
        let declineList = [];
        userDataFormatList = userDataFormatList.filter((userDataFormat) => {
            //辞退がいるか？
            if (userDataFormat.entry_flg === 2) {
                declineList.push(userDataFormat);
            }
            return userDataFormat.entry_flg !== 2;

        })
        //配列を合成します（辞退のユーザーを後ろにしたいので）
        userDataFormatList = userDataFormatList.concat(declineList);
        //indexNbを降り直し
        userDataFormatList = userDataFormatList.map((userDataForma, indexNb) => {
            userDataForma.indexNb = indexNb;
            return userDataForma;
        })
        userDataFormatList = sortArray(userDataFormatList, "indexNb");

        if (callback !== null && callback !== undefined) {
            callback(true, entryCount, userDataFormatList);
        }
    })
}

/**
 * ユーザーの性別から大会の性別を取得
 * @param {*} userSex 
 * @returns 
 */
export const getTournamentSexFromUserSex = (userSex) => {

    let sex = "男子";
    switch (userSex) {
        case "男性":
            sex = "男子";
            break;
        case "女性":
            sex = "女子";
            break;
        case "ジュニア":
            sex = "ジュニア";
            break;
        default:
            break;

    }
    return sex;
}

/**
 * トーナメントの性別からユーザーの性別を取得
 * @param {*} tournamentSex 
 * @returns 
 */
export const getUserSexFromTournamentSex = (tournamentSex) => {

    let sex = "男子";
    switch (tournamentSex) {
        case "男子":
            sex = "男性";
            break;
        case "女子":
            sex = "女性";
            break;
        case "ジュニア":
            sex = "ジュニア";
            break;
        default:
            break;

    }
    return sex;
}


/**
 * 割り振られたユーザーを大会にすべて追加し保存
 * @param {*} tournamentId  トーナメントID
 * @param {*} userDataList  ユーザーリスト
 * @param {*} callback 
 */
export async function saveTournamentAllUserList(tournamentId, userDataList, callback) {
    //データベースに書き込み
    try {
        let promise_results = userDataList.map(async (userData) => {
            //削除されたユーザーは除外
            if (userData.deleteFlg === 0) {
                let tournamentUserData = { uid: userData.uid, entry_flg: userData.entry_flg, statusFlg: userData.statusFlg, groupNb: userData.groupNb, inGroupNb: userData.inGroupNb, indexNb: userData.indexNb };
                return addUserTournament(tournamentUserData, tournamentId, (result, status) => {
                    //エラーか？
                    if (result === false) {
                        throw new Error("大会に参加するユーザーデータを保存に失敗しました=" + status);
                    }
                    userData.parentId = status.id;
                });
            }
        })

        await Promise.all(promise_results);

        promise_results = userDataList.map(async (userData) => {
            await updateUsersTourCount(userData.uid, userData.tour_counts, (result, status) => {
                //エラーか？
                if (result === false) {
                    throw new Error("大会カウントを更新するに失敗しました=" + status);
                }
            });
        });

        await Promise.all(promise_results);


        promise_results = userDataList.map(async (userData) => {
            await addPaymentTournamentUserData(tournamentId, userData.parentId, userData.uid, (result, status) => {

                //エラーか？
                if (result === false) {
                    throw new Error("大会に参加するユーザーデータに決済情報を保存するエリアを追加に失敗しました=" + status);
                }
            });
        });

        await Promise.all(promise_results);

        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * 
 * @param {*} tournamentId 
 * @param {*} property 
 * @param {*} maxEntry 
 * @param {*} userDataList 
 */
export async function finish_individual_addition_function(tournamentId, property, maxEntry, userDataList, callback) {

    //最大エントリー数を保存
    await updateTournamentPart(tournamentId, property, maxEntry, async (result, status) => {
        if (result === false) {
            if (callback !== null && callback !== undefined) {
                callback(result, status);
            }
        } else {
            //大会に参加するユーザーデータを全て削除
            await deleteTournamentEntryUser(tournamentId, async (result, status) => {
                if (result === false) {
                    if (callback !== null && callback !== undefined) {
                        callback(result, status);
                    }
                } else {
                    //データベースに書き込み
                    await saveTournamentAllUserList(tournamentId, userDataList, (result, status) => {
                        if (result === false) {
                            if (callback !== null && callback !== undefined) {
                                callback(result, status);
                            }
                        } else {
                            if (callback !== null && callback !== undefined) {
                                callback(true, "");
                            }

                        }
                    });

                }
            });

        }
    });




}

/**
 * 割り振り完了
 * @param {*} tournamentData 
 * @param {*} userDataList 
 */
export async function finish_allocation_function(tournamentData, userDataList, callback) {
    try {
        //大会データの更新
        await updateMaxEntryTournament(tournamentData.id, tournamentData.max_entry, (result, status) => {
            if (result === false) {
                throw new Error("ユーザーの参加回数の更新に失敗しました=" + status);
            }
        })

        //削除されたユーザーは除外
        userDataList = userDataList.filter((userData) => {
            return userData.deleteFlg === 0;
        })

        //ユーザーの参加回数を+1する
        let promise_results = userDataList.map((userData) => {
            //keyを更新してリストを再描画させたい
            userData.key = userData.serialNb + Math.floor((Math.random() * 1000));
            return addUsersTourCount(userData.uid, tournamentData.area, (result, status) => {
                if (result === false) {
                    throw new Error("ユーザーの参加回数の更新に失敗しました=" + status);
                }
            });
        })

        await Promise.all(promise_results);

        //データベースに書き込み
        await saveTournamentAllUserList(tournamentData.id, userDataList, (result, status) => {
            if (result === false) {
                throw new Error("データベースの更新に失敗しました=" + status);
            }

        });


        //お知らせの読み込み
        await getAnnouncementNewsTemplate(0, async (result, status) => {
            if (result === false) {
                throw new Error("お知らせテンプレートデータの読み込みに失敗しました=" + status);
            }

            let title = status.news_text.title.replace("TTTT", tournamentData.title);
            let newsText = status.news_text.text;
            //ニュース発行
            await publishTournamentNewsAllUsers(userDataList, title, newsText, 0, (result, status) => {
                if (result === false) {
                    throw new Error("ニュースの発行に失敗しました=" + status);
                }
            })


            //大会の割り当てフラグを設定する
            await setTournamentAllocationFlg(tournamentData.id, 1, (result, status) => {
                if (result === false) {
                    throw new Error("大会の割り当てフラグの更新に失敗しました=" + status);
                }
                //戻っただけではキャッシュで0なのでグローバルの変数にも
                tournamentData.allocationFlg = 1;
            });


            if (callback !== null && callback !== undefined) {
                callback(true);
            }
        })


    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }

}

/**
 * エントリーの最大人数で割り振る
 * @param {*} tournamentData 
 * @param {*} callback 
 */
export async function getTournamentAllUsersInTheArea(tournamentData, callback) {
    let areaNb = getAreaNb(tournamentData.area);
    //トーナメントの性別からユーザーの性別を取得
    let userSex = getUserSexFromTournamentSex(tournamentData.category);

    //エリアに対応したユーザーを全取得
    await getAllUsersInTheArea(areaNb, userSex, (result, allUserDataList) => {
        //削除されているユーザーは除外します
        allUserDataList = deleteListDeleted(allUserDataList);
        //参加エリアアカウント見て昇順ソート（小さい順）
        allUserDataList = sortArray(allUserDataList, "tour_counts", areaNb);

        let tmpUserDataList = [];
        for (let i = 0; i < tournamentData.max_entry; i++) {
            //参加回数の少ない順上から取得すればいいよね
            if (allUserDataList.length > i) {
                //トーナメント用ユーザーデータ作成
                let userDataFormat = makeTournamentUser(allUserDataList[i], i);
                //参加割り当てしたので+1
                userDataFormat.tour_counts[areaNb] += 1;
                tmpUserDataList.push(userDataFormat)
            }
        }
        if (callback !== null && callback !== undefined) {
            callback(true, tmpUserDataList);
        }

    });

}

/**
 * 個別検索
 * @param {*} tournamentData 
 * @param {*} search_keyword_text 
 * @param {*} userDataList 
 * @param {*} callback 
 */
export async function getTournamentLikeSearchUser(tournamentData, search_keyword_text, userDataList, callback) {

    try {

        let searchUsers = await likeSearchUser(search_keyword_text.value, "DESC");
        //合格者のみ表示する
        searchUsers = searchUsers.filter((userData) => {
            return userData.technical_test_flg === 1;
        });

        //削除されているユーザーは除外します
        searchUsers = deleteListDeleted(searchUsers);

        //検索結果が1件以上あるかどうか
        if (searchUsers.length > 0) {

            //大会のカテゴリ（性別）違いを除外
            //トーナメントの性別からユーザーの性別を取得
            let userSex = getUserSexFromTournamentSex(tournamentData.category);
            searchUsers = searchUsers.filter((userData) => {
                return userData.sex === userSex;
            });
            //重複ユーザーも除外
            let tempSearchUsers = lodash.cloneDeep(searchUsers);

            tempSearchUsers = tempSearchUsers.filter((searchUser) => {
                let tempUserData = userDataList.filter((userData) => {
                    return ((userData.uid === searchUser.uid) && (userData.deleteFlg === 0));
                });

                return tempUserData.length === 0;
            })



            if (callback !== null && callback !== undefined) {
                callback(true, tempSearchUsers);
            }


        } else {

            if (callback !== null && callback !== undefined) {
                callback(true, []);
            }

        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }

}

/**
 * 指定ユーザーをリストから削除する
 * @param {*} tournamentData 
 * @param {*} userDataList 
 * @param {*} deleteUserData 
 * @param {*} callback 
 */
export async function deleteTournamentUser(tournamentData, userDataList, deleteUserData, callback) {

    //削除する
    userDataList.forEach((data) => {
        if (deleteUserData.uid === data.uid) {
            let areaNb = getAreaNb(tournamentData.area);
            //参照なのでuserDataList配列内のデータが反映される
            data.tour_counts[areaNb] -= 1;
            //マイナスにはならない
            if (data.tour_counts[areaNb] < 0) {
                data.tour_counts[areaNb] = 0;
            }
            //削除フラグを立てるだけ（組み分け決定でデータベースのカウント値を減らしたいので）
            data.deleteFlg = 1;
        }
    });


    if (callback !== null && callback !== undefined) {
        callback(true, [...userDataList]);
    }
}


/**
 * 大会の組み分け確定
 * @param {*} tournamentData 
 * @param {*} userDataList 
 * @param {*} unmodifiedUserDataList 
 * @param {*} callback 
 */
export async function tournamentUserGroupingConfirmed(tournamentData, userDataList, unmodifiedUserDataList, callback) {
    try {
        //ニュース発行
        //お知らせの読み込み
        let title = "";
        //本文設定
        let newsText = "";
        await getAnnouncementNewsTemplate(1, async (result, status) => {
            if (result === false) {
                throw new Error("お知らせテンプレートデータの読み込みに失敗しました=" + status);
            }

            title = status.news_text.title.replace("TTTT", tournamentData.title);
            //本文設定
            newsText = status.news_text.text.replace("TTTT", tournamentData.title);
        })

        let promise_results = userDataList.map(async (userData) => {

            //経過時間を追加
            let elapsedTime = (userData.groupNb + 1) * tournamentData.group_match_interval;
            let elapsedTimeObject = addMinutes(tournamentData.startMillisecond, elapsedTime);

            let tempNewsText = newsText.replace("GGGG", userData.groupNb + 1
            ).replace("NNNN", userData.inGroupNb + 1
            ).replace("SSSS", userData.given_name_kanji
            ).replace("HHHH", elapsedTimeObject.hours
            ).replace("MMMM", elapsedTimeObject.minutes);


            //個別にニュース発行
            return publishTournamentNewsUsers(userData.uid, title, tempNewsText, 0, (result, status) => {
                if (result === false) {
                    throw new Error("ニュースの発行に失敗しました=" + status);
                }
            });
        })

        await Promise.all(promise_results);

        //スコア付けるためのコースデータ追加
        await addScoreDataTournamentUserDatas(tournamentData, userDataList, async (result, status) => {
            if (result === false) {
                throw new Error("スコアデータの作成に失敗しました=" + status);
            }

        })


        //参加ユーザーを更新
        await updateAllUserDataTournament(tournamentData.id, userDataList, async (result, status) => {
            if (result === false) {
                throw new Error("参加ユーザーの更新に失敗しました=" + status);
            }

            //データベースから削除したいので編集で削除されたユーザを検索
            let tempUnmodifiedUserDataList = unmodifiedUserDataList.filter((unmodifiedUserData) => {
                return unmodifiedUserData.deleteFlg === 1;
            })

            if (tempUnmodifiedUserDataList.length > 0) {
                await tempUnmodifiedUserDataList.forEach(async (tempUnmodifiedUserData) => {
                    //トーナメント参加ユーザーを削除したので回数を減算
                    await subUsersTourCount(tempUnmodifiedUserData.uid, tournamentData.area, async (result, status) => {
                        if (result === false) {
                            throw new Error("トーナメント参加ユーザーを削除したので回数を減算に失敗しました=" + status);
                        }
                    });
                    //編集で削除されたのでトーナメント用ユーザデータもデータベースから消す
                    await deleteUserTournament(tournamentData.id, tempUnmodifiedUserData.uid, (result, status) => {
                        if (result === false) {
                            throw new Error("編集で削除されたのでトーナメント用データもデータベースから消すに失敗しました=" + status);
                        }
                    });
                })
            }

        });

        await setGroupingFlgTournament(tournamentData.id, 1, (result, status) => {
            if (result === false) {
                throw new Error("大会の組み分けを終了させるに失敗しました=" + status);
            }
        });

        //編集で削除されたユーザーがいない
        callback(true, "");

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }

    }
}
/**
 * 個別追加
 * @param {*} tournamentData 
 * @param {*} results_list 
 * @param {*} userDataList 
 * @param {*} searchResults 
 * @returns 
 */
export async function individualAddition(tournamentData, results_list, userDataList, searchResults, callback) {
    if (results_list.selectedIndex === 0) {
        callback(false, searchResults);
        return;
    }
    //現在の人数を確認（削除ユーザーはカウントに入れない）
    let nowMaxUserList = userDataList.filter((userData) => {
        return userData.deleteFlg !== 1;
    })
    //割り振られた人数より最大エントリー数のほう小さいならアラート
    if (nowMaxUserList.length >= tournamentData.max_entry) {
        callback(false, searchResults);
        return;
    }
    //エリア番号取得
    let areaNb = getAreaNb(tournamentData.area);

    //検索結果から選択されたユーザーを取得
    let searcUserData = searchResults[results_list.selectedIndex - 1];
    //削除はされたけどまだメモリーに残ってるユーザーを見てエントリーされているか確認
    let resultUserData = userDataList.filter((userData) => {
        if (userData.uid === searcUserData.uid) {
            userData.deleteFlg = 0;       //削除解除
            userData.tour_counts[areaNb] += 1;
        }
        return userData.uid === searcUserData.uid;
    })

    if (resultUserData.length > 0) {
        //追加したのでプルダウンから削除
        searchResults.splice(results_list.selectedIndex - 1, 1);
        if (callback !== null && callback !== undefined) {
            callback(true, searchResults);

        }

    } else {
        //トーナメント用ユーザーデータ作成
        let userDataFormat = makeTournamentUser(searcUserData, userDataList.length);
        //追加したので+1
        userDataFormat.tour_counts[areaNb] += 1;
        //割り振ったユーザーに個別ユーザーを足す
        userDataList.push(userDataFormat);
        //追加したのでプルダウンから削除
        searchResults.splice(results_list.selectedIndex - 1, 1);

        if (callback !== null && callback !== undefined) {
            callback(true, searchResults);
        }

    }

    //    setSearchResults([...searchResults]);

    //    setIndividualAdditionMessage(true);

}

/**
 * エントリーユーザー数の取得
 * @param {*} userDataList 
 * @returns 
 */
export function getEntryAllCount(userDataList) {
    let tempUserDataList = userDataList.filter((userData) => {
        return userData.entry_flg === 1 && userData.deleteFlg === 0;
    })
    return tempUserDataList.length;
}

/**
 * 大会用ユーザーデータにスコアデータを追加する
 * @param {*} tournamentData 
 * @param {*} userDataList 
 * @param {*} callback 
 */
export async function addScoreDataTournamentUserDatas(tournamentData, userDataList, callback) {
    try {

        //ゴルフ場データの取得
        let courseSataList = await getCourse(tournamentData.golfCourseDocId);

        //大会のコースkeyからゴルフ場のコース情報を取得
        let courseList = courseSataList.arrayCourseList.filter((course) => {
            let useCourse = tournamentData.useCourseList.filter((useCourse) => {
                return useCourse.key === course.key;
            })
            //大会データに保存してあるコースデータが、コースデータの中にある場合は見つかった
            return useCourse.length > 0;

        })


        if (courseList.length <= 0) {
            if (callback !== null && callback !== undefined) {
                callback(false, "not use course");
            }
        } else {
            let unifyholeDataList = unifyGolfCourse(courseList);
            let userCourseData = { ranking: -1, score_data_list: [], tournamentId: tournamentData.id, uid: -1, eliminationFlg: 0 }

            unifyholeDataList.forEach((golfCourse) => {
                let score_data_list = { at_bat: golfCourse.atBat, user_at_bat: 99, scorer_uid: "", name: golfCourse.name, change_flg: false };
                userCourseData.score_data_list.push(score_data_list)

            })
            /*
                        let userCourseData = { ranking: -1, score_data_list: [], tournamentId: tournamentData.id, uid: -1 }
                        //コースが複数使用されていたら、合計のコースデータを作成（TODO本来なら、使用するコースの順番がある2021/09/06）
                        courseList.forEach((courseData) => {
                            courseData.holeList.forEach((holeData) => {
                                let score_data_list = { at_bat: holeData.atBat, user_at_bat: 99, scorer_uid: "", name: holeData.name,change_flg: false };
                                userCourseData.score_data_list.push(score_data_list)
                            });
            
                        })
            */
            let promise_results = userDataList.map(async (userData) => {
                //参照なのでコピーしないとオリジナルにならない
                userCourseData = lodash.cloneDeep(userCourseData);
                userCourseData.uid = userData.uid;
                return addScoreDataTournamentUserData(tournamentData.id, userCourseData, userData.uid, (result, status) => {
                    if (callback !== null && callback !== undefined) {
                        //アラーの場合強制例外
                        if (result === false) {
                            throw new Error(status);
                        }
                    }
                });

            })

            await Promise.all(promise_results);
            //完了コースバック
            if (callback !== null && callback !== undefined) {
                callback(true, "");
            }
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }


}

/**
 * ツアーカウント配列のチェック
 * @param {*} userDataList 
 */
export function checkTournamentTourCountsArray(userDataList) {
    userDataList.forEach((userData) => {
        //配列が０の場合は追加しておく
        if (userData.tour_counts.length <= 0) {
            //関東
            userData.tour_counts.push(0);
            //関西
            userData.tour_counts.push(0);
        }
    })
    return userDataList;
}
/**
 * 大会日時の設定
 * @param {*} tournamentData 
 */
export function setTournamentTime(tournamentData) {
    //ツアー開始日と終了日のミリ秒保存
    let tournamentDataTime = tournamentData.eventDate + " " + tournamentData.eventStartTime
    let tournamentFinishDataTime = tournamentData.eventFinishDate + " " + tournamentData.eventFinishTime
    tournamentData.startMillisecond = getMillisecond(tournamentDataTime);
    tournamentData.finishMillisecond = getMillisecond(tournamentFinishDataTime);

    //エントリーの受付開始日と受付終了日のミリ秒保存
    let tournamentEntryStartData = tournamentData.entryStartDate + " 00:00:00";
    let tournamentEntryFinishData = tournamentData.entryFinisDate + " 00:00:00";
    tournamentData.entryStartMillisecond = getMillisecond(tournamentEntryStartData);
    tournamentData.entryFinishMillisecond = getMillisecond(tournamentEntryFinishData);


}

/**
 * 大会にエントリーしているユーザーに延期のお知らせを送信する
 * @param {*} tournamentData 
 * @param {*} callback 
 * 
 */
export async function sendNewsPostponementSchedule(tournamentData, callback) {
    try {
        await getTournamentAllUser(tournamentData.id, async (result, status) => {
            const userDataList = status;
            if (result === false) {
                throw new Error("延期のお知らせを送信に失敗しました=" + status);
            } else {
                //大会データの一部を更新します
                //延期フラグの更新
                await updateTournamentPart(tournamentData.id, "postponed_flg", tournamentData.postponed_flg, async (result, status) => {
                    if (result === false) {
                        throw new Error("延期フラグの更新に失敗しました=" + status);
                    }
                });

                //開催日時の更新
                await updateTournamentPart(tournamentData.id, "startMillisecond", tournamentData.startMillisecond, async (result, status) => {
                    if (result === false) {
                        throw new Error("開催日時の更新に失敗しました=" + status);
                    }
                });

                //終了日時の更新
                await updateTournamentPart(tournamentData.id, "finishMillisecond", tournamentData.finishMillisecond, async (result, status) => {
                    if (result === false) {
                        throw new Error("終了日時の更新に失敗しました=" + status);
                    }
                });

                await publishTournamentNewsAllUsers(userDataList, tournamentData.newsTitle, tournamentData.newsText, 1, (result, status) => {
                    if (result === false) {
                        throw new Error("延期のお知らせを送信に失敗しました=" + status);
                    }
                });
            }
        });

        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * 特殊文字列の確認
 * @param {*} checkString 
 * @param {*} callback false=特殊文字あり true=問題なし
 */
export function checkSpecialCharacters(checkString, callback) {
    //特殊文字チェック
    let result = checkMoji(checkString);
    if (result.length > 0) {
        if (callback !== null && callback !== undefined) {
            callback(false, result);
            return false;
        }

    }else{
        callback(true, "");
        return true;
    }

}

