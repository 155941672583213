import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { TournamentContext, tournamentInitialState } from '../TournamentProvider'
import ManagementFooter from '../../ManagementFooter'
import logo from "../../../images/logo.svg";
import { IconTournament, IconCompletion } from "../../Icons";
import '../../../styles/styles.css';
import { setTournamentTime, checkTournamentTourCountsArray, getDate, addScoreDataTournamentUserDatas, getEntryAllCount, individualAddition, deleteTournamentUser, getTournamentLikeSearchUser, getTournamentAllUsersInTheArea, finish_allocation_function, finish_individual_addition_function, makeTournamentUser, getTournamentAllUserList, getUserSexFromTournamentSex, saveTournamentAllUserList } from "../TournamentUtility";
import { setGroupingFlgTournament, deleteTournamentEntryUser, getTournamentPaymentUserData, setTournamentPaymentUserData, getParticipationTournament, sortArray, setTournamentAllUserEntryFlg, getUsersNews, setUsersNews, likeSearchUser, initAllUsersTourCount, publishTournamentNewsAllUsersDel, addUserTournament, updateTournamentPart, deleteTestUser, testFunction, getTournamentEntryFlg, setTournamentStatus, getUserDataTournament, deleteAllUserTournament, setCopyUser, getAllUsersInTheArea, setTournamentAllocationFlg } from "../../../Database";
import '../../../styles/loader.css'
import Loader from '../../Loader'

function Tournament_016(props) {
    const [value] = useContext(TournamentContext);
    const history = useHistory();

    const [tournament016Data, setTournament016Data] = useState(value.tournament016Data);                //グローバル参照渡し
    const [loadingData, setLoadingData] = useState(undefined);

    useEffect(() => {
        let tempTransitionSource = undefined;
        if (tournament016Data === undefined) return;
        if (tournament016Data.title.length === 0) {
            //transitionSource のグローバルデータを変更したくないのでdeepコピー
            tempTransitionSource = lodash.cloneDeep(value.tournament009Data.tournamentData);
            tempTransitionSource.newsTitle = "";
            tempTransitionSource.newsText = "";
        } else {
            //transitionSource のグローバルデータを変更したくないのでdeepコピー
            tempTransitionSource = lodash.cloneDeep(tournament016Data);

        }

        //選択された大会
        let select_tournament_title = tempTransitionSource.title + " " + tempTransitionSource.category + " " + tempTransitionSource.area;

        document.getElementById("select_tournament_title").innerText = select_tournament_title

        //チェックBOXをチェック
        document.getElementById("postpone_tournament").checked = tempTransitionSource.postponed_flg !== 2 ? "" : "none";

        //開催日
        document.getElementById("tournament_date").value = tempTransitionSource.eventDate;

        //開催時間
        document.getElementById("tournament_time").value = tempTransitionSource.eventStartTime;

        //終了日
        document.getElementById("tournament_finish_date").value = tempTransitionSource.eventFinishDate;

        //終了時間
        document.getElementById("tournament_finish_time").value = tempTransitionSource.eventFinishTime;

        //お知らせタイトル
        document.getElementById("postponment_reason_title").value = tempTransitionSource.newsTitle;

        //お知らせテキスト
        document.getElementById("postponment_reason_content").value = tempTransitionSource.newsText;

        //開催日は未定フラグを見て日付入力エリアを表示、非表示切り替え
        if (tempTransitionSource.postponed_flg === 2) {
            document.getElementById("holding_undecided").style.display = "none";

        } else {
            document.getElementById("holding_undecided").style.display = "";
        }

    }, [tournament016Data, value.tournament009Data.tournamentData]);

    const onClickButton = (e) => {
        switch (e.currentTarget.id) {
            case "button_back":
                history.push("Tournament_009");
                break;
            default:
                break;
        }
    };

    //開催日は未定チェックBOX
    const onChangeCheckBoxinput = (e) => {
        if (e.target.checked === true) {
            document.getElementById("holding_undecided").style.display = "none";
            let tag = document.getElementById("reason_postponement");
            tag.scrollIntoView({
                behavior: "smooth"
            })
        } else {
            document.getElementById("holding_undecided").style.display = "";

        }

    }
    //
    const onSubmit = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();
        const {
            postpone_tournament,        //開催日は未定
            tournament_date,            //開催日
            tournament_time,            //開始時間
            tournament_finish_date,     //終了日
            tournament_finish_time,     //終了時間
            postponment_reason_title,   //お知らせのタイトル
            postponment_reason_content, //お知らせ送信内容
        } = e.target.elements;

        tournament016Data.title = value.tournament009Data.tournamentData.title;
        tournament016Data.category = value.tournament009Data.tournamentData.category;
        tournament016Data.area = value.tournament009Data.tournamentData.area;
        tournament016Data.id = value.tournament009Data.tournamentData.id;

        //開催日は未定のチェックBoxがチェックされているか？
        tournament016Data.postponed_flg = postpone_tournament.checked ? 2 : 1;      //数値で設定する

        tournament016Data.eventDate = tournament_date.value;
        tournament016Data.eventStartTime = tournament_time.value;
        tournament016Data.eventFinishDate = tournament_finish_date.value;
        tournament016Data.eventFinishTime = tournament_finish_time.value;
        //お知らせタイトル
        tournament016Data.newsTitle = postponment_reason_title.value;
        //お知らせ内容
        tournament016Data.newsText = postponment_reason_content.value;
        //日付設定
        setTournamentTime(tournament016Data);
        history.push("Tournament_017");
    }
    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <header className="c-container-vertical c-mt88">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconTournament></IconTournament>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">既存大会延期画面</h1>
                </div>
            </header>
            <main>

                <div className="c-page-section c-mt32">

                    <div className="kanri-container c-mt56 c-mb88">

                        <form onSubmit={onSubmit}>

                            <p className="c-paragraph c-paragraph--left">以下の大会を延期します。</p>
                            <h2 className="c-heading--2 c-heading--left c-mt8" id="select_tournament_title">第1回JCG男子ツアー 関東会場</h2>

                            <h3 className="c-heading--3 c-heading--left c-mt56 c-mb32">新たな開催日時を入力してください。</h3>

                            <div className="postpone-checkbox">
                                <label htmlFor="postpone_tournament" className="postpone-checkbox__label">
                                    開催日は未定
                                </label>
                                <input type="checkbox" name="postpone_tournament" id="postpone_tournament" className="postpone-checkbox__checkbox js-postpone-tournament" onChange={onChangeCheckBoxinput} />
                            </div>
                            <p className="c-subtitle c-subtitle--left c-mt8">未定を選択した場合、後日既存大会変更から開催日を設定してください。</p>
                            <div id="holding_undecided">
                                <div className="input input__set c-mt56 js-schedule-input">
                                    <label htmlFor="tournament_date" className="input input__label">開催日</label>
                                    <input type="date" name="tournament_date" id="tournament_date" defaultValue="2021-06-25" required className="input input__field js-tournament-date" />
                                </div>

                                <div className="input input__set c-mt56 js-schedule-input">
                                    <label htmlFor="tournament_time" className="input input__label">開催時間</label>
                                    <input type="time" name="tournament_time" id="tournament_time" defaultValue="16:00" required className="input input__field js-tournament-time" />
                                </div>

                                <div className="input input__set c-mt56 js-schedule-input">
                                    <label htmlFor="tournament_finish_date" className="input input__label">終了日</label>
                                    <input type="date" name="tournament_finish_date" id="tournament_finish_date" defaultValue="2021-07-25" required className="input input__field js-tournament-finish-date" />
                                </div>

                                <div className="input input__set c-mt56 js-schedule-input">
                                    <label htmlFor="tournament_finish_time" className="input input__label">終了時間</label>
                                    <input type="time" name="tournament_finish_time" id="tournament_finish_time" defaultValue="20:00" required className="input input__field js-tournament-finish-time" />
                                </div>
                            </div>
                            <h3 className="c-heading--3 c-heading--left c-mt56 js-postpone-reason-title" id="reason_postponement">延期理由を入力ください。</h3>
                            <p className="c-subtitle c-subtitle--left c-mt8">参加者にお知らせが送信されます。</p>

                            <div className="c-container-vertical c-mt16">
                                <div className="input input__set input__set--kanri c-margin-reset">
                                    <label htmlFor="postponment_reason_title" className="input input__label">お知らせのタイトル</label>
                                    <input type="text" name="" id="postponment_reason_title" placeholder="お知らせのタイトルを入力してください" required className="input input__field c-paragraph" />
                                </div>
                            </div>

                            <div className="c-container-vertical c-mt16">
                                <div className="input input__set input__set--kanri c-margin-reset">
                                    <label htmlFor="postponment_reason_content" className="input input__label">お知らせ送信内容</label>
                                    <textarea name="" id="postponment_reason_content" placeholder="ここにプッシュ通知で表示する文章を入力してください。" required className="input input__field input__field--textarea c-paragraph"></textarea>
                                </div>
                            </div>

                            <div className="c-button-set c-mt56 c-mb88">
                                <button id="button_next" type="submit" className="button button__primary">次へ</button>
                                <button id="button_back" onClick={(e) => { onClickButton(e) }} className="button button__secondary">戻る</button>
                            </div>

                        </form>

                    </div>

                </div>

            </main>


            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default Tournament_016

