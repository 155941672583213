import React, { useEffect, useState } from 'react';
import '../../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";

function CourseEditStep1Warning(props) {
    const [nowCourse, setNowCourse] = useState({ validation: { validationName: false, validationHole: false, validationTee: false, validationGreen: false } });
    const [windowFlg, setWindowFlg] = useState({ flg: false });
    //描画後呼ばれる
    useEffect(() => {
        setNowCourse(props.nowCourse);

        return () => {
//            console.log("CourseEditStep1Warning");
        };
    }, []);

    useEffect(() => {
        scrollWarningWindow();

    }, [nowCourse.validation]);

    useEffect(() => {
        scrollWindow();
    }, [windowFlg]);

    const scrollWarningWindow = () => {
        let viewFlg={flg:false};
        //何か警告があったら、警告用の背景を表示
        if (nowCourse.validation.validationName === true || nowCourse.validation.validationHole === true || nowCourse.validation.validationTee === true || nowCourse.validation.validationGreen === true) {
            viewFlg.flg=true;
        } else {
            viewFlg.flg=false;
        }
        setWindowFlg({...viewFlg});
    };
    const scrollWindow=()=>{
        if (windowFlg.flg === true) {
            let tagId = props.itemKey;
            let tag = document.getElementById(tagId);
            tag.scrollIntoView(true)
        }
    }

    if(windowFlg.flg===true){
        return (
            <div className="add-course-validation c-mb24" id={"add-course-validation_" + props.itemKey}>
                <span className="c-heading--3 add-course-validation__title">以下の必要な情報が入力されていません。</span>
                <span id="validation_name" className="add-course-validation__item c-subtitle" style={{ display: nowCourse.validation.validationName ? '' : 'none' }}>コースの名称</span>
                <span id="validation_hole" className="add-course-validation__item c-subtitle" style={{ display: nowCourse.validation.validationHole ? '' : 'none' }}>ホール数</span>
                <span id="validation_tee" className="add-course-validation__item c-subtitle" style={{ display: nowCourse.validation.validationTee ? '' : 'none' }}>ティー位置の名称</span>
                <span id="validation_green" className="add-course-validation__item c-subtitle" style={{ display: nowCourse.validation.validationGreen ? '' : 'none' }} >グリーンの名称</span>
            </div>
        )
    }else{

        return (
            <div style={{ display: 'none'}}></div>
        )
    }
}



export default CourseEditStep1Warning

