import React, { useEffect, useState } from 'react';
import CourseEditStep3HoleAndAtBatContainer from './CourseEditStep3HoleAndAtBatContainer'

function CourseEditStep3CourseContainer(props) {
    const [courseName, setCourseName] = useState(props.nowCourse.courseName);
    const [holeAndAtBatList, setHoleAndAtBatList] = useState(props.nowCourse.holeList);

    useEffect(() => {

    }, []);
    return (

        <div className="c-container-horizontal"> {/*<!-- コース名コンテナ -->*/}
            {/*<!-- コース名内容 -->*/}
            <div className="game-data__column">
                <span className="game-data__slot">{courseName}</span>
            </div>
            {/*<!-- コース名の子要素 -->*/}
            <div className="c-container-vertical">
               {/*ここに ホール名」と「規定打数」コンテナをホール数ループ*/}
                {/*動的にコンポーネントが増えていきます*/}
                {holeAndAtBatList.map((holeAndAtBatItem,index) => (
                    <CourseEditStep3HoleAndAtBatContainer
                        key={courseName + "_" + index}
                        nowHoleAndAtBatData={holeAndAtBatItem}
                    />
                ))}

            </div>
        </div>
    )
}


export default CourseEditStep3CourseContainer

