import React, { useEffect, useContext, useState, useCallback } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { useDropzone } from 'react-dropzone';
import { TournamentContext, orijinaltournamentInitialState } from '../TournamentProvider'
import ManagementFooter from '../../ManagementFooter'
import Tournament013Container from './Tournament013Container'
import logo from "../../../images/logo.svg";
import { IconTournament } from "../../Icons";
import '../../../styles/styles.css';
import { } from '../../../Storage'
import { checkSpecialCharacters, getTotalNumberHoles, getTeeAndGreenData, setUseFlagForCourse, makeUseCourseList, inputCancell, lodaImageAndInputCancell, lodaImage, loadCourseList } from "../TournamentUtility";
import Loader from '../../Loader'

function Tournament_013(props) {
    const [value] = useContext(TournamentContext);
    const history = useHistory();

    const [tournament013Data] = useState(value.tournament013Data);                //グローバル参照渡し
    const [transitionSource, setTransitionSource] = useState(undefined);                //グローバル参照渡し
    const [useCourseList, setUseCourseList] = useState([]);                                                   //オブジェクトの細部まで見て更新してくれないので再描様に用意した
    const [courseList, setCourseList] = useState([]);
    const [previewList, setPreviewList] = useState([undefined, undefined, undefined, undefined]);
    const [imageAreaTag] = useState({ id: undefined });
    const [tournamentPictureInputStyleList, setTournamentPictureInputStyleList] = useState([true, true, true]);
    const [imageFileList] = useState([undefined, undefined, undefined]);
    const [loadingData, setLoadingData] = useState(undefined);

    useEffect(() => {
        let pictureList = [];
        let tempTransitionSource = undefined;
        let tempPreviewList = [undefined, undefined, undefined];
        if (value.tournament013Data.title.length === 0) {
            //transitionSource のグローバルデータを変更したくないのでdeepコピー
            tempTransitionSource = lodash.cloneDeep(value.tournament009Data.tournamentData);
            pictureList = value.tournament009Data.tournamentData.pictureList;

        } else {
            //transitionSource のグローバルデータを変更したくないのでdeepコピー
            tempTransitionSource = lodash.cloneDeep(value.tournament013Data);
            pictureList = value.tournament013Data.pictureList;
        }

        setTransitionSource(tempTransitionSource);

        //画像の設定
        //画像UIに反映
        setPreviewList([...pictureList]);

        //----------------------------------------------------
        //データベースからゴルフ場データの読み込み//
        const asyncfunc = (async () => {
            let golfCourseList = await loadCourseList(tournament013Data);
            setCourseList(golfCourseList);
            //ローディング非表示
            setLoadingData({ loadingFlg: false, title: "ゴルフ場データ読み込み中..." });
        });
        //ローディング表示
        setLoadingData({ loadingFlg: true, title: "ゴルフ場データ読み込み中..." });
        asyncfunc();

        //----------------------------------------------------
        //大会名
        document.getElementById("tournament_title").value = tempTransitionSource.title;

        //特殊文字チェック
        checkSpecialCharacters(tempTransitionSource.title, (result, stuta) => {
            if (result === false) {
                alert(stuta);
                return;
            }
        });

        //カテゴリー
        let tournament_category_tag = null;
        switch (tempTransitionSource.category) {
            case "男子":
                tournament_category_tag = document.getElementById("tournament_category_men");
                break;
            case "女子":
                tournament_category_tag = document.getElementById("tournament_category_women");
                break;
            case "ジュニア":
                tournament_category_tag = document.getElementById("tournament_category_junior");
                break;
            default:
                break;
        }
        if (tournament_category_tag !== null) {
            tournament_category_tag.checked = true;
        }
        //地域
        let area_tag = null;
        switch (tempTransitionSource.area) {
            case "関東":
                area_tag = document.getElementById("tournament_area_tokai");
                break;
            case "関西":
                area_tag = document.getElementById("tournament_area_kansai");
                break;
            default:
                break;
        }
        if (area_tag !== null) {
            area_tag.checked = true;
        }

        //開催日
        document.getElementById("tournament_date").value = tempTransitionSource.eventDate;

        //開催時間
        document.getElementById("tournament_time").value = tempTransitionSource.eventStartTime;

        //終了日
        document.getElementById("tournament_finish_date").value = tempTransitionSource.eventFinishDate;

        //終了時間
        document.getElementById("tournament_finish_time").value = tempTransitionSource.eventFinishTime;

        //エントリー受付開始日
        document.getElementById("tournament_start_entry").value = tempTransitionSource.entryStartDate;

        //エントリー受付終了日
        document.getElementById("tournament_finish_entry").value = tempTransitionSource.entryFinisDate;

        //最大エントリー数
        document.getElementById("tournament_max_entry").value = tempTransitionSource.max_entry;

        //エントリー金額
        document.getElementById("entry_amount").value = tempTransitionSource.entry_amount;
        //グループの試合間隔
        document.getElementById("group_match_interval").value = tempTransitionSource.group_match_interval;


    }, [tournament013Data, value.tournament009Data.tournamentData, value.tournament013Data]);


    useEffect(() => {
        if (transitionSource === undefined) return;
        if (courseList.length === 0) {
            //一番上にスクロール//
            window.scrollTo(0, 0);
        } else {
            //データベースから読み込んだゴルフ場データがUIに反映されてからしかプルダウンは設定出来ないので
            //----------------------------------------------------
            //ゴルフ場リストから、ゴルフ場IDを元にリストから検索
            let selectedCourse = courseList.findIndex((data, index) => {
                return data.id === transitionSource.golfCourseDocId;
            });

            //ゴルフ場プルダウンを設定
            if (selectedCourse !== -1) { //
                let tournament_club_options = document.getElementById("tournament_club").options;
                if (tournament_club_options.length >= 2) {
                    tournament_club_options[selectedCourse + 1].selected = true;
                }
            }
            //利用コース
            //コースリスト取得
            let arrayCourseList = courseList[selectedCourse].arrayCourseList;

            let tempUseCourseList = lodash.cloneDeep(arrayCourseList);
            //大会データに保存されている使用コースデータは、使用するコース、ティー、グリーンのみの保存なので
            //利用コースコンポーネントで使用するパラメータを元のコースデータにuseFlgを設定する
            //ゴルフ場の、どのコース、ティー、グリーンがトーナメントで使用されているコース、ティー、グリーンに使用されているか調べてuseFlgを設定する
            //                    元のコースデータ                           使用するコース、ティー、グリーンのみのデータ
            //                          ↓                                               ↓
            setUseFlagForCourse(tempUseCourseList, transitionSource.useCourseList);

            //使用コースをUIに設定
            setUseCourseList(tempUseCourseList);
            document.getElementById("button_next").disabled = false;
            //----------------------------------------------------

        }
        //ゴルフ場プルダウンUI     ゴルフ場コースUI
        //   ↓                       ↓
    }, [courseList, transitionSource]);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length < 1) {
            window.alert('ファイルの形式が適切ではありません');
        } else {
            let imageData = window.URL.createObjectURL(acceptedFiles[0]);
            let indexNb = 0;
            switch (imageAreaTag.id) {
                case "tournament_picture_01_drop":
                    indexNb = 0;
                    document.getElementById("tournament_picture_01").required = false;
                    break;
                case "tournament_picture_02_drop":
                    indexNb = 1;
                    //                    document.getElementById("tournament_picture_02").required = false;
                    break;
                case "tournament_picture_03_drop":
                    indexNb = 2;
                    //                    document.getElementById("tournament_picture_03").required = false;
                    break;
                default:
                    break;
            }
            tournamentPictureInputStyleList[indexNb] = false;
            previewList[indexNb] = imageData;
            imageFileList[indexNb] = acceptedFiles[0];


            setTournamentPictureInputStyleList([...tournamentPictureInputStyleList]);
            setPreviewList([...previewList]);
        }
        /*
        acceptedFiles.forEach((element,index) => {
            saveImage("qqq/",imageAreaTag.id+index,element,(result,data)=>{
                if(result===true){
                    window.alert('保存完了');
                }
            });
                
        });
*/

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previewList]);

    // eslint-disable-next-line no-unused-vars
    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        accept: "image/*",
        onDrop,
        maxSize: 932 * 257,
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true

    });

    const onClickDelete = (e) => {
        //バリデーションが実行されないように
        e.preventDefault();
        let indexNb = 0;
        switch (e.target.id) {
            case "button_cancel_upload_01":
                indexNb = 0;
                inputCancell("tournament_picture_01");
                document.getElementById("tournament_picture_01").required = true;
                break;
            case "button_cancel_upload_02":
                indexNb = 1;
                inputCancell("tournament_picture_02");
                //                document.getElementById("tournament_picture_02").required = true;
                break;
            case "button_cancel_upload_03":
                indexNb = 2;
                inputCancell("tournament_picture_03");
                //                document.getElementById("tournament_picture_03").required = true;
                break;
            default:
                break;
        }
        tournamentPictureInputStyleList[indexNb] = true;
        previewList[indexNb] = undefined;
        imageFileList[indexNb] = undefined;

        setTournamentPictureInputStyleList([...tournamentPictureInputStyleList]);
        setPreviewList([...previewList]);

    }

    const onChangeInput = (e) => {
        let fileName = e.target.files[0];
        if (fileName.size > 1048576) {
            window.alert('ファイルサイズが１Mを超えています');
            inputCancell(e.target.id);
            return;
        }

        let indexNb = 0;
        switch (e.target.id) {
            case "tournament_picture_01":
                indexNb = 0;
                break;
            case "tournament_picture_02":
                indexNb = 1;
                break;
            case "tournament_picture_03":
                indexNb = 2;
                break;
            default:
                break;
        }

        lodaImageAndInputCancell(fileName, e.target.id, (currentSrc) => {
            previewList[indexNb] = currentSrc;
            tournamentPictureInputStyleList[indexNb] = false;
            setTournamentPictureInputStyleList([...tournamentPictureInputStyleList]);
            setPreviewList([...previewList]);

        });

        imageFileList[indexNb] = fileName;
    }

    //ゴルフ場選択
    const onChangeSelect = (e) => {
        let indexNb = e.target.value;
        //コースリスト取得
        let arrayCourseList = courseList[indexNb].arrayCourseList;

        let tempUseCourseList = lodash.cloneDeep(arrayCourseList);
        //グローバルにも保存
        //        tournament002Data.temp.useCourseList = tempUseCourseList;

        tempUseCourseList.map((data) => {
            let primCourseData = getTeeAndGreenData(data);
            data.primCourseData = primCourseData;
            return data;
        });
        //        value.useCourseList=tempUseCourseList;


        //再描画
        setUseCourseList(tempUseCourseList);
    }
    const onSubmit = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();
        const { tournament_title,            //大会名
            tournament_category_men,
            tournament_category_women,
            tournament_category_junior,
            tournament_area_tokai,
            tournament_area_kansai,
            tournament_date,            //開催日
            tournament_time,            //開催時間
            tournament_finish_date,     //終了日
            tournament_finish_time,     //終了時間
            tournament_start_entry,     //エントリー受付開始日
            tournament_finish_entry,    //エントリー受付終了日
            tournament_max_entry,       //最大エントリー数

            entry_amount,               //エントリー金額
            group_match_interval        //グループの試合間隔


        } = e.target.elements;

        //タイトル
        tournament013Data.title = tournament_title.value;
        //特殊文字チェック
        if (checkSpecialCharacters(tournament_title.value, (result, stuta) => {
            if (result === false) {
                alert("タイトルに" + stuta);
            }
        }) === false) {
            return;
        }
        //カテゴリー
        let tournament_category_obj_list = [tournament_category_men, tournament_category_women, tournament_category_junior];
        let tournament_category_obj = tournament_category_obj_list.find((data) => {
            return data.checked === true;
        });
        let category = tournament_category_obj.nextSibling.innerText;
        tournament013Data.category = category;

        //地域
        let tournament_area_list = [tournament_area_tokai, tournament_area_kansai];
        let tournament_area_obj = tournament_area_list.find((data) => {
            return data.checked === true;
        });
        let tournament_area = tournament_area_obj.nextSibling.innerText;
        tournament013Data.area = tournament_area;

        //開催日
        tournament013Data.eventDate = tournament_date.value;

        //開催時間
        tournament013Data.eventStartTime = tournament_time.value;

        //終了日
        tournament013Data.eventFinishDate = tournament_finish_date.value;

        //終了時間
        tournament013Data.eventFinishTime = tournament_finish_time.value;

        //エントリー受付開始日
        tournament013Data.entryStartDate = tournament_start_entry.value;

        //エントリー受付終了日//
        tournament013Data.entryFinisDate = tournament_finish_entry.value;

        //最大エントリー数
        tournament013Data.max_entry = tournament_max_entry.value;

        //選択されたゴルフ場を検索
        let tournament_club_options = document.getElementById("tournament_club").options;
        let golfCourseData = courseList.find((data, index) => {
            if (tournament_club_options[index + 1].selected === true) {
                return lodash.cloneDeep({ ...courseList[index] });
            }
            return false;
        })
        //ゴルフ場IDを保存
        tournament013Data.golfCourseDocId = golfCourseData.id;


        //データベースに保存するコースデータを作成
        let findUseCourseList = makeUseCourseList(useCourseList);

        //使用するコース情報（コース、ティー、グリーン）
        tournament013Data.useCourseList = findUseCourseList;

        //ホール数の保存
        let numberHoles = getTotalNumberHoles(useCourseList);
        tournament013Data.number_holes = numberHoles;
        //ホール数の警告
        if (numberHoles < 18 || numberHoles > 18) {
            alert('１８ホールではないですがよろしいでしょうか？　\n選択されたホール数=' + numberHoles);
        }


        tournament013Data.entry_amount = entry_amount.value;   //エントリー金額
        tournament013Data.group_match_interval = group_match_interval.value; //グループの試合間隔
        tournament013Data.tournament_picture = previewList;  //メイン画像データ
        tournament013Data.tournament_picture_file = imageFileList;  //firebaseにデータを保存するためにファイルオブジェクト
        tournament013Data.tournament_picture = previewList;  //メイン画像データ
        //大会ID
        tournament013Data.id = transitionSource.id;


        //3ん番目の画像がある場合は2番目の画像は必ずあるようにする
        if (previewList[2] !== undefined || imageFileList[2] !== undefined) {
            //プレビューもファイルも2番目になかったらエラー
            if (previewList[1] === undefined && imageFileList[1] === undefined) {
                alert('2番目の画像が選択されていません');
                return;
            }
        }
        //目的（ツアー）
        tournament013Data.usePurpose = transitionSource.usePurpose;
        //画像フラグ
        tournament013Data.pictureList = transitionSource.pictureList;
        value.tournament013Data = lodash.cloneDeep(tournament013Data);

        //コース選択が一つもされていないか確認
        if (findUseCourseList.length === 0) {
            alert('コースが選択されていません');
        } else {

            history.push("/Tournament_014");
        }

    }

    const onClick = (e) => {

        switch (e.currentTarget.id) {
            case "button_back":
                //設定をクリア
                value.tournament013Data = lodash.cloneDeep(orijinaltournamentInitialState.tournament013Data);
                history.push("/Tournament_009");
                break;
            default:
                break;
        }

    }

    //タイトルの特殊文字チェック
    const onChangeInputTitle = (e) => {
        //特殊文字チェック
        checkSpecialCharacters(e.target.value, (result, stuta) => {
            if (result === false) {
                alert(stuta);
                return;
            }
        });
    }
    //---------------------------


    const renderCourseList = () => {
        if (courseList !== undefined) {
            return courseList.map((d, i) => <option key={d.id} value={i}>{d.club_name}</option>);
        } else {
            return null;
        }
    }

    const renderUseCourseList = () => {
        if (useCourseList !== undefined && useCourseList.length > 0) {
            return useCourseList.map((data, i) =>
                <Tournament013Container key={data.key} keyName={data.key} courseData={data}>
                </Tournament013Container>)
        } else {
            return null;
        }
    }

    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <header className="c-container-vertical c-mt56">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt32">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconTournament></IconTournament>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">技能テスト確認・変更画面</h1>
                </div>
            </header>
            <main>

                <form onSubmit={onSubmit}>
                    <div className="c-page-section c-mt32">

                        <div className="kanri-container c-mt56">

                            <p className="c-subtitle c-subtitle--left">選択された大会：</p>
                            <h2 className="c-heading--2 c-heading--left c-mt8">第1回JCG技能テスト関東会場</h2>


                            <h3 className="c-heading--3 c-heading--left c-mt56">上記より変更する箇所を修正してください。</h3>

                            <div className="input input__set c-mt32">
                                <label htmlFor="tournament_title" className="input input__label">大会タイトル</label>
                                <input type="text" name="tournament_title" id="tournament_title" defaultValue="第1回JCG技能テスト関東会場" className="input input__field" onChange={onChangeInputTitle} />
                            </div>

                            <p className="c-subtitle c-subtitle--left c-mt56">開催する大会の性別、またはジュニアかを選択してください</p>

                            <div className="c-radio-buttons c-radio-buttons--gunmetal">

                                <input type="radio" id="tournament_category_men" name="tournament_category" required />
                                <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="tournament_category_men">男子</label>

                                <input type="radio" id="tournament_category_women" name="tournament_category" required />
                                <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="tournament_category_women">女子</label>

                                <input type="radio" id="tournament_category_junior" name="tournament_category" required />
                                <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="tournament_category_junior">ジュニア</label>

                            </div>

                            <p className="c-subtitle c-subtitle--left c-mt56">開催する地域を選択してください。</p>

                            <div className="c-radio-buttons c-radio-buttons--gunmetal">

                                <input type="radio" id="tournament_area_tokai" name="tournament_area" required />
                                <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="tournament_area_tokai">関東</label>

                                <input type="radio" id="tournament_area_kansai" name="tournament_area" required />
                                <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="tournament_area_kansai">関西</label>

                            </div>

                            <div className="input input__set c-mt56">
                                <label htmlFor="tournament_date" className="input input__label">開催日</label>
                                <input type="date" name="tournament_date" id="tournament_date" defaultValue="2021-06-25" required className="input input__field" />
                            </div>

                            <div className="input input__set c-mt56">
                                <label htmlFor="tournament_time" className="input input__label">開催時間</label>
                                <input type="time" name="tournament_time" id="tournament_time" defaultValue="16:00" required className="input input__field" />
                            </div>

                            <div className="input input__set c-mt56">
                                <label htmlFor="tournament_finish_date" className="input input__label">終了日</label>
                                <input type="date" name="tournament_finish_date" id="tournament_finish_date" defaultValue="2021-07-25" required className="input input__field" />
                            </div>

                            <div className="input input__set c-mt56">
                                <label htmlFor="tournament_finish_time" className="input input__label">終了時間</label>
                                <input type="time" name="tournament_finish_time" id="tournament_finish_time" defaultValue="20:00" required className="input input__field" />
                            </div>

                            <div className="input input__set c-mt56">
                                <label htmlFor="tournament_start_entry" className="input input__label">エントリー受付開始日</label>
                                <input type="date" name="tournament_start_entry" id="tournament_start_entry" defaultValue="2021-06-25" required className="input input__field" />
                            </div>

                            <div className="input input__set c-mt56">
                                <label htmlFor="tournament_finish_entry" className="input input__label">エントリー受付終了日</label>
                                <input type="date" name="tournament_finish_entry" id="tournament_finish_entry" defaultValue="2021-07-25" required className="input input__field" />
                            </div>

                            <div className="input input__set c-mt56">
                                <label htmlFor="tournament_max_entry" className="input input__label">最大エントリー数を入力してください。</label>
                                <input type="number" name="tournament_max_entry" id="tournament_max_entry" defaultValue="60" required className="input input__field" />
                            </div>

                            <div className="input input__set c-mt56">
                                <label htmlFor="tournament_club" className="input input__label">利用するゴルフ場。</label>
                                <select name="tournament_club" id="tournament_club" required className="input input__field" onChange={(e) => { onChangeSelect(e) }}>
                                    <option defaultValue="">利用するゴルフ場を選択してください。</option>
                                    {renderCourseList()}
                                </select>
                            </div>

                            {/*<!-- コースを選択エリア -->*/}
                            <div className="c-container-vertical c-container-vertical--dark-slate-gray c-padding--24 c-radius--16 c-mt56">

                                <h2 className="c-heading--2 c-heading--left">利用コース</h2>
                                <p className="c-paragraph c-paragraph--left">利用するコースを選択してください。</p>
                                <div className="c-container-horizontal c-container-horizontal--space-around c-container-horizontal--wrap">
                                    {/*<!-- t一つ。 -->*/}
                                    {renderUseCourseList()}
                                </div>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="given-name-kana" className="input input__label">エントリー金額を入力してください</label>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <input type="number" name="given-name-kana" id="entry_amount" placeholder="" required className="input input__field input__field--extend" />
                                    <span className="input__suffix c-paragraph">円</span>
                                </div>
                            </div>

                            <div className="input input__set">
                                <label htmlFor="given-name-kana" className="input input__label">グループの試合間隔を入力してください</label>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <input type="number" min="0" max="99" name="given-name-kana" id="group_match_interval" placeholder="" required className="input input__field input__field--extend" />
                                    <span className="input__suffix input__suffix--88 c-paragraph">分間隔</span>
                                </div>
                            </div>

                            <h3 className="c-heading--3 c-heading--left c-mt56">メイン画像</h3>

                            <p className="c-subtitle c-subtitle--left c-mt8">※最大3つまで指定できます。</p>
                            <p className="c-subtitle c-subtitle--left">※画像サイズは1MBまででお願いします。</p>
                            <p className="c-subtitle c-subtitle--left">※縦横比は932px×257pxでお願いします。</p>

                            <fieldset id="tournament_picture">

                                <div className="c-container-vertical c-container-vertical--center c-drag-and-drop c-mt32" id="tournament_picture_01_drop" {...getRootProps(
                                    {
                                        onDrop: event => {
                                            imageAreaTag.id = event.target.id;
                                        }
                                    }
                                )}>
                                    <p> ここに画像をドラッグアンドドロップ</p>
                                    <input {...getInputProps()} />
                                    <input type="file" name="tournament_picture_01" id="tournament_picture_01" accept="image/*" capture="camera" className="c-mt56" onChange={(e) => { onChangeInput(e) }} style={{ display: tournamentPictureInputStyleList[0] ? '' : 'none' }} />
                                    <figure className="c-drag-and-drop__preview c-mt16">
                                        <img src={previewList[0]} alt="" srcSet="" />
                                    </figure>
                                    <button id="button_cancel_upload_01" onClick={(e) => { onClickDelete(e) }} className="button button__tertiary">アップロードした画像を削除</button>
                                </div>

                                <div className="c-container-vertical c-container-vertical--center c-drag-and-drop c-mt32" id="tournament_picture_02_drop" {...getRootProps(
                                    {
                                        onDrop: event => {
                                            imageAreaTag.id = event.target.id;
                                        }
                                    }
                                )}>
                                    <p> ここに画像をドラッグアンドドロップ</p>
                                    <input {...getInputProps()} />
                                    <input type="file" name="tournament_picture_02" id="tournament_picture_02" accept="image/*" capture="camera" className="c-mt56" onChange={(e) => { onChangeInput(e) }} style={{ display: tournamentPictureInputStyleList[1] ? '' : 'none' }} />
                                    <figure className="c-drag-and-drop__preview c-mt16">
                                        <img src={previewList[1]} alt="" srcSet="" />
                                    </figure>
                                    <button id="button_cancel_upload_02" onClick={(e) => { onClickDelete(e) }} className="button button__tertiary">アップロードした画像を削除</button>
                                </div>

                                <div className="c-container-vertical c-container-vertical--center c-drag-and-drop c-mt32" id="tournament_picture_03_drop" {...getRootProps(
                                    {
                                        onDrop: event => {
                                            imageAreaTag.id = event.target.id;
                                        }
                                    }
                                )}>
                                    <p> ここに画像をドラッグアンドドロップ</p>
                                    <input {...getInputProps()} />
                                    <input type="file" name="tournament_picture_03" id="tournament_picture_03" accept="image/*" capture="camera" className="c-mt56" onChange={(e) => { onChangeInput(e) }} style={{ display: tournamentPictureInputStyleList[2] ? '' : 'none' }} />
                                    <figure className="c-drag-and-drop__preview c-mt16">
                                        <img src={previewList[2]} alt="" srcSet="" />
                                    </figure>
                                    <button id="button_cancel_upload_03" onClick={(e) => { onClickDelete(e) }} className="button button__tertiary c-mt8">アップロードした画像を削除</button>
                                </div>

                            </fieldset>


                        </div>

                        <div className="c-button-set c-mt56 c-mb88">
                            <button id="button_next" className="button button__primary" disabled>次へ</button>
                            <button id="button_back" onClick={(e) => { onClick(e) }} className="button button__secondary">戻る</button>
                            <button id="button_postpone" onClick={(e) => { onClick(e) }} className="button button__tertiary button__tertiary--red">大会を延期する</button>
                        </div>

                    </div>
                </form>

            </main>
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default Tournament_013

