import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { TournamentContext } from '../TournamentProvider'
import ManagementFooter from '../../ManagementFooter'
import Tournament002Container from './Tournament002Container'
import { getCourseList } from "../../../Database";
import logo from "../../../images/logo.svg";
import { IconTournament } from "../../Icons";
import '../../../styles/styles.css';
import { checkSpecialCharacters, getTotalNumberHoles, getTeeAndGreenData, makeUseCourseList } from "../TournamentUtility";

//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
//import { IconScore, IconPlus, IconEdit } from "../../Icons";

function Tournament_002(props) {

    // export const orijinaltournamentInitialState = {
    //     tournament002Data: {
    //       title: "",
    //       category: "男子",
    //       area: "関東",
    //       eventDate: "",
    //       eventStartTime: "",
    //       eventEndDate: "",
    //       eventEndTime: "",
    //       golfCourseDocId: "",
    //       useCourseList: [
    //         //    {courseID:0}
    //       ],
    //       useTeeId: "",
    //       useGreenId: "",
    //       usePurpose: 0  //1=技能テスト,2=ツアー,3=イベント

    //     },
    //   };

    const [value] = useContext(TournamentContext);
    const history = useHistory();

    const [tournament002Data] = useState(value.tournament002Data);                //グローバル参照渡し
    const [useCourseList, setUseCourseList] = useState(value.tournament002Data.temp.useCourseList);                                                   //オブジェクトの細部まで見て更新してくれないので再描様に用意した
    const [courseList, setCourseList] = useState(tournament002Data.temp.loadCourseList);                                                   //選択用のコースリスト

    useEffect(() => {
        //データベースからコースデータの読み込み
        const getCourse = (async () => {
            if (tournament002Data.temp.loadCourseList.length === 0) {
                let loadCourseList = await getCourseList();
                setCourseList(loadCourseList);
            } else {
                setCourseList([...tournament002Data.temp.loadCourseList]);
            }

        });
        getCourse();
    }, [tournament002Data.temp.loadCourseList]);


    useEffect(() => {
        //タイトルが何もなかったら過去に入力された事なし
        if (tournament002Data.title.length === 0) return;
        //一番上にスクロール
        window.scrollTo(0, 0);
        //引継ぎ
        //document.getElementByIdは何かステート値の値が変化した後に設定すれば変更できるっぽい
        //大会タイトル
        document.getElementById("tournament_title").value = tournament002Data.title;
        //カテゴリー
        let tournament_category_tag = null;
        switch (tournament002Data.category) {
            case "男子":
                tournament_category_tag = document.getElementById("tournament_category_men");
                break;
            case "女子":
                tournament_category_tag = document.getElementById("tournament_category_women");
                break;
            case "ジュニア":
                tournament_category_tag = document.getElementById("tournament_category_junior");
                break;
            default:
                break;
        }
        if (tournament_category_tag !== null) {
            tournament_category_tag.checked = true;
        }

        //地域
        let area_tag = null;
        switch (tournament002Data.area) {
            case "関東":
                area_tag = document.getElementById("tournament_area_tokai");
                break;
            case "関西":
                area_tag = document.getElementById("tournament_area_kansai");
                break;
            default:
                break;
        }
        if (area_tag !== null) {
            area_tag.checked = true;
        }

        //開催日
        document.getElementById("tournament_date").value = tournament002Data.eventDate;

        //開催時間
        document.getElementById("tournament_time").value = tournament002Data.eventStartTime;

        //終了日
        document.getElementById("tournament_finish_date").value = tournament002Data.eventFinishDate;

        //終了時間
        document.getElementById("tournament_finish_time").value = tournament002Data.eventFinishTime;

        //エントリー受付開始日
        document.getElementById("tournament_start_entry").value = tournament002Data.entryStartDate;

        //エントリー受付終了日
        document.getElementById("tournament_finish_entry").value = tournament002Data.entryFinisDate;

        //最大エントリー数
        document.getElementById("tournament_max_entry").value = tournament002Data.max_entry;

        //利用するゴルフ場
        let selectedCourse = tournament002Data.temp.loadCourseList.findIndex((data) => {
            return data.id === tournament002Data.golfCourseDocId;
        })

        if (selectedCourse !== -1) { //
            let tournament_club_options = document.getElementById("tournament_club").options;
            if (tournament_club_options.length >= 2) {
                tournament_club_options[selectedCourse + 1].selected = true;
            }
        }

        //利用目的
        let tournament_purpose_options = document.getElementById("tournament_purpose").options;
        for (let i = 0; i < tournament_purpose_options.length; i++) {
            if (tournament_purpose_options[i].value === tournament002Data.usePurpose) {
                tournament_purpose_options[i].selected = true;
            }

        }

    }, [tournament002Data, courseList]);



    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "button_back":
                history.push("Tournament_001");
                break;
            default:
                break;
        }
    };

    //ゴルフ場選択
    const onChangeSelect = (e) => {
        let indexNb = e.target.value;
        //コースリスト取得
        let arrayCourseList = courseList[indexNb].arrayCourseList;

        let tempUseCourseList = lodash.cloneDeep(arrayCourseList);
        //グローバルにも保存
        //        tournament002Data.temp.useCourseList = tempUseCourseList;

        tempUseCourseList.map((data) => {
            let primCourseData = getTeeAndGreenData(data);
            data.primCourseData = primCourseData;
            return data;
        });
        //        value.useCourseList=tempUseCourseList;


        //再描画
        setUseCourseList(tempUseCourseList);
    }


    const onSubmit = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();
        const { tournament_title,
            tournament_category_men,
            tournament_category_women,
            tournament_category_junior,

            tournament_area_tokai,
            tournament_area_kansai,

            tournament_date,
            tournament_time,
            tournament_finish_date,
            tournament_finish_time,
            tournament_start_entry,
            tournament_finish_entry,
            tournament_max_entry,

            tournament_purpose,
        } = e.target.elements;

        //タイトル
        tournament002Data.title = tournament_title.value;

        //特殊文字チェック
        if (checkSpecialCharacters(tournament_title.value, (result, stuta) => {
            if (result === false) {
                alert("タイトルに"+stuta);
            }
        }) === false) {
            return;
        }

        //カテゴリー
        let tournament_category_obj_list = [tournament_category_men, tournament_category_women, tournament_category_junior];
        let tournament_category_obj = tournament_category_obj_list.find((data) => {
            return data.checked === true;
        });


        let category = tournament_category_obj.nextSibling.innerText;
        tournament002Data.category = category;

        //地域
        let tournament_area_list = [tournament_area_tokai, tournament_area_kansai];
        let tournament_area_obj = tournament_area_list.find((data) => {
            return data.checked === true;
        });
        let tournament_area = tournament_area_obj.nextSibling.innerText;
        tournament002Data.area = tournament_area;


        //開催日
        tournament002Data.eventDate = tournament_date.value;

        //開催時間
        tournament002Data.eventStartTime = tournament_time.value;

        //終了日
        tournament002Data.eventFinishDate = tournament_finish_date.value;

        //終了時間
        tournament002Data.eventFinishTime = tournament_finish_time.value;

        //エントリー受付開始日
        tournament002Data.entryStartDate = tournament_start_entry.value;

        //エントリー受付終了日//
        tournament002Data.entryFinisDate = tournament_finish_entry.value;

        //最大エントリー数
        tournament002Data.max_entry = tournament_max_entry.value;


        //ゴルフ場リストをグローバルに保存
        tournament002Data.temp.loadCourseList = courseList;

        //利用目的
        tournament002Data.usePurpose = tournament_purpose.options[tournament_purpose.selectedIndex].text;

        //ゴルフ場データ保存
        let tournament_club_options = document.getElementById("tournament_club").options;
        let golfCourseData = courseList.find((data, index) => {
            if (tournament_club_options[index + 1].selected === true) {
                return lodash.cloneDeep({ ...courseList[index] });
            }
            return false;
        })

        tournament002Data.golfCourseDocId = golfCourseData.id;

        //使用するゴルフデータの保
        tournament002Data.temp.useGolfCourseData = golfCourseData;

        //グローバルにも保存
        tournament002Data.temp.useCourseList = useCourseList;


        //データベースに保存するコースデータを作成
        let findUseCourseList = makeUseCourseList(useCourseList);

        tournament002Data.useCourseList = findUseCourseList;

        //ホール数の保存
        let numberHoles = getTotalNumberHoles(useCourseList);
        tournament002Data.number_holes = numberHoles;
        //ホール数の警告
        if (numberHoles < 18 || numberHoles > 18) {
            alert('１８ホールではないですがよろしいでしょうか？　\n選択されたホール数=' + numberHoles);
        }

        //コース選択が一つもされていないか確認
        if (findUseCourseList.length === 0) {
            alert('コースが選択されていません');
        } else {
            switch (tournament_purpose.selectedIndex) {
                case 0:
                    break;
                case 1:
                    history.push("Tournament_006");     //実技テスト
                    break;
                case 2:
                    history.push("Tournament_003");     //ツアー
                    break;
                case 3:
                    history.push("Tournament_028");     //イベント
                    break;
                default:
                    break;
            }
        }


    }

    //タイトルの特殊文字チェック
    const onChangeInputTitle = (e) => {
        //特殊文字チェック
        checkSpecialCharacters(e.target.value, (result, stuta) => {
            if (result === false) {
                alert(stuta);
                return;
            }
        });
    }
    //--------------------------
    const renderCourseList = () => {
        if (courseList !== undefined) {
            return courseList.map((d, i) => <option key={d.id} value={i}>{d.club_name}</option>);
        } else {
            return null;
        }
    }

    const renderUseCourseList = () => {
        if (useCourseList !== undefined) {
            return useCourseList.map((data, i) =>
                <Tournament002Container key={data.key} keyName={data.key} courseData={data}>
                </Tournament002Container>)
        } else {
            return null;
        }
    }
    //--------------------------

    return (
        <div className="c-screen c-screen--gunmetal">
            <header className="c-container-vertical c-mt56">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt32">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconTournament></IconTournament>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">新規大会追加画面</h1>
                </div>
            </header>
            <main>
                <form onSubmit={onSubmit}>
                    <div className="kanri-container">

                        <div className="input input__set">
                            <label htmlFor="tournament_title" className="input input__label">大会タイトル<i className="input input__tag">必須</i></label>
                            <input type="text" name="tournament_title" id="tournament_title" placeholder="大会タイトルを入力してください。" required className="input input__field" onChange={onChangeInputTitle} />
                            {/*<span className="input input__validation input__validation--display">大会タイトルを入力してください。</span>*/}
                        </div>

                        <h2 className="c-heading--3 c-heading--left c-mt56">開催する大会のカテゴリを選択してください。</h2>

                        <div className="c-radio-buttons">

                            <input type="radio" id="tournament_category_men" name="tournament_category" required />
                            <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="tournament_category_men">男子</label>

                            <input type="radio" id="tournament_category_women" name="tournament_category" required />
                            <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="tournament_category_women">女子</label>

                            <input type="radio" id="tournament_category_junior" name="tournament_category" required />
                            <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="tournament_category_junior">ジュニア</label>

                        </div>

                        <h2 className="c-heading--3 c-heading--left c-mt56">開催する地域を選択してください。</h2>

                        <div className="c-radio-buttons">

                            <input type="radio" id="tournament_area_tokai" name="tournament_area" required />
                            <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="tournament_area_tokai">関東</label>

                            <input type="radio" id="tournament_area_kansai" name="tournament_area" required />
                            <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="tournament_area_kansai">関西</label>

                        </div>

                        <div className="input input__set c-mt56">
                            <label htmlFor="tournament_date" className="input input__label">開催日を入力してください。<i className="input input__tag">必須</i></label>
                            <input type="date" name="tournament_date" id="tournament_date" required className="input input__field" />
                            {/*<span className="input input__validation input__validation--display">開催日を入力してください。</span>*/}
                        </div>

                        <div className="input input__set c-mt56">
                            <label htmlFor="tournament_time" className="input input__label">開催時間を入力してください。<i className="input input__tag">必須</i></label>
                            <input type="time" name="tournament_time" id="tournament_time" required className="input input__field" />
                            {/*<span className="input input__validation input__validation--display">開催時間を入力してください。</span>*/}
                        </div>

                        <div className="input input__set c-mt56">
                            <label htmlFor="tournament_finish_date" className="input input__label">終了日を入力してください。<i className="input input__tag">必須</i></label>
                            <input type="date" name="tournament_finish_date" id="tournament_finish_date" required className="input input__field" />
                            {/*<span className="input input__validation input__validation--display">終了日を入力してください。</span>*/}
                        </div>

                        <div className="input input__set c-mt56">
                            <label htmlFor="tournament_finish_time" className="input input__label">終了時間を入力してください。<i className="input input__tag">必須</i></label>
                            <input type="time" name="tournament_finish_time" id="tournament_finish_time" required className="input input__field" />
                            {/*<span className="input input__validation input__validation--display">終了時間を入力してください。</span>*/}
                        </div>


                        <div className="input input__set c-mt56">
                            <label htmlFor="tournament_start_entry" className="input input__label">エントリー受付の開始日を入力してください。<i className="input input__tag">必須</i></label>
                            <input type="date" name="tournament_start_entry" id="tournament_start_entry" required className="input input__field" />
                            {/*<span className="input input__validation input__validation--display">終了日を入力してください。</span>*/}
                        </div>

                        <div className="input input__set c-mt56">
                            <label htmlFor="tournament_finish_entry" className="input input__label">エントリー受付の終了日を入力してください。<i className="input input__tag">必須</i></label>
                            <input type="date" name="tournament_finish_entry" id="tournament_finish_entry" required className="input input__field" />
                            {/*<span className="input input__validation input__validation--display">終了日を入力してください。</span>*/}
                        </div>

                        <div className="input input__set c-mt56">
                            <label htmlFor="tournament_max_entry" className="input input__label">最大エントリー数を入力してください。<i className="input input__tag">必須</i></label>
                            <input type="number" name="tournament_max_entry" id="tournament_max_entry" required className="input input__field" />
                            {/*<span className="input input__validation input__validation--display">終了日を入力してください。</span>*/}
                        </div>

                        <div className="input input__set c-mt56">
                            <label htmlFor="tournament_club" className="input input__label">利用するゴルフ場。<i className="input input__tag">必須</i></label>
                            <select name="tournament_club" id="tournament_club" required className="input input__field" onChange={(e) => { onChangeSelect(e) }}>
                                <option value="">利用するゴルフ場を選択してください。</option>
                                {renderCourseList()
                                }
                            </select>
                        </div>

                        <p className="c-subtitle c-subtitle--left">※開催するゴルフ場が選択肢にない場合は、先にゴルフ場の 登録をおこなってください。</p>

                        {/*<!-- コースを選択エリア -->*/}
                        <div className="c-container-vertical c-container-vertical--dark-slate-gray c-padding--24 c-radius--16 c-mt56">

                            <h2 className="c-heading--2 c-heading--left">利用コース</h2>
                            <p className="c-paragraph c-paragraph--left">利用するコースを選択してください。</p>

                            <div className="c-container-horizontal c-container-horizontal--space-around c-container-horizontal--wrap">
                                {/*<!-- t一つ。 -->*/}
                                {renderUseCourseList()}
                            </div>
                        </div>

                        <div className="input input__set c-mt56">
                            <label htmlFor="tournament_purpose" className="input input__label">利用目的。<i className="input input__tag">必須</i></label>
                            <select name="tournament_purpose" id="tournament_purpose" required className="input input__field">
                                <option value="">利用目的を選択してください。</option>
                                <option value="技能テスト">技能テスト</option>
                                <option value="ツアー">ツアー</option>
                                <option value="イベント">イベント</option>
                            </select>
                        </div>

                    </div>

                    <div className="c-button-set c-mt56 c-mb88">
                        {/*<!-- 以下のボタンは「id="tournament_purpose"」の選択されたオプションのよるにurlが変わります。 -->*/}
                        {/*<!-- 技能テスト => onclick="location.href='./tournament-006.html'" -->/*}
  {/*<!-- ツアー => onclick="location.href='./tournament-003.html'"-->/*}
  {/*<!-- イベント => onclick="location.href='./tournament-028.html'"-->*/}
                        <button id="button_next" type="submit" className="button button__primary">次へ</button>
                        <button id="button_back" onClick={(e) => { onButtonClick(e) }} className="button button__secondary">戻る</button>
                    </div>
                </form>
            </main>
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default Tournament_002

