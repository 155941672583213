import React, { useEffect, useState } from 'react';
import '../styles/styles.css';
import '../styles/loader.css'

//propsのloadingFlg true=表示 false=表示
function Loader(props) {
    const [loadingFlg, setLoadingFlg] = useState(false);
    const [title, setTitle] = useState("書き込み中です...");
    useEffect(() => {
        if (props.loadingData !== undefined) {
            if (props.loadingData.loadingFlg !== undefined) {
                setLoadingFlg(props.loadingData.loadingFlg);
            }
            if (props.loadingData.title !== undefined) {

                setTitle(props.loadingData.title);
            }
        }
        if (props.loadingFlg !== undefined) {
            setLoadingFlg(props.loadingFlg);
        }
        if (props.title !== undefined) {
            setTitle(props.title);
        }
    }, [props.loadingData, props.loadingFlg, props.title]);

    return (
        <div id="loading-wrapper" style={loadingFlg ? {} : { display: 'none' }} >
            <div className="loader"></div>
            <div className="kanri-title c-mt56">
                <h1 className="c-heading--1 loader_font" id="loader_text">{title}</h1>
            </div>
        </div>
    )
}



export default Loader

