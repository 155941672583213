import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import { deleteDateUndecidedFlgTournament, changeAllUsersDeleteFlgBoolean_to_number, addPostponedFlgAndDateUndecidedFlgTournament, addNumberHolesTournament, setDebugFlg, getVersion, setVersion, garbageRemoval, setAnnouncementNewsTemplate } from "../Database";
import { AuthContext } from "../auth/AuthProvider";

function ManagementFooter() {
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const [versionNb, setVersionNb] = useState("");
  const [cmpVersionNb] = useState("0.8.0.6");

  useEffect(() => {
    const asyncfunc = (async () => {
      await getVersion((result, status) => {
        if (result === false) {
          //一度main関数内を抜けないと最終的に版えしていないっぽい
          //                                        let databaseCount = localStorage.getItem("databaseCount");
          //                                        localStorage.removeItem("databaseCount");
          alert("バージョンの読み込みに失敗しました！！！　ネットワークの状態を確認してください" + status);
        } else {
          let text = "Vr" + cmpVersionNb;

          if (status > cmpVersionNb) {
            text = "バージョンが違うので、メニュー画面へ遷移してください\n現在のバージョン=" + cmpVersionNb + "\n最新のバージョン=" + status;
            if (history.location.pathname === "/") {
              let warningText = "最新版にするために強制リロードします"
              alert(warningText);
              window.location.reload();
              return;
            } else {
              alert("管理画面のバージョンが上がりましたのでメニューへ遷移してください\n現在のバージョン=" + cmpVersionNb + "\n最新のバージョン=" + status);
            }
          }
          //一度main関数内を抜けないと最終的に版えしていないっぽい
          //          let databaseCount = localStorage.getItem("databaseCount");
          //          localStorage.removeItem("databaseCount");
          updateDatabase();

          setVersionNb(text);

        }
      });
    });
    //ログインしていない場合はデータベースの内容は見れないので、チェックしない
    if (currentUser !== null) {
      asyncfunc();
    }
  }, [cmpVersionNb, history.location.pathname]);


  //データベースの更新
  const updateDatabase = () => {
    // ローカルストレージデータベース更新回数を取得
    let databaseCount = localStorage.getItem("databaseCount");
    //null初回
    if (databaseCount === null) {
      databaseCount = "0";
      localStorage.setItem("databaseCount", databaseCount);
    }
    //数値に変換
    databaseCount = Number(databaseCount);
    processUpdateDatabase(databaseCount)
    console.log(databaseCount);
  }
  //アップデート処理
  const processUpdateDatabase = (updateCount) => {
    let cmpUpdateCount = updateCount;
    let loopFlg = true
    while (loopFlg) {
      switch (updateCount) {
        case 0:
          let textDataList = [
            { news_text: { title: "TTTTエントリー開始のお知らせ", text: 'JCGツアーをご利用いただきありがとうございます。<br>各ツアーの割り振りが完了しましたので、マイページからエントリーをお願いいたします。<br>※エントリー費の決済につきましてはクレジットカードのみとなっております。ご了承ください。<br><br>ご都合が合わずエントリーを辞退された場合でも、エントリー受付期間内ならいつでも再開ができます。<br><br>よろしくお願いいたします。<br><br>※選手の方は下記のガイドラインを必ずお読みください<br><a class="info_link" href="https://jcg-management.co.jp/assets/pdf/guidelines.pdf" target="_blank">ガイドラインはこちら</a><br><br>JCGツアー協会' } },
            { news_text: { title: "TTTTの組み分け完了のお知らせ", text: "JCGツアーをご利用いただきありがとうございます。<br> 【TTTT】の組み分けが完了しました。<br>  【SSSS】様は第GGGG組NNNN番になります。スタート時間はおおよそx時xx分頃になります。<br> ツアー当日はお早めに会場にお越しください。<br>  ※ツアー当日に自然災害等による延期の場合は、ツアー前日の18時までに本サービスのお知らせに送信されますのでご留意ください。<br> ※組変更は原則お受けできません。<br> ※ツアー当日やむを得ず欠席される場合は運営事務局にご連絡ください。" } },
            { news_text: { title: "お知らせタイトル：【TTTT】の結果発表", text: "JCGツアーをご利用いただきありがとうございます。<br> 【TTTT】の結果を発表いたします。<br>  【NNNN】様は<br> スコア：SSSS<br> 最終順位：RRRR<br> 会得賞金：PPPP<br>  ツアーの順位表はメイン画面からご確認ください。<br>  ※ツアーの順位・結果につきましては変更はお受けできません。<br> ※会得された賞金は大会終了日の次月末日に指定の口座にお振込み致します。" } },
          ]
          for (let i = 0; i < 3; i++) {
            const asyncfunc = (async () => {
              await setAnnouncementNewsTemplate(i, textDataList[i], (result, status) => {
                if (result === false) {
                  alert("データベースの書き込みに失敗しました=", status);
                }
              });
              //バージョン書き込み
              await setVersion(cmpVersionNb, (result, status) => {
                if (result === false) {
                  alert("バージョンの書き込みに失敗しました", status);
                }
              })

              //ゴミ削除
              await garbageRemoval((result, status) => {
                if (result === false) {
                  alert("不用なデータの削除に失敗しました", status);
                }
              })
            });
            asyncfunc();
          }

          updateCount++;
          break;
        case 1:
          const asyncfunc2 = (async () => {
            await setDebugFlg((result, status) => {
              if (result === false) {
                alert("デバッグフラグの書き込みに失敗しました", status);
              }
            });

          });
          asyncfunc2();
          updateCount++;
          break;
        case 2:
          const asyncfunc3 = (async () => {
            await addNumberHolesTournament((result, status) => {
              if (result === false) {
                alert("ホール数の書き込みに失敗しました", status);
              }
            });

          });
          asyncfunc3();
          updateCount++;
          break;
        case 3:

          const asyncfunc4 = (async () => {
            await addPostponedFlgAndDateUndecidedFlgTournament((result, status) => {
              if (result === false) {
                alert("ホール数の書き込みに失敗しました", status);
              }
            });

          });
          asyncfunc4();
          updateCount++;
          break;
        case 4:
          const asyncfunc5 = (async () => {
            await changeAllUsersDeleteFlgBoolean_to_number((result, status) => {
              if (result === false) {
                alert("削除フラグの変更に失敗しました=", status);
              }
            });

          });
          asyncfunc5();
          updateCount++;
          break;
        case 5:
          const asyncfunc6 = (async () => {
            await deleteDateUndecidedFlgTournament((result, status) => {
              if (result === false) {
                alert("削除フラグの変更に失敗しました=", status);
              }
            });

          });
          asyncfunc6();
          updateCount++;
          break;
        //データベースが更新したらここに追加していく
        default:
          loopFlg = false;
          break;
      }

    }
    //firebaseのデータベースを更新したら、アプリ内データベースも更新
    if (cmpUpdateCount !== updateCount) {
      localStorage.setItem('databaseCount', updateCount);

    }
  }







  return (
    <div>
      <div>
        {versionNb.split('\n').map((str, index) => {
          return <React.Fragment key={index}>{str}<br /></React.Fragment>
        })}
      </div>
    </div>
  )
}

export default ManagementFooter;





