import React, { useEffect,useContext } from 'react';
import { useHistory } from "react-router-dom"
import { NotificationContext } from './NotificationProvider'
import ManagementFooter from '../ManagementFooter'
import logo from "../../images/logo.svg";
import '../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconNotification } from "../Icons";


function Notification(props) {
    const [value, setValue] = useContext(NotificationContext);
    const history = useHistory();
    useEffect(() => {
        document.getElementsByTagName("*")["push_textarea"].innerText=value.push_textarea;
    }, []);

    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "backToMenu":
                history.push("/");
                break;
            default:
                break;
        }

    };

    const handleSubmit = (e) => {
        const { push_textarea } = e.target.elements;
        setValue({ push_textarea: push_textarea.value});
        history.push("NotificationConfirm");
    };

    return (
        <div className="c-screen c-screen--gunmetal">
            <header className="c-container-vertical c-mt88">

                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>

                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconNotification></IconNotification>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">プッシュ通知画面</h1>
                </div>

            </header>
            <main>
                <form onSubmit={handleSubmit} >
                    <section className="kanri-container">

                        <div className="c-container-vertical c-mt32">
                            <div className="input input__set input__set--kanri">
                                <label htmlFor="" className="input input__label">プッシュ通知内容</label>
                                <textarea name="" id="push_textarea" placeholder="ここにプッシュ通知で表示する文章を入力してください。" required className="input input__field input__field--textarea c-paragraph"></textarea>
                            </div>
                        </div>

                        <div className="c-button-set">
                            <button id="confirmPushNotification" className="button button__primary" type="submit">次へ</button>
                            <button id="backToMenu" className="button button__secondary" onClick={(e) => { onButtonClick(e) }}>戻る</button>
                        </div>

                    </section>
                </form>

                <section className="c-page-section c-page-section--laurel-green c-mt56">

                    <h2 className="c-heading--2 c-mt32">送信履歴</h2>

                    <article className="c-container-vertical c-balanced-width c-mt32">

                        <div className="c-container-vertical c-container-vertical--pale-spring-bud c-mb32 c-prl--24 c-radius--16">
                            <div className="c-container-horizontal c-mt8">
                                <span className="c-subtitle c-subtitle--bold c-pr--24">2021/05/11</span>
                            </div>
                            <p className="c-paragraph c-paragraph--dark-slate-gray c-mt8 c-mb16">ここに書いた文章が表示されます。</p>
                        </div>

                        <div className="c-container-vertical c-container-vertical--pale-spring-bud c-mb32 c-prl--24 c-radius--16">
                            <div className="c-container-horizontal c-mt8">
                                <span className="c-subtitle c-subtitle--bold c-pr--24">2021/05/05</span>
                            </div>
                            <p className="c-paragraph c-paragraph--dark-slate-gray c-mt8 c-mb16">ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。</p>
                        </div>

                        <div className="c-container-vertical c-container-vertical--pale-spring-bud c-mb32 c-prl--24 c-radius--16">
                            <div className="c-container-horizontal c-mt8">
                                <span className="c-subtitle c-subtitle--bold c-pr--24">2021/05/01</span>
                            </div>
                            <p className="c-paragraph c-paragraph--dark-slate-gray c-mt8 c-mb16">ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。</p>
                        </div>

                        <div className="c-container-vertical c-container-vertical--pale-spring-bud c-mb32 c-prl--24 c-radius--16">
                            <div className="c-container-horizontal c-mt8">
                                <span className="c-subtitle c-subtitle--bold c-pr--24">2021/04/30</span>
                            </div>
                            <p className="c-paragraph c-paragraph--dark-slate-gray c-mt8 c-mb16">ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。</p>
                        </div>

                        <div className="c-button-set c-mt16 c-mb88">
                            <button id="" className="button button__tertiary button__tertiary--viewmore c-margin-reset">もっと見る</button>
                        </div>

                    </article>

                </section>

            </main>

            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>


        </div>
    )
}

export default Notification

