import { BrowserRouter as Router, Switch } from "react-router-dom";
import { AuthProvider } from "./auth/AuthProvider";
import PrivateRoute from "./auth/PrivateRoute";
import Menu from './components/Menu'
import Notification from './components/notification/Notification'
import NotificationConfirm from './components/notification/NotificationConfirm'
import NotificationCompleted from './components/notification/NotificationCompleted'
import Announcement from './components/announcement/Announcement'
import AnnouncementConfirm from './components/announcement/AnnouncementConfirm'
import AnnouncementCompleted from './components/announcement/AnnouncementCompleted'
import AnnouncementSetNewsText from './components/announcement/AnnouncementSetNewsText'
import Membership from './components/membership/Membership'
import MembershipEdit from './components/membership/MembershipEdit'
import MembershipConfirm from './components/membership/MembershipConfirm'
import MembershipCompleted from './components/membership/MembershipCompleted'
import MembershipCancel from './components/membership/MembershipCancel'
import MembershipCancelCompleted from './components/membership/MembershipCancelCompleted'
import MembershipRestoreCompleted from './components/membership/MembershipRestoreCompleted'
import MembershipRestore from './components/membership/MembershipRestore'
import Course from './components/course/Course'
import CourseAddStep1 from './components/course/step1/CourseAddStep1'
import CourseAddStep2 from './components/course//step2/CourseAddStep2'
import CourseAddStep3 from './components/course//step3/CourseAddStep3'
import CourseAddStep4 from './components/course//step4/CourseAddStep4'
import CourseAddCompleted from './components/course/CourseAddCompleted'
import CourseEditStep1 from './components/course/change_course/step1/CourseEditStep1'
import CourseEditStep2 from './components/course/change_course/step2/CourseEditStep2'
import CourseEditStep3 from './components/course/change_course/step3/CourseEditStep3'
import CourseEditCompleted from './components/course/CourseEditCompleted'

import Tournament_001 from './components/tournament/Tournament_001'
import Tournament_002 from './components/tournament/addTournament/Tournament_002'
import Tournament_003 from './components/tournament/addTournament/Tournament_003'
import Tournament_004 from './components/tournament/addTournament/Tournament_004'
import Tournament_005 from './components/tournament/addTournament/Tournament_005'

import Tournament_006 from './components/tournament/addTournament/Tournament_006'
import Tournament_007 from './components/tournament/addTournament/Tournament_007'
import Tournament_008 from './components/tournament/addTournament/Tournament_008'

import Tournament_009 from './components/tournament/changeTournament/Tournament_009'
import Tournament_010 from './components/tournament/changeTournament/Tournament_010'
import Tournament_011 from './components/tournament/changeTournament/Tournament_011'
import Tournament_012 from './components/tournament/changeTournament/Tournament_012'
import Tournament_013 from './components/tournament/changeTournament/Tournament_013'
import Tournament_014 from './components/tournament/changeTournament/Tournament_014'
import Tournament_015 from './components/tournament/changeTournament/Tournament_015'

import Tournament_016 from './components/tournament/postponed/Tournament_016'
import Tournament_017 from './components/tournament/postponed/Tournament_017'
import Tournament_018 from './components/tournament/postponed/Tournament_018'
import Tournament_019 from './components/tournament/postponed/Tournament_019'
import Tournament_020 from './components/tournament/postponed/Tournament_020'
import Tournament_021 from './components/tournament/postponed/Tournament_021'
import Tournament_022 from './components/tournament/deleteTournament/Tournament_022'
import Tournament_023 from './components/tournament/deleteTournament/Tournament_023'

import Tournament_024 from './components/tournament/viewEntryPlayersTournament/Tournament_024'
import Tournament_025 from './components/tournament/viewEntryPlayersTournament/Tournament_025'
import Tournament_026 from './components/tournament/viewEntryPlayersTournament/Tournament_026'


import Tournament_028 from './components/tournament/addTournament/Tournament_028'
import Tournament_029 from './components/tournament/addTournament/Tournament_029'
import Tournament_030 from './components/tournament/addTournament/Tournament_030'

import Score_001_1 from './components/score/Score_001_1'
import Score_001_2 from './components/score/Score_001_2'
import Score_009 from './components/score/Score_009'
import Score_010 from './components/score/Score_010'

import DataClear from './components/DataClear'

import UserBankInfopage from './components/UserBankInfo/UserBankInfoPage'

import { AnnouncementProvider } from './components/announcement/AnnouncementProvider'
import { NotificationProvider } from './components/notification/NotificationProvider'
import { MembershipProvider } from './components/membership/MembershipProvider'
import { CourseProvider } from './components/course/CourseProvider'
import { TournamentProvider } from './components/tournament/TournamentProvider'
import { ScoreProvider } from './components/score/ScoreProvider'

function App() {
  return (
    <div className="App">
      <AuthProvider>
        {/*お約束タグ */}
        <Router>
          {/*このタグがあると最初に一致したURLを一つだけ表示してくれる 来れが無かったら全部表示！ */}
          {/*exactが書かれているのでpathは完全一致でないと開かない */}
          <Switch>
            <PrivateRoute path="/" name="Login Page" exact component={Menu} />
            <PrivateRoute path="/Menu" name="Menu Page" exact component={Menu} />
            <AnnouncementProvider>
              <NotificationProvider>
                <MembershipProvider>
                  <CourseProvider>
                    <TournamentProvider>
                      <ScoreProvider>
                        <PrivateRoute path="/Notification" name="Notification Page" exact component={Notification} />
                        <PrivateRoute path="/NotificationConfirm" name="NotificationConfirm Page" exact component={NotificationConfirm} />
                        <PrivateRoute path="/NotificationCompleted" name="NotificationCompleted Page" exact component={NotificationCompleted} />
                        <PrivateRoute path="/AnnouncementSetNewsText" name="AnnouncementSetNewsText Page" exact component={AnnouncementSetNewsText} />

                        <PrivateRoute path="/Announcement" name="Announcement Page" exact component={Announcement} />
                        <PrivateRoute path="/AnnouncementConfirm" name="AnnouncementConfirm Page" exact component={AnnouncementConfirm} />
                        <PrivateRoute path="/AnnouncementCompleted" name="AnnouncementCompleted Page" exact component={AnnouncementCompleted} />

                        <PrivateRoute path="/Membership" name="Membership Page" exact component={Membership} />
                        <PrivateRoute path="/MembershipEdit" name="MembershipEdit Page" exact component={MembershipEdit} />
                        <PrivateRoute path="/MembershipConfirm" name="MembershipConfirm Page" exact component={MembershipConfirm} />
                        <PrivateRoute path="/MembershipCompleted" name="MembershipCompleted Page" exact component={MembershipCompleted} />
                        <PrivateRoute path="/MembershipCancel" name="MembershipCancel Page" exact component={MembershipCancel} />
                        <PrivateRoute path="/MembershipCancelCompleted" name="MembershipCancelCompleted Page" exact component={MembershipCancelCompleted} />
                        <PrivateRoute path="/MembershipRestoreCompleted" name="MembershipRestoreCompleted Page" exact component={MembershipRestoreCompleted} />
                        <PrivateRoute path="/MembershipRestore" name="MembershipRestore Page" exact component={MembershipRestore} />

                        <PrivateRoute path="/Course" name="Course Page" exact component={Course} />
                        <PrivateRoute path="/CourseAddStep1" name="CourseAddStep1 Page" exact component={CourseAddStep1} />
                        <PrivateRoute path="/CourseAddStep2" name="CourseAddStep2 Page" exact component={CourseAddStep2} />
                        <PrivateRoute path="/CourseAddStep3" name="CourseAddStep3 Page" exact component={CourseAddStep3} />
                        <PrivateRoute path="/CourseAddStep4" name="CourseAddStep4 Page" exact component={CourseAddStep4} />
                        <PrivateRoute path="/CourseAddCompleted" name="CourseAddCompleted Page" exact component={CourseAddCompleted} />

                        <PrivateRoute path="/CourseEditStep1" name="CourseEditStep1 Page" exact component={CourseEditStep1} />
                        <PrivateRoute path="/CourseEditStep2" name="CourseEditStep2 Page" exact component={CourseEditStep2} />
                        <PrivateRoute path="/CourseEditStep3" name="CourseEditStep3 Page" exact component={CourseEditStep3} />
                        <PrivateRoute path="/CourseEditCompleted" name="CourseEditCompleted Page" exact component={CourseEditCompleted} />

                        <PrivateRoute path="/Tournament_001" name="Tournament_001 Page" exact component={Tournament_001} />
                        <PrivateRoute path="/Tournament_002" name="Tournament_002 Page" exact component={Tournament_002} />
                        <PrivateRoute path="/Tournament_003" name="Tournament_003 Page" exact component={Tournament_003} />
                        <PrivateRoute path="/Tournament_004" name="Tournament_004 Page" exact component={Tournament_004} />
                        <PrivateRoute path="/Tournament_005" name="Tournament_005 Page" exact component={Tournament_005} />
                        <PrivateRoute path="/Tournament_006" name="Tournament_006 Page" exact component={Tournament_006} />
                        <PrivateRoute path="/Tournament_007" name="Tournament_007 Page" exact component={Tournament_007} />
                        <PrivateRoute path="/Tournament_008" name="Tournament_008 Page" exact component={Tournament_008} />
                        <PrivateRoute path="/Tournament_009" name="Tournament_009 Page" exact component={Tournament_009} />
                        <PrivateRoute path="/Tournament_010" name="Tournament_010 Page" exact component={Tournament_010} />
                        <PrivateRoute path="/Tournament_011" name="Tournament_011 Page" exact component={Tournament_011} />
                        <PrivateRoute path="/Tournament_012" name="Tournament_012 Page" exact component={Tournament_012} />
                        <PrivateRoute path="/Tournament_013" name="Tournament_013 Page" exact component={Tournament_013} />
                        <PrivateRoute path="/Tournament_014" name="Tournament_014 Page" exact component={Tournament_014} />
                        <PrivateRoute path="/Tournament_015" name="Tournament_015 Page" exact component={Tournament_015} />
                        <PrivateRoute path="/Tournament_016" name="Tournament_016 Page" exact component={Tournament_016} />
                        <PrivateRoute path="/Tournament_017" name="Tournament_017 Page" exact component={Tournament_017} />
                        <PrivateRoute path="/Tournament_018" name="Tournament_018 Page" exact component={Tournament_018} />
                        <PrivateRoute path="/Tournament_019" name="Tournament_019 Page" exact component={Tournament_019} />
                        <PrivateRoute path="/Tournament_020" name="Tournament_020 Page" exact component={Tournament_020} />
                        <PrivateRoute path="/Tournament_021" name="Tournament_021 Page" exact component={Tournament_021} />
                        <PrivateRoute path="/Tournament_022" name="Tournament_022 Page" exact component={Tournament_022} />
                        <PrivateRoute path="/Tournament_023" name="Tournament_023 Page" exact component={Tournament_023} />
                        <PrivateRoute path="/Tournament_024" name="Tournament_024 Page" exact component={Tournament_024} />
                        <PrivateRoute path="/Tournament_025" name="Tournament_025 Page" exact component={Tournament_025} />
                        <PrivateRoute path="/Tournament_026" name="Tournament_026 Page" exact component={Tournament_026} />

                        <PrivateRoute path="/Tournament_028" name="Tournament_028 Page" exact component={Tournament_028} />
                        <PrivateRoute path="/Tournament_029" name="Tournament_029 Page" exact component={Tournament_029} />
                        <PrivateRoute path="/Tournament_030" name="Tournament_030 Page" exact component={Tournament_030} />

                        <PrivateRoute path="/Score_001_1" name="Score_001_1 Page" exact component={Score_001_1} />
                        <PrivateRoute path="/Score_001_2" name="Score_001_2 Page" exact component={Score_001_2} />
                        <PrivateRoute path="/Score_009" name="Score_009 Page" exact component={Score_009} />
                        <PrivateRoute path="/Score_010" name="Score_010 Page" exact component={Score_010} />
                        {/*デバッグ用のボタン*/}
                        <PrivateRoute path="/DataClear" name="DataClear Page" exact component={DataClear} />

                        {/*仮のユーザーが登録した口座番号ページ*/}
                        <PrivateRoute path="/UserBankInfopage" name="UserBankInfopage Page" exact component={UserBankInfopage} />

                      </ScoreProvider>
                    </TournamentProvider>
                  </CourseProvider>
                </MembershipProvider>
              </NotificationProvider>
            </AnnouncementProvider>
          </Switch>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
