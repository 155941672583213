import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom"
import { CourseContext } from '../CourseProvider'
import CourseHeader from '../CourseHeader'
import ManagementFooter from '../../ManagementFooter'
import CourseAddStep3ColumnTitleContainer from './CourseAddStep3ColumnTitleContainer'
import CourseAddStep3CourseContainer from './CourseAddStep3CourseContainer'
import '../../../styles/styles.css';
import { makeCourseArrayCourseList } from '../CourseUtility'

//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";

function CourseAddStep3(props) {
    const [value, setValue] = useContext(CourseContext);
    const history = useHistory();
    const [arrayCourseList, setArrayCourseList] = useState([]);
    useEffect(() => {

        value.CourseAddStep3.arrayCourseList = makeCourseArrayCourseList(value);
        setArrayCourseList(value.CourseAddStep3.arrayCourseList);

    }, []);

    //コース情報コピー完了
    useEffect(() => {
    }, [arrayCourseList]);

    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "next":
                history.push("CourseAddStep4");
                break;
            case "back":
                history.push("CourseAddStep2");
                break;
            default:
                break;
        }
    };


    return (
        <div className="c-screen c-screen--gunmetal">
            <CourseHeader stepNb={"step3"}></CourseHeader>
            <main>
                <section className="c-page-section c-mt16">
                    <h2 className="c-heading--2 c-heading--center c-mt32">ホール名、規定打数、Yard数をいれてください。</h2>
                    {/*<!-- メインコンテナ -->*/}
                    <div className="c-container-vertical c-mt16">
                        {/*動的にコンポーネントが増えていきます*/}
                        {
                            arrayCourseList.map(
                                courseAddItem =>
                                (
                                    <React.Fragment key={courseAddItem.key + "_React.Fragment"}>
                                        <CourseAddStep3ColumnTitleContainer key={courseAddItem.key + "_CourseAddStep3ColumnTitleContainer"} ></CourseAddStep3ColumnTitleContainer>
                                        <CourseAddStep3CourseContainer key={courseAddItem.key} nowCourse={courseAddItem} />
                                    </React.Fragment>
                                )
                            )
                        }


                    </div> {/*<!-- メインコンテナ -->*/}

                    <div id="add-course-button" className="c-button-set c-mt32 c-mb88">
                        <button type="submit" id="next" onClick={(e) => { onButtonClick(e) }} className="button button__primary">次へ</button>
                        <button id="back" onClick={(e) => { onButtonClick(e) }} className="button button__secondary">戻る</button>
                    </div>
                </section>
            </main>
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default CourseAddStep3

