import React, { useEffect, useState } from "react";

// contextの作成
export const MembershipContext = React.createContext([
  {
    search_keyword_text: null,
    userData: null,
    searchResults: null,
    selectedIndex: 0
  }
]);
export const MembershipProvider = ({ children }) => {
  const [value, setValue] = useState({
    search_keyword_text: null,
    userData: [],
    searchResults: [],
    selectedIndex: 0
  });
  useEffect(() => {
  }, []);
  return (
    // Contextを使用して認証に必要な情報をコンポーネントツリーに流し込む。
    <MembershipContext.Provider
      value={
        [value, setValue]
      }
    >
      {children}
    </MembershipContext.Provider>
  );
}