import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import firebase from '../../firebase/Firebase';
import { sortArray } from '../Utility';
import './styles.css';
import '../../styles/loader.css'
import Loader from '../Loader'
import UserBankInfoPageContainer from './UserBankInfoPageContainer'
import ManagementFooter from '../ManagementFooter';
import logo from "../../images/logo.svg";

import { IconAnnouncement } from "../Icons";

function UserBankInfoPage(props) {
    const testTbl = [
        {

            bank_info: {
                bank_name: "みずほ銀行",//銀行名
                branch_name: "大森支店",//支店名
                account_type: "普通",                                   //口座の種類
                account_number: "0000011111",                               //口座番号
                account_holder: "ミホノブルボン"                               //名義
            },
            user_data: {
                given_name_kanji: "美穂",
                family_name_kanji: "古本",                                    //選手名
                serial_nb: "SN3768377124424124",                                                    //選手ID
                phone_number: "08045671234",                                            //電話番号
            }
        },
        {
            bank_info: {
                bank_name: "みずほ銀行",//銀行名
                branch_name: "大森支店",//支店名
                account_type: "普通",                                   //口座の種類
                account_number: "0000011111",                               //口座番号
                account_holder: "ミホノブルボン"                               //名義
            },
            user_data: {
                given_name_kanji: "美穂",
                family_name_kanji: "古本",                                    //選手名
                serial_nb: "SN928374569283475629",                                                    //選手ID
                phone_number: "08045671234",                                            //電話番号

            }
        }
    ]

    const history = useHistory();
    const [userDataList, setUserDataList] = useState(testTbl);
    const [loadingData, setLoadingData] = useState(undefined);
    useEffect(() => {
        //ローデング表示
        //        setLoadingData({ loadingFlg: true, title: "ユーザーデータを読み込み中..." });

        setLoadingData({ loadingFlg: true, title: "ユーザーデータを読み込み中..." });
        getAccountNumberArray("getPlayerList", "", (result, status) => {
            setUserDataList(status);
            setLoadingData({ loadingFlg: false, title: "ユーザーデータを読み込み中..." });
        })

    }, []);

    /**
     * function
     */
    const getAccountNumberArray = (apiName, data, callback) => {
        const functions = firebase.functions();
        const func = functions.httpsCallable(apiName);

        let uid = "pitLPxZpQk2ko2ernnF7";
        func(
            {
                uid: uid,
                OrderID: "38614781113083611632423545",
                DevelopmentFlg: 1,
                tournamentId: "PI7BkF5JkMqAqzqdU7hr",
                entryFlg: 1,
                payment_data: { order_id: 987698769, uid: "asdfasdf", tournamentId: "aaaaaaaaaaaaaaaaaa" },
                is_admin: 1
            }).then(res => {
                if (res.data.status === 200) {
                    if (callback !== null && callback !== undefined) {
                        console.error("OKOK");
                        callback(true, res.data.data);
                    }
                } else {
                    if (callback !== null && callback !== undefined) {
                        console.error("NGNG");
                        callback(false, res.data);
                    }

                }
            }).catch(e => {
                console.error(e);
                if (callback !== null && callback !== undefined) {
                    callback(false, "");

                }
                alert("通信エラーが発生しました通信状態をご確認ください");
            });

    }



    const onClickButton = (e) => {
        switch (e.currentTarget.id) {
            case "button_back":
                history.push("/");
                break;
            case "":
                break;
            default:
                break;
        }



    };

    //----------------------------------------
    const renderUserDataList = () => {
        if (userDataList !== undefined) {
            return userDataList.map((data, i) => {
                if(Object.keys(data.user_data).length <= 5) return null;
                if (data.user_data.serial_nb.length <= 0) {
                    data.user_data.serial_nb=String(Math.floor( Math.random() * 101 ));
                }
                return <UserBankInfoPageContainer key={data.user_data.serial_nb} userData={data}>
                </UserBankInfoPageContainer>
            })
        } else {
            return null;
        }
    }

    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <header className="c-container-vertical c-mt88">

                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>

                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconAnnouncement></IconAnnouncement>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">選手登録一覧</h1>
                </div>

            </header>
            <main>
                <table>
                    <tbody>
                        <tr className="c-container-horizontal">
                            <td className="c-data__title c-data__title--144 c-data__title--dark-slate-gray">選手名</td>
                            <td className="c-data__title c-data__title--232 c-data__title--dark-slate-gray">選手ID</td>
                            <td className="c-data__title c-data__title--144 c-data__title--dark-slate-gray">電話番号</td>
                            <td className="c-data__title c-data__title--144 c-data__title--dark-slate-gray">銀行名</td>
                            <td className="c-data__title c-data__title--144 c-data__title--dark-slate-gray">支店名</td>
                            <td className="c-data__title c-data__title--144 c-data__title--dark-slate-gray">口座の種別</td>
                            <td className="c-data__title c-data__title--144 c-data__title--dark-slate-gray">口座番号</td>
                            <td className="c-data__title c-data__title--232 c-data__title--dark-slate-gray">口座名義人名</td>
                        </tr>

                        {/*<!-- 会員グループ一つ -->*/}
                        {renderUserDataList()}



                    </tbody>
                </table>
                <div className="c-button-set">
                    <button id="button_back" onClick={(e) => { onClickButton(e) }} className="button button__secondary">戻る</button>
                </div>
            </main>

            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default UserBankInfoPage

