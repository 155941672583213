import React, { useEffect } from 'react';
import logo from "../../images/logo.svg";
import '../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconCourse, IconGolfBall } from "../Icons";


function CourseHeader(props) {
    const tagIdList=[
        "step1",
        "step2",
        "step3",
        "step4",
        "step5"
        
    ];
    useEffect(() => {
        //一度すべて非アクティブにする
        tagIdList.forEach((date)=>{
            document.getElementsByTagName("*")[date].className ="kanri-breadcrumb__item"; 
        });
        document.getElementsByTagName("*")[props.stepNb].className ="kanri-breadcrumb__item kanri-breadcrumb__item--active"; 

    }, []);

    return (
        <header className="c-container-vertical c-mt56">

            <div className="c-logo c-logo--144">
                <img src={logo} alt="JCG Management" className="c-logo__source" />
            </div>

            <div className="kanri-title c-mt32">
                <div className="kanri-title__icon">
                    <figure className="c-icon c-icon--white c-icon--32">
                        <IconCourse></IconCourse>
                    </figure>
                </div>
                <h1 className="c-heading--1">ゴルフ場：基本情報追加</h1>
            </div>

            <div className="kanri-breadcrumb c-mt56">

                <div className="kanri-breadcrumb__item kanri-breadcrumb__item--active" id="step1">
                    <div className="c-icon c-icon--32">
                        <IconGolfBall></IconGolfBall>
                    </div>
                    <p className="kanri-breadcrumb__label c-subtitle">基本情報</p>
                </div>

                <div className="kanri-breadcrumb__item" id="step2">
                    <div className="c-icon c-icon--32">
                        <IconGolfBall></IconGolfBall>
                    </div>
                    <p className="kanri-breadcrumb__label c-subtitle">コース情報 A</p>
                </div>

                <div className="kanri-breadcrumb__item" id="step3">
                    <div className="c-icon c-icon--32">
                        <IconGolfBall></IconGolfBall>
                    </div>
                    <p className="kanri-breadcrumb__label c-subtitle">コース情報 B</p>
                </div>

                <div className="kanri-breadcrumb__item" id="step4">
                    <div className="c-icon c-icon--32">
                        <IconGolfBall></IconGolfBall>
                    </div>
                    <p className="kanri-breadcrumb__label c-subtitle">ゴルフ場登録確認</p>
                </div>

                <div className="kanri-breadcrumb__item" id="step5">
                    <div className="c-icon c-icon--32">
                        <IconGolfBall></IconGolfBall>
                    </div>
                    <p className="kanri-breadcrumb__label c-subtitle">ゴルフ場登録完了</p>
                </div>

            </div>

        </header>
    )
}



export default CourseHeader

