import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { TournamentContext, tournamentInitialState } from '../TournamentProvider'
import ManagementFooter from '../../ManagementFooter'
import logo from "../../../images/logo.svg";
import { IconTournament, IconCompletion } from "../../Icons";
import '../../../styles/styles.css';
import { sendNewsPostponementSchedule, checkTournamentTourCountsArray, getDate, addScoreDataTournamentUserDatas, getEntryAllCount, individualAddition, deleteTournamentUser, getTournamentLikeSearchUser, getTournamentAllUsersInTheArea, finish_allocation_function, finish_individual_addition_function, makeTournamentUser, getTournamentAllUserList, getUserSexFromTournamentSex, saveTournamentAllUserList } from "../TournamentUtility";
import { setGroupingFlgTournament, deleteTournamentEntryUser, getTournamentPaymentUserData, setTournamentPaymentUserData, getParticipationTournament, sortArray, setTournamentAllUserEntryFlg, getUsersNews, setUsersNews, likeSearchUser, initAllUsersTourCount, publishTournamentNewsAllUsersDel, addUserTournament, updateTournamentPart, deleteTestUser, testFunction, getTournamentEntryFlg, setTournamentStatus, getUserDataTournament, deleteAllUserTournament, setCopyUser, getAllUsersInTheArea, setTournamentAllocationFlg } from "../../../Database";
import '../../../styles/loader.css'
import Loader from '../../Loader'

function Tournament_020(props) {
    const [value] = useContext(TournamentContext);
    const history = useHistory();

    const [tournament020Data] = useState(value.tournament019Data);                //グローバル参照渡し
    const [loadingData, setLoadingData] = useState(undefined);

    useEffect(() => {
        if (tournament020Data === undefined) return;

        //選択された大会
        let select_tournament_title = tournament020Data.title + " " + tournament020Data.category + " " + tournament020Data.area;

        document.getElementById("select_tournament_title").innerText = select_tournament_title;

        let postponement_schedule_text = "";

        if (tournament020Data.postponed_flg === 2) {
            postponement_schedule_text = "未定";
        } else {
            postponement_schedule_text = tournament020Data.eventDate + " " + tournament020Data.eventStartTime + "～" + tournament020Data.eventFinishDate + " " + tournament020Data.eventFinishTime;
        }


        //延期日程
        document.getElementById("postponement_schedule").innerText = postponement_schedule_text

        //お知らせタイトル
        document.getElementById("postponment_reason_title").innerText = tournament020Data.newsTitle;
        //お知らせ内容
        document.getElementById("postponment_reason_content").innerText = tournament020Data.newsText;


    }, [tournament020Data, value.tournament016Data, value.tournament016Data.tournamentData]);

    const onClickButton = (e) => {
        switch (e.currentTarget.id) {
            case "button_edit":
                history.push("Tournament_019");
                break;
            case "button_send":
                //延期フラグを解除する
                tournament020Data.postponed_flg = 0;

                const asyncfunc = (async () => {
                    await sendNewsPostponementSchedule(tournament020Data, (result, status) => {
                        //ローディング非表示
                        setLoadingData({ loadingFlg: false, title: "延期情報を設定中..." });
                        if (result === false) {
                            alert("延期情報の設定中に失敗しました", status);
                        } else {
                            history.push("Tournament_021");
                        }
                    });

                });
                //ローディング表示
                setLoadingData({ loadingFlg: true, title: "延期情報を設定中..." });

                asyncfunc();

                break;
            default:
                break;
        }
    };
    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <header className="c-container-vertical c-mt88">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconTournament></IconTournament>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">既存大会延期画面</h1>
                </div>
            </header>
            <main>

                <section className="kanri-container">
                    <h2 className="c-heading--2 c-mt72">以下の内容で延期します。よろしいですか？</h2>

                    <div className="c-container-vertical c-mt32">
                        <div className="confirm confirm__vertical">
                            <span className="confirm confirm__label" >大会名</span>
                            <p className="confirm confirm__field c-paragraph" id="select_tournament_title">第1回JCG男子ツアー 関東会場</p>
                        </div>

                        <div className="confirm confirm__vertical">
                            <span className="confirm confirm__label">延期日程</span>
                            <p className="confirm confirm__field c-paragraph" id="postponement_schedule">未定</p>
                        </div>
                        <div className="confirm confirm__vertical">
                            <span className="confirm confirm__label">延期理由</span>
                            <p className="confirm confirm__field c-paragraph" id="postponment_reason_title">第1回JCG男子ツアー 関東会場　大会延期のお知らせ</p>
                            <p className="confirm confirm__field confirm__field--textarea c-paragraph c-mt16" id="postponment_reason_content">
                                台風18号接近に伴い、進路を鑑みますと当日大荒れになると予想されます。<br />
                                参加者の皆様の安全を考慮し、今回は延期といたします。<br />
                                <br />
                                後日改めて開催日をお知らせいたします。
                            </p>
                        </div>
                    </div>

                    <p className="c-paragraph c-paragraph--center c-mt32">大会参加者全員にお知らせが送信されます。</p>
                    <p className="c-paragraph c-paragraph--center">よろしいですか？</p>

                    <div className="c-button-set c-mb88">
                        <button id="button_send" onClick={(e) => { onClickButton(e) }} className="button button__primary">開催日時を決定する</button>
                        <button id="button_edit" onClick={(e) => { onClickButton(e) }} className="button button__secondary">修正する</button>
                    </div>

                </section>

            </main>
        </div>
    )
}

export default Tournament_020

