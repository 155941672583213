import React, { useEffect, useState } from "react";
import lodash from 'lodash';
export const orijinalProviderInitialState = {

  score_001_1_Data: {
    temp: { loadTournamentList: [] }

  },
  score_001_2_Data: {
    tournamentData: undefined

  },
  
  score_009_Data: {
    tournamentData: undefined
  },

};

export const scoreProviderInitialState = lodash.cloneDeep(orijinalProviderInitialState);

// contextの作成
export const ScoreContext = React.createContext(scoreProviderInitialState);


export const ScoreProvider = ({ children }) => {
  const [value, setValue] = useState(scoreProviderInitialState);
  useEffect(() => {
    //    console.log("CourseProvider");
  }, []);

  return (
    // Contextを使用して認証に必要な情報をコンポーネントツリーに流し込む。
    <ScoreContext.Provider
      value={
        [value, setValue]
      }
    >
      {children}
    </ScoreContext.Provider>
  );
}




