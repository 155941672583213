import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { ScoreContext } from './ScoreProvider'
import ManagementFooter from '../ManagementFooter'
import logo from "../../images/logo.svg";
import { IconScore } from "../Icons";
import '../../styles/styles.css';
import { setTotalUserAtBat, checkScoreInput, setUserDataToScoreDataAndEntryData, checkScoreConfirmation, setRanking } from "./ScoreUtility";
import '../../styles/loader.css'
import Loader from '../Loader'
import Score009Accordion from './Score_009_Accordion'

function Score_010(props) {
    const [value] = useContext(ScoreContext);
    const history = useHistory();
    //------------------------------------------



    useEffect(() => {

    }, []);


    const onClickButton = (e) => {
        switch (e.currentTarget.id) {
            case "back_menu":
                history.push("/");
                break;
            default:
                break;
        }
    };
    return (
        <div className="c-screen c-screen--gunmetal">
            <header className="c-container-vertical c-mt56">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt32">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconScore></IconScore>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">大会のスコア確定完了画面</h1>
                </div>
            </header>
            <main>

                <div className="c-vertical-container c-mt56">
                    <figure className="c-icon c-icon--acid-green c-icon--88 animation--zoomIn">
                        <icon-completion></icon-completion>
                    </figure>
                </div>

                <p className="c-paragraph c-paragraph--center c-mt32">
                    大会のスコアが確定されました。
                </p>

                <div className="c-button-set">
                    <button id="back_menu"  onClick={(e) => { onClickButton(e) }} className="button button__primary">メインへ戻る</button>
                </div>

            </main>
            {/*----------------------------------------------------------*/}
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default Score_010

