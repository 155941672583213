import React, { useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom"
import { TournamentContext } from './TournamentProvider'
import lodash from 'lodash';
import ManagementFooter from '../ManagementFooter'
import logo from "../../images/logo.svg";
import '../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconScore, IconTournament, IconPlus, IconEdit } from "../Icons";
import { orijinaltournamentInitialState } from './TournamentProvider'

function Tournament_001(props) {
    const [, setValue] = useContext(TournamentContext);
    const history = useHistory();
    useEffect(() => {
        setValue(lodash.cloneDeep(orijinaltournamentInitialState));
    }, [setValue]);


    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "add_tour":
                history.push("Tournament_002");
                break;
            case "change_tournament":
                history.push("Tournament_009");
                break;
            case "button_back":
                history.push("/");
                break;
            default:
                break;
        }
    };

    return (
        <div className="c-screen c-screen--gunmetal">
            <header className="c-container-vertical c-mt56">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt32">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconTournament></IconTournament>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">新規試合追加画面</h1>
                </div>
            </header>
            <main>

                <div className="c-container-horizontal c-mt144">
                    <button id="add_tour" onClick={(e) => { onButtonClick(e) }} className="kanri-option">
                        <div className="kanri-option__icon">
                            <figure className="c-icon c-icon--white c-icon--32">
                                <IconPlus></IconPlus>
                            </figure>
                        </div>
                        <h1 className="c-heading--1 kanri-option__text">新規大会追加</h1>
                    </button>

                    <button id="change_tournament" onClick={(e) => { onButtonClick(e) }} className="kanri-option">
                        <div className="kanri-option__icon">
                            <figure className="c-icon c-icon--white c-icon--32">
                                <IconEdit></IconEdit>
                            </figure>
                        </div>
                        <h1 className="c-heading--1 kanri-option__text">既存大会<br></br>確認・変更</h1>
                    </button>

                </div>

                <div className="c-container-horizontal c-mt56">

                    <button id="entry_list" onClick={(e) => { onButtonClick(e) }} className="kanri-option">
                        <div className="kanri-option__icon">
                            <figure className="c-icon c-icon--white c-icon--32">
                                <IconScore></IconScore>
                            </figure>
                        </div>
                        <h1 className="c-heading--1 kanri-option__text">エントリー<br></br>している選手一覧</h1>
                    </button>
                </div>

                <div className="c-button-set c-mt88 c-mb88">
                    <button id="button_back" onClick={(e) => { onButtonClick(e) }} className="button button__secondary">戻る</button>
                </div>

            </main>

            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default Tournament_001

