import firebase from './firebase/Firebase';
import { sortArray } from './components/Utility';

const version = "1";

/**
 * ゴミ削除
 * @param {*} callback 
 */
export async function garbageRemoval(callback) {
    let db = firebase.firestore();
    try {
        await db.collection('management_version').doc("version_doc").delete();
        await db.collection('news_template').doc("news_allocation").delete();
        await db.collection('news_template').doc("news_grouping").delete();
        await db.collection('news_template').doc("news_score").delete();
        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }
    } catch (error) {
        //もうなくて消せない場合もエラーになるので成功にする
        if (callback !== null && callback !== undefined) {
            callback(true, error);
        }
    }
}



/**
 * バージョンの取得
 * @param {*} callBack      終了時に呼ばれるコールバック 
 */
export async function getVersion(callback) {
    let db = firebase.firestore();
    try {
        let versionNbDocRef = await db.collection('management_work').doc("version_doc").get();
        if (callback !== null && callback !== undefined) {
            if (versionNbDocRef.exists === false) {
                callback(true, "0.0.0");
            } else {
                callback(true, versionNbDocRef.data().version_nb);
            }
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * バージョンの設定
 * @param {*} callBack      終了時に呼ばれるコールバック 
 */
export async function setVersion(versionNb, callback) {
    let db = firebase.firestore();
    try {
        let versionTextData = { version_nb: versionNb }
        let version_nb_doc = (await db.collection('management_work').doc("version_doc").get());
        if (version_nb_doc.exists === false) {
            await db.collection('management_work').doc("version_doc").set(versionTextData);
        }


        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * ユーザー検索
 * @param {*} query 検索キーワード 
 * @param {*} orderBySerial DESC=降順(小さい順) ASC=昇順(大きい順)
 * @returns 
 */
export async function likeSearchUser(query, orderBySerial) {

    const keys = [
        "given_name_kanji",    //氏名(姓)
        "family_name_kanji",   //氏名(名)
        "given_name_kana",     //氏名カナ(姓)
        "family_name_kana",    //氏名カナ(名)
        "birthday",             //生年月日(年齢も入れておく)
        "age",                 //年齢
        "sex",                 //性別
        "phone_number",        //電話番号
        "email",               //メールアドレス
        "postal_code",         //郵便番号
        "address_level1",      //都道府県
        "address_level2",      //市区町村や都
        "address_line1",       //町名
        "address_line2",       //番地
        "address_line3",       //建物名（必修ではない）
        "occupation",          //職業
        "serial_nb",           //シリアル番号
    ]

    const items = await likeAndSearch(keys, query, "users_data", orderBySerial, "serial_nb");

    return items;

}
/**
 * ライク検索 OR
 * @param {*} keys 検索キーの配列
 * @param {*} query 検索キーワード
 * @param {*} collection 検索コレクション名
 * @param {*} orderBy DESC=降順(小さい順) ASC=昇順(大きい順) 
 * @param {*} fieldName  ソートするフィールド名 
 * @returns 
 */

export async function likeSearch(keys, query, collection, orderBy, fieldName) {
    const querySnapshot = { docs: [] };

    //全角スペースを半角スペースに変換
    query = query.replace(/　/g, " ");
    let queryWords = query.trim().split(' ');
    let db = firebase.firestore();

    try {
        if (query) {
            const search = (query) => {
                return queryWords.map((queryWord) => {
                    const promise_result = keys.map((key) => {
                        const result = db.collection(collection).orderBy(key).startAt(queryWord).endAt(queryWord + '\uf8ff').get();
                        return result;
                    });
                    return promise_result;
                }
                );
            }
            let promise_results = search(query);
            let result = null;
            for (let i = 0; i < promise_results.length; i++) {
                result = await Promise.all(promise_results[i]);
                result.forEach((search) => {
                    querySnapshot.docs = querySnapshot.docs.concat(search.docs);
                });
            }


        } else {
            // No query
            querySnapshot.docs = await firebase
                .firestore()
                .collection(collection)
                // Pagination (optional)
                // .orderBy(sortField, sortOrder)
                // .startAfter(startAfter)
                // .limit(perPage)
                .get();
        }
    } catch (err) {
        console.log(err)
    }
    //idが重複した場合は削除
    querySnapshot.docs = getArrayDeleteDouble2(querySnapshot.docs, "id");

    // Appends id and creates clean Array
    let items = [];
    querySnapshot.docs.forEach(doc => {
        let item = doc.data();
        item.uid = doc.id;
        items.push(item);
    });

    //ソート
    items.sort((a, b) => {
        //基本は昇順
        let retrunResult = 1;
        switch (orderBy) {
            case "DESC":    //降順
                retrunResult = retrunResult * -1;
                break;
            default:
                break;
        }

        if (a[fieldName] < b[fieldName]) {
            return retrunResult;
        } else {
            return (retrunResult * -1);
        }
    });

    // Filters duplicates
    return items;
}
/**
 * 文字列判定
 * @param {*} obj 
 * @returns false=文字ではない true=文字
 */
function isString(obj) {
    return typeof (obj) == "string" || obj instanceof String;
};

/**
 * ライク検索 AND(orライク検索後、ループで検索かけている)
 * @param {*} keys 
 * @param {*} query 
 * @param {*} collection 
 * @param {*} orderBy 
 * @param {*} fieldName 
 * @returns 
 */
export async function likeAndSearch(keys, query, collection, orderBy, fieldName) {
    let queryWords = query.trim().split(/[\u{20}\u{3000}]/u);
    //まずはORライクサーチ
    let items = await likeSearch(keys, query, collection, orderBy, fieldName);

    let newItems = items.filter((item) => {
        let result = keys.filter((key) => {
            let result = queryWords.filter((queryWord) => {
                let result = false;
                if (isString(item[key]) === true) {
                    //文字列内に指定文字が含まれているか確認
                    result = item[key].includes(queryWord);
                } else {
                    //文字列以外なら普通に比較する
                    result = item[key] === queryWord;
                }
                return result;
            })
            return result.length !== 0;

        })
        return result.length === queryWords.length;
    })
    // Filters duplicates
    return newItems;
}




/**
 * ユーザーのアップデート
 * @param {*} uid 
 * @param {*} data 
 */
export async function updateUser(uid, data) {

    let db = firebase.firestore();
    //バージョンデータ
    data.version = version;
    const userRef = db.collection('users_data').doc(uid)

    await userRef.update(data);

}


/**
 * ユーザーの削除
 * @param {*} uid 
 */
export async function deletegUser(uid) {


}

/**
 * ユーザーの復帰
 * @param {*} uid 
 */
export async function restoreUser(uid) {

}

/**
 * ユーザーの取得
 * @param {*} uid 
 */
export async function getUser(uid, callback) {
    try {
        let db = firebase.firestore();
        let userData = (await db.collection('users_data').doc(uid).get()).data();
        userData.id = uid;
        if (callback !== null && callback !== undefined) {
            callback(true, userData);
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * ツアーカウントを加算する
 * @param {*} uid   ユーザーUID
 * @param {*} areaName 関東、関西
 * @param {*} callBack 
 */
export async function addUsersTourCount(uid, areaName, callback) {
    try {
        let areaNb = getAreaNb(areaName);

        let db = firebase.firestore();
        let tour_counts = (await db.collection('users_data').doc(uid).get()).data().tour_counts;
        //指定されたindexより配列が小さい場合
        if (tour_counts.length <= areaNb) {
            for (let i = tour_counts.length; i <= areaNb; i++) {
                tour_counts.push(0);
            }
        }
        tour_counts[areaNb] += 1;

        let res = await db.collection('users_data').doc(uid).set({
            tour_counts: tour_counts,
        }, { merge: true })
        if (callback !== null && callback !== undefined) {
            callback(true, res);
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }

}

/**
 * ツアーカウントを減算する
 * @param {*} uid   ユーザーUID
 * @param {*} areaName 関東、関西
 * @param {*} callBack 
 */
export async function subUsersTourCount(uid, areaName, callback) {
    try {
        let areaNb = getAreaNb(areaName);

        let db = firebase.firestore();
        let tour_counts = (await db.collection('users_data').doc(uid).get()).data().tour_counts;
        //指定されたindexより配列が小さい場合
        if (tour_counts.length <= areaNb) {
            for (let i = tour_counts.length; i <= areaNb; i++) {
                tour_counts.push(0);
            }
        }
        tour_counts[areaNb] -= 1;

        let res = await db.collection('users_data').doc(uid).set({
            tour_counts: tour_counts,
        }, { merge: true })
        if (callback !== null && callback !== undefined) {
            callback(true, res);
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}


/**
 * ツアーカウントを更新する
 * @param {*} uid   ユーザーUID
 * @param {*} tour_counts ツアーカウントリスト
 * @param {*} callBack 
 */
export async function updateUsersTourCount(uid, tour_counts, callback) {
    try {
        let db = firebase.firestore();
        let res = await db.collection('users_data').doc(uid).set({
            tour_counts: tour_counts,
        }, { merge: true })
        if (callback !== null && callback !== undefined) {
            callback(true, res);
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * コースデータの保存
 * @param {*} data 
 */
export async function saveCourse(data) {

    let db = firebase.firestore();
    //バージョンデータ
    data.version = version;
    await db.collection('course_data').add(data);

}

/**
 * コースデータの更新
 * @param {*} data
 * @param {*} docId 
 */
export async function updateCourse(data, docId) {

    let db = firebase.firestore();
    const docData = db.collection('course_data').doc(docId);
    //バージョンデータ
    data.version = version;
    const res = await docData.update(data);
    return res;
}


/**
 * コースリストデータの取得 
 * @returns 
 */
export async function getCourseList() {
    let db = firebase.firestore();
    let documentList = await (await db.collection('course_data').get()).docs;
    let courseList = [];
    for (let i = 0; i < documentList.length; i++) {
        let courseData = (await db.collection('course_data').doc(documentList[i].id).get()).data();
        //ドキュメントIDも必要なので
        courseData.id = documentList[i].id;
        courseList.push(courseData);
    }
    return courseList;

}

/**
 * コースデータの取得 
 * @param {*} docId 
 * @returns 
 */
export async function getCourse(docId) {
    let db = firebase.firestore();
    let courseData = (await db.collection('course_data').doc(docId).get()).data();
    courseData.id = docId;
    return courseData;
}


/**
 * 大会データの保存
 * @param {*} data 
 * @returns id
 */
export async function saveTournament(data) {
    let db = firebase.firestore();
    //バージョンデータ
    data.version = version;
    const res = await db.collection('tournament_data').add(data);
    return res.id;
}

/**
 * 大会データの更新
 * @param {*} data
 * @param {*} docId 
 */
export async function updateTournament(data, docId) {

    let db = firebase.firestore();
    //バージョンデータ
    data.version = version;
    const docData = db.collection('tournament_data').doc(docId);
    const res = await docData.update(data);
    return res;
}

/**
 * 大会データの更新（使ってないかも）
 * @param {*} data
 * @param {*} docId 
 */
export async function setTournament(data, docId) {

    let db = firebase.firestore();
    //バージョンデータ
    data.version = version;
    const docData = db.collection('tournament_data').doc(docId);
    const res = await docData.set(data);
    return res;
}

/**
 * 大会データの取得
 * @param {*} data
 * @param {*} docId 
 */
export async function getTournament(docId) {

    let db = firebase.firestore();
    const docData = db.collection('tournament_data').doc(docId);
    const res = await docData.data();
    return res;
}

/**
 * 大会データの取得 
 * @returns 
 */
export async function getTournamentList() {
    let db = firebase.firestore();
    let documentList = (await db.collection('tournament_data').get()).docs;
    let tournamentList = [];
    for (let i = 0; i < documentList.length; i++) {
        let docId = documentList[i].id;
        let tournamentData = (await db.collection('tournament_data').doc(docId).get()).data();
        tournamentData.id = docId;
        //データべーにには画像無しは文字列0で保存されているけど画像無しはundefinedで管理してるので変更
        tournamentData.pictureList.forEach((data, index) => {
            if (data.length === 0) {
                tournamentData.pictureList[index] = undefined;
            }
        })
        tournamentList.push(tournamentData);
    }

    //開始日時で昇順ソート
    tournamentList.sort((a, b) => {
        if (a.eventDate < b.eventDate) return -1;
        if (a.eventDate > b.eventDate) return 1;
        return 0;
    });

    return tournamentList;

}

/**
 * 大会データの削除
 * @param {*} data 
 * @returns id
 */
export async function deleteTournament(tournamentId, callback) {
    try {
        let db = firebase.firestore();
        await deleteTournamentEntryUser(tournamentId, async (result, status) => {
            if (result === false) {
                throw new Error("大会に参加しているエントリーユーザーの削除に失敗しました=" + status);
            }
            await db.collection('tournament_data').doc(tournamentId).delete();

            if (callback !== null && callback !== undefined) {
                callback(true, "");
            }
        });

    } catch (reeor) {
        if (callback !== null && callback !== undefined) {
            callback(false, reeor);
        }
    }
}

/**
 * 大会に参加しているエントリーユーザーの削除
 * @param {*} data 
 * @returns id
 */

export async function deleteTournamentEntryUser(tournamentId, callback) {
    try {
        let db = firebase.firestore();
        const entry_user_docsRef = await db.collection('tournament_data').doc(tournamentId).collection('entry_user').get();

        //サブコレクションpayment_dataの削除
        await deleteTournamentPayment_data(tournamentId, entry_user_docsRef, async (result, status) => {
            if (result === false) {
                throw new Error("サブコレクションpayment_dataの削除に失敗しました=" + status);
            }
            //サブコレクションscore_dataの削除
            await deleteTournamentScore_data(tournamentId, entry_user_docsRef, async (result, status) => {
                if (result === false) {
                    throw new Error("サブコレクションscore_dataの削除に失敗しました=" + status);
                }
                //サブコレクションentry_userの削除
                let promise_results = entry_user_docsRef.docs.map((entry_user_docRef) => {
                    return db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(entry_user_docRef.id).delete();

                })
                await Promise.all(promise_results);
            })

            if (callback !== null && callback !== undefined) {
                callback(true, "");
            }

        });

    } catch (reeor) {
        if (callback !== null && callback !== undefined) {
            callback(false, reeor);
        }
    }
}


/**
 * サブコレクションpayment_dataの削除
 * @param {*} tournamentId 
 * @param {*} entry_user_docsRef 
 * @param {*} callback 
 */
async function deleteTournamentPayment_data(tournamentId, entry_user_docsRef, callback) {
    try {
        let db = firebase.firestore();
        //サブコレクションpayment_dataの削除
        let promise_results = entry_user_docsRef.docs.map(async (entry_user_docRef) => {
            let payment_data_docsRef = await db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(entry_user_docRef.id).collection('payment_data').get();
            return payment_data_docsRef.docs.map(async (payment_data_docRef) => {
                return await db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(entry_user_docRef.id).collection('payment_data').doc(payment_data_docRef.id).delete();
            })

        })
        await Promise.all(promise_results);
        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }
    } catch (reeor) {
        if (callback !== null && callback !== undefined) {
            callback(false, reeor);
        }
    }
}


/**
 * サブコレクションscore_dataの削除
 * @param {*} tournamentId 
 * @param {*} entry_user_docsRef 
 * @param {*} callback 
 */
async function deleteTournamentScore_data(tournamentId, entry_user_docsRef, callback) {
    try {
        let db = firebase.firestore();
        //サブコレクションscore_dataの削除
        let promise_results = entry_user_docsRef.docs.map(async (entry_user_docRef) => {
            let score_data_docsRef = await db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(entry_user_docRef.id).collection('score_data').get();
            return score_data_docsRef.docs.map(async (score_data_docRef) => {
                return await db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(entry_user_docRef.id).collection('score_data').doc(score_data_docRef.id).delete();
            })

        })

        await Promise.all(promise_results);
        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }
    } catch (reeor) {
        if (callback !== null && callback !== undefined) {
            callback(false, reeor);
        }
    }
}

/**
 * 大会の割り当てフラグを設定する
 * @param {*} tournamentId  大会ID 
 * @param {*} allocationFlg 0=未割り振り、1=割り振り済（メイン画面にエントリーを表示させる為の割り振り）
 * @param {*} callBack      終了時に呼ばれるコールバック  * 
 */
export async function setTournamentAllocationFlg(tournamentId, allocationFlg, callback) {
    let db = firebase.firestore();
    try {
        await db.collection('tournament_data').doc(tournamentId).set({
            allocationFlg: allocationFlg,
        }, {
            merge: true
        }).then((docRef) => {
            if (callback !== null && callback !== undefined) {
                callback(true);
            }
        }).catch(error => {
            // error
            if (callback !== null && callback !== undefined) {
                callback(false, error);
            }
        });

    } catch (error) {
        // error
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }

}


/**
 * 大会に参加するユーザーデータを保存
 * @param {*} data 
 * @param {*} tournament_docId 
 * @returns 
 */
export async function addUserTournament(data, tournament_docId, callback) {
    let db = firebase.firestore();
    try {
        //バージョンデータ
        data.version = version;

        let entry_user_listRef = db.collection('tournament_data').doc(tournament_docId).collection('entry_user');

        const res = await entry_user_listRef.add(data);

        if (callback !== null && callback !== undefined) {
            callback(true, res);
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}


/**
 * 大会に参加するユーザーデータに決済情報を保存するエリアを追加 
 * @param {*} tournamentId 
 * @param {*} entry_userId 
 * @param {*} uid 
 * @param {*} callback 
 */
export async function addPaymentTournamentUserData(tournamentId, entry_userId, uid, callback) {
    let db = firebase.firestore();
    try {
        //アプリ側がデータを設定するので空(バージョンデータのみ)

        let paymentTournamentUserData = { version: version, uid: uid, tournamentId: tournamentId };

        let res = await db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(entry_userId).collection('payment_data').add(paymentTournamentUserData
        ).then((docRef) => {
        }).catch(error => {
            // error
            if (callback !== null && callback !== undefined) {
                callback(false, error);
            }
        });

        if (callback !== null && callback !== undefined) {
            callback(true, res);
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}


/**
 * 大会に参加している全てのユーザーのエントリーフラグを設定
 * @param {*} entryFlg 
 * @param {*} callback 
 */
export async function setEntryFlgAllUsersTournament(entryFlg, callback) {
    let db = firebase.firestore();
    try {
        let tournament_dataDocsRef = (await db.collection('tournament_data').get());


        let promise_results = tournament_dataDocsRef.docs.map(async (doc) => {
            let tournament_dataDocRef = (await db.collection('tournament_data').doc(doc.id).get());

            await setTournamentAllUserEntryFlg(tournament_dataDocRef.id, entryFlg, (result, status) => {
                if (result === false) {
                    throw new Error("大会に参加しているユーザーエントリーフラグの更新に失敗しました=" + status);
                }
            });
            return doc;
        });

        await Promise.all(promise_results);

        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }

}
/**
 * 全ての大会の振り分け完了フラグのクリア
 * @param {*} allocationFlg 
 * @param {*} callback 
 */
export async function setAllocationFlgAllTournament(allocationFlg, callback) {
    let db = firebase.firestore();
    try {
        let tournament_dataDocsRef = (await db.collection('tournament_data').get());


        let promise_results = tournament_dataDocsRef.docs.map(async (doc) => {
            let tournament_dataDocRef = (await db.collection('tournament_data').doc(doc.id).get());

            await setTournamentAllocationFlg(tournament_dataDocRef.id, allocationFlg, (result, status) => {
                if (result === false) {
                    throw new Error("大会の振り分けフラグの更新に失敗しました=" + status);
                }
            });
            return doc;
        });

        await Promise.all(promise_results);

        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }

}


/**
 * 全ての大会のユーザーデータを削除
 * @param {*} allocationFlg 
 * @param {*} callback 
 */
export async function deleteAllUserAllTournament(callback) {
    let db = firebase.firestore();
    try {
        let tournament_dataDocsRef = (await db.collection('tournament_data').get());


        let promise_results = tournament_dataDocsRef.docs.map(async (doc) => {
            let tournament_dataDocRef = (await db.collection('tournament_data').doc(doc.id).get());

            await deleteTournamentEntryUser(tournament_dataDocRef.id, (result, status) => {
                if (result === false) {
                    throw new Error("大会のユーザー削除に失敗しました=" + status);
                }
            });

            return doc;
        });

        await Promise.all(promise_results);

        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }

}


/**
 * 全ての大会の組み分け完了フラグのクリア
 * @param {*} groupingFlg  組み分けフラグ0=未振り分け 1=振り分け完了
 * @param {*} callback 
 */
export async function setGroupingFlgAllTournament(groupingFlg, callback) {
    let db = firebase.firestore();
    try {
        let tournament_dataDocsRef = (await db.collection('tournament_data').get());


        let promise_results = tournament_dataDocsRef.docs.map(async (doc) => {
            let tournament_dataDocRef = (await db.collection('tournament_data').doc(doc.id).get());

            await setGroupingFlgTournament(tournament_dataDocRef.id, groupingFlg, (result, status) => {
                if (result === false) {
                    throw new Error("大会の組み分け完了フラグの更新に失敗しました=" + status);
                }
            });
            return doc;
        });

        await Promise.all(promise_results);

        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}


/**
 * 全ての大会のスコア決定フラグのクリア
 * @param {*} fixFlg  0=未終了 1=終了
 * @param {*} callback 
 */
export async function setFixFlgAllTournament(fixFlg, callback) {
    let db = firebase.firestore();
    try {
        let tournament_dataDocsRef = (await db.collection('tournament_data').get());


        let promise_results = tournament_dataDocsRef.docs.map(async (doc) => {
            let tournament_dataDocRef = (await db.collection('tournament_data').doc(doc.id).get());

            await setFixFlgTournament(tournament_dataDocRef.id, fixFlg, (result, status) => {
                if (result === false) {
                    throw new Error("大会のスコア決定フラグの更新に失敗しました=" + status);
                }
            });
            return doc;
        });

        await Promise.all(promise_results);

        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}


/**
 * 大会に参加している大会用ユーザーデータを全て取得
 * @param {*} data 
 * @param {*} tournament_docId 
 * @returns 
 */
export async function getAllUserDataTournament(tournamentId, callback) {
    let db = firebase.firestore();
    try {
        let tournament_dataRef = (await db.collection('tournament_data').doc(tournamentId).collection('entry_user').get());

        if (tournament_dataRef.docs.length === 0) {
            if (callback !== null && callback !== undefined) {
                callback(false, "");
            }

        } else {

            let promise_results = tournament_dataRef.docs.map((doc) => {
                return db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(doc.id).get();
            })

            let results = await Promise.all(promise_results);
            let allUserDataTournament = results.map((result) => {
                let userDataTournament = result.data();
                userDataTournament.id = result.id;
                return userDataTournament;
            })

            if (callback !== null && callback !== undefined) {
                callback(true, allUserDataTournament);
            }

        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * 大会用ユーザーデータリストの内容を全て更新
 * @param {*} data 
 * @param {*} tournament_docId 
 * @returns 
 */
export async function updateAllUserDataTournament(tournamentId, userDataList, callback) {
    let db = firebase.firestore();
    try {
        let promise_results = userDataList.map(async (userData) => {
            //userDataListに対応した大会用データを取得
            let docRef = await db.collection('tournament_data').doc(tournamentId).collection('entry_user').where("uid", "==", userData.uid).get();
            //複数のidは絶対ないはず
            let id = docRef.docs[0].id;
            return db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(id).set({
                groupNb: userData.groupNb,
                inGroupNb: userData.inGroupNb,
            }, {
                merge: true
            }).then((docRef) => {
            }).catch(error => {
                // error
                if (callback !== null && callback !== undefined) {
                    callback(false, error);
                }
            });
        })
        //設定完了まで待つ
        let results = await Promise.all(promise_results);
        if (callback !== null && callback !== undefined) {
            callback(true, results);
        }


    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }

}

/**
 * 大会に参加するユーザーデータを全て削除
 * @param {*} data 
 * @param {*} tournament_docId 
 */
export async function deleteAllUserTournament(tournament_docId, callback) {
    let db = firebase.firestore();
    try {
        let docs = (await db.collection('tournament_data').doc(tournament_docId).collection('entry_user').get()).docs;
        let promise_results = docs.map((doc) => {
            return db.collection('tournament_data').doc(tournament_docId).collection('entry_user')
                .doc(doc.id)
                .delete();

        })
        await Promise.all(promise_results);

        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * 大会に参加するユーザーデータを削除
 * @param {*} tournamentId 
 * @param {*} uid 
 */
export async function deleteUserTournament(tournamentId, uid, callback) {
    let db = firebase.firestore();
    try {
        let docsRef = await db.collection('tournament_data').doc(tournamentId).collection('entry_user').where("uid", "==", uid).get();

        let promise_results = docsRef.docs.map((doc) => {
            return db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(doc.id)
                .delete();

        })
        await Promise.all(promise_results);

        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}


/**
 * ユーザーIDから参加大会を取得
 * @param {*} uid 
 * @returns 
 */
export async function getParticipationTournament(uid) {
    let db = firebase.firestore();
    let entryTournamentList = [];
    let tournament_dataRef = db.collection('tournament_data');

    //大会に割り振られているユーザーを検索
    let collectionGroupRef = await (db.collectionGroup('entry_user').where("uid", "==", uid).get());

    let collectionGroupRef_docList = collectionGroupRef.docs;
    //まだ登録されてない
    if (collectionGroupRef_docList.length === 0) {
        return null;
    }

    for (let i = 0; i < collectionGroupRef_docList.length; i++) {
        let data = collectionGroupRef_docList[i];
        //大会idを取得//
        let tournamentId = data.ref.parent.parent.id;
        let entryUserData = data.data();
        if (entryUserData.uid === uid) {
            //大会データを取得
            let tournamentData = (await tournament_dataRef.doc(tournamentId).get()).data();
            //@マーク以降のデータは不用なので
            let titles = tournamentData.title.split("@");
            tournamentData.title = titles[0];
            //ゴルフ場のデータを取得
            let golfCourseData = (await db.collection('course_data').doc(tournamentData.golfCourseDocId).get()).data();
            //@マーク以降のデータは不用なので
            let club_names = golfCourseData.club_name.split("@");
            golfCourseData.club_name = club_names[0];
            tournamentData.golfCourseData = golfCourseData;
            //arrayCourseListは不要なので
            delete tournamentData.golfCourseData.arrayCourseList;
            tournamentData.id = tournamentId;

            //決済データの取得得
            let payment_data_docRef = await (db.collectionGroup('payment_data').where("tournamentId", "==", tournamentId).where("uid", "==", uid).get());
            let payment_data = payment_data_docRef.docs[0].data();
            tournamentData.payment_data = payment_data;


            //大会結果の取得
            if (tournamentData.fix_flg) {
                let score_data_allDocRef = await db.collectionGroup('score_data').where("tournamentId", "==", tournamentId).get();

                let promise_results = score_data_allDocRef.docs.map(async (score_data_DocRef) => {
                    //絶対に大会内にはスコアデータは一つだけのはず
                    let scoreData = score_data_DocRef.data();
                    //ユーザーデータの取得
                    let userData = (await db.collection('users_data').doc(scoreData.uid).get()).data();

                    //コースの合計スコア
                    let course_total_at_bat = scoreData.score_data_list.reduce((sum, element) => {
                        //んーー何故か文字
                        return sum + Number(element.at_bat);
                    }, 0);

                    //ユーザーの合計スコア
                    let user_total_at_bat = scoreData.score_data_list.reduce((sum, element) => {
                        //んーー何故か文字
                        return sum + Number(element.user_at_bat);
                    }, 0);

                    let name = userData.given_name_kanji + " " + userData.family_name_kanji;
                    let scoreObj = {
                        ranking: scoreData.ranking,
                        name: name,
                        user_score: scoreData.user_score,
                        total_user_at_bat: user_total_at_bat,
                        prize_money: scoreData.prize_money,
                        course_total_at_bat: course_total_at_bat
                    }
                    return scoreObj;
                });

                let resultList = await Promise.all(promise_results);


                tournamentData.results = resultList;
            }
            entryTournamentList.push(tournamentData);

        }

    }

    console.log("-------------------------------------------6");
    return entryTournamentList;
}

/**
 * 大会に参加しているユーザーのエントリーフラグを設定する
 * @param {*} tournamentId  大会ID
 * @param {*} uid           ユーザーID
 * @param {*} entryFlg      エントリーフラグ　0=未エントリー 1=エントリー済 
 * @param {*} callBack      終了時に呼ばれるコールバック 
 */
export async function setTournamentEntryFlg(tournamentId, uid, entryFlg, callback) {
    let db = firebase.firestore();
    try {
        let tournament_dataRef = (await db.collection('tournament_data').doc(tournamentId).collection('entry_user').where("uid", "==", uid).get());
        let docs = tournament_dataRef.docs;
        //一人以上見つかったならNG 見つからなくてもNG
        if (docs.length > 1 || docs.length === 0) {
            if (callback !== null && callback !== undefined) {
                callback(false);
            }
        } else {
            //一人しかいないはず
            db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(docs[0].id).set({
                entry_flg: entryFlg,
            }, {
                merge: true
            }).then((docRef) => {
                if (callback !== null && callback !== undefined) {
                    callback(true);
                }
            }).catch(error => {
                // error
                if (callback !== null && callback !== undefined) {
                    callback(false, error);
                }
            });

        }

    } catch (error) {
        // error
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }

}

/**
 * 大会に参加しているユーザーのエントリーフラグを取得する
 * @param {*} tournamentId  大会ID
 * @param {*} uid           ユーザーID
 * @param {*} callBack      終了時に呼ばれるコールバック 
 */
export async function getTournamentEntryFlg(tournamentId, uid, callback) {
    let db = firebase.firestore();
    try {
        let tournament_dataRef = (await db.collection('tournament_data').doc(tournamentId).collection('entry_user').where("uid", "==", uid).get());
        let docs = tournament_dataRef.docs;
        //一人以上見つかったならNG
        if (docs.length > 1 || docs.length === 0) {
            if (callback !== null && callback !== undefined) {
                callback(false, -1);
            }
        } else {
            //一人しかいないはず
            db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(docs[0].id).get(
            ).then((docRef) => {
                if (callback !== null && callback !== undefined) {
                    let entry_user = docRef.data();
                    callback(true, entry_user.entry_flg);
                }
            }).catch(error => {
                // error
                if (callback !== null && callback !== undefined) {
                    callback(false, error);
                }
            });

        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }

    }
}

/**
 * 大会に参加している全てのユーザーデータを取得する
 * @param {*} tournamentId  大会ID
 * @param {*} callBack      終了時に呼ばれるコールバック 
 */
export async function getTournamentAllUser(tournamentId, callback) {
    let db = firebase.firestore();
    try {
        let tournament_dataRef = (await db.collection('tournament_data').doc(tournamentId).collection('entry_user').get());

        let docs = tournament_dataRef.docs;
        let users_data_doc_list = [];
        let entry_user_list = [];
        for (let i = 0; i < docs.length; i++) {
            let entry_user = docs[i].data();
            entry_user_list.push(entry_user);
            let users_data_doc = db.collection('users_data').doc(entry_user.uid);
            users_data_doc_list.push(users_data_doc);

        }
        let userDocRefList = await getUidDocList('users_data', users_data_doc_list);

        let userDataList = [];
        for (let i = 0; i < userDocRefList.length; i++) {
            let userData = userDocRefList[i].data();
            let entry_user = entry_user_list.filter((data) => {
                return data.uid === userDocRefList[i].id;
            })
            userData.entryData = entry_user[0];
            userData.uid = entry_user[0].uid;
            userDataList.push(userData);
        }

        if (callback !== null && callback !== undefined) {
            callback(true, userDataList);
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}



/**
 * 大会に参加しているユーザーデータを取得する
 * @param {*} tournamentId  大会ID
 * @param {*} uid           ユーザーのuid
 * 
 * @param {*} callBack      終了時に呼ばれるコールバック 
 */
export async function getTournamentEntryUser(tournamentId, uid, callback) {
    let db = firebase.firestore();
    try {
        let tournament_entry_dataRef = (await db.collection('tournament_data').doc(tournamentId).collection('entry_user').where("uid", "==", uid).get());


        if (callback !== null && callback !== undefined) {
            //一人しかいないはず
            callback(true, tournament_entry_dataRef.docs[0].data());
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}



/**
 * 大会に参加しているユーザーデータの決済情報を取得する
 * @param {*} utournamentIdid       大会ID
 * @param {*} uid       ユーザーuid
 * @param {*} callback  コールバック
 */
export async function getTournamentPaymentUserData(tournamentId, uid, callback) {
    try {
        let db = firebase.firestore();
        let payment_dataDocRef = (await db.collectionGroup('payment_data').where("tournamentId", "==", tournamentId).where("uid", "==", uid).get());

        //一人しかいないはず
        let payment_data = payment_dataDocRef.docs[0].data();

        if (callback !== null && callback !== undefined) {
            callback(true, payment_data);
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * 大会に参加しているユーザーデータの決済情報を設定する
 * @param {*} utournamentIdid       大会ID
 * @param {*} uid       ユーザーuid
 * @param {*} data      保存するデータ
 * @param {*} callback  コールバック
 */
export async function setTournamentPaymentUserData(tournamentId, uid, data, callback) {
    try {
        let db = firebase.firestore();
        //一人しかいないはず
        let collectionGroupRef = await db.collectionGroup('payment_data').where("tournamentId", "==", tournamentId).where("uid", "==", uid).get();
        let entry_user_docRef = collectionGroupRef.docs[0].ref.parent.parent;
        let payment_data_docRef = collectionGroupRef.docs[0].ref;

        //動的にオブジェクトを作成したいので
        let objData = {};
        let propertyList = Object.keys(data);
        propertyList.forEach((property) => {
            //更新してほしくないプロパテぃー判定
            if (property !== "uid" && property !== "tournamentId" && property !== "version") {
                objData[property] = data[property];
            }
        })


        await db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(entry_user_docRef.id).collection('payment_data').doc(payment_data_docRef.id)
            .set(
                objData
                , {
                    merge: true
                }).then((docRef) => {
                    if (callback !== null && callback !== undefined) {
                        callback(true, "");
                    }

                }).catch(error => {
                    throw new Error(error);
                });


    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}



/**
 * 大会に参加している全てのユーザーのエントリーフラグを設定する
 * @param {*} tournamentId  大会ID
 * @param {*} entryFlg  エントリーフラグ
 * @param {*} callBack      終了時に呼ばれるコールバック 
 */
export async function setTournamentAllUserEntryFlg(tournamentId, entryFlg, callback) {
    let db = firebase.firestore();
    try {
        let tournament_dataRef = (await db.collection('tournament_data').doc(tournamentId).collection('entry_user').get());

        if (tournament_dataRef.docs.length === 0) {
            if (callback !== null && callback !== undefined) {
                callback(true, "");
            }

        } else {
            for (let i = 0; i < tournament_dataRef.docs.length; i++) {
                await db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(tournament_dataRef.docs[i].id).set({
                    entry_flg: entryFlg,
                }, {
                    merge: true
                }).then((docRef) => {
                    //                    console.log("OK");
                }).catch(error => {
                    // error
                    if (callback !== null && callback !== undefined) {
                        callback(false, error);
                    }
                });
            }

            if (callback !== null && callback !== undefined) {
                callback(true);
            }

        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}


/**
 * 大会に参加しているユーザーのステータスフラグを設定する
 * @param {*} tournamentId  大会ID 
 * @param {*} uid   ユーザーuid
 * @param {*} statusFlg 0=普通,1=失格,2=復帰
 * @param {*} callBack      終了時に呼ばれるコールバック  * 
 */
export async function setTournamentStatus(tournamentId, uid, statusFlg, callback) {
    let db = firebase.firestore();

    let tournament_dataRef = (await db.collection('tournament_data').doc(tournamentId).collection('entry_user').where("uid", "==", uid).get());
    let docs = tournament_dataRef.docs;
    //一人以上見つかったならNG
    if (docs.length > 1) {
        if (callback !== null && callback !== undefined) {
            callback(false);
        }
    } else {
        //一人しかいないはず
        db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(docs[0].id).set({
            statusFlg: statusFlg,
        }, {
            merge: true
        }).then((docRef) => {
            if (callback !== null && callback !== undefined) {
                callback(true);
            }
        }).catch(error => {
            // error
            if (callback !== null && callback !== undefined) {
                callback(false, error);
            }
        });

    }

}


/**
 * 大会データの一部を更新
 * @param {*} docId         大会ID
 * @param {*} propertyName  プロパティー名
 * @param {*} propertyData プロパティーデータ
 * @param {*} callBack      終了コールバック
 * @returns 
 */
export async function updateTournamentPart(docId, propertyName, propertyData, callback) {

    let db = firebase.firestore();

    db.collection('tournament_data').doc(docId).set({
        [propertyName]: propertyData,
    }, { merge: true })
        .then((docRef) => {
            if (callback !== null && callback !== undefined) {
                callback(true, "");
            }
        })
        .catch((error) => {
            // error
            if (callback !== null && callback !== undefined) {
                callback(false, error);
            }
        })
}

/**
 * 参加エリアのユーザーをすべて取得
 * @param {*} area 0=関東 1=関西
 * @param {*} sex 男子 女子 ジュニア
 * @param {*} callback 戻りコールバック
 * @returns 
 */
export async function getAllUsersInTheArea(areaNb, sex, callback) {
    let db = firebase.firestore();

    const users_dataRef = (await db.collection("users_data").orderBy("sex").startAt(sex).endAt(sex + "\uf8ff").where("technical_test_area", "==", areaNb).where("technical_test_flg", "==", 1).get());
    let users_dataDocs = users_dataRef.docs;

    //一人以上見つかったならNG
    if (users_dataDocs.length <= 0) {
        if (callback !== null && callback !== undefined) {
            callback(false, []);
        }
    } else {
        let userDataList = []
        for (let i = 0; i < users_dataDocs.length; i++) {
            let data = users_dataDocs[i];
            let user_data = data.data();
            user_data.uid = data.id;
            userDataList.push(user_data);
        }
        if (callback !== null && callback !== undefined) {
            callback(true, userDataList);
        }

    }

}

/**
 * 大会に参加しているユーザーにニュース発行する
 * @param {*} userDataList 
 * @param {*} title 
 * @param {*} newsText 
 * @param {*} callBack 
 */
export async function publishTournamentNewsAllUsers(userDataList, title, newsText, priority, callback) {
    try {
        let db = firebase.firestore();
        let nowDateTime = Date.now();

        let promise_results = userDataList.map((userData) => {
            let tempNewsText = newsText;
            let newsData = { version: version, title: title, news_text: tempNewsText, priority: priority, date_time: nowDateTime, view_flag: 0 }
            return db.collection("users_data").doc(userData.uid).collection("news").add(newsData);
        });
        await Promise.all(promise_results);

        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}
/**
 * 大会に参加している特定のユーザーにニュース発行する
 * @param {*} userDataList 
 * @param {*} title 
 * @param {*} newsText 
 * @param {*} callBack 
 */
export async function publishTournamentNewsUsers(uid, title, newsText, priority, callback) {
    try {
        let db = firebase.firestore();
        let nowDateTime = Date.now();

        let newsData = { version: version, title: title, news_text: newsText, priority: priority, date_time: nowDateTime, view_flag: 0 }
        await db.collection("users_data").doc(uid).collection("news").add(newsData);

        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * 大会用ユーザーデータにスコアデータを追加する
 * @param {*} data 
 * @param {*} tournament_docId 
 * @returns 
 */
export async function addScoreDataTournamentUserData(tournamentId, userCourseData, uid, callback) {
    try {
        let db = firebase.firestore();
        //バージョンを追加
        userCourseData.version = version;
        //大会のユーザーデータを設定
        let entry_user_docRef = await db.collection('tournament_data').doc(tournamentId).collection('entry_user').where("uid", "==", uid).get();

        //一人しかいないはず！！
        let entry_user_doc = entry_user_docRef.docs[0];
        //ドキュメントを自動に生成したいのでadd
        await db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(entry_user_doc.id).collection('score_data').add(userCourseData)
            .then((docRef) => {
                if (callback !== null && callback !== undefined) {
                    callback(true, "");
                }
            }).catch(error => {
                // error
                if (callback !== null && callback !== undefined) {
                    callback(false, error);
                }
            });
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * 大会を終了させる 
 * @param {*} tournamentId 
 * @param {*} fixFlg  0=未終了 1=終了
 * @param {*} callback 
 */
export async function setFixFlgTournament(tournamentId, fixFlg, callback) {
    try {
        let db = firebase.firestore();
        //大会のユーザーデータを設定
        await db.collection('tournament_data').doc(tournamentId).set({
            fix_flg: fixFlg
        }, { merge: true })
            .then((docRef) => {
                if (callback !== null && callback !== undefined) {
                    callback(true, "");
                }
            }).catch(error => {
                // error
                if (callback !== null && callback !== undefined) {
                    callback(false, error);
                }
            });
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}


/**
 * 大会の組み分けを終了させる 
 * @param {*} tournamentId 
 * @param {*} groupingFlg  組み分けフラグ0=未振り分け 1=振り分け完了
 * @param {*} callback 
 */
export async function setGroupingFlgTournament(tournamentId, groupingFlg, callback) {
    try {
        let db = firebase.firestore();
        //大会のユーザーデータを設定
        await db.collection('tournament_data').doc(tournamentId).set({
            grouping_flg: groupingFlg
        }, { merge: true })
            .then((docRef) => {
                if (callback !== null && callback !== undefined) {
                    callback(true, "");
                }
            }).catch(error => {
                // error
                if (callback !== null && callback !== undefined) {
                    callback(false, error);
                }
            });
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * 大会の最大エントリー数の更新 
 * @param {*} tournamentId 
 * @param {*} maxEntry  組み分けフラグ0=未振り分け 1=振り分け完了
 * @param {*} callback 
 */
export async function updateMaxEntryTournament(tournamentId, maxEntry, callback) {
    try {
        let db = firebase.firestore();
        //最大エントリー数を設定
        await db.collection('tournament_data').doc(tournamentId).set({
            max_entry: maxEntry
        }, { merge: true })
            .then((docRef) => {
                if (callback !== null && callback !== undefined) {
                    callback(true, "");
                }
            }).catch(error => {
                // error
                if (callback !== null && callback !== undefined) {
                    callback(false, error);
                }
            });
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * 指定した大会のスコアデータを全て取得する
 * @param {*} data 
 * @param {*} tournament_docId 
 * @returns 
 */
export async function getAllTournamentScoreData(tournamentId, callback) {
    try {
        let db = firebase.firestore();
        let tournamentData = (await db.collection('tournament_data').doc(tournamentId).get()).data();
        //組み分けが終わっているか？
        switch (tournamentData.grouping_flg) {
            case undefined:
            case 0:
                if (callback !== null && callback !== undefined) {
                    callback(true, []);
                    return;
                }
                break;
            default:
                break;
        }

        if (tournamentData.grouping_flg === undefined) {
            if (callback !== null && callback !== undefined) {
                callback(true, []);
                return;
            }
        }
        //大会のユーザーデータを設定
        let entry_user_allDocRef = await db.collection('tournament_data').doc(tournamentId).collection('entry_user').get();
        let promise_results = entry_user_allDocRef.docs.map((entry_user_DocRef) => {
            return db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(entry_user_DocRef.id).collection('score_data').get();
        })

        let score_data_allDocRef = await Promise.all(promise_results);
        let score_data_list = score_data_allDocRef.map((score_data_DocRef) => {
            //絶対に大会内にはスコアデータは一つだけのはず
            return score_data_DocRef.docs[0].data();
        });

        if (callback !== null && callback !== undefined) {
            callback(true, score_data_list);
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * スコアデータをデータベースに更新する
 * @param {*} tournamentId 
 * @param {*} uid 
 * @param {*} scoreDataList 
 * @param {*} callback 
 */
export async function updateScoreDatatoDatabase(tournamentId, uid, scoreData, callback) {
    try {
        let db = firebase.firestore();
        //大会のスコアデータドキュメントをまず取得
        let score_data_docRef = await db.collectionGroup('score_data').where("tournamentId", "==", tournamentId).where("uid", "==", uid).get();

        //一人しかいないはず！！
        let score_data_doc = score_data_docRef.docs[0];
        let entry_user_id = score_data_doc.ref.parent.parent.id;
        //ドキュメントを自動に生成したいのでadd
        await db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(entry_user_id).collection('score_data').doc(score_data_doc.id).set({
            eliminationFlg: scoreData.eliminationFlg,
            ranking: scoreData.ranking,
            score_data_list: scoreData.score_data_list,
            user_score: scoreData.userScore,
            prize_money: scoreData.prizeMoney             //賞金
        }, { merge: true })
            .then((docRef) => {
                if (callback !== null && callback !== undefined) {
                    callback(true, "");
                }
            }).catch(error => {
                // error
                if (callback !== null && callback !== undefined) {
                    callback(false, error);
                }
            });
    } catch (error) {
        console.log(error);
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}



/**
 * ユーザーのニュースを取得
 * @param {*} userDataList 
 * @param {*} newsText 
 * @param {*} callBack 
 */
export async function getUsersNews(uid, callback) {
    try {
        let db = firebase.firestore();

        //ニュースをすべて取得
        await db.collection("users_data").doc(uid).collection("news").get(
        ).then((decsRef) => {
            let userNewsList = decsRef.docs.map((doc) => {
                let userNews = doc.data();
                userNews.id = doc.id;
                return userNews;
            });


            //開始日時で昇順ソート
            userNewsList = sortArray(userNewsList, "date_time");
            if (callback !== null && callback !== undefined) {
                callback(true, userNewsList);
            }
        }).catch(error => {
            if (callback !== null && callback !== undefined) {
                callback(false, error);
            }
        });

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}


/**
 * ユーザーのニュースを設定する
 * @param {*} userDataList 
 * @param {*} newsText 
 * @param {*} callBack 
 */
export async function setUsersNews(uid, newsData, callback) {
    try {
        let db = firebase.firestore();
        if (newsData.hasOwnProperty("id") === false ||
            newsData.hasOwnProperty("title") === false ||
            newsData.hasOwnProperty("date_time") === false ||
            newsData.hasOwnProperty("news_text") === false ||
            newsData.hasOwnProperty("priority") === false ||
            newsData.hasOwnProperty("version") === false ||
            newsData.hasOwnProperty("view_flag") === false) {
            if (callback !== null && callback !== undefined) {
                callback(false, "No properties");
            }
        } else {
            //ニュースを更新します
            await db.collection("users_data").doc(uid).collection("news").doc(newsData.id).update(newsData
            ).then((decsRef) => {
                if (callback !== null && callback !== undefined) {
                    callback(true, "");
                }
            }).catch(error => {
                if (callback !== null && callback !== undefined) {
                    callback(false, error);
                }
            });

        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}


/**
 * お知らせのテンプレートを読み込み
 * @param {*} templateNb 0=振り分け 1=組み分け 2=スコア
 * @param {*} callback 
 */
export async function getAnnouncementNewsTemplate(templateNb, callback) {
    try {
        let db = firebase.firestore();
        let docName = "";
        switch (templateNb) {
            case 0:
                docName = "news_allocation";
                break;
            case 1:
                docName = "news_grouping";
                break;
            case 2:
                docName = "news_score";
                break;
            default:
                break;
        }

        if (callback !== null && callback !== undefined) {
            let news_template = await db.collection("management_work").doc(docName).get();
            callback(true, news_template.data());
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }

}

/**
 * お知らせのテンプレートを更新
 * @param {*} templateNb 0=振り分け 1=組み分け 2=スコア
 * @param {*} callback 
 */
export async function updateAnnouncementNewsTemplate(templateNb, textData, callback) {
    try {
        let db = firebase.firestore();
        let docName = "";
        switch (templateNb) {
            case 0:
                docName = "news_allocation";
                break;
            case 1:
                docName = "news_grouping";
                break;
            case 2:
                docName = "news_score";
                break;
            default:
                break;
        }

        await db.collection("management_work").doc(docName).update(textData
        ).then((decsRef) => {
            if (callback !== null && callback !== undefined) {
                callback(true, "");
            }
        }).catch(error => {
            if (callback !== null && callback !== undefined) {
                callback(false, error);
            }
        });
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }

}

/**
 * お知らせのテンプレートを設定
 * @param {*} templateNb 0=振り分け 1=組み分け 2=スコア
 * @param {*} callback 
 */
export async function setAnnouncementNewsTemplate(templateNb, textData, callback) {
    try {
        let db = firebase.firestore();
        let docName = "";
        switch (templateNb) {
            case 0:
                docName = "news_allocation";
                break;
            case 1:
                docName = "news_grouping";
                break;
            case 2:
                docName = "news_score";
                break;
            default:
                break;
        }

        let newsDataDoc = (await db.collection("management_work").doc(docName).get());
        if (newsDataDoc.exists === false) {
            await db.collection("management_work").doc(docName).set(textData
            ).then((decsRef) => {
                if (callback !== null && callback !== undefined) {
                    callback(true, "");
                }
            }).catch(error => {
                if (callback !== null && callback !== undefined) {
                    callback(false, error);
                }
            });

        } else {
            if (callback !== null && callback !== undefined) {
                callback(true, "");
            }
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }

}

//-----------------------------------------------------------------------------
/**
 * uidDocリストからuidに対応したdosリストを取得
 * @param {*} collectionName 
 * @param {*} docList 
 * @returns 
 */
export async function getUidDocList(collectionName, getUidDocList) {
    let db = firebase.firestore();
    let loopCount = Math.floor(getUidDocList.length / 10);
    //余りが出た場合+1
    loopCount += Math.floor(getUidDocList.length % 10) !== 0 ? 1 : 0;
    let getUidDocListList = [];

    for (let i = 0; i < loopCount; i++) {
        getUidDocListList[i] = [];
        for (let j = 0; j < 10; j++) {
            if (getUidDocList.length > (i * 10 + j)) {
                getUidDocListList[i].push(getUidDocList[i * 10 + j]);
            } else {
                //終わり
                break;
            }
        }
    }
    let uidDocRefList = [];
    let paht = firebase.firestore.FieldPath.documentId();
    for (let i = 0; i < loopCount; i++) {
        //リスト内のdoc全てを取得できるみたい
        let uidDocRefList10 = await db.collection(collectionName)
            .where(
                paht,
                'in',
                getUidDocListList[i]        //リストは仕様で10個までしかだめらしいので2次元配列で対応＞＜
            ).get();
        uidDocRefList = uidDocRefList.concat(uidDocRefList10.docs);
    }
    return uidDocRefList;
}


/**
 * 連想配列の指定キーの値が重複する場合は削除する
 *
 * @param {array} ary* 連想配列
 * @param {string} key* 重複判別するキー
 * @return {array} 処理後のary
 */
export const getArrayDeleteDouble2 = (ary, key) => {
    let map = new Map(ary.map(o => [o[key], o]));
    return Array.from(map.values());
}

/**
 * エリア文字から番号を取得
 * @param {*} areaName 
 * @returns 0=関東,1=関西
 */
export const getAreaNb = (areaName) => {
    let areaNb = 0;
    switch (areaName) {
        case "関東":
            areaNb = 0;
            break;
        case "関西":
            areaNb = 1;
            break;
        default:
            areaNb = 0;
            break;
    }
    return areaNb;

}



//-----------------------------------------------------------------------------
/**
 * functionテスト
 */
export function testFunction(apiName, data, callback) {
    const functions = firebase.functions();
    const func = functions.httpsCallable(apiName);

    let uid = "pitLPxZpQk2ko2ernnF7";
    func(
        {
            uid: uid,
            OrderID: "38614781113083611632423545",
            DevelopmentFlg: 1,
            tournamentId: "PI7BkF5JkMqAqzqdU7hr",
            entryFlg: 1,
            payment_data: { order_id: 987698769, uid: "asdfasdf", tournamentId: "aaaaaaaaaaaaaaaaaa" }
        }).then(res => {
            if (res.data.status === 200) {
                if (callback !== null && callback !== undefined) {
                    console.error("OKOK");
                    callback(true, res.data);
                }
            } else {
                if (callback !== null && callback !== undefined) {
                    console.error("NGNG");
                    callback(false, res.data);
                }

            }
        }).catch(e => {
            console.error(e);
            if (callback !== null && callback !== undefined) {
                callback(false, "");

            }
            alert("通信エラーが発生しました通信状態をご確認ください");
        });

}

/**
 * ユーザーをコピー
 * @param {*} uid 
 * @param {*} counterName 
 * @returns 
 */
export async function setCopyUser(uid, counterName) {
    let db = firebase.firestore();
    let userData = (await db.collection('users_data').doc(uid).get()).data();

    counterName = ('0000' + counterName).slice(-4);           //4桁にする

    userData.given_name_kana = userData.given_name_kana + "_" + counterName;
    userData.given_name_kanji = userData.given_name_kanji + "_" + counterName;
    userData.serial_nb = "SN" + autoId();
    let randomFlg = Math.floor(Math.random() * 3);   //0-2
    //三分の一の確率で参加フラグを立てる
    if (randomFlg === 1) {
        userData.technical_test_flg = 1;
    } else {
        userData.technical_test_flg = 0;
    }
    const res = await db.collection('users_data').add(userData);
    return res.id;

}

function autoId() {
    const chars =
        '0123456789';
    let autoId = '';
    for (let i = 0; i < 16; i++) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return autoId;
}


/**
 * テストユーザーデータを全て削除
 * @param {*} data 
 */
export async function deleteTestUser(callback) {
    let db = firebase.firestore();
    try {
        let docs = (await db.collection('users_data').get()).docs;

        let promise_results = docs.map(async (doc) => {
            if (doc.id.length < 22) {
                //お知らせも削除
                let newsDocs = await db.collection('users_data').doc(doc.id).collection('news').get();
                let promise_results = newsDocs.docs.map((doc) => {
                    return db.collection('users_data').doc(doc.id).collection('news').doc(doc.id).delete();
                })
                await Promise.all(promise_results);

                return db.collection('users_data')
                    .doc(doc.id)
                    .delete();
            }
            return db.collection('users_data').doc(doc.id).get();
        })

        await Promise.all(promise_results);

        if (callback !== null && callback !== undefined) {
            callback(true);
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false);
        }
    }
}


/**
 * 全てのユーザーのニュース削除
 * @param {*} userDataList 
 * @param {*} newsText 
 * @param {*} callback 
 */
export async function publishTournamentNewsAllUsersDel(callback) {
    try {
        let db = firebase.firestore();
        let collectionGroupRef = await db.collectionGroup('news').get();

        let collectionGroupRef_docList = collectionGroupRef.docs;

        let promise_results = collectionGroupRef_docList.map(async (data) => {
            return db.collection("users_data").doc(data.ref.parent.parent.id).collection("news").doc(data.id).delete();
        })
        await Promise.all(promise_results);

        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * トーナメントカウントを初期化
 * @param {*} callback 
 */
export async function initAllUsersTourCount(callback) {
    try {
        let db = firebase.firestore();

        let docs = (await db.collection('users_data').get()).docs;

        let promise_results = docs.map((doc) => {

            let userData = doc.data();
            userData.tour_counts = [0, 0];
            return db.collection('users_data')
                .doc(doc.id)
                .update(userData);
        });

        await Promise.all(promise_results);

        if (callback !== null && callback !== undefined) {
            callback(true);
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false);
        }
    }
}

/**
 * デバッグフラグの読み込み
 * @param {*} data 
 * @param {*} tournament_docId 
 * @returns 
 */
export async function getDebugFlg(callback) {
    try {
        let db = firebase.firestore();
        let debugFlg = (await db.collection('management_work').doc("debug_flg").get()).data();

        if (callback !== null && callback !== undefined) {
            callback(true, debugFlg);
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}


/**
 * デバッグフラグの設定
 * @param {*} data 
 * @param {*} tournament_docId 
 * @returns 
 */
export async function setDebugFlg(callback) {
    try {
        let db = firebase.firestore();
        let debugFlg = { debug_flg: "0" }
        let debug_flg_doc = (await db.collection('management_work').doc("debug_flg").get());
        if (debug_flg_doc.exists === false) {
            await db.collection('management_work').doc("debug_flg").set(debugFlg
            ).then((decsRef) => {
                if (callback !== null && callback !== undefined) {
                    callback(true, "");
                }
            }).catch(error => {
                if (callback !== null && callback !== undefined) {
                    callback(false, error);
                }
            });
        } else {
            if (callback !== null && callback !== undefined) {
                callback(true, debugFlg);
            }
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}



/**
 * 全ての大会のスコアをランダムで設定する
 * @param {*} userDataList 
 * @param {*} newsText 
 * @param {*} callback 
 */
export async function setAllTournamentScore(callback) {
    try {
        let db = firebase.firestore();
        let tournamentDocsRef = await db.collection('tournament_data').get();

        let promise_results = tournamentDocsRef.docs.map(async (docRef) => {
            let score_dataGroupRefList = await db.collectionGroup('score_data').where("tournamentId", "==", docRef.id).get();
            let promise_results2 = score_dataGroupRefList.docs.map(async (score_dataDocsRef) => {
                let tournamentId = docRef.id;
                let entry_userId = score_dataDocsRef.ref.parent.parent.id;
                let score_dataId = score_dataDocsRef.id;


                let score_data = score_dataDocsRef.data();
                let score_data_list = score_data.score_data_list.map((score_data) => {
                    let at_bat = Number(score_data.at_bat);
                    var min = 1;
                    var max = at_bat + 3;
                    let user_at_bat = Math.floor(Math.random() * (max + 1 - min)) + min;
                    score_data.user_at_bat = user_at_bat;
                    return score_data;
                });
                //大会別スコアデータ
                await db.collection('tournament_data').doc(tournamentId).collection('entry_user').doc(entry_userId).collection('score_data').doc(score_dataId).set({
                    score_data_list: score_data_list
                }, { merge: true })
                    .then((docRef) => {
                    }).catch(error => {
                        throw new Error("ランダムスコアの設定に失敗しました=" + error);

                    });

            });
            let score_data = await Promise.all(promise_results2);


            console.log(score_data);
            return docRef;
        })
        let score_data = await Promise.all(promise_results);

        console.log(score_data);
        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}


/**
 * 大会データにホール数を追加する（number_holesの追加）
 * @param {*} userDataList 
 * @param {*} newsText 
 * @param {*} callback 
 */
export async function addNumberHolesTournament(callback) {

    try {
        let db = firebase.firestore();
        //全てのトーナメント
        let tournamentDocsRef = await db.collection('tournament_data').get();
        let promise_results = tournamentDocsRef.docs.map(async (docRef) => {
            let tournamentDataDoc = (await db.collection('tournament_data').doc(docRef.id).get());
            //大会のデータがそもそもない場合は終了
            if (tournamentDataDoc.exists === false) {
                return docRef;
            }

            let tournamentData = tournamentDataDoc.data();
            //すでにnumber_holesがある場合は何もしない
            if (tournamentData.number_holes !== undefined) {
                return docRef;
            }

            let courseData = (await db.collection('course_data').doc(tournamentData.golfCourseDocId).get()).data();
            let numberHoles = 0;
            tournamentData.useCourseList.forEach((useCourse, index) => {
                let course = courseData.arrayCourseList.filter((arrayCourse) => {
                    //大会で使用するコースを探す
                    return arrayCourse.courseName === useCourse.courseName;
                });
                //見つかるのは一つのコースだけなので最初のホール数を加算
                numberHoles += course[0].holeList.length;
            });

            //大会データにホール数を追加
            await db.collection('tournament_data').doc(docRef.id).set({
                number_holes: numberHoles
            }, { merge: true })
                .then((docRef) => {
                }).catch(error => {
                    throw new Error("大会データにホール数の設定に失敗しました=" + error);

                });

            return docRef;
        });

        await Promise.all(promise_results);
        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}


/**
 * 大会データに延期フラグと延期状態フラグを追加（postponed_flgとdate_undecided_flgの追加）
 * @param {*} callback 
 */
export async function addPostponedFlgAndDateUndecidedFlgTournament(callback) {

    try {
        let db = firebase.firestore();
        //全てのトーナメント
        let tournamentDocsRef = await db.collection('tournament_data').get();
        let promise_results = tournamentDocsRef.docs.map(async (docRef) => {
            let tournamentDataDoc = (await db.collection('tournament_data').doc(docRef.id).get());
            //大会のデータがそもそもない場合は終了
            if (tournamentDataDoc.exists === false) {
                return docRef;
            }

            let tournamentData = tournamentDataDoc.data();
            //すでにpostponed_flgかつdate_undecided_flgがある場合は何もしない
            if (tournamentData.postponed_flg !== undefined && tournamentData.date_undecided_flg) {
                return docRef;
            }

            //大会データに延期フラグを追加
            await db.collection('tournament_data').doc(docRef.id).set({
                postponed_flg: 0,
                date_undecided_flg: 0
            }, { merge: true })
                .then((docRef) => {
                }).catch(error => {
                    throw new Error("延期フラグの設定に失敗しました=" + error);

                });

            return docRef;
        });

        await Promise.all(promise_results);
        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * 大会データの延期状態フラグを削除（date_undecided_flgの削除）
 * @param {*} callback 
 */
export async function deleteDateUndecidedFlgTournament(callback) {

    try {
        let db = firebase.firestore();
        //全てのトーナメント
        let tournamentDocsRef = await db.collection('tournament_data').get();
        let promise_results = tournamentDocsRef.docs.map(async (docRef) => {
            let tournamentDataDoc = (await db.collection('tournament_data').doc(docRef.id).get());
            //大会のデータがそもそもない場合は終了
            if (tournamentDataDoc.exists === false) {
                return docRef;
            }

            let tournamentData = tournamentDataDoc.data();
            //すでにdate_undecided_flgが削除されていたら何もしない
            if (tournamentData.date_undecided_flg === undefined) {
                return docRef;
            }

            //大会データの延期状態フラグを削除
            await db.collection('tournament_data').doc(docRef.id).update({
                date_undecided_flg: firebase.firestore.FieldValue.delete()

            })
                .then((docRef) => {
                }).catch(error => {
                    throw new Error("延期状態フラグ削除に失敗しました=" + error);

                });

            return docRef;
        });

        await Promise.all(promise_results);
        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}



/**
 * delete_flgがbooleになっている全てのユーザーを数値に変更する
 * @param {*} userDataList 
 * @param {*} newsText 
 * @param {*} callback 
 */
export async function changeAllUsersDeleteFlgBoolean_to_number(callback) {
    try {
        let db = firebase.firestore();
        let collectionDodsRef = await db.collection('users_data').get();

        let collectionDodsRef_docList = collectionDodsRef.docs;

        let promise_results = collectionDodsRef_docList.map(async (doc) => {
            let userData = (await db.collection("users_data").doc(doc.id).get()).data();
            if (userData.delete_flg === false || userData.delete_flg === true) {
                await db.collection("users_data").doc(doc.id).set({
                    delete_flg: userData.delete_flg ? 1 : 0,
                }, { merge: true })
                    .then((docRef) => {
                    }).catch(error => {
                        throw new Error("削除フラグの変更に失敗しました=" + error);

                    });
            }
            return userData;
        })
        await Promise.all(promise_results);

        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}


/**
 * 全ての大会の延期フラグを設定
 * @param {*} postponedFlg   0=通常、1=延期
 * @param {*} dateUndecidedFlg  0=通常延期 1=未定
 * @param {*} callback 
 */
export async function setAllTournamentCancellationPostponement(postponedFlg, dateUndecidedFlg, callback) {
    let db = firebase.firestore();
    try {
        let tournament_dataDocsRef = (await db.collection('tournament_data').get());


        let promise_results = tournament_dataDocsRef.docs.map(async (doc) => {
            let tournament_dataDocRef = (await db.collection('tournament_data').doc(doc.id).get());

            //延期フラグの更新
            await updateTournamentPart(tournament_dataDocRef.id, "postponed_flg", postponedFlg, async (result, status) => {
                if (result === false) {
                    throw new Error("延期フラグの更新に失敗しました=" + status);
                }
            });
            //延期状態フラグの更新
            await updateTournamentPart(tournament_dataDocRef.id, "date_undecided_flg", dateUndecidedFlg, async (result, status) => {
                if (result === false) {
                    throw new Error("延期状態フラグの更新に失敗しました=" + status);
                }
            });

            return doc;
        });

        await Promise.all(promise_results);

        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }

    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}



