import React, { useEffect, useState } from 'react';
import CourseStep3TeePositionComponent from './CourseStep3TeePositionComponent'
import '../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";

function CourseAddStep3HoleAndAtBatContainer(props) {
    const [holeName, setCourseName] = useState(props.nowHoleAndAtBatData.name);
    const [atBat, setAtBat] = useState(props.nowHoleAndAtBatData.atBat);
    const [teePositionList, setTeePositionList] = useState(props.nowHoleAndAtBatData.teePositionList);

    useEffect(() => {


    }, []);

    const onChangeHoleNameInput=(e)=>{
        props.nowHoleAndAtBatData.name=e.target.value;
    }

    const onChangeAtBatInput=(e)=>{
        props.nowHoleAndAtBatData.atBat=e.target.value;
    }


    return (
        <div className="c-container-horizontal">    {/*<!-- 「ホール名」と「規定打数」コンテナ -->*/}

            {/*<!-- 「ホール名」内容 -->*/}
            <div className="game-data__column">
                <input type="number"className="game-data__slot game-data__slot--edit" defaultValue={holeName} onChange={(e)=>{onChangeHoleNameInput(e)}}/>
            </div>
            {/*<!-- 「規定打数」内容 -->*/}
            <div className="game-data__column">
                <input type="number" className="game-data__slot game-data__slot--edit" defaultValue={atBat} onChange={(e)=>{onChangeAtBatInput(e)}}/>
            </div>

            <div className="c-container-vertical">   {/*<!-- 「規定打数」の子要素 -->*/}
                {/*!-- 「規定打数」の子要素 --> */}
                {/*動的にコンポーネントが増えていきます*/}
                {teePositionList.map(teeData => (
                    <CourseStep3TeePositionComponent
                        key={teeData.key}
                        nowTeeData={teeData}
                    />
                ))}
            </div>
        </div>


    )
}



export default CourseAddStep3HoleAndAtBatContainer

