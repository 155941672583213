import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { ScoreContext } from './ScoreProvider'
import ManagementFooter from '../ManagementFooter'
import logo from "../../images/logo.svg";
import { IconScore } from "../Icons";
import '../../styles/styles.css';
import '../../styles/icomoon.css';
import { getTotalUserAtBat, getTotalAtBat, getUserScore } from "./ScoreUtility";
import '../../styles/loader.css'
import Loader from '../Loader'
import Score009ScoreContent from './Score_009_ScoreContent'

function Score_009_Accordion(props) {

    const [Score_009_ScoreContentList, setScore_009_ScoreContentList] = useState(undefined);
    const [selectOpenAccordionNb, setSelectOpenAccordionNb] = useState(0);
    const [classNameScoreAccordion] = useState(["c-container-horizontal", "c-container-horizontal score-accordion--active"]);
    const [classNameSoreAccordionContent] = useState(["score-accordion__content", "score-accordion__content score-accordion__content--extended"]);

    const [userScoreData, setUserScoreData] = useState(props.userScoreData);

    const [ranking, setRanking] = useState("-");    //ランキング
    const [serial_nb, setSerial_nb] = useState("-");  //シリアル番号
    const [full_name_kanji, setFull_name_kanji] = useState("-"); //氏名（漢字）
    const [full_name_kana, setFull_name_kana] = useState("-");   //氏名（カナ）
    const [total_user_at_bat, setTotal_user_at_bat] = useState("-");       //ユーザーのトータル打数
    const [phone_number, setPhone_number] = useState("-");       //電話番号
    const [total_at_bat, setTotal_at_bat] = useState("-");       //コースのトータル打数

    const [user_score, setUser_score] = useState(0);       //コースのトータル打数

    const [inputId] = useState("score_confirm_player_" + props.userScoreData.userData.serial_nb);       //インプットのID

    const [selectColumnClassNameNb, setSelectColumnClassNameNb] = useState(0);
    //0=通常 1=未入力 2=失格
    const [columnClassName] = useState(["c-data__slot--pale-spring-bud", "c-data__slot--score-not-defined", "c-data__slot--elimination"]);

    const [selectColumnClassNameChangeInputNb, setSelectColumnClassNameChangeInputNb] = useState(0);
    //0=通常 1=変更
    const [columnClassNameChangeInput] = useState(["", "c-data__slot--confirm"]);

    useEffect(() => {
        if (userScoreData.eliminationFlg === 1) {
            setRanking("失格");
        } else {
            setRanking(userScoreData.ranking);
        }
        setSerial_nb(userScoreData.userData.serial_nb);
        setFull_name_kanji(userScoreData.userData.given_name_kanji + userScoreData.userData.family_name_kanji);
        setFull_name_kana(userScoreData.userData.given_name_kana + userScoreData.userData.family_name_kana);

        if (userScoreData.change_flg === true) {
            setSelectColumnClassNameChangeInputNb(1);
        } else {
            setSelectColumnClassNameChangeInputNb(0);

        }
        setTotal_user_at_bat(userScoreData.totalUserAtBat);



        setPhone_number(userScoreData.userData.phone_number);
        setScore_009_ScoreContentList(userScoreData.score_data_list);

        let totalAtBat = getTotalAtBat(userScoreData.score_data_list);
        setTotal_at_bat(totalAtBat);

        let userScore = getUserScore(userScoreData.score_data_list);
        //お知らせでスコアが必要なので
        userScoreData.userScore = userScore;
        setUser_score(userScore);

        //失格ならinputチェックBOXをチェックする
        if (userScoreData.eliminationFlg === 1) {
            //チェックBOXをチェック
            document.getElementById(inputId).checked = true;
            setSelectColumnClassNameNb(2);
        } else {
            //チェックBOXをノンチェック
            document.getElementById(inputId).checked = false;
            //失格確認
            //未入力なら
            if (userScoreData.notEntered === 1) {
                setSelectColumnClassNameNb(1);
            } else {
                setSelectColumnClassNameNb(0);
            }

        }
        //インプットを非活性にする
        document.getElementById(inputId).disabled = true;

    }, [inputId, userScoreData.change_flg, userScoreData.eliminationFlg, userScoreData.notEntered, userScoreData.ranking, userScoreData.score_data_list, userScoreData.totalUserAtBat, userScoreData.userData.family_name_kana, userScoreData.userData.family_name_kanji, userScoreData.userData.given_name_kana, userScoreData.userData.given_name_kanji, userScoreData.userData.phone_number, userScoreData.userData.serial_nb]);



    /**
     * 各ホールのスコア表示[+]又は[-]を押したときのボタンイベント
     * @param {*} e 
     */
    const onClickButton = (e) => {
        e.preventDefault();
        //設定するクラスのインデックス番号を決定する
        setSelectOpenAccordionNb((data) => {
            data ^= 1;
            return data;
        })

    }

    const onClickInput = (e) => {
        let result = e.target.checked;
        //eliminationFlg失格フラグの設定
        if (result === false) {
            //失格ではない
            userScoreData.eliminationFlg = 0;

        } else {
            //失格
            userScoreData.eliminationFlg = 1;

        }
        props.callback(e);
    }


    //------------------------------------------------------
    const renderScoreContentList = () => {

        if (Score_009_ScoreContentList !== undefined) {
            return Score_009_ScoreContentList.map((data, i) => {
                return <Score009ScoreContent key={"Score009ScoreContent_" + i} holeData={data} keyId={"ScoreContent" + userScoreData.uid + "_" + i} inputCallback={props.inputCallback}></Score009ScoreContent>
            })
        } else {
            return null;
        }
    }

    return (
        <div className="score-accordion">
            {/*<!-- アコーディオン閉じていると表示されてる選手情報。 -->*/}
            <div className={classNameScoreAccordion[selectOpenAccordionNb]}> {/*score-accordion--activeこれを加えると -*/}
                {/*<!-- 普通状態です。 -->*/}
                <div className={"c-data__slot " + columnClassName[selectColumnClassNameNb]} id="ranking">{ranking}</div>
                <div className={"c-data__slot c-data__slot--232 " + columnClassName[selectColumnClassNameNb]} id="serial_nb">{serial_nb}</div>
                <div className={"c-data__slot c-data__slot--232 " + columnClassName[selectColumnClassNameNb]} id="full_name_kanji">{full_name_kanji}</div>
                <div className={"c-data__slot c-data__slot--232 " + columnClassName[selectColumnClassNameNb]} id="full_name_kana" >{full_name_kana}</div>
                <div className={"c-data__slot " + columnClassName[selectColumnClassNameNb]} id="total_at_bat">{total_user_at_bat}</div>
                <div className={"c-data__slot  c-data__slot--144 " + columnClassName[selectColumnClassNameNb]} id="phone_number">{phone_number}</div>
                <button className={"score-accordion__button button button__data c-data__slot c-data__slot--144 c-data__slot--center " + columnClassName[selectColumnClassNameNb]} onClick={(e) => { onClickButton(e) }}></button>
                <div className={"score-accordion__checkbox c-data__slot c-data__slot--center " + columnClassName[selectColumnClassNameNb]}>
                    <input type="checkbox" name={inputId} id={inputId} className="score-accordion__checkbox-input" onClick={(e) => { onClickInput(e) }} />
                    <label htmlFor={inputId} className="score-accordion__checkbox-label" style={{ opacity: 0.6 }}></label>
                </div>

            </div>
            {/*<!-- アコーディオン開くと表示する情報。 -->*/}
            <div className={classNameSoreAccordionContent[selectOpenAccordionNb]}> {/*ここのクラスに　score-accordion__content--extended を設定すると出てくる*/}

                <div className="score-accordion__score-wrapper">
                    {/*<!-- タイトル行 -->*/}
                    <div className="c-container-horizontal c-mt32">
                        <div className="c-data__title c-data__title--short-height">ホール数</div>
                        <div className="c-data__title c-data__title--short-height">パー</div>
                        <div className="c-data__title c-data__title--short-height">打数</div>
                        <div className="c-data__title c-data__title--short-height">変更する打数</div>
                        <div className="c-data__title c-data__title--144 c-data__title--short-height">スコア記入者名</div>
                    </div>

                    {/*<!-- スコア内容 -->*/}
                    <div className="c-container-vertical">
                        {/*ここにスコアを複数レンダリング*/}
                        {renderScoreContentList()}
                    </div> {/*<!-- スコア内容終了 -->*/}

                    {/*<!-- 合計エリア -->*/}
                    <div className="c-container-horizontal">
                        <div className="c-data__slot c-data__slot--white">合計</div>
                        <div className="c-data__slot c-data__slot--white">{total_at_bat}</div>
                        <div className={"c-data__slot c-data__slot--white " + columnClassNameChangeInput[selectColumnClassNameChangeInputNb]}>{total_user_at_bat}</div>
                    </div>
                    {/*<!-- スコアエリア -->*/}
                    <div className="c-container-horizontal">
                        <div className="c-data__slot c-data__slot--178 c-data__slot--white">スコア</div>
                        <div className={"c-data__slot c-data__slot--white " + columnClassNameChangeInput[selectColumnClassNameChangeInputNb]}>{user_score}</div>
                    </div>
                </div>
            </div>

        </div >
    )
}


export default Score_009_Accordion

