import React, { useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom"
import { CourseContext } from '../CourseProvider'
import CourseHeader from '../CourseHeader'
import ManagementFooter from '../../ManagementFooter'
import logo from "../../../images/logo.svg";
import '../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconCourse, IconGolfBall } from "../../Icons";

function CourseAddStep1(props) {
    const [value, setValue] = useContext(CourseContext);
    const history = useHistory();
    useEffect(() => {
        document.getElementsByTagName("*")["club_name"].value = value.CourseAddStep1.club_name;
        document.getElementsByTagName("*")["club_address"].value = value.CourseAddStep1.club_address;
        document.getElementsByTagName("*")["club_phone"].value = value.CourseAddStep1.club_phone;
        document.getElementsByTagName("*")["club_website"].value = value.CourseAddStep1.club_website;
    }, []);

    useEffect(() => {

    }, [value]);

    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "button_back":
                history.push("Course");
                break;
            default:
                break;
        }
    };

    //検索処理
    const onSearchSubmit = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();

        const { club_name, club_address, club_phone, club_website } = e.target.elements;
        let inputData = {
            club_name: club_name.value,
            club_address: club_address.value,
            club_phone: club_phone.value,
            club_website: club_website.value,
        };
        value.CourseAddStep1 = inputData;
        setValue(value);
        history.push("CourseAddStep2");


    };

    return (
        <div className="c-screen c-screen--gunmetal">
            <CourseHeader stepNb={"step1"}></CourseHeader>
            <main>

                <section className="c-page-section c-mt16">

                    <div className="kanri-container">

                        <form onSubmit={onSearchSubmit}>

                            <div className="input input__set input__set--kanri">
                                <label htmlFor="" className="input input__label">ゴルフ場名</label>
                                <input type="text" name="club_name" id="club_name" minlenght="2" placeholder="ゴルフ場名を入力してください" autoComplete="" required className="input input__field" />
                                <span className="input input__validation input__validation--display">ゴルフ場名を入力してください</span>
                            </div>

                            <div className="input input__set input__set--kanri">
                                <label htmlFor="" className="input input__label">住所</label>
                                <input type="text" name="club_address" id="club_address" minlenght="2" placeholder="住所を入力してください。" autoComplete="" required className="input input__field" />
                                <span className="input input__validation input__validation--display">住所を入力してください。</span>
                            </div>

                            <div className="input input__set input__set--kanri">
                                <label htmlFor="" className="input input__label">電話番号</label>
                                <input type="tel" name="club_phone" id="club_phone" pattern="[0-9]{3,11}" autoComplete="tel" placeholder="電話番号をいれてください。" required className="input input__field" />
                                <span className="input input__validation input__validation--display">電話番号をいれてください。</span>
                            </div>

                            <div className="input input__set input__set--kanri">
                                <label htmlFor="" className="input input__label">公式サイト</label>
                                <input type="text" name="club_website" id="club_website" minlenght="2" placeholder="公式サイトのURLをいれてください。" autoComplete="" required className="input input__field" />
                                <span className="input input__validation input__validation--display">公式サイトのURLをいれてください。</span>
                            </div>


                            <div className="c-button-set c-mb88">
                                <button id="button_next" className="button button__primary" type="submit" >次へ</button>
                                <button id="button_back" onClick={(e) => { onButtonClick(e) }} className="button button__secondary">戻る</button>
                            </div>
                        </form>

                    </div>

                </section>

            </main>

            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default CourseAddStep1

