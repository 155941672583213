import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom"
import { originalInitialState, CourseContext } from '../../CourseProvider'
import lodash from 'lodash';
import ManagementFooter from '../../../ManagementFooter'
import CourseEditStep3BasicInfo from './CourseEditStep3BasicInfo'
import CourseEditHeader from '../CourseEditHeader'
import CourseEditStep3ColumnTitleContainer from './CourseEditStep3ColumnTitleContainer'
import CourseEditStep3CourseContainer from './CourseEditStep3CourseContainer'
import { updateCourse } from "../../../../Database";
import '../../../../styles/styles.css';
import { makeCourseStep4CmpData } from '../../CourseUtility'
import Loader from '../../../Loader'

function CourseEditStep3(props) {
    const [value, setValue] = useContext(CourseContext);
    const history = useHistory();
    const [arrayCourseList, setArrayCourseList] = useState([]);
    const [loadingData, setLoadingData] = useState(undefined);

    useEffect(() => {
        window.scrollTo(0, 0);
        makeCourseStep4CmpData(value);
        setArrayCourseList([...value.CourseAddStep4.arrayCourseList]);

    }, []);

    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "button_create":
                const saveDataBase = (async () => {
                    await updateCourse({ ...value.CourseAddStep1, ...value.CourseAddStep3 }, value.selectCourseData.id);
                    //初期化する
                    setValue(lodash.cloneDeep(originalInitialState));
                    //ローディング非表示
                    setLoadingData({ loadingFlg: false, title: "ゴルフ場データ更新中..." });

                    history.push("CourseEditCompleted");
                });
                //ローディング表示
                setLoadingData({ loadingFlg: true, title: "ゴルフ場データ更新中..." });

                saveDataBase();
                break;
            case "button_edit":
                history.push("CourseEditStep2");
                break;
            default:
                break;
        }
    };

    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <CourseEditHeader stepNb={"step4"}></CourseEditHeader>
            <main>
                <section className="c-page-section c-mt16">

                    <h2 className="c-heading--2 c-mt32">下記内容でゴルフ場を登録します。よろしいですか？</h2>
                    <CourseEditStep3BasicInfo courseData={value}></CourseEditStep3BasicInfo>
                    <h2 className="c-heading--2 c-mt56">ホール名、規定打数、Yard数。</h2>
                    <div className="c-container-vertical c-mt16">
                        {/*動的にコンポーネントが増えていきます*/}
                        {
                            arrayCourseList.map(
                                courseAddItem =>
                                (
                                    <React.Fragment key={courseAddItem.key + "_React.Fragment"}>
                                        <CourseEditStep3ColumnTitleContainer key={courseAddItem.key + "_CourseEditStep3ColumnTitleContainer"}></CourseEditStep3ColumnTitleContainer>
                                        <CourseEditStep3CourseContainer key={courseAddItem.key} nowCourse={courseAddItem} />
                                    </React.Fragment>
                                )
                            )
                        }
                    </div> {/*<!-- メインコンテナ -->*/}
                    <div id="add-course-button" className="c-button-set c-mt32 c-mb88">
                        <button id="button_create" onClick={(e) => { onButtonClick(e) }}
                            className="button button__primary">作成</button>
                        <button id="button_edit" onClick={(e) => { onButtonClick(e) }}
                            className="button button__secondary">修正</button>
                    </div>
                </section>
            </main>
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default CourseEditStep3

