import { addPaymentTournamentUserData, getCourse, addScoreDataTournamentUserData, publishTournamentNewsUsers, updateUsersTourCount, deleteUserTournament, subUsersTourCount, updateAllUserDataTournament, getAreaNb, addUsersTourCount, likeSearchUser, getAllUsersInTheArea, publishTournamentNewsAllUsers, setTournamentAllocationFlg, updateTournamentPart, deleteAllUserTournament, updateTournament, saveTournament, getCourseList, getTournamentList, deleteTournament, getTournamentAllUser, addUserTournament } from "../Database";
import lodash from 'lodash';

/**
 * 日付文字からミリ秒の数値を取得
 * @param {*} datestr 
 * @returns 
 */
export function getMillisecond(datestr) {
    //ミリ秒の取得
    let millisecond = Date.parse(datestr);
    return millisecond;
}

/**
 * ミリ秒から年/月/日の取得
 * @param {*} millisecond 
 * @returns 
 */
export function getDate(millisecond) {

    const date = new Date(millisecond);

    //2桁にする
    let month = ('00' + (date.getMonth() + 1)).slice(-2);
    let datedate = ('00' + date.getDate()).slice(-2);
    return date.getFullYear() + "-" + month + "-" + datedate;

}

/**
  * ミリ秒から時:分を取得
 * @param {*} millisecond 
 * @returns 
 */
export function getTime(millisecond) {

    const date = new Date(millisecond)
    //2桁にする
    let hours = ('00' + date.getHours()).slice(-2);
    let minutes = ('00' + date.getMinutes()).slice(-2);
    return hours + ":" + minutes;

}

/**
  * 指定したミリ秒から、指定した時間を加算して時:分を取得
 * @param {*} millisecond 
 * @param {*} addMinutes 加算時間
 * @returns { hours: hours, minutes: minutes }; 
 */
export function addMinutes(millisecond, addMinutes) {

    const date = new Date(millisecond)
    //分を加算
    date.setMinutes(date.getMinutes() + addMinutes);

    //2桁にする
    let hours = ('00' + date.getHours()).slice(-2);
    let minutes = ('00' + date.getMinutes()).slice(-2);
    return { hours: hours, minutes: minutes };

}


/**
 * ゴルフコースを統一する
 * @param {*} courseList 
 */
export function unifyGolfCourse(courseList) {
    let unifyholeDataList = [];
    //コースが複数使用されていたら、合計のコースデータを作成（TODO本来なら、使用するコースの順番がある2021/09/06）
    courseList.forEach((courseData) => {
        courseData.holeList.forEach((holeData) => {
            unifyholeDataList.push(lodash.cloneDeep(holeData));
        });
    })

    return unifyholeDataList;
}

/**
 * 抹消ならリストから削除
 * @param {*} userDataList 
 * @returns 
 */
export function deleteListDeleted(userDataList) {
    //なんかbooleと数値があって面倒なんだけど
    //削除されているユーザーは除外します
    userDataList = userDataList.filter((allUserData) => {
        let result = true;

        if (allUserData.delete_flg === true) {
            result = false;
        }

        if (allUserData.delete_flg === 1) {
            result = false;
        }


        return result;
    })

    return userDataList;
}


/**
 * 配列のソート昇順ソート（小さい順）
 * @param {*} array 
 * @param {*} property 
 * @param {*} indexNb 
 * @param {*} orderFlg "asc" 昇順（小さい順） "desc" 降順 （大きい順）
 * @returns 
 */
export const sortArray = (array, property, indexNb, orderFlg) => {

    switch (orderFlg) {
        case "asc":
        default:        //undefinedも含む
            if (indexNb === undefined) {
                //indexNBで昇順ソート（小さい順）
                array.sort((a, b) => {
                    if (a[property] < b[property]) return -1;
                    if (a[property] > b[property]) return 1;
                    return 0;
                });

            } else {
                //indexNBで昇順ソート（小さい順）
                array.sort((a, b) => {
                    //indexNbを指定してるのに配列が存在しない場合は、動的に追加する
                    if (a[property].length <= indexNb) {
                        for (let i = a[property].length; i <= indexNb; i++) {
                            a[property].push(0);
                        }
                    }

                    //indexNbを指定してるのに配列が存在しない場合は、動的に追加する
                    if (b[property].length <= indexNb) {
                        for (let i = b[property].length; i <= indexNb; i++) {
                            b[property].push(0);
                        }
                    }
                    if (a[property][indexNb] < b[property][indexNb]) return -1;
                    if (a[property][indexNb] > b[property][indexNb]) return 1;
                    return 0;
                });

            }

            break;
        case "desc":
            if (indexNb === undefined) {
                //indexNBで降順ソート（大きい順）
                array.sort((a, b) => {
                    if (a[property] > b[property]) return -1;
                    if (a[property] < b[property]) return 1;
                    return 0;
                });

            } else {
                //indexNBで降順ソート（大きい順）
                array.sort((a, b) => {
                    //indexNbを指定してるのに配列が存在しない場合は、動的に追加する
                    if (a[property].length <= indexNb) {
                        for (let i = a[property].length; i <= indexNb; i++) {
                            a[property].push(0);
                        }
                    }

                    //indexNbを指定してるのに配列が存在しない場合は、動的に追加する
                    if (b[property].length <= indexNb) {
                        for (let i = b[property].length; i <= indexNb; i++) {
                            b[property].push(0);
                        }
                    }
                    if (a[property][indexNb] > b[property][indexNb]) return -1;
                    if (a[property][indexNb] < b[property][indexNb]) return 1;
                    return 0;
                });

            }

            break;
    }

    return array
}



/**
   * 文字列を確認します
   * @param {*} str 
   * @returns 特殊文字が含まれている場合はエラー文字を出力します
   */
export const checkMoji = (str) => {
    let arr = str.split('');
    let result = arr.filter((c) => {
        return !isUnicodeChar(c);
    })
    if (result.length > 0) {
        let isChineseCharacterResult = isChineseCharacter(result[0]);
        isChineseCharacterResult = isChineseCharacterResult + "[" + result + "]が含まれています";
        return isChineseCharacterResult;
    } else {
        return "";
    }

}



/**
 * unicode文字かを判定します
 * @param {*} c 
 * @returns 
 */
function isUnicodeChar(c) { // c:判別したい文字
    var unicode = c.charCodeAt(0);
    let result = false;
    if ((unicode >= 0x0000 && unicode <= 0x007F) ||
        (unicode >= 0x3000 && unicode <= 0x303f) ||
        (unicode >= 0x3040 && unicode <= 0x309f) ||
        (unicode >= 0x30a0 && unicode <= 0x30ff) ||
        (unicode >= 0xff00 && unicode <= 0xffef) ||
        (unicode >= 0x4e00 && unicode <= 0x9faf) ||
        (unicode >= 0x3400 && unicode <= 0x4dbf)
    ) {
        result = true;
    }
    return result;
}

const isChineseCharacter = (char) => {
    char += '';
    const code = char.codePointAt(0);
    // CJK統合漢字
    if (0x4e00 <= code && code <= 0x9fef) {
        return "CJK統合漢字";
    }
    if (0x3400 <= code && code <= 0x4db5) {
        return "CJK統合漢字拡張A";
    }
    if (0x20000 <= code && code <= 0x2a6d6) {
        return "CJK統合漢字拡張B";
    }

    if (0x2a700 <= code && code <= 0x2b734) {
        return "CJK統合漢字拡張C";
    }
    if (0x2b740 <= code && code <= 0x2b81d) {
        return "CJK統合漢字拡張D";
    }
    if (0x2b820 <= code && code <= 0x2cea1) {
        return "CJK統合漢字拡張E";
    }
    if (0x2ceb0 <= code && code <= 0x2ebe0) {
        return "CJK統合漢字拡張F";
    }
    if (0xf900 <= code && code <= 0xfaff) {
        return "CJK互換漢字";
    }
    if (0x2f800 <= code && code <= 0x2fa1f) {
        return "CJK互換漢字補助";
    }
    if (0xe0100 <= code && code <= 0xe01ef) {
        return "異体字セレクタ補助";
    }
    if (0x2f00 <= code && code <= 0x2fdf) {
        return "康煕部首";
    }
    if (0x2e80 <= code && code <= 0x2eff) {
        return "CJK部首補助";
    }
    if (0x31c0 <= code && code <= 0x31ef) {
        return "CJKの筆画";
    }

    return "";

}