import { useHistory } from "react-router-dom"
import React, { useContext, useEffect} from "react";
import { AuthContext } from "../auth/AuthProvider";
import ManagementFooter from './ManagementFooter'
import logo from "../images/logo.svg";
import '../styles/styles.css';

function Login() {
  //コンテクストのloginパラメーターとエラーを取得
  const { login } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    document.getElementsByTagName("*")["email_error"].style.display="none";
    document.getElementsByTagName("*")["password_erroe"].style.display="none";

  }, []);  
/*
  //これをやるならinput全てでonFormChangeを呼んで
  const onFormChange =(e)=>{
    switch(e.target.id){
      case "email":
        document.getElementsByTagName("*")["email_error"].style.display="none";
        break;
      case "password":
        document.getElementsByTagName("*")["password_erroe"].style.display="none";
        break;
      default:
        break;
      }
    console.log(e);
  }
*/
  // AuthContextからlogin関数を受け取る
  const handleSubmit = event => {
    event.preventDefault();

    const { email, password } = event.target.elements;
    login(email.value, password.value, history, "/", errorCallBack);
  };

  const errorCallBack = ((e) => {
    document.getElementsByTagName("*")["email_error"].style.display="none";
    document.getElementsByTagName("*")["password_erroe"].style.display="none";

    switch(e.code){
      case "auth/wrong-password":
        document.getElementsByTagName("*")["password_erroe"].style.display="";
      break;
      default:
        document.getElementsByTagName("*")["email_error"].style.display="";
        break;
    }
    console.log("NG");
  });

  return (
    <div className="c-screen c-screen--gunmetal">

      <header className="c-container-vertical c-mt144">
        <div className="c-logo c-logo--376">
          <img src={logo} alt="JCG Management" className="c-logo__source" />
        </div>
      </header>
      <main>

        <article className="kanri-container kanri-container--medium">
          <form onSubmit={handleSubmit} >

            <div className="c-container-vertical c-mt56">

              <div className="input input__set input input__set--kanri">
                <label htmlFor="" className="input input__label">ユーザーID</label>
                <input type="email" name="" id="email" minlenght="2" placeholder="IDを入力してください。" autoComplete="" required className="input input__field" />
                <span className="input input__validation input__validation--display" id="email_error">ユーザーIDが違います。</span>
              </div>

              <div className="input input__set input input__set--kanri">
                <label htmlFor="" className="input input__label">パスワード</label>
                <input type="password" name="" id="password" minlenght="2" placeholder="パスワードを入力してください。" autoComplete="" required className="input input__field"/>
                <span className="input input__validation input__validation--display" id="password_erroe">パスワードが違います。</span>
              </div>

            </div>

            <div className="c-button-set">
              <button id="loginButton" className="button button__primary" type="submit">ログイン</button>
            </div>

          </form>
        </article>
      </main>

      <footer>
      <ManagementFooter></ManagementFooter>
      </footer>


    </div>
  )
}
export default Login;


