import React, { useEffect, useState } from 'react';
import lodash from 'lodash';
import CourseEditStep1TeeItem from './CourseEditStep1TeeItem'
import CourseEditStep1GreenItem from './CourseEditStep1GreenItem'
//import CourseStep2Warning from './CourseStep2Warning'
import CourseEditStep1Warning from './CourseEditStep1Warning'
import '../../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconPlus, IconDelete } from "../../../Icons";


function CourseEditStep1Container(props) {
    const [arrayTeeList, setArrayTeeList] = useState([]);
    const [arrayGreenList, setArrayGreenList] = useState([]);
    const [courseName, setCourseName] = useState("");
    const [hallSelection, setHallSelection] = useState(["", ""]);
    const [articleId, setArticle] = useState("");
    const [nowCourse, setNowCourse] = useState(props.nowCourse);
    const [itemKey, setIemKey] = useState(props.itemKey);

    useEffect(() => {
        return () => {
            setArrayTeeList([]);
            setArrayGreenList([]);
            setHallSelection(["", ""]);
            setNowCourse(null);
            setIemKey("");
            console.log("");
        };
    }, []);

    useEffect(() => {
        setArrayTeeList([...nowCourse.arrayTeeList]);
        setArrayGreenList([...nowCourse.arrayGreenList]);
        setCourseName(nowCourse.courseName);
        setArticle(itemKey);
        switch (nowCourse.numberOfholes) {
            case "9":
                setHallSelection(["checked", ""]);
                break;
            case "18":
                setHallSelection(["", "checked"]);
                break;
            default:
                break;
        }

    }, [nowCourse, itemKey]);


    //ティーの追加
    const onSubmitAddTee = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();
        const { tee_name } = e.target.elements;
        //大本に追加
        let teeData = { key: "tee_id_" + arrayTeeList.length, name: tee_name.value };
        nowCourse.arrayTeeList.push(teeData);

        //描画用追加
        let newArrayTeeList = [...nowCourse.arrayTeeList];
        setArrayTeeList(newArrayTeeList);
        //クリアしておきますか
        tee_name.value = "";


    };

    //グリーンーの追加
    const onSubmitAddgreen = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();
        const { green_name } = e.target.elements;

        //大本に追加
        let greenData = { key: "green_id_" + arrayGreenList.length, name: green_name.value };
        nowCourse.arrayGreenList.push(greenData);

        //描画用追加
        let newarrayGreenList = [...nowCourse.arrayGreenList];
        setArrayGreenList(newarrayGreenList);
        //クリアしておきますか
        green_name.value = "";

    };

    //ティーの削除
    const onClickRemoveTee = (teeItemKey) => {

        let newArrayTeeList = nowCourse.arrayTeeList.filter(data => {
            return data.key !== teeItemKey;
        });
        //大本に追加
        nowCourse.arrayTeeList = newArrayTeeList;
        
        //描画用更新
        setArrayTeeList(newArrayTeeList);

    };

    //グリーンの削除
    const onClickRemoveGreen = (greenItemKey) => {
        let newArrayGreenList = nowCourse.arrayGreenList.filter(data => {
            return data.key !== greenItemKey;
        });
        //大本に追加
        nowCourse.arrayGreenList = newArrayGreenList;
        //描画用更新
        setArrayGreenList(newArrayGreenList);
    };

    //コース名入力
    const onChangeCourseName = (e) => {
        nowCourse.courseName = e.target.value;
    };

    //コース選択
    const onChangeCourseSelect = (e) => {
        let radioId = e.target.id.replace("_" + itemKey, "");
        let holeNb = e.target.value;
        nowCourse.numberOfholes = holeNb;
        switch (radioId) {
            case "addCourse-1-hole9":
                setHallSelection(["checked", ""]);
                break;
            case "addCourse-1-hole18":
                setHallSelection(["", "checked"]);
                break;
            default:
                break;
        }
    };

    return (
        <article id={articleId} className="golf-course-add c-container-vertical c-container-vertical--left c-container-vertical--gunmetal c-padding--32 c-radius--24 c-mt56">
            {/*cssの「.golf-course-add:first-of-type .golf-course-delete」で first-of-typeが指定されているので初めの一個目は表示されないほうになっている{*/}
            <div className="golf-course-delete">
                <button className="button button__delete delete_button" onClick={(e) => { props.onClickRemove(itemKey) }}>
                    <figure className="c-icon c-icon--white c-icon--24">
                        <IconDelete></IconDelete>
                    </figure>
                </button>
            </div>

            {/*警告ウインドウ*/}
            <CourseEditStep1Warning itemKey={itemKey} nowCourse={nowCourse}></CourseEditStep1Warning>

            <h2>コースの名称</h2>

            <div className="input input__set input__set--kanri c-mt16">
                <input type="text" name="course_name" id="course_name" minlenght="2" placeholder="入力されたコースの名称" autoComplete="" required className="input input__field" onChange={(e) => { onChangeCourseName(e) }} defaultValue={courseName} form="form_button_next" />
            </div>

            <h2 className="c-mt56">ホール数</h2>
            <p className="c-paragraph c-mt8">ホール数を選択してください。</p>
            <div className="c-radio-button-set">
                <input type="radio" id={"addCourse-1-hole9_" + itemKey} name={"radio-button-set_" + itemKey} required className="button button__secondary" value="9" onChange={(e) => { onChangeCourseSelect(e) }} checked={hallSelection[0]}></input>
                <label className="button button__secondary entry-participant hole--9 c-mt16" htmlFor={"addCourse-1-hole9_" + itemKey}>9ホール</label>
                <input type="radio" id={"addCourse-1-hole18_" + itemKey} name={"radio-button-set_" + itemKey} required className="button button__secondary" value="18" onChange={(e) => { onChangeCourseSelect(e) }} checked={hallSelection[1]}></input>
                <label className="button button__secondary entry-non-participant hole--18 c-mt16" htmlFor={"addCourse-1-hole18_" + itemKey}>18ホール</label>
            </div>

            <h2 className="c-mt56">ティー位置の名称</h2>
            <p className="c-paragraph c-mt8">※ティー位置が複数ある場合はすべて追加してください。</p>

            <div className="course-add-items c-mt16">

                <form onSubmit={onSubmitAddTee}>
                    <div className="input input__set input__set--kanri c-margin-reset">
                        <input type="text" name="tee_name" id="tee_name" minlenght="2" placeholder="（例：バック,レディース）" autoComplete="" required className="input input__field input__field--rounded-right c-pr--144 tee_name" />

                        <div className="course-add-button">
                            <button id="add_tee" className="button button__primary button--small c-margin-reset add_tee">
                                <div className="button__icon">
                                    <figure className="c-icon c-icon--white c-icon--16">
                                        <IconPlus></IconPlus>
                                    </figure>
                                </div>
                                <span className="c-button-text c-margin-reset c-padding--16 c-pl--32">追加</span>
                            </button>
                        </div>
                    </div>
                </form>

                <div className="c-container-vertical c-container-vertical--left c-mt16">
                    <span className="c-subtitle">追加されたティー位置:</span>

                    <div className="c-container-horizontal c-container-horizontal--wrap tee_container">

                        {/*動的にコンポーネントが増えていきます*/}

                        {arrayTeeList.map(teeItem => (
                            <CourseEditStep1TeeItem key={teeItem.key} itemKey={teeItem.key} name={teeItem.name} onClickRemoveTee={onClickRemoveTee} />
                        ))}

                    </div>
                </div>
            </div>

            <h2 className="c-mt56">グリーンの名称</h2>
            <p className="c-paragraph c-mt8">※グリーンが複数ある場合はすべて追加してください。</p>

            <div className="course-add-items c-mt16">
                <form onSubmit={onSubmitAddgreen}>
                    <div className="input input__set input__set--kanri c-margin-reset">
                        <input type="text" name="" id="green_name" minlenght="2" placeholder="（例：BENT,KORAI）" autoComplete="" required className="input input__field input__field--rounded-right c-pr--144 green_name" />

                        <div className="course-add-button">
                            <button id="add_green" className="button button__primary button--small c-margin-reset add_green">
                                <div className="button__icon">
                                    <figure className="c-icon c-icon--white c-icon--16">
                                        <IconPlus></IconPlus>
                                    </figure>
                                </div>
                                <span className="c-button-text c-margin-reset c-padding--16 c-pl--32">追加</span>
                            </button>
                        </div>
                    </div>
                </form>
                <div className="c-container-vertical c-container-vertical--left c-mt16">
                    <span className="c-subtitle">追加されたグリーン:</span>

                    <div className="c-container-horizontal c-container-horizontal--wrap green_container">

                        {/*動的にコンポーネントが増えていきます*/}
                        {arrayGreenList.map(greenItem => (
                            <CourseEditStep1GreenItem key={greenItem.key} itemKey={greenItem.key} name={greenItem.name} onClickRemoveGreen={onClickRemoveGreen} />
                        ))}

                    </div>
                </div>
            </div>

        </article>

    )
}




export default CourseEditStep1Container

