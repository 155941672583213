import React, { useEffect, useState } from "react";
import lodash from 'lodash';
export const orijinaltournamentInitialState = {

  tournament002Data: {
    title: "",
    category: "",
    area: "",
    eventDate: "",
    eventStartTime: "",
    eventFinishDate: "",
    eventFinishTime: "",
    golfCourseDocId: "",
    allocationFlg: 0,  //割り当てフラグ
    fix_flg: 0,        //大会確定フラグ
    useCourseList: [

    ],

    usePurpose: "",  //1=技能テスト,2=ツアー,3=イベント
    temp: {
      useCourseList: [],
      loadCourseList: []
    }
  },
  tournament003Data: {
    first_place: "",    //1位
    second_place: "",   //2位
    third_place: "",    //3位
    fourth_place: "",   //4日から73位
    special_prize: "",  //副賞
    entry_amount: "",   //エントリー金額
    group_match_interval: "", //グループの試合間隔
    tournament_picture: [undefined, undefined, undefined],  //メイン画像1
    tournament_picture_file: [undefined, undefined, undefined],
    pictureList: [],
  },

  tournament006Data: {
    entry_amount: "",
    group_match_interval: "", //グループの試合間隔
    tournament_picture: undefined,  //メイン画像データ
    tournament_picture_file: undefined,  //firebaseにデータを保存するためにファイルオブジェクト
    pictureList: [],
  },
  tournament009Data: {
    tournamentData: {
      usePurpose: undefined,
    },

    temp: {
      loadTournamentList: []
    },
  },

  tournament010Data: {
    title: "",            //大会タイトル
    category: "",         //開催する大会のカテゴリ
    area: "",             //開催する地域
    eventDate: "",        //開催日
    eventStartTime: "",   //開催時間
    eventFinishDate: "",  //終了日
    eventFinishTime: "",  //終了時間
    golfCourseDocId: "",  //使用ゴルフ場ID
    useCourseList: [      //使用するコース情報（コース、ティー、グリーン）

    ],
    first_place: "",    //1位
    second_place: "",   //2位
    third_place: "",    //3位
    fourth_place: "",   //4日から73位
    special_prize: "",  //副賞
    entry_amount: "",   //エントリー金額
    group_match_interval: "", //グループの試合間隔
    tournament_picture: undefined,  //メイン画像データ
    temp: {
      loadCourseList: [],
    },
  },
  tournament011Data: {

  },
  tournament013Data: {
    title: "",            //大会タイトル
    category: "",         //開催する大会のカテゴリ
    area: "",             //開催する地域
    eventDate: "",        //開催日
    eventStartTime: "",   //開催時間
    eventFinishDate: "",  //終了日
    eventFinishTime: "",  //終了時間
    golfCourseDocId: "",  //使用ゴルフ場ID
    useCourseList: [      //使用するコース情報（コース、ティー、グリーン）

    ],
    entry_amount: "",   //エントリー金額
    group_match_interval: "", //グループの試合間隔
    tournament_picture: undefined,  //メイン画像データ
    temp: {
      loadCourseList: [],
    },
  },

  tournament016Data: {
    title: "",            //大会タイトル
    eventDate: "",        //開催日
    eventStartTime: "",   //開催時間
    eventFinishDate: "",  //終了日
    eventFinishTime: "",  //終了時間
    postponed_flg:  0,    //0=通常、1=延期
    newsTitle:"",         //お知らせタイトル
    newsText:""           //お知らせテキスト
  },

  tournament017Data: {
    title: "",            //大会タイトル
    eventDate: "",        //開催日
    eventStartTime: "",   //開催時間
    eventFinishDate: "",  //終了日
    eventFinishTime: "",  //終了時間
    postponed_flg:  0,    //0=通常、1=延期
    newsTitle:"",         //お知らせタイトル
    newsText:""           //お知らせテキスト
  },

  tournament019Data: {
    title: "",            //大会タイトル
    eventDate: "",        //開催日
    eventStartTime: "",   //開催時間
    eventFinishDate: "",  //終了日
    eventFinishTime: "",  //終了時間
    postponed_flg:  0,    //0=通常、1=延期
    newsTitle:"",         //お知らせタイトル
    newsText:""           //お知らせテキスト
  },

  tournament022Data: {
  },

  tournament025Data: {
    userDataList: [],
    unmodifiedUserDataList:[]
  },

  tournament028Data: {
    first_place: "",    //1位
    second_place: "",   //2位
    third_place: "",    //3位
    fourth_place: "",   //4日から73位
    special_prize: "",  //副賞
    entry_amount: "",   //エントリー金額
    group_match_interval: "", //グループの試合間隔
    tournament_picture: [undefined, undefined, undefined],  //メイン画像1
    tournament_picture_file: [undefined, undefined, undefined],
  },

};

export const tournamentInitialState = lodash.cloneDeep(orijinaltournamentInitialState);

// contextの作成
export const TournamentContext = React.createContext(tournamentInitialState);


export const TournamentProvider = ({ children }) => {
  const [value, setValue] = useState(tournamentInitialState);
  useEffect(() => {
    //    console.log("CourseProvider");
  }, []);

  return (
    // Contextを使用して認証に必要な情報をコンポーネントツリーに流し込む。
    <TournamentContext.Provider
      value={
        [value, setValue]
      }
    >
      {children}
    </TournamentContext.Provider>
  );
}




