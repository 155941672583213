import React, { useEffect, useState } from 'react';
import '../../styles/styles.css';

function Score_009_ScoreContent(props) {
    const [holeData, setHoleData] = useState(props.holeData);

    const [selectColumnClassNameNb, setSelectColumnClassNameNb] = useState(0);

    //0=通常 1=変更
    const [columnClassName] = useState(["", "c-data__slot--confirm"]);

    useEffect(() => {
        //データベースからコースデータの読み込み
        //TODO入力者uidから本来なら入力者をデーターベースから取得してくる
        //holeData.scorer_uid
        holeData.scorer_userFullName = "管理者";
        setHoleData(holeData);
        if (holeData.change_flg === undefined || holeData.change_flg === false) {
            setSelectColumnClassNameNb(0)
        } else {
            setSelectColumnClassNameNb(1)

        }

    }, [holeData, holeData.scorer_userFullName]);


    return (

        <div className="c-container-horizontal">
            <div className="c-data__slot c-data__slot--white c-data__slot--short-height">{holeData.name}</div>
            <div className="c-data__slot c-data__slot--white c-data__slot--short-height">{holeData.at_bat}</div>
            <div className={"c-data__slot c-data__slot--white c-data__slot--short-height " + columnClassName[selectColumnClassNameNb]}>{holeData.user_at_bat}</div>
            <div className="c-data__slot c-data__slot--white c-data__slot--short-height c-data__slot--144">{holeData.scorer_userFullName}</div>
        </div>

    )
}


export default Score_009_ScoreContent

