import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import { AnnouncementContext } from './AnnouncementProvider';
import ManagementFooter from '../ManagementFooter';
import logo from "../../images/logo.svg";
import '../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconAnnouncement } from "../Icons";
import { getNewsTemplate, updateNewsTemplate } from "./AnnouncementUtility";
import Loader from '../Loader'

function AnnouncementSetNewText(props) {
    const history = useHistory();

    const [loadingData, setLoadingData] = useState(undefined);
    const [newsText, setNewsText] = useState({ news_text: { title: "", text: "" } });
    const [selectNb, setSelectNb] = useState(-1);



    useEffect(() => {
        document.getElementsByTagName("*")["input_title"].value = newsText.news_text.title;
        document.getElementsByTagName("*")["send_notification"].value = newsText.news_text.text;
    }, [newsText]);




    const onSubmit = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();
        //        const { select_kinds, input_title, send_notification } = e.target.elements;
        const asyncfunc = (async () => {
            await updateNewsTemplate(selectNb, newsText, (result, status) => {
                //ローディング非表示
                setLoadingData({ loadingFlg: false, title: "定型文を更新中..." });

                if (result === false) {
                    alert("定型文の更新に失敗しました=" + status);
                } else {
                    alert("定型文を更新しました");
                }
            });
        });
        //ローディング表示
        setLoadingData({ loadingFlg: true, title: "定型文を更新中..." });
        asyncfunc();
    };

    const onClickButton = (e) => {
        switch (e.currentTarget.id) {
            case "button_back":
                history.push("/");
                break;
            case "":
                break;
            default:
                break;
        }
    };

    const onSelectChange = (e) => {
        let _selectNb = Number(e.target.value);
        setSelectNb(_selectNb);
        if (_selectNb === -1) return;
        const asyncfunc = (async () => {
            await getNewsTemplate(_selectNb, (result, status) => {
                //ローディング非表示
                setLoadingData({ loadingFlg: false, title: "定型文を読み込み中..." });
                if (result === false) {
                    alert("定型文の読み込みに失敗しました=" + status);
                } else {
                    setNewsText(status)

                }

            });
        });
        //ローディング表示
        setLoadingData({ loadingFlg: true, title: "定型文を読み込み中..." });
        asyncfunc();
    };


    const onChangeTextarea = (e) => {
        newsText.news_text.text = e.target.value;
    }

    const onChangeInput = (e) => {
        newsText.news_text.title = e.target.value;
    }

    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <header className="c-container-vertical c-mt88">

                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>

                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconAnnouncement></IconAnnouncement>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">お知らせ文言設定</h1>
                </div>

            </header>
            <main>

                <section className="kanri-container">
                    <div className="c-container-vertical c-mt32">
                        <div className="input input__set input__set--kanri">
                            <label htmlFor="" className="input input__label">お知らせの種類を選択してください。</label>
                            <select name="" id="select_kinds" required className="input input__field" onChange={(e) => onSelectChange(e)}>
                                <option value="-1">選択してください</option>
                                <option value="0">大会の選手割り振り決定</option>
                                <option value="1">大会の選手組み分け決定</option>
                                <option value="2">スコア決定</option>
                            </select>
                        </div>
                    </div>
                    <form onSubmit={onSubmit} >

                        <div className="c-container-vertical c-mt16">
                            <div className="input input__set input__set--kanri">
                                <label htmlFor="" className="input input__label">お知らせのタイトル</label>
                                <input type="text" name="" id="input_title" placeholder="お知らせのタイトルを入力してください" required className="input input__field c-paragraph"  onChange={(e) => onChangeInput(e)}/>
                            </div>
                        </div>

                        <div className="c-container-vertical c-mt16">
                            <div className="input input__set input__set--kanri">
                                <label htmlFor="" className="input input__label">お知らせ送信内容</label>
                                <textarea name="" id="send_notification" placeholder="お知らせ定型文。" required className="input input__field input__field--textarea c-paragraph" onChange={(e) => onChangeTextarea(e)}></textarea>
                            </div>
                        </div>

                        <div className="c-button-set">
                            <button id="button_next" className="button button__primary">データベースに保存</button>
                            <button id="button_back" onClick={(e) => { onClickButton(e) }} className="button button__secondary">戻る</button>
                        </div>
                    </form>
                </section>



            </main>
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default AnnouncementSetNewText

