import { orijinalProviderInitialState } from './ScoreProvider'
import lodash from 'lodash';
import findLastIndex from "lodash/findLastIndex"
import { saveImage, loadImageFromFirebase, deleteImage } from '../../Storage'
import { setTournamentResults, getTournament, getAnnouncementNewsTemplate, setFixFlgTournament, updateScoreDatatoDatabase, getTournamentEntryUser, likeSearch, getUser, likeAndSearch, getAllTournamentScoreData, getCourse, addScoreDataTournamentUserData, publishTournamentNewsUsers, updateUsersTourCount, deleteUserTournament, subUsersTourCount, updateAllUserDataTournament, getAreaNb, addUsersTourCount, likeSearchUser, getAllUsersInTheArea, publishTournamentNewsAllUsers, setTournamentAllocationFlg, updateTournamentPart, deleteAllUserTournament, updateTournament, saveTournament, getCourseList, getTournamentList, deleteTournament, getTournamentAllUser, addUserTournament } from "../../Database";
import { unifyGolfCourse, getMillisecond, getDate, getTime, sortArray } from '../Utility'

/**
 * ユーザー検索
 * @param {*} queryList 検索キーワード 
 * @param {*} orderBySerial DESC=降順(小さい順) ASC=昇順(大きい順)
 * @returns 
 */
export async function likeSearchTournament(queryList, orderBySerial, callback) {
    try {
        const keys = [
            "area",         //エリア関東　関西
            "category",     //カテゴリー 男子 女子 ジュニア
            "usePurpose",   //ツアーの種類 ツアー 技能テスト イベント
        ]
        let queryText = "";
        queryList.forEach((query) => {
            queryText += query + " ";
        });
        queryText = queryText.slice(0, -1);
        let items = await likeAndSearch(keys, queryText, "tournament_data", orderBySerial, "startMillisecond");


        //スコアのデータを取得する
        let promise_results = items.map((item) => {
            return getAllTournamentScoreData(item.uid, (result, status) => {
                if (result === false) {
                    throw new Error("スコアデータの読み込みに失敗しました=" + status);
                }
                item.scoreDataList = status;
            })

        })

        await Promise.all(promise_results);

        items = items.filter((item) => {
            return item.scoreDataList.length > 0;
        })

        //開催日順に並び替え
        items = sortArray(items, "startMillisecond", undefined, "asc");

        items.forEach((data) => {
            //開催日
            data.eventDate = getDate(data.startMillisecond);
            //開催時間
            data.eventStartTime = getTime(data.startMillisecond);
            //終了日
            data.eventFinishDate = getDate(data.finishMillisecond);
            //終了時間
            data.eventFinishTime = getTime(data.finishMillisecond);

            //エントリー受付開始日
            data.entryStartDate = getDate(data.entryStartMillisecond);;

            //エントリー受付終了日
            data.entryFinisDate = getDate(data.entryFinishMillisecond);;

        })


        if (callback !== null && callback !== undefined) {
            callback(true, items);
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }


}

/**
 * スコアデータにユーザーデータとエントリーを設定する
 * @param {*} scoreDataList 
 * @param {*} callback 
 */
export async function setUserDataToScoreDataAndEntryData(scoreDataList, callback) {
    try {
        let promise_results = scoreDataList.map(async (scoreData) => {
            await getUser(scoreData.uid, (result, status) => {
                if (result === false) {
                    throw new Error("スコアデータの読み込みに失敗しました=" + status);
                }
                //参照なので直接追加する
                scoreData.userData = status;
            })
        })

        await Promise.all(promise_results);


        //エントリー情報の取得
        promise_results = scoreDataList.map((scoreData) => {
            return getTournamentEntryUser(scoreData.tournamentId, scoreData.uid, (result, status) => {
                if (result === false) {
                    throw new Error("エントリーの読み込みに失敗しました=" + status);
                }

                scoreData.entry_user = status;
            })
        })

        await Promise.all(promise_results);


        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }

}

/**
 * ユーザーの合計打数を取得
 * @param {*} scoreDataList 
 */
export function getTotalUserAtBat(scoreDataList) {
    let total = scoreDataList.reduce((sum, element) => {
        return sum + element.user_at_bat;
    }, 0);

    return total;
}

/**
 * コースの合計打数を取得
 * @param {*} scoreDataList 
 * @param {*} callback 
 */
export function getTotalAtBat(scoreDataList) {
    let total = scoreDataList.reduce((sum, element) => {
        //んーー何故か文字
        return sum + Number(element.at_bat);
    }, 0);

    return total;
}


/**
 * コースのユーザースコアを取得
 * @param {*} scoreDataList 
 * @param {*} callback 
 */
export function getUserScore(scoreDataList) {

    let totalUserAtBat = getTotalUserAtBat(scoreDataList);
    let TotalAtBat = getTotalAtBat(scoreDataList);
    let userScore = totalUserAtBat - TotalAtBat;
    if (userScore > 0) {
        userScore = "+" + String(userScore);
    } else {
        userScore = String(userScore);

    }

    return userScore;
}

/**
 * スコアの確定をチェック 
 * @param {*} allUserScoreList 
 * @returns 未入力ユーザー数を返します
 */
export function checkScoreConfirmation(allUserScoreList) {
    let result = allUserScoreList.map((userScore) => {
        //失格なら無視する
        if (userScore.eliminationFlg === 1) {
            //失格なら発見未入力プロパティーは無効にする
            userScore.notEntered = 0;
            return userScore;
        } else {
            //ホールの打数が99を見つける
            let score_data = userScore.score_data_list.filter((score_data) => {
                //99と50打以上は未入力と判断
                return score_data.user_at_bat === 99 || score_data.user_at_bat >= 50;
            })
            //ホールの打数が99が発見未入力プロパティー追加
            if (score_data.length !== 0) {
                userScore.notEntered = 1;
            } else {
                userScore.notEntered = 0;

            }
            return userScore;
        }
    })
    return result;
}

/**
 * 全てのユーザーのトータルスコアの設定 
 * @param {*} allUserScoreList 
 * @returns 順位順位に並んだallUserScoreList
 */
export function setTotalUserAtBat(allUserScoreList) {
    //失格を配列から削除して、順位を計算したいので、全く違う配列で行う
    let copyAllUserScoreList = lodash.cloneDeep(allUserScoreList);
    //失格者は除外
    copyAllUserScoreList = copyAllUserScoreList.filter((userScore) => {
        return userScore.eliminationFlg !== 1;
    })

    //トータルスコアをユーザー毎に設定する
    copyAllUserScoreList.map((userScore) => {
        //ユーザーの合計打数を取得
        let totalUserAtBat = getTotalUserAtBat(userScore.score_data_list);
        userScore.totalUserAtBat = totalUserAtBat;
        return userScore;
    })
    //元の配列にスコアを入れる
    allUserScoreList.forEach((userScore) => {
        copyAllUserScoreList.forEach((copyAllUserScore) => {
            if (userScore.uid === copyAllUserScore.uid) {
                userScore.ranking = copyAllUserScore.ranking;
                userScore.Tranking = copyAllUserScore.Tranking;
                //一度だけバックアップを取った値と同じか調べて違うならUIを変更したいのでフラグを立てます
                if (userScore.backTotalUserAtBat !== copyAllUserScore.totalUserAtBat) {
                    userScore.change_flg = true;
                } else {
                    delete userScore.change_flg;
                }
                userScore.totalUserAtBat = copyAllUserScore.totalUserAtBat;

            }

        });
    });
    return allUserScoreList;
}

/**
 * ランキングの設定 
 * @param {*} allUserScoreList 
 * @param {*} tournamentData 
 * @returns 順位順位に並んだallUserScoreList
 */
export function setRanking(allUserScoreList, tournamentData) {
    //失格を配列から削除して、順位を計算したいので、全く違う配列で行う
    let copyAllUserScoreList = lodash.cloneDeep(allUserScoreList);
    //失格者は除外
    copyAllUserScoreList = copyAllUserScoreList.filter((userScore) => {
        return userScore.eliminationFlg !== 1;
    })

    //トータルスコアをユーザー毎に設定する
    copyAllUserScoreList.map((userScore) => {
        //ユーザーの合計打数を取得
        let totalUserAtBat = getTotalUserAtBat(userScore.score_data_list);
        userScore.totalUserAtBat = totalUserAtBat;
        return userScore;
    })

    //トータルスコアでソート
    let sortAllUserScoreList = sortArray(copyAllUserScoreList, "totalUserAtBat", undefined, "asc");

    //とりあえず順位を入れる
    sortAllUserScoreList = sortAllUserScoreList.map((userScore, index) => {
        userScore.ranking = index + 1;
        return userScore;
    })

    //重複順位設定
    sortAllUserScoreList = sortAllUserScoreList.map((userScore, index, originUserScore) => {
        //順位でソートされた配列の上から何番目に存在するか確認する
        let tranking = originUserScore.findIndex((data) => {
            return userScore.totalUserAtBat === data.totalUserAtBat;
        })
        userScore.Tranking = tranking + 1;
        return userScore;
    })

    //順位タイがいくつあるかのリストを作成
    let duplicateList = sortAllUserScoreList.filter((val, idx, arr) => {
        // 要素の番号と最後に見つかった位置が異なる場合
        // かつ
        // 最初に見つかった位置と、配列の要素番号が一緒の場合  
        let lastResult = findLastIndex(arr, (findLastArrData) => { return findLastArrData.Tranking === val.Tranking }) !== idx;
        let firstResult = arr.findIndex((findIndexArrData) => { return findIndexArrData.Tranking === val.Tranking }) === idx;
        if (val.Tranking === "失格") {
            return false;
        }
        return lastResult && firstResult;
    });

    //---------------------------------
    //順位タイのグループリスト作成
    let tieList = [];
    duplicateList.forEach((duplicate, index) => {
        let tmpSortAllUserScoreList = sortAllUserScoreList.filter((sortAllUserScore) => {
            return sortAllUserScore.Tranking === duplicate.Tranking;
        });
        tieList.push(tmpSortAllUserScoreList);

    });

    let maxHole = tournamentData.number_holes;
    //順位タイがいた場合各ホールのスコアを後ろから見ていき成績が良かった方順で決定するが、全く同じがった場合は、組の番号で順位を決める組も同じなら、組内の番号で決める
    tieList.forEach((userDataList) => {
        //順位タイ分ランキングを検査する
        let ranking = userDataList[0].ranking;
        userDataList.forEach(() => {
            ranking = calculateRankingTie(userDataList, maxHole, ranking, userDataList);
        })
    })

    //タイ用のデータにランキングが入っているので本番用のランキングに入れる
    sortAllUserScoreList.forEach((sortAllUserScore) => {
        if (sortAllUserScore.tieData !== undefined) {
            sortAllUserScore.ranking = sortAllUserScore.tieData.ranking;
        }
    })

    //ランキングでソートする
    sortAllUserScoreList = sortArray(sortAllUserScoreList, "ranking", undefined, "asc");
    //---------------------------------------

    //元の配列に順位計算した値を入れる
    allUserScoreList.forEach((userScore) => {
        sortAllUserScoreList.forEach((sortUserScore) => {
            if (userScore.uid === sortUserScore.uid) {
                userScore.ranking = sortUserScore.ranking;
                //賞金設定
                switch (userScore.ranking) {
                    //1位
                    case 1:
                        userScore.prizeMoney = tournamentData.first_place;
                        break;
                    //2位
                    case 2:
                        userScore.prizeMoney = tournamentData.second_place;
                        break;
                    //3位
                    case 3:
                        userScore.prizeMoney = tournamentData.third_place;
                        break;
                    //4位
                    case 4:
                        userScore.prizeMoney = tournamentData.fourth_place;
                        break;
                    //5位
                    case 5:
                        userScore.prizeMoney = tournamentData.fifth_place;
                        break;
                    default:
                        break;
                }

                //6位-10位
                if (userScore.ranking >= 6 && userScore.ranking <= 10) {
                    userScore.prizeMoney = tournamentData.sixth_tenth;
                }
                //11位-60位
                if (userScore.ranking >= 11 && userScore.ranking <= 60) {
                    userScore.prizeMoney = tournamentData.eleventh_sixtieth;
                }
                //61位以降
                if (userScore.ranking >= 61) {
                    userScore.prizeMoney = 0;
                }

                userScore.Tranking = sortUserScore.Tranking;
                userScore.totalUserAtBat = sortUserScore.totalUserAtBat;
                //一度だけバックアップ
                if (userScore.backTotalUserAtBat === undefined) {
                    userScore.backTotalUserAtBat = sortUserScore.totalUserAtBat;
                }
            }
        });
        //失格だったら、ランキングの所を失格にする
        if (userScore.eliminationFlg === 1) {
            userScore.ranking = 9999;
            userScore.Tranking = 9999;

        }
    });
    //元の配列を順位で並び替える
    allUserScoreList = sortArray(allUserScoreList, "ranking", undefined, "asc");

    return allUserScoreList;
}

/**
 * 順位タイのランキング計算
 * @param {*} userDataList 
 * @param {*} holeNb 
 * @param {*} ranking 
 * @returns 
 */
function calculateRankingTie(userDataList, holeNb, ranking) {

    //比較データの作成
    let cmpTieDataList = userDataList.map((userData) => {

        let user_at_bat = userData.score_data_list[holeNb - 1].user_at_bat;
        let groupNb_inGroupNb = userData.entry_user.groupNb * 10 + userData.entry_user.inGroupNb;
        let decision = undefined;
        let ranking = -1;
        //決定されたユーザーか？
        if (userData.tieData !== undefined) {
            decision = userData.tieData.decision;
            ranking = userData.tieData.ranking;
        }
        //ソート用にtieDataを追加
        userData.tieData = { user_at_bat: user_at_bat, uid: userData.uid, ranking: ranking, groupNb_inGroupNb: groupNb_inGroupNb, decision: decision }
        return userData.tieData;
    });


    //決定されたユーザーは除外
    cmpTieDataList = cmpTieDataList.filter((cmpTieData) => {
        return cmpTieData.decision !== 1;
    })
    //比較対象がいないので終了
    if (cmpTieDataList.length <= 0) {
        return ranking;
    }
    //小さい順に並べる
    cmpTieDataList = sortArray(cmpTieDataList, "user_at_bat", undefined, "asc");
    //１ホール目まで調べ順位が決まらなかった
    if (holeNb <= 1 && cmpTieDataList.length > 1) {
        //グループで並び替える
        cmpTieDataList = sortArray(cmpTieDataList, "groupNb_inGroupNb", undefined, "asc");
        cmpTieDataList.forEach((cmpTieData, index) => {
            cmpTieData.ranking = ranking++;
            //決定したので除外
            cmpTieData.decision = 1;
        })
        //グループで順位を決めたので終わり
        return ranking;
    }

    let user_at_bat = cmpTieDataList[0].user_at_bat;
    let result = cmpTieDataList.filter((cmpTieData, index) => {
        //自分自身は含めない
        if (index === 0) {
            return false;
        }
        return user_at_bat === cmpTieData.user_at_bat;
    })
    //現在調べているスコアと重複があるが
    if (result.length >= 1) {
        //現在調べているユーザー以降に重複があるので重複したユーザーだけで、前のホールをチェック もちろん決定したユーザーは除外
        userDataList = userDataList.filter((userData) => {
            if (userData.tieData.decision === 1) {
                return false;
            }
            return user_at_bat === userData.score_data_list[holeNb - 1].user_at_bat;
        })
    } else {
        //重複が無い場合は順位決定
        cmpTieDataList[0].ranking = ranking;
        cmpTieDataList[0].decision = 1;     //
        ranking++;
        return ranking;
    }

    ranking = calculateRankingTie(userDataList, holeNb - 1, ranking);
    return ranking;
}
/**
 * スコア入力の確認
 * @param {*} allUserScoreList 
 * @param {*} callback 
 */
export async function checkScoreInput(allUserScoreList, callback) {
    //スコア確定チェック
    let tmpAllUserScoreList = checkScoreConfirmation(allUserScoreList);
    //notEntered未入力プロパティーとeliminationFlg失格フラグの確認をする
    tmpAllUserScoreList = tmpAllUserScoreList.filter((tmpAllUserScore) => {
        if (tmpAllUserScore.notEntered === 1) {
            return true;
        }
        return false;
    })
    let result = false;
    //一人でも未入力がいたら
    if (tmpAllUserScoreList.length > 0) {
        result = true;

    }
    if (callback !== null && callback !== undefined) {
        callback(result, "");
    }

    //        console.log(tmpAllUserScoreList);

}
/**
 * 不用なプロパティーを削除
 * @param {*} allUserScoreList 
 * @returns 
 */
export function deleteProperty(allUserScoreList) {
    allUserScoreList.map((userScore) => {
        userScore.score_data_list = userScore.score_data_list.map((score_data) => {
            //不用なプロパティーは削除
            delete score_data.scorer_userFullName;
            return score_data;
        });
        return userScore;
    });
    //なんか違うメモリにしないと、Promiseで復活しるから仕方なく＞＜
    return lodash.cloneDeep(allUserScoreList);
}

/**
 * 全てのスコアデータをデータベースに更新する
 * @param {*} allUserScoreList 
 * @param {*} callback 
 */
export async function updateAllScoreDatatoDatabase(allUserScoreList, tournamentData, callback) {
    try {
        //不用なプロパティーを削除
        allUserScoreList = deleteProperty(allUserScoreList);
        let promise_results = allUserScoreList.map(async (userScore) => {
            return updateScoreDatatoDatabase(userScore.tournamentId, userScore.uid, userScore, (result, status) => {
                if (result === false) {
                    throw new Error("スコア更新に失敗しました=" + status);
                }
            });
        })

        await Promise.all(promise_results);

        let newsTemplate = "";
        //お知らせの読み込み
        await getAnnouncementNewsTemplate(2, async (result, status) => {
            if (result === false) {
                throw new Error("お知らせテンプレートデータの読み込みに失敗しました=" + status);
            }

            newsTemplate = status;

        })

        promise_results = allUserScoreList.map(async (allUserScore) => {

            let title = newsTemplate.news_text.title.replace("TTTT", tournamentData.title);
            let userName = allUserScore.userData.given_name_kanji + " " + allUserScore.userData.family_name_kanji;
            let newsText = newsTemplate.news_text.text.replace("TTTT", tournamentData.title).replace("NNNN", userName).replace("SSSS", allUserScore.userScore).replace("RRRR", allUserScore.ranking).replace("PPPP", allUserScore.prizeMoney);
            //失格の場合はお知らせ発行しない？？
            if (allUserScore.eliminationFlg !== 1) {
                //ニュース発行
                await publishTournamentNewsUsers(allUserScore.uid, title, newsText, 0, (result, status) => {
                    if (result === false) {
                        throw new Error("ニュースの発行に失敗しました=" + status);
                    }
                })
            } else {
                //失格の場合は何か文章が違いう？
            }
            return allUserScore;
        })

        await Promise.all(promise_results);

        //大会を終了させる
        await setFixFlgTournament(allUserScoreList[0].tournamentId, 1, (result, status) => {
            if (result === false) {
                throw new Error("大会の終了に失敗しました=" + status);
            }
        });

        if (callback !== null && callback !== undefined) {
            callback(true, "");
        }


    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}


