import React, { useEffect, useState } from 'react';
import '../../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconPlus } from "../../../Icons";

function CourseEditStep1TeeItem(props) {
    const [teeNama, setTeeNama] = useState();

    useEffect(() => {
        setTeeNama(props.name)
    }, []);

    const onButtonClick = (e) => {

        props.onClickRemoveTee(props.itemKey);
    };

    return (
        <div className="course-single-item">
            <button className="course-single-item__delete" onClick={(e) => { onButtonClick(e) }}>
                <figure className="c-icon c-icon--white c-icon--8 course-single-item__delete-icon">
                    <IconPlus></IconPlus>
                </figure>
            </button>
            <span className="c-subtitle c-mr16" id={teeNama}>{teeNama}</span>
        </div>
    )
}


export default CourseEditStep1TeeItem

