import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom"
import { originalInitialState, CourseContext } from '../CourseProvider'
import CourseHeader from '../CourseHeader'
import lodash from 'lodash';
import ManagementFooter from '../../ManagementFooter'
import CourseAddStep4BasicInfo from './CourseAddStep4BasicInfo'
import CourseAddStep4ColumnTitleContainer from './CourseAddStep4ColumnTitleContainer'
import CourseAddStep3CourseContainer from './CourseAddStep4CourseContainer'
import { saveCourse } from "../../../Database";
import { copyStep3FromStep4Data, makeCourseStep4CmpData } from '../CourseUtility'
import '../../../styles/styles.css';
import Loader from '../../Loader'

//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";

function CourseAddStep4(props) {
    const [value, setValue] = useContext(CourseContext);
    const history = useHistory();
    const [arrayCourseList, setArrayCourseList] = useState([]);
    const [loadingData, setLoadingData] = useState(undefined);

    useEffect(() => {
        copyStep3FromStep4Data(value);
        makeCourseStep4CmpData(value);
        setArrayCourseList([...value.CourseAddStep4.arrayCourseList]);

    }, []);

    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "button_create":
                const saveDataBase = (async () => {
                    await saveCourse({ ...value.CourseAddStep1, ...value.CourseAddStep3 });
                    //初期化する
                    setValue(lodash.cloneDeep(originalInitialState));
                    //ローディング非表示
                    setLoadingData({ loadingFlg: false, title: "ゴルフ場データ保存中..." });

                    history.push("CourseAddCompleted");
                });
                //ローディング表示
                setLoadingData({ loadingFlg: true, title: "ゴルフ場データ保存中..." });
                saveDataBase();
                break;
            case "button_edit":
                history.push("CourseAddStep3");
                break;
            default:
                break;
        }
    };

    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <CourseHeader stepNb={"step4"}></CourseHeader>
            <section className="c-page-section c-mt16">

                <h2 className="c-heading--2 c-mt32">下記内容でゴルフ場を登録します。よろしいですか？</h2>
                <CourseAddStep4BasicInfo courseData={value}></CourseAddStep4BasicInfo>
                <h2 className="c-heading--2 c-mt56">ホール名、規定打数、Yard数。</h2>
                {/*動的にコンポーネントが増えていきます*/}
                {
                    arrayCourseList.map(
                        courseAddItem =>
                        (
                            <React.Fragment key={courseAddItem.key + "_React.Fragment"}>
                                <CourseAddStep4ColumnTitleContainer key={courseAddItem.key + "_CourseAddStep4ColumnTitleContainer"}></CourseAddStep4ColumnTitleContainer>
                                <CourseAddStep3CourseContainer key={courseAddItem.key} nowCourse={courseAddItem} />
                            </React.Fragment>
                        )
                    )
                }
                <div id="add-course-button" className="c-button-set c-mt32 c-mb88">
                    <button id="button_create" onClick={(e) => { onButtonClick(e) }}
                        className="button button__primary">作成</button>
                    <button id="button_edit" onClick={(e) => { onButtonClick(e) }}
                        className="button button__secondary">修正</button>
                </div>
            </section>
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default CourseAddStep4

