import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { ScoreContext } from './ScoreProvider'
import ManagementFooter from '../ManagementFooter'
import logo from "../../images/logo.svg";
import { IconScore } from "../Icons";
import '../../styles/styles.css';
import { likeSearchTournament } from "./ScoreUtility";
import '../../styles/loader.css'
import Loader from '../Loader'

function Score_001_2_ScoreContent(props) {
    const history = useHistory();

    const [score_001_1_Data] = useState();                //グローバル参照渡し
    const [loadingData, setLoadingData] = useState(undefined);

    const [holeData, setHoleData] = useState(props.holeData);
    const [keyId] = useState(props.keyId);

    const [inputData, setInputData] = useState(0);

    useEffect(() => {
        //データベースからコースデータの読み込み
        //TODO入力者uidから本来なら入力者をデーターベースから取得してくる
        //holeData.scorer_uid
        holeData.scorer_userFullName = "管理者";
        if (holeData.change_flg !== undefined && holeData.change_flg === true) {
            document.getElementById(keyId).value = holeData.user_at_bat;
        }
        setHoleData(holeData);
    }, [holeData, holeData.scorer_userFullName, keyId]);

    const onChangeInput = (e) => {
        setInputData(Number(e.target.value));

    }
    const onKeyDownInput = (e) => {
        if (e.keyCode === 13) {
            //変更フラグを設定（確認画面でオレンジにしたいから）
            holeData.change_flg = true;
            //数値確認&0以外
            if (inputData !== 0) {  //数値以外だと0になっている
                holeData.user_at_bat = inputData;
                props.inputCallback(e);
                let keyIds = keyId.split("_");
                let nextNb = Number(keyIds[1]) + 1;

                let nextInputId = keyIds[0] + "_" + nextNb;
                let elementById = document.getElementById(nextInputId);
                if (elementById !== null) {
                    elementById.focus();
                }
            } else {
                alert("数値ではありません又は０です");
            }
        }
    }

    return (

        <div className="c-container-horizontal">
            <div className="c-data__slot c-data__slot--white c-data__slot--short-height">{holeData.name}</div>
            <div className="c-data__slot c-data__slot--white c-data__slot--short-height">{holeData.at_bat}</div>
            <div className="c-data__slot c-data__slot--white c-data__slot--short-height">{holeData.user_at_bat}</div>
            <input type="number" id={keyId} className="c-data__slot c-data__slot--edit c-data__slot--short-height" onChange={(e) => { onChangeInput(e) }} onKeyDown={(e) => { onKeyDownInput(e) }} />
            <div className="c-data__slot c-data__slot--white c-data__slot--short-height c-data__slot--144">{holeData.scorer_userFullName}</div>
        </div>

    )
}



export default Score_001_2_ScoreContent

