import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import { MembershipContext } from './MembershipProvider'
import ManagementFooter from '../ManagementFooter'
import logo from "../../images/logo.svg";
import '../../styles/styles.css';
import { likeSearchUser } from "../../Database";
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconMembership } from "../Icons";
import Loader from '../Loader'

function Membership(props) {
    const [value, setValue] = useContext(MembershipContext);
    const history = useHistory();
    const [searchResults, setSearchResults] = useState(value.searchResults);
    const [selectUserData, setselectUserData] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(value.selectedIndex);
    const [loadingData, setLoadingData] = useState(undefined);

    useEffect(() => {
        //検索エラーを一旦非表示
        document.getElementsByTagName("*")["search_error"].style.display = "none";
        // 検索結果セクションを非表示する。
        document.getElementsByTagName("*")["search_result"].style.display = "none";
        // 会員情報セクションを非表示する。
        document.getElementsByTagName("*")["member_info"].style.display = "none";

        // 「このユーザー削除されています」メッセージを非表示する。(このように設定すると、タグの中でstyleを設定しても変化しなくなる)
        //        document.getElementsByTagName("*")["status_message"].style.display = "none";
        // 「出場資格を抹消する」ボタンを非表示。
        //        document.getElementsByTagName("*")["cancel_member"].style.display = "none";
        // 「会員を復帰させる」ボタンを非表示。
        //        document.getElementsByTagName("*")["restore_member"].style.display = "none";
        // 「修正」ボタンを非表示する。       
        //        document.getElementsByTagName("*")["edit_member_info"].style.display = "none";
        //        document.getElementsByTagName("*")["push_textarea"].innerText = value.push_textarea;

        //検索が引き継がれていたら入力させておく
        if (value.search_keyword_text !== null) {
            document.getElementsByTagName("*")["search_keyword_text"].value = value.search_keyword_text;
        }

        //戻ってきた時のキャッシュがある場合は、リストを設定しておく
        if (searchResults !== undefined && searchResults.length > 0) {
            searchResult(searchResults, (result) => {
            });
            //戻ってきた時のリストの初期選択をする
            if (selectedIndex !== 0) {
                let selectTags = document.getElementById("results_list");
                selectTags[selectedIndex].selected = true;
            }
        }


    }, [searchResults, selectedIndex, value.search_keyword_text]);
    useEffect(() => {
        document.getElementsByTagName("*")["user_title"].innerHTML =
            selectUserData.serial_nb +
            " " +
            selectUserData.given_name_kanji +
            " " +
            selectUserData.family_name_kanji +
            " （" +
            selectUserData.given_name_kana +
            " " +
            selectUserData.family_name_kana +
            "） " +
            selectUserData.phone_number;
    }, [selectUserData]);

    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "back_menu":
            case "back_menu_copy":

                history.push("/");
                break;
            case "edit_member_info":
                history.push("MembershipEdit");
                break;
            case "cancel_member":
                history.push("MembershipCancel");
                break;
            case "restore_member":
                history.push("MembershipRestore");
                break;

            default:
                break;
        }
    };
    //検索処理
    const onSearchSubmit = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();

        const { search_keyword_text } = e.target.elements;
        value.search_keyword_text = search_keyword_text.value;

        const search = (async () => {
            let users = await likeSearchUser(search_keyword_text.value, "DESC");
            //ローディング非表示
            setLoadingData({ loadingFlg: false, title: "ユーザーデータを検索中..." });
            //検索結果処理
            searchResult(users, (result,status) => {
                if (result === true) {
                    value.searchResults = status;
                    setSearchResults(status);

                }
            });
        });
        //ローディング表示
        setLoadingData({ loadingFlg: true, title: "ユーザーデータを検索中..." });
        search();

        //        history.push("NotificationConfirm");
        //        console.log(e);

    };

    const onMemberInfoSubmit = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();
        const { results_list } = e.target.elements;

        let selectUserData = searchResults[results_list.selectedIndex - 1];

        setselectUserData(selectUserData);
        value.selectedIndex = results_list.selectedIndex;
        //会員情報変更画面へデータを引継ぎをしたいので
        value.userData = selectUserData;
        let tag = document.getElementById("member_info");

        // 会員情報セクションを表示する。
        tag.style.display = "";
        //スクロールする
        tag.scrollIntoView({
            behavior: "smooth"
        })

        //
        //削除ユーザーの場合情報赤色フレームを表示。
        if (selectUserData.delete_flg === 1) {

            document.getElementsByTagName("*")["member_status"].className = "search-member-info--deleted c-container-vertical search-member-info c-mb88";
        } else {
            document.getElementsByTagName("*")["member_status"].className = "c-container-vertical search-member-info c-mb88";

        }

    }

    /**
     * 検索結果の処理
     * @param {*} users 
     * @param {*} cakkback 
     */
    const searchResult = (users, cakkback) => {

        //検索結果が1件以上あるかどうか
        if (users.length > 0) {
            //シリアルナンバーが無いのはおかしいけどデバック環境で存在しているので削除
            users = users.filter((userData) => {
                return userData.serial_nb.length > 0;
            })
            // 検索結果セクションを表示する。
            document.getElementsByTagName("*")["search_result"].style.display = "";
            //検索エラーを一非表示
            document.getElementsByTagName("*")["search_error"].style.display = "none";

            let number_of_searches = users.length + "件";
            document.getElementsByTagName("*")["number_of_searches"].innerHTML = number_of_searches;
            if (cakkback !== undefined) {
                cakkback(true,users);
            }

        } else {
            // 検索結果セクションを非表示する。
            document.getElementsByTagName("*")["search_result"].style.display = "none";
            //検索エラーを一表示
            document.getElementsByTagName("*")["search_error"].style.display = "";
            if (cakkback !== undefined) {
                cakkback(false,[]);
            }

        }



    }


    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <header className="c-container-vertical c-mt88">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconMembership></IconMembership>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">会員情報管理画面</h1>
                </div>
            </header>
            <main>

                <section className="kanri-container">

                    <form onSubmit={onSearchSubmit}>

                        <div className="c-container-vertical c-mt32">
                            <div className="input input__set input input__set--kanri">
                                <label htmlFor="" className="input input__label">会員ナンバー,名前,生年月日,メールアドレス,電話番号等で検索</label>
                                <input type="text" name="" id="search_keyword_text" minlenght="2" placeholder="検索キーワードを入力してください。" autoComplete="" required className="input input__field" />
                                <span className="input input__validation input__validation--display" id="search_error">ユーザーが見つかりませんでした。キーワードを変えて検索してください。</span>
                            </div>
                        </div>
                        <div className="c-button-set">
                            <button id="search_button" className="button button__primary" type="submit">検索</button>
                            <button id="back_menu" className="button button__secondary" onClick={(e) => { onButtonClick(e) }}>戻る</button>
                        </div>

                    </form>

                </section>

                <section id="search_result" className="c-page-section c-page-section--laurel-green c-top-triangle--gunmetal c-mt56">

                    <article className="kanri-container">

                        <div className="c-container-horizontal c-container-horizontal--center c-mt72">
                            <h2 className="c-heading--2 c-heading--center">検索結果</h2>
                            <span className="c-tag c-tag--gunmetal c-heading--2 c-radius--16 c-ml8" id="number_of_searches">３件</span>
                        </div>

                        <div className="c-container-vertical">

                            <form onSubmit={onMemberInfoSubmit}>

                                <div className="input input__set input__set--kanri">
                                    <label htmlFor="" className="input input__label">リストから検索結果を選択してください。</label>
                                    <select name="" id="results_list" required className="input input__field">
                                        <option value=""> - - -</option>
                                        {searchResults.map(d => <option key={d.serial_nb} value={d.serial_nb}>{d.serial_nb} {d.given_name_kanji} {d.family_name_kanji}（{d.given_name_kana} {d.family_name_kana}）{d.phone_number} {d.delete_flg === 1 ? "削除" : ""}</option>)}
                                    </select>
                                </div>

                                <div className="c-button-set c-mb56">
                                    <button id="show_member_info" className="button button__secondary" type="submit">情報を見る</button>
                                </div>

                            </form>

                        </div>

                    </article>

                </section>

                {/*</section><!-- 会員情報表示セクション -->*/}
                <section id="member_info" className="c-page-section c-top-triangle--laurel-green">

                    <article className="kanri-container c-mt72">

                        <p className="c-paragraph">選択されたユーザーの個人情報</p>
                        <h2 className="c-heading--1 c-mt8" id="user_title">p0012345 山田太郎 080-0000-0001</h2>


                        <div id="member_status" className="c-container-vertical search-member-info c-mb88">

                            {/*<!-- 削除されている会員場合は表示されています。 -->*/}
                            <div id="status_message" className="search-member-info__message-container" style={{ display: selectUserData.delete_flg === 1 ? '' : 'none' }}>
                                <span className="c-heading--2 search-member-info__delete-message"><i>!</i>このユーザー削除されています</span>
                            </div>

                            <div className="confirm confirm__vertical c-margin-reset">
                                <span className="confirm confirm__label">氏名(姓)</span>
                                <p className="confirm confirm__field" id="given_name_kanji">{selectUserData.given_name_kanji}</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">氏名(名)</span>
                                <p className="confirm confirm__field" id="family_name_kanji" >{selectUserData.family_name_kanji}</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">氏名カナ(姓)</span>
                                <p className="confirm confirm__field" id="given_name_kana">{selectUserData.given_name_kana}</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">氏名カナ(名)</span>
                                <p className="confirm confirm__field" id="family_name_kana">{selectUserData.family_name_kana}</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">生年月日</span>
                                <p className="confirm confirm__field" id="birthday">{selectUserData.birthday}</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">年齢</span>
                                <p className="confirm confirm__field" id="age">{selectUserData.age}才</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">性別</span>
                                <p className="confirm confirm__field" id="sex">{selectUserData.sex}</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">電話場号</span>
                                <p className="confirm confirm__field" id="phone_number">{selectUserData.phone_number}</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">メールアドレス</span>
                                <p className="confirm confirm__field" id="email">{selectUserData.email}</p>
                            </div>

                            <div className="kanri-info c-mt16">
                                <h3 className="c-heading--3 c-mt32">エントリー済みの大会</h3>
                                <div className="kanri-info kanri-info__contents c-mt16">
                                    <div className="c-container-horizontal">
                                        <p className="c-paragraph">第1回JCGツアー</p>
                                        <span className="c-subtitle c-tag c-tag--laurel-green">入金済み</span>
                                    </div>
                                    <div className="c-container-horizontal">
                                        <p className="c-paragraph">第4回JCGツアー</p>
                                        <span className="c-subtitle c-tag c-tag--laurel-green">未入金</span>
                                    </div>
                                </div>
                            </div>

                            <div className="confirm confirm__vertical">
                                <h3 className="confirm confirm__title c-heading--3">住所</h3>
                                <div className="confirm confirm__vertical confirm__vertical--no-mt c-mt16">
                                    <span className="confirm confirm__label">郵便番号</span>
                                    <p className="confirm confirm__field confirm__field--medium" id="postal_code">{selectUserData.postal_code}</p>
                                </div>
                                <div className="confirm confirm__horizontal">
                                    <div className="confirm confirm__vertical">
                                        <span className="confirm confirm__label">都道府県</span>
                                        <p className="confirm confirm__field confirm__field--small" id="address_level1">{selectUserData.address_level1}</p>
                                    </div>
                                    <div className="confirm confirm__vertical confirm__vertical--stretch">
                                        <span className="confirm confirm__label">市区町村や郡</span>
                                        <p className="confirm confirm__field confirm__field--stretch" id="address_level2">{selectUserData.address_level2}</p>
                                    </div>
                                </div>
                                <div className="confirm confirm__horizontal">
                                    <div className="confirm confirm__vertical">
                                        <span className="confirm confirm__label">町名</span>
                                        <p className="confirm confirm__field confirm__field--medium" id="address_line1">{selectUserData.address_line1}</p>
                                    </div>
                                    <div className="confirm confirm__vertical confirm__vertical--stretch">
                                        <span className="confirm confirm__label">地番</span>
                                        <p className="confirm confirm__field confirm__field--stretch" id="address_line2">{selectUserData.address_line2}</p>
                                    </div>
                                </div>
                                <div className="confirm confirm__horizontal">
                                    <div className="confirm confirm__vertical confirm__vertical--stretch">
                                        <span className="confirm confirm__label">建物名</span>
                                        <p className="confirm confirm__field" id="address_line3">{selectUserData.address_line3}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">職業</span>
                                <p className="confirm confirm__field" id="occupation">{selectUserData.occupation}</p>
                            </div>

                            {/*<!-- 削除されている会員場合は表示されています。 -->*/}
                            <div id="cancel_reason" className="confirm confirm__vertical" style={{ display: selectUserData.delete_flg === 1 ? '' : 'none' }}>
                                <span className="confirm confirm__label">削除する理由</span>
                                <p className="confirm confirm__field confirm__field--textarea" id="cancel_reason_content">{selectUserData.delete_comment}</p>
                            </div>

                            <div className="c-button-set">
                                <button id="edit_member_info" onClick={(e) => { onButtonClick(e) }} className="button button__primary" style={{ display: selectUserData.delete_flg === 1 ? 'none' : '' }}>修正する</button>
                                <button id="back_menu_copy" onClick={(e) => { onButtonClick(e) }} className="button button__secondary">戻る</button>

                                <button id="cancel_member" onClick={(e) => { onButtonClick(e) }} className="button button__tertiary button__tertiary--red" style={{ display: selectUserData.delete_flg === 1 ? 'none' : '' }}>出場資格を抹消する</button>
                                {/*<!-- 削除されている会員場合は表示されています。 -->*/}
                                <button id="restore_member" onClick={(e) => { onButtonClick(e) }} className="button button__tertiary" style={{ display: selectUserData.delete_flg === 1 ? '' : 'none' }}>会員を復帰させる</button>
                            </div>

                        </div>

                    </article>

                </section>

            </main>
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}


export default Membership

