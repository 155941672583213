import React, { useEffect,useContext } from 'react';
import { useHistory } from "react-router-dom"
import { NotificationContext } from './NotificationProvider'
import ManagementFooter from '../ManagementFooter'
import logo from "../../images/logo.svg";
import '../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconNotification } from "../Icons";

function NotificationConfirm(props) {
    const [value, setValue] = useContext(NotificationContext);
    const history = useHistory();    
    useEffect(() => {
        document.getElementsByTagName("*")["push_textarea"].innerText=value.push_textarea;
    }, []);  

    const onButtonClick= (e)=>{
        switch(e.currentTarget.id){
            case "modifyNotification":
                history.push("Notification");
            break;
            case "sendNotification":
                history.push("NotificationCompleted");
                break;
            default:
            break;
        }

    };    
    return (
        <div className="c-screen c-screen--gunmetal">
            <header className="c-container-vertical c-mt88">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconNotification></IconNotification>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">プッシュ通知確認画面</h1>
                </div>
            </header>
            <main>

                <section className="kanri-container">

                    <div className="c-container-vertical c-mt32">
                        <div className="confirm confirm__vertical">
                            <span className="confirm confirm__label">プッシュ通知内容</span>
                            <p className="confirm confirm__field confirm__field--textarea c-paragraph" id="push_textarea"> ここに書いた文章が表示されます。ここに書いた文章が表示されます。 ここに書いた文章が表示されます。ここに書いた文章が表示されます。 ここに書いた文章が表示されます。 ここに書いた文章が表示されます。ここに書いた文章が表示されます。 ここに書いた文章が表示されます。ここに書いた文章が表示されます。 ここに書いた文章が表示されます。</p>
                        </div>
                    </div>

                    <div className="c-button-set c-mb88">
                        <button id="sendNotification" className="button button__primary" onClick={(e)=>{onButtonClick(e)}}>送信</button>
                        <button id="modifyNotification" className="button button__secondary" onClick={(e)=>{onButtonClick(e)}}>修正する</button>
                    </div>

                </section>

            </main>

            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>

        </div>
    )
}

export default NotificationConfirm

