import firebase from './firebase/Firebase';

/**
 * 画像の保存
 * @param {*} stragePath 
 * @param {*} image 
 * @param {*} callBack 
 */
export async function saveImage(stragePath, image, callBack) {
  const storage = firebase.storage();
  const storageRef = storage.ref(stragePath); //どのフォルダの配下に入れるかを設定
  const imagesRef = storageRef.child("img.png"); //ファイル名

  const upLoadTask = imagesRef.put(image);

  upLoadTask.on(
    "state_changed",
    (snapshot) => {
      console.log("snapshot", snapshot);
      const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log(percent + "% done");
    },
    (error) => {
      console.log("err", error);
      if (callBack !== null) {
        callBack(false, error);
      }
    },
    () => {
      upLoadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        //        console.log("File available at", downloadURL);
        if (callBack !== null) {
          callBack(true, downloadURL);
        }
      });
    }
  );


}


/**
 * Storageからの読み込み
 * @param {*} uid 
 * @param {*} callBack 
 */
export async function loadImageFromFirebase(stragePath, callBack) {
  let storage = firebase.storage();
  let storageRef = storage.ref();
  let path = stragePath + "/img.png";
  let tangRef = storageRef.child(path);
  tangRef.getDownloadURL().then((url) => {
    if (callBack !== null) {
      callBack(true, url);
    }
  }).catch((error) => {
    callBack(false, error);

  });
}


/**
 * ファイルの削除
 * @param {*} uid 
 * @param {*} callBack 
 */
export async function deleteImage(uid, callBack) {
  let storage = firebase.storage();
  let storageRef = storage.ref();
  let storageChildRef = storage.ref().child(uid);
  let delFileCount = 0;
  storageChildRef.listAll().then((result) => {
    delFileCount = result.prefixes.length;
    result.prefixes.forEach((folderRef) => {
      let path = folderRef.fullPath + "/img.png";
      let desertRef = storageRef.child(path);
      desertRef.delete().then(() => {
        delFileCount--;
        if (callBack !== null && delFileCount === 0) {
          callBack(true, "");
        }

      }).catch((error) => {
        if (callBack !== null) {
          callBack(false, error);
        }
      });
    });
  }).catch((error) => {
    if (callBack !== null) {
      callBack(false, error);
    }

  });

}

