import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { TournamentContext } from '../TournamentProvider'
import logo from "../../../images/logo.svg";
import ManagementFooter from '../../ManagementFooter'
import Tournament007Container from '././Tournament004_007_029Container'
import '../../../styles/styles.css';
import { IconTournament } from "../../Icons";
import { onSubmitCommon04_07_29 } from "../TournamentUtility";
import Loader from '../../Loader'

function Tournament_007(props) {
    const [value] = useContext(TournamentContext);
    const history = useHistory();
    const [tournament002Data] = useState(value.tournament002Data);                //グローバル参照渡し
    const [tournament006Data] = useState(value.tournament006Data);                //グローバル参照渡し
    const [imageDataList, setImageDataList] = useState([undefined, undefined, undefined]);
    const [loadingData, setLoadingData] = useState(undefined);
    useEffect(() => {
        //一番上にスクロール
        window.scrollTo(0, 0);
        //大会タイトル
        document.getElementById("tournament_title").innerText = tournament002Data.title;
        //開催する大会のカテゴリ
        document.getElementById("tournament_category").innerText = tournament002Data.category;
        //開催する地域
        document.getElementById("tournament_area").innerText = tournament002Data.area;
        //開催日時と終了日時
        let eventDate = tournament002Data.eventDate.split("-");
        let eventStartTime = tournament002Data.eventStartTime.split("-");
        let eventFinishDate = tournament002Data.eventFinishDate.split("-");
        let eventFinishTime = tournament002Data.eventFinishTime.split("-");
        //開催日時と終了日時
        let tournament_open_close_date_time = eventDate[0] + "年" + eventDate[1] + "月" + eventDate[2] + "日" + eventStartTime[0] + "分~" +
            eventFinishDate[0] + "年" + eventFinishDate[1] + "月" + eventFinishDate[2] + "日" + eventFinishTime[0] + "分";

        document.getElementById("tournament_open_close_date_time").innerText = tournament_open_close_date_time;

        //エントリーの受付開始日と終了日
        let entryStartDate = tournament002Data.entryStartDate.split("-");
        let entryFinisDate = tournament002Data.entryFinisDate.split("-");

        let tournament_entry_start_entry_finish_date = entryStartDate[0] + "年" + entryStartDate[1] + "月" + entryStartDate[2] + "日~" +
            entryFinisDate[0] + "年" + entryFinisDate[1] + "月" + entryFinisDate[2] + "日";

        document.getElementById("tournament_entry_start_entry_finish_date").innerText = tournament_entry_start_entry_finish_date;

        //最大エントリー数
        document.getElementById("tournament_max_entry").innerText = tournament002Data.max_entry;

        //利用するゴルフ場
        document.getElementById("tournament_club").innerText = tournament002Data.temp.useGolfCourseData.club_name;

        //エントリー金額
        document.getElementById("entry_amount").innerText = tournament006Data.entry_amount;
        //グループの試合間隔
        document.getElementById("group_match_interval").innerText = tournament006Data.group_match_interval;


        //画像を設定
        setImageDataList([...tournament006Data.tournament_picture]);
    }, [tournament002Data.area, tournament002Data.category, tournament002Data.entryFinisDate, tournament002Data.entryStartDate, tournament002Data.eventDate, tournament002Data.eventFinishDate, tournament002Data.eventFinishTime, tournament002Data.eventStartTime, tournament002Data.max_entry, tournament002Data.temp.useGolfCourseData.club_name, tournament002Data.title, tournament006Data.entry_amount, tournament006Data.group_match_interval, tournament006Data.tournament_picture]);


    const onSubmit = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();
        //保存するのに時間がかかるので2度クリック帽子
        document.getElementById("button_next").disabled = true;
        document.getElementById("button_back").disabled = true;

        let tournamentData = lodash.cloneDeep({ ...tournament002Data, ...tournament006Data });
        let tournament_picture_file = lodash.cloneDeep(tournamentData.tournament_picture_file);

        const asyncfunc = (async () => {
            onSubmitCommon04_07_29(document, tournamentData, tournament_picture_file, (result, status) => {
                //ローディング非表示
                setLoadingData({ loadingFlg: false, title: "技能テストデータ追加中..." });

                if (result === false) {
                    alert("保存エラー=" + status);
                } else {
                    history.push("Tournament_008");
                }
            });
        });

        //ローディング表示
        setLoadingData({ loadingFlg: true, title: "技能テストデータ追加中..." });
        asyncfunc();
    }

    const renderUseCourseList = () => {
        if (tournament002Data.useCourseList !== undefined) {
            return tournament002Data.useCourseList.map((data, i) =>
                <Tournament007Container key={data.key} courseData={data}>
                </Tournament007Container>)
        } else {
            return null;
        }
    }
    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <header className="c-container-vertical c-mt56">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt32">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconTournament></IconTournament>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">新規技能テスト追加確認画面</h1>
                </div>
            </header>
            <main>

                <div className="c-page-section c-mt32">

                    <form onSubmit={onSubmit}>
                        <div className="kanri-container">

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">大会タイトル</span>
                                <p className="confirm confirm__field" id="tournament_title" >第２回技能テスト関東会場</p>
                            </div>

                            <div className="confirm confirm__vertical c-mt56">
                                <span className="confirm confirm__label">開催する大会のカテゴリ</span>
                                <p className="confirm confirm__field" id="tournament_category" >男子</p>
                            </div>

                            <div className="confirm confirm__vertical c-mt56">
                                <span className="confirm confirm__label">開催する地域</span>
                                <p className="confirm confirm__field" id="tournament_area" >関東</p>
                            </div>

                            <div className="confirm confirm__vertical c-mt56">
                                <span className="confirm confirm__label">開催日時と終了日時</span>
                                <p className="confirm confirm__field" id="tournament_open_close_date_time" >2021年6月13日10時~2021年6月14日19時</p>
                            </div>

                            <div className="confirm confirm__vertical c-mt56">
                                <span className="confirm confirm__label">エントリーの受付開始日と終了日</span>
                                <p className="confirm confirm__field" id="tournament_entry_start_entry_finish_date" >2021年6月13日~2021年6月14日</p>
                            </div>

                            <div className="confirm confirm__vertical c-mt56">
                                <span className="confirm confirm__label">最大エントリー数</span>
                                <p className="confirm confirm__field" id="tournament_max_entry" >20</p>
                            </div>

                            <div className="confirm confirm__vertical c-mt56">
                                <span className="confirm confirm__label">利用するゴルフ場</span>
                                <p className="confirm confirm__field" id="tournament_club" >大多喜カントリークラブ</p>
                            </div>

                            {/*<!-- コースを選択エリア -->*/}
                            <div className="c-container-vertical c-container-vertical--pale-spring-bud c-padding--24 c-radius--16 c-mt56">

                                <h2 className="c-heading--2 c-heading--left">利用コース</h2>
                                <p className="c-paragraph c-paragraph--left">利用するコースを選択してください。</p>
                                <div className="c-container-horizontal c-container-horizontal--space-around c-container-horizontal--wrap">
                                    {renderUseCourseList()}
                                </div>
                            </div>

                            <div className="confirm confirm__vertical c-mt56">
                                <span className="confirm confirm__label">利用目的</span>
                                <p className="confirm confirm__field" id="tournament_purpose" >技能テスト</p>
                            </div>

                            <div className="confirm confirm__vertical c-mt56">
                                <span className="input input__label">エントリー金額</span>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <p className="confirm confirm__field confirm__field--extend" id="entry_amount">1万</p>
                                    <span className="input__suffix c-paragraph">円</span>
                                </div>
                            </div>

                            <div className="confirm confirm__vertical c-mt56">
                                <span className="input input__label">グループの試合間隔</span>
                                <div className="c-container-horizontal c-container-horizontal--center">
                                    <p className="confirm confirm__field confirm__field--extend" id="group_match_interval">5</p>
                                    <span className="input__suffix c-paragraph">分</span>
                                </div>
                            </div>

                            <h2 className="c-heading--2 c-heading--left c-mt56">メイン画像</h2>

                            <div className="c-container-vertical c-mt32">
                                <p className="c-paragraph c-paragraph--left">画像１</p>
                                <figure className="c-drag-and-drop__preview c-mt16">
                                    <img src={imageDataList[0]} id="tournament_picture_01" alt="" />
                                </figure>
                            </div>

                            <div className="c-container-vertical c-mt32">
                                <p className="c-paragraph c-paragraph--left">画像2</p>
                                <figure className="c-drag-and-drop__preview c-mt16">
                                    <img src={imageDataList[1]} id="tournament_picture_02" alt="" />
                                </figure>
                            </div>

                            <div className="c-container-vertical c-mt32">
                                <p className="c-paragraph c-paragraph--left">画像3</p>
                                <figure className="c-drag-and-drop__preview c-mt16">
                                    <img src={imageDataList[2]} id="tournament_picture_03" alt="" />
                                </figure>
                            </div>

                            <h2 className="c-heading--2 c-mt56">上記内容で作成します。よろしいですか?</h2>

                        </div>

                        <div className="c-button-set c-mt32 c-mb88">
                            <button id="button_next" className="button button__primary">作成</button>
                            <button id="button_back" className="button button__secondary" onClick={(e) => { history.push("Tournament_006") }}>戻る</button>
                        </div>
                    </form>

                </div>

            </main>

            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>

        </div>
    )
}


export default Tournament_007

