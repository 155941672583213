import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { TournamentContext, tournamentInitialState } from '../TournamentProvider'
import ManagementFooter from '../../ManagementFooter'
import logo from "../../../images/logo.svg";
import { IconTournament, IconCompletion } from "../../Icons";
import '../../../styles/styles.css';
import { getDate, deleteTournamentUser, getTournamentLikeSearchUser, getTournamentAllUsersInTheArea, finish_allocation_function, finish_individual_addition_function, makeTournamentUser, getTournamentAllUserList, getUserSexFromTournamentSex, saveTournamentAllUserList, tournamentUserGroupingConfirmed } from "../TournamentUtility";
import { sortArray, getUsersNews, setUsersNews, likeSearchUser, initAllUsersTourCount, publishTournamentNewsAllUsersDel, addUserTournament, updateTournamentPart, deleteTestUser, testFunction, getTournamentEntryUser, setTournamentStatus, getUserDataTournament, deleteAllUserTournament, setCopyUser, getAllUsersInTheArea, setTournamentAllocationFlg } from "../../../Database";
import Tournament025GroupNumber from './Tournament025GroupNumber'
import Loader from '../../Loader'

function Tournament_025(props) {
    const [value] = useContext(TournamentContext);
    const history = useHistory();

    const [tournament025Data] = useState(value.tournament025Data);
    const [userDataList, setUserDataList] = useState(value.tournament025Data.userDataList);
    const [unmodifiedUserDataList, setUnmodifiedUserDataList] = useState(value.tournament025Data.unmodifiedUserDataList);
    const [loadingFlg, setLoadingFlg] = useState(false);

    //グループ用のリスト
    const [groupList, setGroupList] = useState([]);

    useEffect(() => {
        const maxGroupMember = 4;        //組の最大人数は4人
        const groupListDataTbl = {
            key: -1,
            groupNb: 1,              //グループ番号
            userDataList: []       //メンバーデータリスト
        }
        if (userDataList === undefined) return;
        if (groupList.length !== 0) return;
        //エントリー終了期間にならないと決定できないようにする
        const date1 = new Date().getTime();
        //現在の時間がエントリー終了日を過ぎているか？
        if (date1 < tournament025Data.entryFinishMillisecond) {
            document.getElementById("button_grouping_confirmed").disabled = true;                //組み分け確定ボタン非活性
        } else {
            document.getElementById("button_grouping_confirmed").disabled = false;                //組み分け確定ボタン活性
        }
        //組み分けフラグ0=未振り分け 1=振り分け完了
        if (tournament025Data.grouping_flg === 1) {
            document.getElementById("button_grouping_confirmed").disabled = true;                //組み分け確定ボタン非活性

        } else {
            document.getElementById("button_grouping_confirmed").disabled = false;                //組み分け確定ボタン活性

        }

        //グループの作成
        let groupLength = Math.floor(userDataList.length / 4) + ((userDataList.length % 4) > 0 ? 1 : 0);
        groupList.length = 0;
        for (let i = 0; i < groupLength; i++) {
            let groupListData = lodash.cloneDeep(groupListDataTbl);
            groupListData.groupNb = i + 1;

            groupListData.key = Math.floor((Math.random() * 1000));
            groupList.push(groupListData);
            for (let j = 0; j < maxGroupMember; j++) {
                let userDataListIndex = i * maxGroupMember + j;
                if (userDataList.length > userDataListIndex) {
                    let userData = userDataList[userDataListIndex];
                    userData.groupNb = i;
                    userData.inGroupNb = j;
                    groupList[i].userDataList.push(userData);
                }
            }
        }

        /*
            最終組の人数確認
            最終組が2人の場合前の組から1名
            最終組が1人の場合前の組から1名、さらにその前の組から1名
            
        */
        //調整回数 1人=2回 2人=1回 3人=0回
        let checkNbPeople = 3 - Math.floor(userDataList.length % 4);
        //1以上3未満なら調整
        if (checkNbPeople >= 1 && checkNbPeople < 3) {
            let groupNbSub1 = Math.floor(userDataList.length / 4) - 1;
            let lastGroupNb = groupNbSub1 + ((userDataList.length % 4) > 0 ? 1 : 0);
            for (let i = 0; i < checkNbPeople; i++) {
                let getGroupNb = groupNbSub1 - i;
                let userData = groupList[getGroupNb].userDataList[maxGroupMember - 1];
                userData.groupNb = lastGroupNb;

                groupList[lastGroupNb].userDataList.push(userData);
                userData.inGroupNb = groupList[lastGroupNb].userDataList.length - 1;
                groupList[getGroupNb].userDataList.splice(3, 1);     //一人減らす
            }
        }
        //グループが0組なら何もしない
        if (groupList.length !== 0) {
            setGroupList([...groupList]);
        }


    }, [groupList, tournament025Data.entryFinishMillisecond, tournament025Data.grouping_flg, userDataList]);

    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "button_grouping_confirmed":
                setLoadingFlg(true)
                const asyncfunc = (async () => {
                    ////削除されたユーザーは最終決定でツアー参加回数を減らすので、全てのユーザー編集データも渡す
                    //データベースに保存
                    await tournamentUserGroupingConfirmed(tournament025Data, userDataList, unmodifiedUserDataList, (result, status) => {
                        setLoadingFlg(false);

                        if (result === false) {
                            alert("データベース更新エラー=" + status);
                        } else {
                            history.push("Tournament_026");
                        }
                    });
                });
                asyncfunc();
                break;
            case "button_back":
                history.push("Tournament_024");
                break;
            default:
                break;
        }
    }


    //--------------------------
    const renderGroupNumber = () => {
        if (groupList !== undefined) {
            return groupList.map((data, i) =>
                <Tournament025GroupNumber key={data.key} groupData={data}>
                </Tournament025GroupNumber>)
        } else {
            return null;
        }
    }

    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingFlg={loadingFlg}></Loader>
            <header className="c-container-vertical c-mt88">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconTournament></IconTournament>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">組み分け確認画面</h1>
                </div>
            </header>
            <main>
                <article>

                    <section className="c-page-section c-mt56">

                        <h2 className="c-heading--1 c-mt56">下記組み分けで確定します。よろしいですか？</h2>
                        <h3 className="c-heading--2 c-mt16"> 2021/08/01第1回JCGツアー[関東]</h3>

                        {/*<!-- 会員データ開始 -->*/}
                        {/*<!-- 「T024」ページからの変更された情報分は「c-data__slot--confirm」のクラス名を追加します。 -->*/}
                        <div className="c-container-vertical c-container-vertical--gunmetal c-padding--8 c-radius--8 c-mt32">

                            {/*<!-- カラムタイトル -->*/}
                            <div className="c-container-horizontal">
                                <div className="c-data__title c-data__title--56 c-data__title--dark-slate-gray">組</div>
                                <div className="c-data__title c-data__title--dark-slate-gray">有無辞</div>
                                <div className="c-data__title c-data__title--232 c-data__title--dark-slate-gray">選手ID</div>
                                <div className="c-data__title c-data__title--232 c-data__title--dark-slate-gray">選手名</div>
                                <div className="c-data__title c-data__title--232 c-data__title--dark-slate-gray">フリガナ</div>
                                <div className="c-data__title c-data__title--144 c-data__title--dark-slate-gray">
                                    <div className="double-slot double-slot--vertical">
                                        <span>ツアー参加回数</span>
                                        <div className="double-slot">
                                            <span className="double-slot double-slot__slot">関東</span>
                                            <span className="double-slot double-slot__slot">関西</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<!-- データテーブルコンテナ -->*/}
                            <div className="c-container-vertical">
                                {/*<!-- 会員グループ一つ -->*/}
                                {renderGroupNumber()}
                            </div>
                            {/*<!-- 会員データ終了。 -->*/}

                        </div>
                        <div className="c-button-set">
                            <p className="c-warning c-paragraph--center c-mt16">
                                組み分けを確定するとユーザーの追加と削除が
                                出来なくなり、組み分けたすべてのユーザーに
                                お知らせが送信されます。
                            </p>

                            <button id="button_grouping_confirmed" onClick={(e) => { onButtonClick(e) }} className="button button__primary">組み分け確定</button>
                            <button id="button_back" onClick={(e) => { onButtonClick(e) }} className="button button__secondary c-mb88">戻る</button>
                        </div>

                    </section>

                </article>

            </main>

            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}


export default Tournament_025

