import React from 'react';
import { useHistory } from "react-router-dom"
import logo from "../../../images/logo.svg";
import '../../../styles/styles.css';
import { IconMembership } from "../../Icons";
import ManagementFooter from '../../ManagementFooter'

function Tournament_026(props) {
    const history = useHistory();

    return (
        <div className="c-screen c-screen--gunmetal">

            <header className="c-container-vertical c-mt88">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconMembership></IconMembership>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">エントリー選手変更完了</h1>
                </div>
            </header>

            <main>

                <div className="c-vertical-container c-mt56">
                    <figure className="c-icon c-icon--acid-green c-icon--88 animation--zoomIn">
                        <icon-completion></icon-completion>
                    </figure>
                </div>

                <p className="c-paragraph c-paragraph--center c-mt32">ツアー内容の変更が完了しました。</p>

                <div className="c-button-set c-mt56">
                    <button id="back_menu"  onClick={(e) => { history.push("/") }} className="button button__primary">メインへ戻る</button>
                </div>

            </main>


            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default Tournament_026

