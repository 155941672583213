import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom"
import ManagementFooter from '../ManagementFooter'
import logo from "../../images/logo.svg";
import '../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconNotification, IconCompletion } from "../Icons";

function NotificationCompleted(props) {
    const history = useHistory();
    useEffect(() => {

    }, []);
    const onButtonClick= (e)=>{
        switch(e.currentTarget.id){
            case "backToMenu":
                history.push("/");
            break;
            default:
            break;
        }
    };

    return (
        <div className="c-screen c-screen--gunmetal">
            <header className="c-container-vertical c-mt88">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconNotification></IconNotification>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">プッシュ通知完了</h1>
                </div>
            </header>

            <main>

                <div className="c-vertical-container c-mt56">
                    <figure className="c-icon c-icon--acid-green c-icon--88 animation--zoomIn">
                        <IconCompletion></IconCompletion>
                    </figure>
                </div>

                <p className="c-paragraph c-paragraph--center c-mt32">プッシュ通知が送信されました。</p>

                <div className="c-button-set c-mt56 c-mb88">
                    <button id="backToMenu" className="button button__primary" onClick={(e)=>{onButtonClick(e)}}>メインページへ</button>
                </div>

            </main>

            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )

}

export default NotificationCompleted

