import React, { useEffect, useState } from "react";

// contextの作成
export const NotificationContext = React.createContext([
    {push_textarea:""
    }
]);
export const NotificationProvider = ({ children }) => {
    const [value, setValue] = useState({
        push_textarea: "",
      });    
    useEffect(() => {
      }, []);    
    return (
        // Contextを使用して認証に必要な情報をコンポーネントツリーに流し込む。
        <NotificationContext.Provider
            value={
                    [value, setValue]
                 }
        >
          {children}
        </NotificationContext.Provider>
      );    
}
