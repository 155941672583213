import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { CourseContext } from '../../CourseProvider'
import '../../../../styles/styles.css';
import { getCourseList } from "../../../../Database";
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconPlus } from "../../../Icons";
import CourseEditStep1BasicInfo from './CourseEditStep1BasicInfo'
import CourseEditStep1Container from './CourseEditStep1Container'
import CourseEditHeader from '../CourseEditHeader'
import { change_CourseDatabase_To_CourseStepData } from '../../CourseUtility'
import { setCourseStep2kWarningFlg } from '../../CourseUtility'
import Loader from '../../../Loader'


function CourseEditStep1(props) {
    const [value, setValue] = useContext(CourseContext);
    const history = useHistory();
    const [courseList, setCourseList] = useState([]);
    const [objectData, setObjectData] = useState({
        stepData: value,
        clubInformationViewFlg: false,
    });
    const [arrayCourseList, setArrayCourseList] = useState([]);
    const [loadingData, setLoadingData] = useState(undefined);

    const getCourse = (async () => {
        let courseList = value.courseList;
        if (courseList === undefined) {
            courseList = await getCourseList();
            //ローディング表示
            setLoadingData({ loadingFlg: false, title: "ゴルフ場データ読み込み中..." });
            //コースリストをグローバル変数に追加
            value.courseList = courseList;
            setValue({ ...value });
        }else{
            //ローディング表示
            setLoadingData({ loadingFlg: false, title: "ゴルフ場データ読み込み中..." });
        }
        setCourseList(courseList);
    });

    useEffect(() => {
        //再描画
        if (objectData.stepData.CourseAddStep2.arrayCourseList.length > 0) {
            setArrayCourseList(objectData.stepData.CourseAddStep2.arrayCourseList);
            objectData.clubInformationViewFlg = true;
            //再描画
            setObjectData({ ...objectData });
        }
    }, [arrayCourseList]);

    useEffect(() => {
        //selectが完成してから選択状態を設定しないと、設定状態にならない
        //※作成中にselectedを設定しても選択状態にならなかった
        if (courseList.length > 1) {
            let selectTags = document.getElementById("results_list");
            courseList.forEach((data, i) => {
                if (value.CourseAddStep1.club_phone === data.club_phone) {
                    selectTags[i + 1].selected = true;
                }
            });
        }
    }, [courseList, value.CourseAddStep1.club_phone]);

    useEffect(() => {

        //ローディング表示
        setLoadingData({ loadingFlg: true, title: "ゴルフ場データ読み込み中..." });
        //データベースからコースデータの読み込み
        getCourse();
    }, []);

    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "button_back":
                clearWarningFlg();
                history.push("Course");
                break;
            case "button_next":
                history.push("CourseEditStep2");
                break;
            default:
                break;
        }
    };

    const onSubmit = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();
        const { results_list, button_next } = e.target.elements;

        if (results_list !== undefined) {
            let selectNb = results_list.selectedIndex - 1;
            let selectCourseData = courseList[selectNb];

            //Step方式のデータに変換
            let stepData = change_CourseDatabase_To_CourseStepData(selectCourseData);
            //描画用に設定
            objectData.stepData = stepData;
            //選択コースデータ
            stepData.selectCourseData = selectCourseData;
            //データベースから読み込んだコースデータ
            stepData.courseList = value.courseList;
            //一度コンテナを０にしないと、内部のデータが残ったままになり、初期化もしてくれないので、一度0にする
            setArrayCourseList([]);
            //消すとスクロースバーが消えて、ぴくってなるから表示したまま
            //        objectData.clubInformationViewFlg=false;

            //グローバルに保存
            setValue(stepData);

        }

        if (button_next !== undefined) {
            let findArrayCourseList = setCourseStep2kWarningFlg(value);

            //再描画
            setArrayCourseList([...arrayCourseList]);

            //エラーが無かったら次へ
            if (findArrayCourseList === undefined) {
                history.push("CourseEditStep2");
            }

        }

    }



    //ゴルフコースの追加
    const onClickAdd = (e) => {
        let arrayCourseList = value.CourseAddStep2.arrayCourseList;
        let copyArrayCourseList = lodash.cloneDeep(arrayCourseList[arrayCourseList.length - 1]);

        copyArrayCourseList.key = "course_id_" + arrayCourseList.length;
        //コース名は初期化しておく
        copyArrayCourseList.courseName = "";
        arrayCourseList.push(copyArrayCourseList);

        setArrayCourseList([...value.CourseAddStep2.arrayCourseList]);
    };

    //ゴルフコースの削除
    const onClickRemove = (courseItemKey) => {
        let newArrayCourseList = value.CourseAddStep2.arrayCourseList.filter(data => {
            return data.key !== courseItemKey;
        });
        value.CourseAddStep2.arrayCourseList = newArrayCourseList;
        setArrayCourseList([...value.CourseAddStep2.arrayCourseList]);
    };

    //ワーニングフラグのクリア
    const clearWarningFlg = () => {
        let arrayCourseList = value.CourseAddStep2.arrayCourseList;

        arrayCourseList.forEach(data => {
            data.validationName = false;
            data.validationHole = false;
            data.validationTee = false;
            data.validationGreen = false;
        });


    }
    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <CourseEditHeader></CourseEditHeader>
            <main>
                <section className="kanri-container">
                    <form onSubmit={onSubmit}>
                        <div className="input input__set input__set--kanri c-mt56">
                            <label htmlFor="" className="input input__label ">変更するゴルフ場を選んでください。</label>
                            <select name="" id="results_list" required className="input input__field">
                                <option value=""> - - -</option>
                                {courseList.map((d, i) => <option key={d.id} value={i}>{d.club_name}</option>)}
                            </select>
                        </div>

                        <div className="c-button-set c-mb88">
                            <button id="button_select" className="button button__primary" type="submit">選択</button>
                            <button id="button_back" onClick={(e) => { onButtonClick(e) }} className="button button__secondary">戻る</button>
                        </div>
                    </form>
                </section>
                {/*選択された後に表示されるエリア*/}
                <section id="club_information" className="c-page-section c-top-triangle--gunmetal" style={{ display: objectData.clubInformationViewFlg ? '' : 'none' }}>
                    <h2 className="c-heading--2 c-mt88">変更する箇所に変更内容を入力してください。</h2>
                    <CourseEditStep1BasicInfo stepData={objectData.stepData}></CourseEditStep1BasicInfo>
                    <div className="kanri-container">
                        <div id="golf_course">
                            {/*動的にコンポーネントが増えていきます*/}
                            {arrayCourseList.map(courseAddItem => (
                                <CourseEditStep1Container
                                    key={courseAddItem.key}
                                    itemKey={courseAddItem.key}
                                    nowCourse={courseAddItem}
                                    onClickRemove={onClickRemove}
                                />
                            ))}

                        </div>
                        <p className="c-paragraph c-mt56">※コースが複数ある場合はすべて追加してください。</p>
                        <div className="c-button-set c-mt32 c-mb88">
                            <button id="add_course_button" className="button button__secondary c-margin-reset" onClick={(e) => { onClickAdd(e) }}>
                                <div className="button__icon button__icon--gunmetal">
                                    <figure className="c-icon c-icon--white c-icon--24">
                                        <IconPlus></IconPlus>
                                    </figure>
                                </div>
                                <span className="c-button-text">他のコースを追加する</span>
                            </button>

                            <form id="form_button_next" onSubmit={onSubmit}>
                                <button id="button_next" className="button button__primary" type="submit">次へ</button>
                                <button id="button_back" onClick={(e) => { onButtonClick(e) }} className="button button__secondary">戻る</button>
                            </form>

                        </div>
                    </div>
                </section>
            </main>

        </div>



    )
}



export default CourseEditStep1

