import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { ScoreContext } from './ScoreProvider'
import ManagementFooter from '../ManagementFooter'
import logo from "../../images/logo.svg";
import { IconScore } from "../Icons";
import '../../styles/styles.css';
import { likeSearchTournament } from "./ScoreUtility";
import '../../styles/loader.css'
import Loader from '../Loader'
function Score_001_1(props) {
    const [value] = useContext(ScoreContext);
    const history = useHistory();

    const [score_001_1_Data] = useState(value.score_001_1_Data);                //グローバル参照渡し
    const [radioInputList] = useState([0, 0, 0]);
    const [tournamentList, setTournamentList] = useState([]);
    const [loadingData, setLoadingData] = useState(undefined);

    useEffect(() => {
        //データベースからコースデータの読み込み

        //選択ボタン非活性
        document.getElementById("button_search_criteria").disabled = true;
        //大会選択部分を非表示
        document.getElementById("search_result").style.display = "none";

    }, []);

    const onClickRadio = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        //        e.preventDefault();
        let radioInputListIndex = 0;
        switch (e.target.id) {
            case "competition_tour":    //ツアー
            case "competition_test":    //技能テスト
            case "competition_event":    //イベント
                radioInputListIndex = 0;
                break;
            case "tour_type_men":       //男子
            case "tour_type_woman":     //女子
            case "tour_type_junior":    //ジュニア
                radioInputListIndex = 1;
                break;

            case "venue_area_tokai":    //関東
            case "venue_area_kansai":   //関西
                radioInputListIndex = 2;

                break;
            default:
                break;
        }
        radioInputList[radioInputListIndex] = 1;


        let tmpRadioInputList = radioInputList.filter((radioInput) => {
            return radioInput === 1;
        })
        if (tmpRadioInputList.length >= 3) {
            //選択ボタン活性
            document.getElementById("button_search_criteria").disabled = false;
            //大会選択部分を表示
            document.getElementById("search_result").style.display = "";

        }




    }


    /**
     * ラジオボタンで選択した情報を元に大会の検索をする
     */
    const onSubmitChoice = (e) => {
        e.preventDefault();
        const {
            competition_tour,
            competition_test,
            competition_event,

            tour_type_men,
            tour_type_woman,
            tour_type_junior,

            venue_area_tokai,
            venue_area_kansai,

        } = e.target.elements;

        let competition_type_elements = Array.from(document.getElementsByName('competition_type'));
        let tour_type_type_elements = Array.from(document.getElementsByName('tour_type'));
        let venue_area_type_elements = Array.from(document.getElementsByName('venue_area'));

        //大会の種類
        let competition_type_element = competition_type_elements.filter((element) => {
            return element.checked === true;
        });

        //ツアーの種類
        let tour_type_type_element = tour_type_type_elements.filter((element) => {
            return element.checked === true;
        });

        let venue_area_type_element = venue_area_type_elements.filter((element) => {
            return element.checked === true;
        });

        let likeSearchList = [];
        likeSearchList.push(competition_type_element[0].labels[0].innerText);
        likeSearchList.push(tour_type_type_element[0].labels[0].innerText);
        likeSearchList.push(venue_area_type_element[0].labels[0].innerText);
        //データベースからコースデータの読み込み
        const getCourse = (async () => {
            await likeSearchTournament(likeSearchList, "DESC", (result, status) => {
                setLoadingData({ loadingFlg: false, title: "検索中・・・" });   //ローデング非表示
                if (result === true) {
                    score_001_1_Data.temp.loadTournamentList = status;
                    setTournamentList(status);
                }else{
                    alert("検索に失敗しました=" + status);
                }
            });

        });

        setLoadingData({ loadingFlg: true, title: "検索中・・・" });   //ローデング表示
        getCourse();

    }

    /**
     * 大会の選択
     * @param {*} e 
     */
    const onSubmitTournament = (e) => {
        e.preventDefault();

        const { tournament_club,

        } = e.target.elements;


        let selectNb = tournament_club.selectedIndex - 1;



        value.score_001_2_Data.tournamentData = tournamentList[selectNb];;
        history.push("Score_001_2");
    }

    const onClickButton = (e) => {
        switch (e.currentTarget.id) {
            case "button_back":
                history.push("/");
                break;
            default:
                break;
        }
    };
    //------------------------------------------------    
    const renderTournamentList = () => {
        if (tournamentList !== undefined) {
            return tournamentList.map((d, i) => {
                let fixText = "";
                if (d.fix_flg !== 0) {
                    fixText = "終了";
                }
                let tournamentData = d.eventDate + " " + d.title + " " + d.category + " " + d.area + " " + fixText;
                return <option key={i} value={i}>{tournamentData}</option>;

            })
        } else {
            return null;
        }
    }
    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <header className="c-container-vertical c-mt56">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt32">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconScore></IconScore>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">スコア管理画面</h1>
                </div>
            </header>
            <main>

                <section className="c-page-section c-mt32">

                    <div className="kanri-container">

                        <form onSubmit={onSubmitChoice} className="search_criteria">{/*<!-- ブラウザーでプレビューのため<form>タグは<div>タグに変更しました。 -->*/}
                            <p className="c-subtitle c-subtitle--left c-mt32">
                                大会の種類を選択してください
                            </p>

                            <div className="c-radio-buttons c-radio-buttons--gunmetal">

                                <input type="radio" id="competition_tour" name="competition_type" required onClick={(e) => { onClickRadio(e) }} />
                                <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="competition_tour">
                                    ツアー
                                </label>

                                <input type="radio" id="competition_test" name="competition_type" required onClick={(e) => { onClickRadio(e) }} />
                                <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="competition_test">
                                    技能テスト
                                </label>

                                <input type="radio" id="competition_event" name="competition_type" required onClick={(e) => { onClickRadio(e) }} />
                                <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="competition_event">
                                    イベント
                                </label>

                            </div>

                            <p className="c-subtitle c-subtitle--left c-mt32">
                                ツアーの種類を選択してください
                            </p>

                            <div className="c-radio-buttons c-radio-buttons--gunmetal">

                                <input type="radio" id="tour_type_men" name="tour_type" required onClick={(e) => { onClickRadio(e) }} />
                                <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="tour_type_men">
                                    男子
                                </label>

                                <input type="radio" id="tour_type_woman" name="tour_type" required onClick={(e) => { onClickRadio(e) }} />
                                <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="tour_type_woman">
                                    女子
                                </label>

                                <input type="radio" id="tour_type_junior" name="tour_type" required onClick={(e) => { onClickRadio(e) }} />
                                <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="tour_type_junior">
                                    ジュニア
                                </label>

                            </div>

                            <p className="c-subtitle c-subtitle--left c-mt32">
                                開催地域を選択してください
                            </p>

                            <div className="c-radio-buttons c-radio-buttons--gunmetal">

                                <input type="radio" id="venue_area_tokai" name="venue_area" required onClick={(e) => { onClickRadio(e) }} />
                                <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="venue_area_tokai">
                                    関東
                                </label>

                                <input type="radio" id="venue_area_kansai" name="venue_area" required onClick={(e) => { onClickRadio(e) }} />
                                <label className="c-radio-buttons__label c-subtitle c-mt16" htmlFor="venue_area_kansai">
                                    関西
                                </label>

                            </div>

                            <div className="c-button-set c-mt32 c-mb88">
                                <button type="submit" id="button_search_criteria" className="button button__primary" disabled>検索</button>
                                <button id="button_back" onClick={(e) => { onClickButton(e) }} className="button button__secondary">戻る</button>

                            </div>
                        </form>

                    </div>

                </section>

                <section className="c-page-section c-page-section--ivory c-top-triangle--white" id="search_result">

                    <div className="kanri-container">
                        <form onSubmit={onSubmitTournament} className="search_criteria">{/*<!-- ブラウザーでプレビューのため<form>タグは<div>タグに変更しました。 -->*/}

                            <div className="input input__set c-mt88">
                                <label htmlFor="tournament_club" className="input input__label">
                                    大会を選択してください
                                </label>
                                <select name="tournament_club" id="tournament_club" required className="input input__field">
                                    <option value="">　ー　ー　ー　</option>
                                    {renderTournamentList()}
                                </select>
                            </div>

                            <div className="c-button-set c-mt32 c-mb88">
                                <button id="button_next" className="button button__primary">選択</button>
                            </div>
                        </form>
                    </div>

                </section>

            </main>

            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}



export default Score_001_1

