import lodash from 'lodash';
import { saveImage, loadImageFromFirebase, deleteImage } from '../../Storage'
import { getAnnouncementNewsTemplate, updateAnnouncementNewsTemplate,setGroupingFlgTournament, addPaymentTournamentUserData, getCourse, addScoreDataTournamentUserData, publishTournamentNewsUsers, updateUsersTourCount, deleteUserTournament, subUsersTourCount, updateAllUserDataTournament, sortArray, getAreaNb, addUsersTourCount, likeSearchUser, getAllUsersInTheArea, publishTournamentNewsAllUsers, setTournamentAllocationFlg, updateTournamentPart, deleteAllUserTournament, updateTournament, saveTournament, getCourseList, getTournamentList, deleteTournament, getTournamentAllUser, addUserTournament } from "../../Database";
import { getMillisecond, getDate, getTime, unifyGolfCourse } from '../Utility'


/**
 * お知らせ文言のタイトルと文言取得 
 * @param {*} templateNb 0=振り分け 1=組み分け 2=スコア
 * @param {*} callback 
 */
export async function getNewsTemplate(templateNb, callback) {
    try {

        await getAnnouncementNewsTemplate(templateNb, (result, status) => {
            if (result === false) {
                throw new Error("お知らせ文言の読み込みに失敗しました=" + status);
            }
            //正規表現でやらないと先頭の一個だけしか変換しないよ
            let newText = status.news_text.text.replace(/<br>/g, "<br>\n");

            status.news_text.text = newText;
            if (callback !== null && callback !== undefined) {
                callback(true, status);
            }
        });
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}

/**
 * お知らせ文言のタイトルと文言を更新 
 * @param {*} templateNb 0=振り分け 1=組み分け 2=スコア
 * @param {*} callback 
 */
 export async function updateNewsTemplate(templateNb, textData,callback) {
    try {
        let newText = textData.news_text.text.replace(/<br>\n/g, "<br>");
        textData.news_text.text=newText;
        await updateAnnouncementNewsTemplate(templateNb, textData,(result, status) => {
            if (result === false) {
                throw new Error("お知らせ文言の更新に失敗しました=" + status);
            }
            if (callback !== null && callback !== undefined) {
                callback(true, "");
            }
        });
    } catch (error) {
        if (callback !== null && callback !== undefined) {
            callback(false, error);
        }
    }
}


