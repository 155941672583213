import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import { MembershipContext } from './MembershipProvider'
import ManagementFooter from '../ManagementFooter'
import logo from "../../images/logo.svg";
import '../../styles/styles.css';
import { updateUser } from "../../Database";
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconMembership } from "../Icons";
import Loader from '../Loader'

function MembershipRestore(props) {
    const [value, setValue] = useContext(MembershipContext);
    const history = useHistory();
    const [userData, setuserData] = useState([]);
    const [loadingData, setLoadingData] = useState(undefined);

    useEffect(() => {
        //useStateは非同期で動いているのですぐに反映されない
        setuserData(value.userData);

    }, []);

    //userDataが変化があった時に呼ばれるuseEffect（初回レンダリングで呼ばれてしまうのでisFirstRender）
    useEffect(() => {
        //初回に呼ばれてしまうので、値が入力された時点で処理する
        let keyList = Object.keys(userData)
        keyList.forEach((value) => {
            //表示されないステータスもあるので、エラーの場合は何もしないようにする
            try {
                document.getElementsByTagName("*")[value].innerHTML = userData[value];
            } catch (e) {
                //表示されないステータスはエラーになるけど分かっているのでOK
                //                    console.log(e);
            }
        });

        document.getElementsByTagName("*")["user_title"].innerHTML = userData.serial_nb + " " + userData.given_name_kanji + " " + userData.family_name_kanji + " （" + userData.given_name_kana + " " + userData.family_name_kana + "）" + " " + userData.phone_number;

    }, [userData]);


    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "restore_member_complete":
                const update = (async () => {
                    let uid = userData.uid;
                    delete userData.uid;
                    userData.delete_flg = 0;                  //削除フラグを0にして復帰させる
                    await updateUser(uid, userData);
                    //ローディング非表示
                    setLoadingData({ loadingFlg: false, title: "ユーザーデータを復帰中..." });
                    history.push("MembershipRestoreCompleted");
                });
                //ローディング表示
                setLoadingData({ loadingFlg: true, title: "ユーザーデータを復帰中..." });
                update();
                break;
            case "back_member_info":
                history.push("Membership");
                break;
            default:
                break;
        }

    };

    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <header className="c-container-vertical c-mt88">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconMembership></IconMembership>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">出場資格復帰画面</h1>
                </div>
            </header>
            <main>

                <section id="member_info" className="c-page-section c-top-triangle--gunmetal c-mt48">

                    <article className="kanri-container c-mt72">

                        <span className="c-paragraph"> 下記ユーザーの出場資格を復帰します。よろしいですか?</span>
                        <h2 className="c-heading--1 c-mt8" id="user_title">p0012345 山田太郎 080-0000-0001</h2>


                        <div id="member_status" className="c-container-vertical search-member-info search-member-info--restore c-mb88">


                            <div className="confirm confirm__vertical c-margin-reset">
                                <span className="confirm confirm__label">氏名(姓)</span>
                                <p className="confirm confirm__field" id="given_name_kanji">山田</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">氏名(名)</span>
                                <p className="confirm confirm__field" id="family_name_kanji" >太郎</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">氏名カナ(姓)</span>
                                <p className="confirm confirm__field" id="given_name_kana">ヤマダ</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">氏名カナ(名)</span>
                                <p className="confirm confirm__field" id="family_name_kana">タロウ</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">生年月日</span>
                                <p className="confirm confirm__field" id="birthday">1987-05-17</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">年齢</span>
                                <p className="confirm confirm__field" id="age">33才</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">性別</span>
                                <p className="confirm confirm__field" id="sex">男性</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">電話場号</span>
                                <p className="confirm confirm__field" id="phone_number">08077778888</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">メールアドレス</span>
                                <p className="confirm confirm__field" id="email">tatoeba@example.com</p>
                            </div>

                            <div className="confirm confirm__vertical">
                                <h3 className="confirm confirm__title c-heading--3">住所</h3>
                                <div className="confirm confirm__vertical confirm__vertical--no-mt c-mt16">
                                    <span className="confirm confirm__label">郵便番号</span>
                                    <p className="confirm confirm__field confirm__field--medium" id="postal_code">2500315</p>
                                </div>
                                <div className="confirm confirm__horizontal">
                                    <div className="confirm confirm__vertical">
                                        <span className="confirm confirm__label">都道府県</span>
                                        <p className="confirm confirm__field confirm__field--small" id="address_level1">神奈川県</p>
                                    </div>
                                    <div className="confirm confirm__vertical confirm__vertical--stretch">
                                        <span className="confirm confirm__label">市区町村や郡</span>
                                        <p className="confirm confirm__field confirm__field--stretch" id="address_level2">足柄下郡箱根町</p>
                                    </div>
                                </div>
                                <div className="confirm confirm__horizontal">
                                    <div className="confirm confirm__vertical">
                                        <span className="confirm confirm__label">町名</span>
                                        <p className="confirm confirm__field confirm__field--medium" id="address_line1">塔之澤</p>
                                    </div>
                                    <div className="confirm confirm__vertical confirm__vertical--stretch">
                                        <span className="confirm confirm__label">地番</span>
                                        <p className="confirm confirm__field confirm__field--stretch" id="address_line2">7-21-5</p>
                                    </div>
                                </div>
                                <div className="confirm confirm__horizontal">
                                    <div className="confirm confirm__vertical confirm__vertical--stretch">
                                        <span className="confirm confirm__label">建物名</span>
                                        <p className="confirm confirm__field" id="address_line3">ゴルデンビルA15</p>
                                    </div>
                                </div>
                            </div>

                            <div className="confirm confirm__vertical">
                                <span className="confirm confirm__label">職業</span>
                                <p className="confirm confirm__field" id="occupation">学生</p>
                            </div>
                            {/*<!--<form onSubmit="">-->*/}

                            <div className="c-button-set">
                                <button id="restore_member_complete" onClick={(e) => { onButtonClick(e) }} className="button button__primary">復帰する</button>
                                <button id="back_member_info" onClick={(e) => { onButtonClick(e) }} className="button button__secondary c-mb32">戻る</button>
                            </div>


                            {/*<!--</form>-->*/}

                        </div>

                    </article>

                </section>

            </main>
            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )
}


export default MembershipRestore

