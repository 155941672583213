import React, {useContext } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { TournamentContext } from '../TournamentProvider'
import logo from "../../../images/logo.svg";
import '../../../styles/styles.css';
import { IconMembership,IconCompletion } from "../../Icons";
import ManagementFooter from '../../ManagementFooter'
import { orijinaltournamentInitialState } from '../TournamentProvider'

function Tournament_005(props) {
    const [,setValue] = useContext(TournamentContext);
    const history = useHistory();
    const onClick = (e) => {
        setValue(lodash.cloneDeep(orijinaltournamentInitialState));
        history.push("Tournament_002");
    }

    return (
        <div className="c-screen c-screen--gunmetal">
            <header className="c-container-vertical c-mt88">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconMembership></IconMembership>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">新規ツアー追加完了</h1>
                </div>
            </header>
            <main>

                <div className="c-vertical-container c-mt56">
                    <figure className="c-icon c-icon--acid-green c-icon--88 animation--zoomIn">
                        <IconCompletion></IconCompletion>
                    </figure>
                </div>

                <p className="c-paragraph c-paragraph--center c-mt32">新規ツアーの作成が完了しました。</p>

                <div className="c-button-set">
                    <button id="back_menu" onClick={(e)=>{history.push("/")}} className="button button__primary">メインへ戻る</button>
                    <button id="add_another_tournament" onClick={(e)=>{onClick(e)}} className="button button__secondary">さらに大会を追加する</button>
                </div>

            </main>

            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>


        </div>
    )
}



export default Tournament_005

