import React, { useEffect } from 'react';
import '../../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";

function CourseEditStep1BasicInfo(props) {

    useEffect(() => {
        //バリデーションエラーを非表示
        document.getElementById("span_club_name").style.display = "none";;
        document.getElementById("span_club_address").style.display = "none";;
        document.getElementById("span_club_phone").style.display = "none";;
        document.getElementById("span_club_website").style.display = "none";;

    }, []);

    useEffect(() => {
        if(props.stepData.CourseAddStep1!==undefined){
            document.getElementsByTagName("*")["club_name"].value = props.stepData.CourseAddStep1.club_name;
            document.getElementsByTagName("*")["club_address"].value = props.stepData.CourseAddStep1.club_address;
            document.getElementsByTagName("*")["club_phone"].value = props.stepData.CourseAddStep1.club_phone;
            document.getElementsByTagName("*")["club_website"].value = props.stepData.CourseAddStep1.club_website;
        }
    }, [props]);

    const onChangeInput = (e) => {
        let tag = document.getElementById("span_" + e.target.id);
        if (document.getElementsByTagName("*")[e.target.id].value.length <= 0) {
            tag.style.display = "";
        } else {
            tag.style.display = "none";

        }
        props.stepData.CourseAddStep1[e.target.id] = e.target.value;
    };

    return (
        <div className="kanri-container">
            <div className="input input__set input__set--kanri">
                <label htmlFor="" className="input input__label">ゴルフ場名</label>
                <input type="text" name="club_name" id="club_name" minlenght="2" placeholder="ゴルフ場名を入力してください" autoComplete="" required className="input input__field" onChange={(e) => { onChangeInput(e) }} form="form_button_next" />
                <span className="input input__validation input__validation--display" id="span_club_name">ゴルフ場名を入力してください</span>
            </div>

            <div className="input input__set input__set--kanri">
                <label htmlFor="" className="input input__label">住所</label>
                <input type="text" name="club_address" id="club_address" minlenght="2" placeholder="住所を入力してください。" autoComplete="" required className="input input__field" onChange={(e) => { onChangeInput(e) }} form="form_button_next" />
                <span className="input input__validation input__validation--display" id="span_club_address">住所を入力してください。</span>
            </div>

            <div className="input input__set input__set--kanri">
                <label htmlFor="" className="input input__label">電話番号</label>
                <input type="tel" name="club_phone" id="club_phone" pattern="[0-9]{3,11}" autoComplete="tel" placeholder="電話番号をいれてください。" required className="input input__field" onChange={(e) => { onChangeInput(e) }} form="form_button_next" />
                <span className="input input__validation input__validation--display" id="span_club_phone">電話番号をいれてください。</span>
            </div>

            <div className="input input__set input__set--kanri">
                <label htmlFor="" className="input input__label">公式サイト</label>
                <input type="text" name="club_website" id="club_website" minlenght="2" placeholder="公式サイトのURLをいれてください。" autoComplete="" required className="input input__field" onChange={(e) => { onChangeInput(e) }} form="form_button_next" />
                <span className="input input__validation input__validation--display" id="span_club_website">公式サイトのURLをいれてください。</span>
            </div>
        </div >
    )
}



export default CourseEditStep1BasicInfo

