import React, { useEffect,useState } from 'react';
import '../../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";

function CourseEditStep3BasicInfo(props) {
    const [courseData, setCourseData] = useState(props.courseData);
    
    useEffect(() => {
//        console.log(value.CourseAddStep1.club_name);
        
    }, [courseData]);    
    return (
        <div className="kanri-container">

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">ゴルフ場名</span>
            <p className="confirm confirm__field" id="club_name">{courseData.CourseAddStep1.club_name}</p>
          </div>

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">住所</span>
            <p className="confirm confirm__field" id="club_address">{courseData.CourseAddStep1.club_address}</p>
          </div>

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">電話番号</span>
            <p className="confirm confirm__field" id="club_phone">{courseData.CourseAddStep1.club_phone}</p>
          </div>

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">公式サイト</span>
            <p className="confirm confirm__field" id="club_website">{courseData.CourseAddStep1.club_website}</p>
          </div>

        </div>
    )
}



export default CourseEditStep3BasicInfo

