import React, { useEffect, useState } from 'react';
import '../../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";

function CourseEditStep2GreenAndYardComponent(props) {
    const [greenData, setGreenData] = useState(props.nowGreenData);
    useEffect(() => {

    }, []);

    const onChangeInput = (e) => {
        greenData.yard = e.target.value;
    }
    return (

        <div className="c-container-horizontal">    {/*<!-- 「グリーン名」と「YARD」内容グルプ -->*/}

            {/*<!-- 「グリーン名」内容 -->*/}
            <div className="game-data__column game-data__column--144">
                <span className="game-data__slot">{greenData.name}</span>
            </div>

            {/*<!-- 「YARD」内容 -->*/}
            <div className="game-data__column">
                <input type="number" className="game-data__slot game-data__slot--edit" id="yard_count" defaultValue={greenData.yard} onChange={(e) => { onChangeInput(e) }} />
            </div>

        </div>
    )
}

export default CourseEditStep2GreenAndYardComponent

