import React, { useEffect  } from 'react';

function CourseEditStep3ColumnTitleContainer(props) {
    useEffect(() => {

    }, []);

    return (
        <div className="c-container-horizontal">    {/*<!-- カラムタイトルコンテナ -->*/}
            <span className="game-data__title game-data__title--first">コース名</span>
            <span className="game-data__title">ホール名</span>
            <span className="game-data__title">規定打数</span>
            <span className="game-data__title game-data__title--144">ティー位置名</span>
            <span className="game-data__title game-data__title--144">グリーン名</span>
            <span className="game-data__title">YARD</span>
        </div>
    )
}



export default CourseEditStep3ColumnTitleContainer

