import React, { useEffect,useContext,useState } from 'react';
import { useHistory } from "react-router-dom"
import { CourseContext } from '../CourseProvider'
import CourseHeader from '../CourseHeader'
import ManagementFooter from '../../ManagementFooter'
import logo from "../../../images/logo.svg";
import '../../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconCourse, IconGolfBall } from "../../Icons";

function CourseAddStep4BasicInfo(props) {
    const [value, setValue] = useContext(CourseContext);
    const history = useHistory();
    const [courseData, setCourseData] = useState(props.courseData);
    
    useEffect(() => {
//        console.log(value.CourseAddStep1.club_name);
        
    }, [courseData]);    
    return (
        <div className="kanri-container">

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">ゴルフ場名</span>
            <p className="confirm confirm__field" id="club_name">{courseData.CourseAddStep1.club_name}</p>
          </div>

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">住所</span>
            <p className="confirm confirm__field" id="club_address">{courseData.CourseAddStep1.club_address}</p>
          </div>

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">電話番号</span>
            <p className="confirm confirm__field" id="club_phone">{courseData.CourseAddStep1.club_phone}</p>
          </div>

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">公式サイト</span>
            <p className="confirm confirm__field" id="club_website">{courseData.CourseAddStep1.club_website}</p>
          </div>

        </div>
    )
}



export default CourseAddStep4BasicInfo

