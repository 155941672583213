import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import { AnnouncementContext } from './AnnouncementProvider';
import ManagementFooter from '../ManagementFooter';
import logo from "../../images/logo.svg";
import '../../styles/styles.css';
//import { IconNotification, IconAnnouncement, IconMembership, IconCourse, IconScore, IconTournament, IconCompletion, IconPlus, IconEdit, IconPencil, IconGolfBall, IconDelete, IconPlayer } from "../Icons";
import { IconAnnouncement } from "../Icons";

function Announcement(props) {
    const [value, setValue] = useContext(AnnouncementContext);
    const [inputTitle] = useState(value.input_title);
    const [sendNotification] = useState(value.send_notification);

    const history = useHistory();
    useEffect(() => {
        document.getElementsByTagName("*")["input_title"].value = inputTitle;
        document.getElementsByTagName("*")["send_notification"].innerText = sendNotification;
    }, [inputTitle, sendNotification]);

    const handleSubmit = (e) => {
        const { input_title, send_notification } = e.target.elements;
        setValue({ input_title: input_title.value, send_notification: send_notification.value });
        history.push({ pathname: "AnnouncementConfirm", state: { input_title: input_title.value, send_notification: send_notification.value } });
        console.log(e);
    };

    const onButtonClick = (e) => {
        switch (e.currentTarget.id) {
            case "backToMenu":
                history.push("/");
                break;
            default:
                break;
        }
    };
    return (
        <div className="c-screen c-screen--gunmetal">
            <header className="c-container-vertical c-mt88">

                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>

                <div className="kanri-title c-mt56">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconAnnouncement></IconAnnouncement>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">お知らせ送信画面</h1>
                </div>

            </header>
            <main>

                <section className="kanri-container">

                    <div className="c-container-vertical c-mt32">
                        <div className="input input__set input__set--kanri">
                            <label htmlFor="" className="input input__label">お知らせを送信するユーザー属性を選んでください。</label>
                            <select name="" id="" required className="input input__field">
                                <option value="">選択してください</option>
                                <option value="全ユーザー">全ユーザー</option>
                                <option value="次回技能テスト参加者">次回技能テスト参加者</option>
                                <option value="直近の技能テスト合格者">直近の技能テスト合格者</option>
                                <option value="直近の技能テスト不合格者">直近の技能テスト不合格者</option>
                                <option value="ツアー選手">ツアー選手</option>
                                <option value="2021/08/02 第1回JCGツアー[千葉会場] 参加者">2021/08/02 第1回JCGツアー[千葉会場] 参加者</option>
                                <option value="2021/08/02 第1回JCGツアー[東京会場] 参加者">2021/08/02 第1回JCGツアー[東京会場] 参加者</option>
                                <option value="2021/08/02 第1回JCGツアー[大阪会場] 参加者">2021/08/02 第1回JCGツアー[大阪会場] 参加者</option>
                                <option value="2021/08/02 第1回JCGツアー[兵庫会場] 参加者">2021/08/02 第1回JCGツアー[兵庫会場] 参加者</option>
                            </select>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} >
                        <div className="c-container-vertical c-mt16">
                            <div className="input input__set input__set--kanri">
                                <label htmlFor="" className="input input__label">お知らせのタイトル</label>
                                <input type="text" name="" id="input_title" placeholder="お知らせのタイトルを入力してください" required className="input input__field c-paragraph" />
                            </div>
                        </div>
                        <div className="c-container-vertical c-mt16">
                            <div className="input input__set input__set--kanri">
                                <label htmlFor="" className="input input__label">お知らせ送信内容</label>
                                <textarea name="" id="send_notification" placeholder="ここにプッシュ通知で表示する文章を入力してください。" required className="input input__field input__field--textarea c-paragraph"></textarea>
                            </div>
                        </div>
                        <div className="c-button-set">
                            <button id="sendAnnouncement" className="button button__primary" type="submit">次へ</button>
                            <button id="backToMenu" className="button button__secondary" onClick={(e) => { onButtonClick(e) }}>戻る</button>
                        </div>
                    </form>

                </section>

                <section className="c-page-section c-page-section--laurel-green c-mt56">

                    <h2 className="c-heading--3 c-mt32">送信履歴</h2>

                    <article className="c-container-vertical c-balanced-width c-mt32">

                        <div className="c-container-vertical c-container-vertical--pale-spring-bud c-mb32 c-prl--24 c-radius--16">
                            <div className="c-container-horizontal c-mt8">
                                <span className="c-subtitle c-subtitle--bold c-pr--24">2021/05/11</span>
                                <span className="c-tag c-tag--full c-subtitle c-prl--24">全ユーザー</span>
                            </div>
                            <h1 className="c-heading--3 c-mt16">お知らせのタイトルです。</h1>
                            <p className="c-paragraph c-paragraph--dark-slate-gray c-mt8 c-mb16">ここに書いた文章が表示されます。</p>
                        </div>

                        <div className="c-container-vertical c-container-vertical--pale-spring-bud c-mb32 c-prl--24 c-radius--16">
                            <div className="c-container-horizontal c-mt8">
                                <span className="c-subtitle c-subtitle--bold c-pr--24">2021/05/05</span>
                                <span className="c-tag c-tag--full c-subtitle c-prl--24">次回技能テスト参加者</span>
                            </div>
                            <h1 className="c-heading--3 c-mt16">お知らせのタイトルです。</h1>
                            <p className="c-paragraph c-paragraph--dark-slate-gray c-mt8 c-mb16">ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。</p>
                        </div>

                        <div className="c-container-vertical c-container-vertical--pale-spring-bud c-mb32 c-prl--24 c-radius--16">
                            <div className="c-container-horizontal c-mt8">
                                <span className="c-subtitle c-subtitle--bold c-pr--24">2021/05/01</span>
                                <span className="c-tag c-tag--full c-subtitle c-prl--24">2021/08/02第1回JCGツアー[兵庫会場]参加者2</span>
                            </div>
                            <h1 className="c-heading--3 c-mt16">お知らせのタイトルです。</h1>
                            <p className="c-paragraph c-paragraph--dark-slate-gray c-mt8 c-mb16">ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。</p>
                        </div>

                        <div className="c-container-vertical c-container-vertical--pale-spring-bud c-mb32 c-prl--24 c-radius--16">
                            <div className="c-container-horizontal c-mt8">
                                <span className="c-subtitle c-subtitle--bold c-pr--24">2021/04/30</span>
                                <span className="c-tag c-tag--full c-subtitle c-prl--24">2021/08/02 第1回JCGツアー[兵庫会場] 参加者</span>
                            </div>
                            <h1 className="c-heading--3 c-mt16">お知らせのタイトルです。</h1>
                            <p className="c-paragraph c-paragraph--dark-slate-gray c-mt8 c-mb16">ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。ここに書いた文章が表示されます。</p>
                        </div>

                        <div className="c-button-set c-mt16 c-mb88">
                            <button id="" className="button button__tertiary button__tertiary--viewmore c-margin-reset">もっと見る</button>
                        </div>

                    </article>

                </section>

            </main>

            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>
        </div>
    )

}

export default Announcement

