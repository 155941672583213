import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import lodash from 'lodash';
import { TournamentContext } from '../TournamentProvider'
import ManagementFooter from '../../ManagementFooter'
import logo from "../../../images/logo.svg";
import { IconTournament } from "../../Icons";
import '../../../styles/styles.css';
import { getTournamentDataList } from "../TournamentUtility";
import '../../../styles/loader.css'
import Loader from '../../Loader'

function Tournament_009(props) {

    const [value] = useContext(TournamentContext);
    const history = useHistory();

    const [tournament009Data] = useState(value.tournament009Data);                //グローバル参照渡し
    const [tournamentList, setTournamentList] = useState(tournament009Data.temp.loadTournamentList);
    const [loadingData, setLoadingData] = useState(undefined);

    useEffect(() => {
        //データベースからコースデータの読み込み
        const getCourse = (async () => {
            if (tournament009Data.temp.loadTournamentList.length === 0) {
                const loadTournamentList = await getTournamentDataList();
                if (loadTournamentList.length !== 0) {
                    tournament009Data.temp.loadTournamentList = loadTournamentList;
                    setTournamentList(loadTournamentList);

                }
            } else {
                setTournamentList([...tournament009Data.temp.loadTournamentList]);
            }
            setLoadingData({ loadingFlg: false, title: "" });
        });
        setLoadingData({ loadingFlg: true, title: "大会データ読み込み中・・・" });
        getCourse();
    }, [tournament009Data.temp, tournament009Data.temp.loadTournamentList, tournament009Data.temp.loadTournamentList.length]);

    useEffect(() => {
        //データベースが
        if (tournament009Data.tournamentData.usePurpose === undefined) return;
        //一番上にスクロール
        window.scrollTo(0, 0);

        //利用する大会
        let selecteTournament = tournament009Data.temp.loadTournamentList.findIndex((data) => {
            return data.id === tournament009Data.tournamentData.id;
        })

        if (selecteTournament !== -1) { //
            let tournament_club_options = document.getElementById("tournament_club").options;
            if (tournament_club_options.length >= 2) {
                tournament_club_options[selecteTournament + 1].selected = true;
            }
        }

        //どのボタンを有効にするかを設定
        let tag_id = tournament009Data.tournament_category_obj_id;
        document.getElementById(tag_id).checked = true;

    }, [tournament009Data.temp.loadTournamentList, tournament009Data.tournamentData.id, tournament009Data.tournamentData.usePurpose, tournament009Data.tournament_category_obj_id]);



    const onSubmit = (e) => {
        //これをやらないと同じページを呼ばれてしまう
        e.preventDefault();

        const {
            tournament_club,                //選択リスト
            tournament_change_contents,     //大会内容を変更する
            tournament_postpone,            //大会を延期する
            entry_view_participants,        //エントリーしている選手を見る
            tournament_delete,              //大会を削除する
            tournament_decide_reschedule,   //延期中の大会の日程を決める
        } = e.target.elements;

        //カテゴリー
        let tournament_category_obj_list = [tournament_change_contents, tournament_postpone, entry_view_participants, tournament_delete, tournament_decide_reschedule];
        let tournament_category_obj = tournament_category_obj_list.find((data) => {
            return data.checked === true;
        });

        //グローバルに保存しておく
        tournament009Data.tournament_category_obj_id = tournament_category_obj.id;
        //選択されたデータを検索
        tournament009Data.tournamentData = tournament009Data.temp.loadTournamentList.find((data, index) => {
            if (tournament_club[index + 1].selected === true) {
                return lodash.cloneDeep({ ...tournament009Data.temp.loadTournamentList[index] });
            }
            return false;
        })
        switch (tournament_category_obj.id) {
            case "tournament_change_contents":

                let usePurpose = tournament009Data.tournamentData.usePurpose;
                switch (usePurpose) {
                    case "技能テスト":
                        history.push("Tournament_013");
                        break;
                    case "ツアー":
                        value.header_title = "大会内容確認・変更画面";
                        history.push("Tournament_010");
                        break;
                    case "イベント":
                        value.header_title = "イベント内容確認・変更画面";
                        history.push("Tournament_010");
                        break;
                    default:
                        break;

                }
                break;
            //既存大会延期画面
            case "tournament_postpone":
                if (tournament009Data.tournamentData.allocationFlg === 0) {
                    alert("大会にユーザーが割り振られていません");
                } else {
                    history.push("Tournament_016");
                }
                break;
            //エントリー選手閲覧・変更画面
            case "entry_view_participants":
                history.push("Tournament_024");

                break;
            //大会削除確認
            case "tournament_delete":
                history.push("Tournament_022");
                break;
            //延期中の大会日程決定画面
            case "tournament_decide_reschedule":
                if (tournament009Data.tournamentData.postponed_flg === 0) {
                    alert("大会は延期されていません");
                } else {
                    history.push("Tournament_019");
                }
                break;
            default:
                break;
        }


    }

    const onClickButton = (e) => {
    }

    const onClickBackButton = (e) => {
        switch (e.currentTarget.id) {
            case "button_back":
                history.push("Tournament_001");
                break;
            default:
                break;
        }
    };

    //--------------------------
    const renderTournamentList = () => {
        if (tournamentList !== undefined) {
            return tournamentList.map((d, i) => {
                let postponed = "";
                let eventDate = d.eventDate;

                switch (d.postponed_flg) {
                    //通常
                    case 0:
                        break;
                    //延期
                    case 1:
                        postponed="【延期】";
                        break;
                    //開催日未定
                    case 2:
                        postponed="【延期】";
                        eventDate="--------------";
                        break;
                    default:
                        break

                }
                let tournamentData = eventDate + " " + d.title + " " + d.category + " " + d.area + postponed;
                return <option key={i} value={i}>{tournamentData}</option>;

            })
        } else {
            return null;
        }
    }
    return (
        <div className="c-screen c-screen--gunmetal">
            {/*ローディング */}
            <Loader loadingData={loadingData}></Loader>
            <header className="c-container-vertical c-mt56">
                <div className="c-logo c-logo--144">
                    <img src={logo} alt="JCG Management" className="c-logo__source" />
                </div>
                <div className="kanri-title c-mt32">
                    <div className="kanri-title__icon">
                        <figure className="c-icon c-icon--white c-icon--32">
                            <IconTournament></IconTournament>
                        </figure>
                    </div>
                    <h1 className="c-heading--1">既存大会変更・確認画</h1>
                </div>
            </header>

            <main>

                <div className="c-page-section c-mt32">

                    <div className="kanri-container c-mt56">

                        <h2 className="c-heading--2">大会を選択してください。</h2>

                        <form onSubmit={onSubmit}>

                            <div className="input input__set c-mt32">
                                <label htmlFor="tournament_club" className="input input__label">大会</label>
                                <select name="tournament_club" id="tournament_club" required className="input input__field">
                                    <option value="">　ー　ー　ー</option>
                                    {renderTournamentList()
                                    }
                                </select>
                            </div>

                            <h3 className="c-heading--3 c-mt32">実行するアクションを選択してください。</h3>

                            <div className="c-radio-buttons c-radio-buttons--center c-radio-buttons--gunmetal c-mt16">

                                <input type="radio" id="tournament_change_contents" name="tournament_actions" required />
                                <label className="c-radio-buttons__label c-radio-buttons__label--232 c-subtitle c-mt16" htmlFor="tournament_change_contents">大会内容を変更する</label>

                                <input type="radio" id="tournament_postpone" name="tournament_actions" required />
                                <label className="c-radio-buttons__label c-radio-buttons__label--232 c-subtitle c-mt16" htmlFor="tournament_postpone">大会を延期する</label>

                                <input type="radio" id="entry_view_participants" name="tournament_actions" required />
                                <label className="c-radio-buttons__label c-radio-buttons__label--232 c-subtitle c-mt16" htmlFor="entry_view_participants">エントリーしている選手を見る</label>

                                <input type="radio" id="tournament_delete" name="tournament_actions" required />
                                <label className="c-radio-buttons__label c-radio-buttons__label--232 c-subtitle c-mt16" htmlFor="tournament_delete">大会を削除する</label>

                                <input type="radio" id="tournament_decide_reschedule" name="tournament_actions" required />
                                <label className="c-radio-buttons__label c-radio-buttons__label--232 c-subtitle c-mt16" htmlFor="tournament_decide_reschedule">延期中の大会の日程を決める</label>

                            </div>

                            {/*<!-- ラジオボタンの選択されたオプションのよるにurlは変わります。 -->*/}
                            <div className="c-button-set c-mt32 c-mb88">
                                <button type="submit" id="button_next" onClick={(e) => { onClickButton(e) }} className="button button__primary">次へ</button>
                                <button id="button_back" onClick={(e) => { onClickBackButton(e) }} className="button button__secondary">戻る</button>
                            </div>

                        </form>

                    </div>

                </div>

            </main>

            <footer>
                <ManagementFooter></ManagementFooter>
            </footer>


        </div>
    )
}


export default Tournament_009

