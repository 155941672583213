import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions'
import "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENTID

  };
  
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  if (process.env.REACT_APP_FB_NODE_ENV === 'development') {
    firebase.functions().useEmulator("localhost", 5001);
  } 
}
  
export default firebase;